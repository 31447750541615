
import { Fragment } from 'react';
import useToggle from "../hooks/useToggle";

export default function ExpandContainer({ getState, setState }) 
{

  const { isOpen, toggle } = useToggle(getState, setState);

  return <Fragment>
    <div 
      onClick={toggle} 
      className="width-100 height-100 flex-grow-1">
      <div 
        className={`expand-rows up-arrow`} 
        style={{ 
          transform: `rotate(${isOpen ? 0 : 180}deg)`,
          transition: "all 0.25s" }}>
      </div>
    </div>
  </Fragment>
}

