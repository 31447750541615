import { useState, useRef, Fragment } from 'react';
import UILIB from 'components';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { leaseCompanies, fieldTypes } from 'classes/quotesHelpers';
import { fieldWorkflow } from '../functions';
import useUpdateDealEntity from '../hooks/useUpdateDealEntity';
import cloneDeep from 'lodash/cloneDeep';

export default function LeaseDetails(props)
{

  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while saving this record, please reload or contact support';
  const versionError = 'A newer version of this record has already been saved. Please reload the data';
  const exceptions = [400,404,409,500];

  const timerObject = useRef({});

  const [render, setRender] = useState(false);
  const [working, setWorking] = useState(false);

  const { handleUpdateDealEntity } = useUpdateDealEntity(props.ourDeal, setWorking, timerObject);

  function addTitle(title, mandatory) {
    return (
      <td width="200">
        <div className="flex-container row nowrap">
          <div className="flex-item flex-grow-1 start">{title}</div>
          <div className="flex-item end">{Boolean(mandatory) && <span className='text red'>*</span>}</div>
        </div>
      </td>);
  }

  const updateLocalField = async (field, event, timeout) => {

    let localData = cloneDeep(props.ourDeal);

    try {

      switch (field) {
        case fieldTypes.LEASECOMPANYINFO:
          localData = { ...props.ourDeal, [field]: event.target.value };
          break;
        case fieldTypes.OTHERLEASECOMPANY:
          localData = { ...props.ourDeal, [field]: event.target.value };
          break;
        case fieldTypes.COMPANYPAYMENTLENGTH:
          localData = { ...props.ourDeal, [field]: event.target.value };
          break;
        case fieldTypes.LEASERATE:
          const leaseCalculation = ((props.ourDeal.pricing.total / 1000) * event.target.value).toFixed(2);
          localData = { 
            ...props.ourDeal, 
            [field]: event.target.value,
            leasePeriodPayment: leaseCalculation };
          break;
        case fieldTypes.DEALCURRENCY:
          localData = { ...props.ourDeal, [field]: event.target.value };
          break;
        case fieldTypes.RVINCLUDED:
          localData = { ...props.ourDeal, [field]: event.target.value };
          fieldWorkflow({ ...props, dealData: localData });
          break;
        case fieldTypes.RVPERCENT:
          localData = { ...props.ourDeal, [field]: event.target.value };
          break;
        case fieldTypes.REBATEVALUE:
          localData = { ...props.ourDeal, [field]: event.target.value };
          break;
        case fieldTypes.REBAEPERCENT:
          localData = { ...props.ourDeal, [field]: event.target.value };
          break;
        case fieldTypes.ISMEMBER:
          localData = { ...props.ourDeal, [field]: event.target.value };
          break;
        case fieldTypes.CPCLOCATION:
          localData = { ...props.ourDeal, [field]: event.target.value };
          break;
        case fieldTypes.CPCLOT:
          localData = { ...props.ourDeal, [field]: event.target.value };
          break;
        case fieldTypes.SERVICETERM:
          localData = { ...props.ourDeal, [field]: event.target.value };
          break;
        case fieldTypes.MINICOMPORDIRECTBUY:
          localData = { ...props.ourDeal, [field]: event.target.value };
          break;
        case fieldTypes.TENDERORCONTRACTREF:
          localData = { ...props.ourDeal, [field]: event.target.value };
          break;
        default:
            break;
      }

      if(props?.handleCheckErrors) await props.handleCheckErrors(false);
      
      props.setOurDeal({ ...props.ourDeal, ...localData });
      setRender(!render);

      const response = await handleUpdateDealEntity(localData, field, [field], timeout);
      if(exceptions.includes(Number(response.status))) {  
        if(Number(response.status) === 400) toast.error(invalidConfig);
        if(Number(response.status) === 404) toast.error(notFoundError);
        if(Number(response.status) === 409) toast.error(versionError);  
      }

    } catch (error) {
      console.log(error);
      toast.error(exceptionError);
    }
  }

  return <Fragment>
    <UILIB.Paper className="flex-container row height-100">
      {!props.fieldWorkflowObj.hidePanelRight && <div className="width-100">   
        <table width="100%" cellPadding="0" cellSpacing="0" className="subTable">
          <tbody>
            {props.fieldWorkflowObj.leaseCompanyInfo && <tr>
              {addTitle('Lease Company', props.fieldWorkflowObj[fieldTypes.LEASECOMPANYINFO])}
              <td>
                <UILIB.Select 
                  name={fieldTypes.LEASECOMPANYINFO}
                  disabled={!props.isActive || working} 
                  onChange={async (event) => await updateLocalField(fieldTypes.LEASECOMPANYINFO, event, 500)} 
                  value={props.ourDeal.leaseCompanyInfo}
                  errored={props?.formErrors && props.formErrors?.leaseCompanyInfo}
                  data={leaseCompanies} />
              </td>
            </tr>}
            {props.ourDeal.leaseCompanyInfo === 'Other' && <tr>
              {addTitle('Other Lease Company', props.fieldWorkflowObj[fieldTypes.OTHERLEASECOMPANY])}
              <td>
                <UILIB.TextInput 
                  className="textInput input"
                  disabled={!props.isActive || working} 
                  name={fieldTypes.OTHERLEASECOMPANY}
                  value={props.ourDeal.otherLeaseCompany} 
                  errored={props?.formErrors && props.formErrors?.otherLeaseCompany}
                  onChange={async (event) => await updateLocalField(fieldTypes.OTHERLEASECOMPANY, event, 500)}  />
              </td>
            </tr>}
            {props.fieldWorkflowObj.companyPaymentLength && <tr>
              {addTitle('Lease Agreement Length (Months)', props.fieldWorkflowObj[fieldTypes.COMPANYPAYMENTLENGTH])}
              <td>
                <UILIB.TextInput
                  className="textInput input"
                  disabled={!props.isActive || working} 
                  placeholder="0"
                  type="number"
                  name={fieldTypes.COMPANYPAYMENTLENGTH}
                  value={props.ourDeal.companyPaymentLength}
                  onChange={async (event) => await updateLocalField(fieldTypes.COMPANYPAYMENTLENGTH, event, 500)} 
                  errored={props?.formErrors && props.formErrors?.companyPaymentLength} />
              </td>
            </tr>}
            {props.fieldWorkflowObj.leaseRate && <tr>
              {addTitle('Lease Rate', props.fieldWorkflowObj[fieldTypes.LEASERATE])}
              <td>
                <UILIB.TextInput
                  className="textInput input"
                  disabled={!props.isActive || working} 
                  placeholder="0"
                  name={fieldTypes.LEASERATE}
                  type="number"
                  value={props.ourDeal.leaseRate}
                  onChange={async (event) => await updateLocalField(fieldTypes.LEASERATE, event, 500)} 
                  errored={props.formErrors && props.formErrors.leaseRate} />
              </td>
            </tr>}
            {props.fieldWorkflowObj.leasePeriodPayment && <tr>
              {addTitle('Lease Period Payment', false)}
              <td>
                <div className="flex-item start">{props.ourDeal.leasePeriodPayment}</div>
              </td>
            </tr>}
            {props.fieldWorkflowObj.dealCurrency && <tr>
              {addTitle('Sales Invoice Currency', props.fieldWorkflowObj[fieldTypes.DEALCURRENCY])}
              <td>
                <UILIB.Select 
                  name={fieldTypes.DEALCURRENCY}
                  disabled={!props.isActive || working} 
                  onChange={async (event) => await updateLocalField(fieldTypes.DEALCURRENCY, event, 500)} 
                  value={props.ourDeal.dealCurrency}
                  errored={props?.formErrors && props?.formErrors?.dealCurrency}
                  data={[
                      { label: "GBP", value: "GBP" },
                      { label: "Euro", value: "Euro" },
                      { label: "Dollar", value: "Dollar" }]} />
              </td>
            </tr>}
            {props.fieldWorkflowObj.rvIncluded && <tr>
              {addTitle('RV Included', props.fieldWorkflowObj[fieldTypes.RVINCLUDED])}
              <td>
                <UILIB.Select 
                  name={fieldTypes.RVINCLUDED}
                  disabled={!props.isActive || working} 
                  onChange={async (event) => await updateLocalField(fieldTypes.RVINCLUDED, event, 500)} 
                  value={props.ourDeal.rvIncluded}
                  errored={props?.formErrors && props?.formErrors?.rvIncluded}
                  data={[
                      { label: "Please Select", value: "-1" },
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" }]} />
              </td>
            </tr>}
            {props.fieldWorkflowObj.rvIncluded && props.ourDeal.rvIncluded === 'Yes' && <tr>
              {addTitle('RV%', props.fieldWorkflowObj[fieldTypes.RVPERCENT])}
              <td>
                <UILIB.TextInput
                  className="textInput input"
                  disabled={!props.isActive || working} 
                  placeholder="0"
                  name={fieldTypes.RVPERCENT}
                  value={props.ourDeal.rvPercent}
                  onChange={async (event) => await updateLocalField(fieldTypes.RVPERCENT, event, 500)} 
                  type="number"
                  errored={props?.formErrors && props.formErrors?.rvPercent} />
              </td>
            </tr>}
            {props.fieldWorkflowObj.rebateValue && <tr>
              {addTitle('Rebate Value', props.fieldWorkflowObj[fieldTypes.REBATEVALUE])}
              <td>
                <UILIB.TextInput 
                  className="textInput input"
                  disabled={!props.isActive || working} 
                  placeholder="0" 
                  name={fieldTypes.REBATEVALUE}
                  value={props.ourDeal.rebateValue} 
                  onChange={async (event) => await updateLocalField(fieldTypes.REBATEVALUE, event, 500)} 
                  type="number" 
                  errored={props?.formErrors && props?.formErrors?.rebateValue} />
              </td>
            </tr>}
            {props.fieldWorkflowObj.rebatePercent && <tr>
              {addTitle('Rebate Percent', props.fieldWorkflowObj[fieldTypes.REBAEPERCENT])}
              <td>
                <UILIB.TextInput 
                  className="textInput input"
                  disabled={!props.isActive || working} 
                  placeholder="0" 
                  name={fieldTypes.REBAEPERCENT}
                  value={props.ourDeal.rebatePercent} 
                  onChange={async (event) => await updateLocalField(fieldTypes.REBAEPERCENT, event, 500)} 
                  type="number" 
                  errored={props?.formErrors && props?.formErrors?.rebatePercent} />
              </td>
            </tr>}
            {props.fieldWorkflowObj.isMember && <tr>
              {addTitle('Is Member', props.fieldWorkflowObj[fieldTypes.ISMEMBER])}
              <td>
                <UILIB.Select 
                  name={fieldTypes.ISMEMBER}
                  disabled={!props.isActive || working} 
                  onChange={async (event) => await updateLocalField(fieldTypes.ISMEMBER, event, 500)} 
                  value={props.ourDeal.isMember}
                  errored={props?.formErrors && props?.formErrors?.isMember}
                  data={[
                      { label: "Please Select", value: "-1" },
                      { label: "Yes", value: 1 },
                      { label: "No", value: 0 }]} />
              </td>
            </tr>}
            {props.fieldWorkflowObj.cpcLocation && <tr>
              {addTitle('CPC Location', props.fieldWorkflowObj[fieldTypes.CPCLOCATION])}
              <td>
                <UILIB.TextInput 
                  className="textInput input"
                  disabled={!props.isActive || working} 
                  name={fieldTypes.CPCLOCATION}
                  value={props.ourDeal.cpcLocation} 
                  onChange={async (event) => await updateLocalField(fieldTypes.CPCLOCATION, event, 500)} 
                  errored={props?.formErrors && props?.formErrors?.cpcLocation} />
              </td>
            </tr>}
            {props.fieldWorkflowObj.cpcLot && <tr>
              {addTitle('CPC Lot', props.fieldWorkflowObj[fieldTypes.CPCLOT])}
              <td>
                <UILIB.Select 
                  name={fieldTypes.CPCLOT}
                  disabled={!props.isActive || working} 
                  onChange={async (event) => await updateLocalField(fieldTypes.CPCLOT, event, 500)} 
                  value={props.ourDeal.cpcLot} 
                  errored={props?.formErrors && props?.formErrors?.cpcLot} 
                  data={[
                    { label: "Please Select", value: -1 },
                    { label: "Lot 1", value: 1 },
                    { label: "Lot 2", value: 2 }]}/>
              </td>
            </tr>}
            {props.fieldWorkflowObj.serviceTerm && <tr>
              {addTitle('Service Term', props.fieldWorkflowObj[fieldTypes.SERVICETERM])}
              <td>
                <UILIB.TextInput 
                  className="textInput input"
                  disabled={!props.isActive || working} 
                  placeholder="0" 
                  type="number" 
                  name={fieldTypes.SERVICETERM}
                  value={props.ourDeal.serviceTerm} 
                  onChange={async (event) => await updateLocalField(fieldTypes.SERVICETERM, event, 500)} 
                  errored={props?.formErrors && props?.formErrors?.serviceTerm} />
              </td>
            </tr>}
            {props.fieldWorkflowObj.scottishProcurement && <tr>
              {addTitle('Mini Competition or Direct Buy', props.fieldWorkflowObj[fieldTypes.MINICOMPORDIRECTBUY])}
              <td>
              <UILIB.Select 
                  name={fieldTypes.MINICOMPORDIRECTBUY}
                  disabled={!props.isActive || working} 
                  onChange={async (event) => await updateLocalField(fieldTypes.MINICOMPORDIRECTBUY, event, 500)} 
                  value={props.ourDeal.miniCompOrDirectBuy}
                  errored={props?.formErrors && props?.formErrors?.miniCompOrDirectBuy}
                  data={[
                      { label: "Please Select", value: "-1" },
                      { label: "MC", value: "MC" },
                      { label: "DB", value: "DB" }]} />
              </td>
            </tr>
            }
            {props.fieldWorkflowObj.scottishProcurement && <tr>
              {addTitle(`Contracting Authority's Tender or Contract Ref`, props.fieldWorkflowObj[fieldTypes.TENDERORCONTRACTREF])}
              <td>
                <UILIB.TextInput 
                  className="textInput input"
                  disabled={!props.isActive || working}  
                  name={fieldTypes.TENDERORCONTRACTREF}
                  value={props.ourDeal.tenderOrContractRef} 
                  onChange={async (event) => await updateLocalField(fieldTypes.TENDERORCONTRACTREF, event, 500)} 
                  errored={props?.formErrors && props?.formErrors?.tenderOrContractRef} />
              </td>
            </tr>
            }
          </tbody>
        </table>
      </div>}
    </UILIB.Paper>
  </Fragment>
}