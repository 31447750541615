import { Fragment, useState, useCallback, useEffect } from 'react';
import UILIB from 'components';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Axios from "classes/axios";
import { useDropzone } from 'react-dropzone'
import Link from 'components/Link';
import Modules from '../Modules'

export default function DocumentUpload(props) {   

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});
    const [selectedClass, setSelectedClass] = useState(-1);
    const [classes, setClasses] = useState([{ label: "loading", value: -1 }]);
    const [readyToUpload, setReadyToUpload] = useState();

    const onDrop = useCallback(acceptedFiles => {
        setReadyToUpload(acceptedFiles[0]);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: false });

    useEffect(() => {
        getClasses();
    }, []);

    const getClasses = async () => {
        let classes = await Axios.get(`/documents/classes`);
        setClasses(classes.data);
    }

    const handleClickOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        clearForm();
        setOpen(false);
        setError({});
        refresh();
    }

    const refresh = async () => {
        props.onUpload();
    }

    const handleUpload = async () => {
        if (selectedClass > -1) {
            setLoading(true);
            var formData = new FormData();
            formData.maxDataSize = Infinity;
            formData.append('file', readyToUpload);
            formData.append('class', selectedClass);
            await Axios({
              method: 'post',
              url: `/documents/opportunityDocs/${props.ourDeal.dynamicsOpportunityNumber}/${props.ourDeal.dealID}`,
              data: formData,
              maxContentLength: 100000000,
              maxBodyLength: 1000000000
            }).then(() => {
              handleClose();
            }).catch(() => { 
              setError({ error: true, option: 1, messageText: "There was an error uploading the file" }) 
            })
        } else {
            setError({ error: true, option: 1, messageText: "There was an error uploading the file" });
        }
        setLoading(false);
    }

    const clearForm = () => {
        setReadyToUpload();
        setSelectedClass(-1);
        setError({});
    }

    return <Fragment>
        <div className={"col-xs-12 text-right"}>
            <Modules.ViewMfiles ourDeal={props.ourDeal} />
            <UILIB.Button 
              disabled={!props.isActive || !props?.dataLoaded?.document} 
              className={props.className} 
              onClick={handleClickOpen} value="Upload a Document" />
        </div>
        <Dialog open={open} onClose={handleClose} >
            <DialogTitle>Upload a Document</DialogTitle>
            <DialogContent style={{ minWidth: "400px" }}>
                <p className={"mar-b10"}>Please note: your file will upload directly into M-Files and will display on the document list below a short time later (up to 15 seconds).</p>
                {!readyToUpload &&
                    <div {...getRootProps()} >
                        <input {...getInputProps()} />
                        {
                            isDragActive ?
                                <p>Drop the files here ...</p> :
                                <p style={{ cursor: "pointer" }}>Drag files into this box or click <u>here to select file</u></p>
                        }
                    </div>}
                {readyToUpload &&
                    <div>
                        <b>{readyToUpload.name}</b>
                        <Link style={{ float: "right" }} onClick={() => clearForm()}>Remove</Link>
                        <br />
                        <br />
                        Select Document Type:
                        <UILIB.Select
                            name="Class"
                            value={selectedClass}
                            onChange={async (event) => { setSelectedClass(event.target.value) }}
                            data={classes}
                            errored={error}
                        />
                    </div>}
            </DialogContent>
            <DialogActions className={"mar-b10 mar-r15"}>
                <UILIB.Button onClick={handleClose} disabled={(!props.isActive || loading)} value="Cancel" />
                <UILIB.Button 
                  onClick={handleUpload} 
                  disabled={!(Number(selectedClass) > -1) || (!props.isActive || loading)} 
                  loading={loading}
                  value={"Upload"} 
                />
            </DialogActions>
        </Dialog>
    </Fragment>
}