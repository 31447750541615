import { memo } from 'react';

const DataRowEmpty = memo(({ header = [] }) => {
  return <tr>
    <th 
      style={{ height: '100px' }}
      colSpan={header.length} 
      align="center">
        <div className="flex-container row center align-center height-100">
          {'No Data Found'}
        </div>
    </th>
  </tr>
})
export default DataRowEmpty