import UILIB from 'components';
import { useSelector } from 'react-redux';

export default function Footer() {
    const yearNow = new Date().getFullYear();
    const copyright = `© ${yearNow} Agilico`;
    const releaseVersion = useSelector((state) => state.releaseVersion);
    
    return <div className='flex-container row between footerContainer' id="hubs-footer">
        <div className='flex-item mar-l10 footerChild'>Version No. {releaseVersion}</div>
        <div className='flex-container row footerChild'>  
          <div className='icon-wrench flex-item center mar-r5' />
          <div className='flex-item mar-r20'>
            <a href = "mailto: app.support@agilico.co.uk">
              Raise Support Ticket
            </a>
          </div>
          <div className='icon-bubble-user flex-item center mar-r5' />
          <div className='flex-item'>
            <UILIB.Link href={`https://forms.office.com/r/1V0NPz2K66`} rel='noreferrer' target='_blank'>
              Suggestions
            </UILIB.Link>
          </div>
        </div>
        <div className='flex-item mar-r10 footerChild'>{copyright}</div>
    </div>
}
