import { Fragment } from 'react'
import UILIB from 'components';
import Drawer from '../Drawers';
import { useDispatch } from 'react-redux';
import { setDrawer } from "store/actions";


export default function DeleteOrder(props) 
{

  const dispatch = useDispatch();

  const deleteOrder = () => {
    dispatch(setDrawer({
      open: true,
      content: <Drawer.OrderDeleteDrawer
        ourDeal={props.dealData}
        setOurDeal={props.setDealData}
        history={props.history}
        route={props.route}
        isActive={props.isActive}
        onCancel={() => dispatch(setDrawer({ open: false, content: null }))}
      />
    }));
  }

  return <Fragment>
    <div className="flex-item width-100">
      <UILIB.Button
        disabled={!props.isActive}
        className="red width-100"
        value="DELETE Order"
        title='Deletes the order and opens the original quote'
        onClick={() => deleteOrder()} />
    </div>
  </Fragment>
}