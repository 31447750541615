import { useState, useEffect, useRef } from 'react';
import { yesNoBool, createTextInputTable, createSelectInputTable, createLookupTextInputTable, createDateInput } from 'classes/controls/genericTableControls';
import { createNumberInput } from 'classes/controls/formControls';
import { checkFormErrors } from 'classes/formErrors/formErrors';
import { getDevicePDIFormErrors } from 'classes/formErrors/repository';
import { formatAddress } from 'classes/dataValidation';
import BlockAttachmentsTable from './attachmentsTable';
import { getSerials } from 'classes/helpers';
import { useLocation } from "react-router-dom";
import DealLinkTableCell from '../../shared/dealLink';
import { useSelector } from "react-redux";
import { getEmptyMeters } from "../functions";
import moment from 'moment';
import UILIB from 'components';
import Axios from 'classes/axios';


export default function PdiDraw({ permissions, history, pdiModalData, setPdiModalData, isDisabled, entityData, sync }) {

    const rowVersionMessageProduct = 'A newer version of this product record has already been saved. Please reload the data.';
    const rowVersionMessagePDI = 'A newer version of this PDI record has already been saved. Please reload the data.';
    const rowVersionMessageReading = 'A newer version of a reading record has already been saved. Please reload the data.';
    const missingRecordMessageProduct = 'Unable to find product record in db. Please reload the data.';
    const missingRecordMessagePDI = 'Unable to find PDI record in db. Please reload the data.';
    const missingRecordMessageReading = 'Unable to find a reading record in db. Please reload the data.';
    const unableToSaveErrorMessage = 'Unable to save current record, please contact support.';
    const unableToSaveWarningMessage = 'Unable to save current record, please check below for more information.';


    const location = useLocation();
    const account = useSelector((state) => state.account);
    const completedBy = useRef(null);
    const [errored, setErrored] = useState({ error: false, message: '' });
    const deliveryAddress = pdiModalData.entity.address.find(x => Number(x.id) === Number(pdiModalData.entity.siteAddress));
    const dealLink = DealLinkTableCell(pdiModalData.entity.deal[0], history, location.pathname, permissions.entityEnable, {}, true);
    const entityRaw = useRef({ ...pdiModalData.entity, formattedAddress: (deliveryAddress) ? formatAddress(deliveryAddress) : '' });
    const entity = useRef({ prodID: pdiModalData.entity.prodID, dealId: pdiModalData.entity.dealID, pdiId: pdiModalData.entity.pdi[0].pdiID });
    const engStatus = entityRaw.current.deal[0].engStatus === 6;
    const [entityMeters, setEntityMeters] = useState(getEmptyMeters(pdiModalData.entity.pdi[0].readings));
    const [entityErrors, setEntityErrors] = useState({});
    const [warehouseData, setWarehouseData] = useState([]);
    const [saving, setSaving] = useState(false);
    const [render, setRender] = useState(false);
    const [meterRender, setMeterRender] = useState(false);


    const changeMeterInput = async (ev, context) => {
      setEntityMeters({ 
        ...entityMeters, 
        [context]: { 
          ...entityMeters[context], [ev.target.name]: Number(ev.target.value) } });
      setRender(!render);
    }

    const changeInput = async (value, name, ref) => {
      entity.current = { ...ref, [name]: value };
      if (name in entityErrors) setEntityErrors({ ...entityErrors, [name]: '' });
      setRender(!render);
    }

    const changeSerialInput = async (data, field) => {
      entity.current[field] = data.label;
      setMeterRender(!meterRender);
      setRender(!render);
    }

    const changeCompleted = (value, name, ref) => {
      entity.current = { 
        ...ref, [name]: value, 'pdiCompletedDate': moment().utc(true).format("YYYY-MM-DD HH:mm:ss"), 'pdiCompletedBy': account.azureId };
      setRender(!render);
    }

    const validSubmit = async () => {
      setErrored({ error: false, message: '' });
      const entityResult = await checkFormErrors(getDevicePDIFormErrors(entity.current), setEntityErrors, null, null, entity.current, null);
      if(entityResult.errorCount === 0) return true;
      setErrored({ error: true, message: unableToSaveWarningMessage });
      return false;
    }

    const processEdit = async () => {

      setSaving(true);

      const productEntity = { 
        rowVersion: pdiModalData.entity.rowVersion,
        warehouse: entity.current.warehouseId, 
        specificDeviceLocation: entity.current.specificDeviceLocation 
      };

      const pdiEntity = {  
        rowVersion: pdiModalData.entity.pdi[0].rowVersion,
        serialNo: entity.current.serialNo, 
        additionalLicencesText1: entity.current.additionalLicencesText1, 
        additionalLicencesText2: entity.current.additionalLicencesText2, 
        currentFirmware: entity.current.currentFirmware,
        firmWareUpdatedTo: entity.current.firmWareUpdatedTo,
        macAddress: entity.current.macAddress,
        pdiCompleted: entity.current.pdiCompleted,
        pdiCompletedDate: entity.current.pdiCompletedDate,
        pdiCompletedBy: entity.current.pdiCompletedBy,
        remoteLicenceText: entity.current.remoteLicenceText
      };

      const updatedProduct = await Axios.put(`/entities/product/${pdiModalData.entity.dealID}/${entity.current.prodID}`, { entity: productEntity });
      const updatedPdi = await Axios.put(`/entities/pdi/${pdiModalData.entity.dealID}/${entity.current.pdiId}`, { entity: pdiEntity });
      const updateReadings = await Axios.put(`/entities/workflow/deals/actionCreateUpdateMeters/${entity.current.pdiId}`, entityMeters);

      pdiModalData.entity = { ...pdiModalData.entity, ...updatedProduct.data.result[0] }
      pdiModalData.entity.pdi[0] = { ...pdiModalData.entity.pdi[0], ...updatedPdi.data.result[0] }
      pdiModalData.entity.pdi[0].readings = updateReadings.data.result;

      setEntityMeters(getEmptyMeters(updateReadings.data.result));

      if(updatedProduct.status === 400 || updatedPdi.status === 400 || updateReadings.status === 400) {
        setErrored({ error: true, message: unableToSaveErrorMessage }); 
        return false;
      }

      if(updatedProduct.status !== 200 || updatedPdi.status !== 200 || updateReadings.status !== 200) {
        if (updatedProduct.status === 404) setErrored({ error: true, message: missingRecordMessageProduct });
        if (updatedPdi.status === 404) setErrored({ error: true, message: missingRecordMessagePDI });
        if (updateReadings.status === 404) setErrored({ error: true, message: missingRecordMessageReading });
        if (updatedProduct.status === 409) setErrored({ error: true, message: rowVersionMessageProduct }); 
        if (updatedPdi.status === 409) setErrored({ error: true, message: rowVersionMessagePDI }); 
        if (updateReadings.status === 409) setErrored({ error: true, message: rowVersionMessageReading }); 
        return false;
      }

      return pdiEntity;
    }

    const submitForm = async () => {
        try {

            const valid = await validSubmit();
            if(!valid) throw new Error('form not valid');

            const process = await processEdit();
            if(!process) throw new Error('unable to process request');

            const index = entityData.result.findIndex(x => Number(x.prodID) === Number(entityRaw.current.prodID));
            entityData.result[index] = { ...pdiModalData.entity, ...process };

            setPdiModalData({ ...entityRaw.current, ...process, show: false });
            sync.set(!sync.get);

        }
        catch (err) {
            console.log(err);
            setErrored({ error: true, message: unableToSaveErrorMessage });
        } finally {
          setSaving(false);
        }
    }

    useEffect(() => {
      const actionAsync = async () => {
        const warehouseResult = await Axios.get(`/entities/warehouses`).then(api => api.data.result);
        const warehouseMapped = warehouseResult.map(x => { return { value: x.id, label: x.warehouse } });
        setWarehouseData(warehouseMapped);
      }
      actionAsync();
      // eslint-disable-next-line
    }, [])


    return <div className="genericModalContainer">
        <div className="genericModalContent" style={{ width: "55%", height: "65%", overflowY: 'auto' }}>
          <div className='width-100'>
              <div className="row mar-b15">
                <div className='flex-item flex-grow-1'>
                  <h2>Device PDI</h2>
                </div>
                <div className='flex-container end'>
                  <UILIB.Button className='red mar-r10' value='Close' disabled={saving} onClick={() => { setPdiModalData({ ...pdiModalData, show: false }) }} />
                  <UILIB.Button className='green' disabled={!isDisabled} loading={saving} value={'Save'} onClick={() => submitForm()} />
                </div>
                {errored.error && <div className="flex-container width-100 end">
                  <div className="errored message">{errored.message}</div>
                </div>}
              </div>
              {createTextInputTable('Customer:', 'companyName', false, 800, 200, 600, entityRaw.current.deal[0], entityErrors, entity, changeInput, null)}
              {createTextInputTable('Order #:', 'dealId', false, 800, 200, 600, pdiModalData.entity.dealID, entityErrors, entity, changeInput, dealLink.value)}
              {createTextInputTable('Device Ref:', 'deviceNo', false, 800, 200, 600, entityRaw.current, entityErrors, entity, changeInput, null)}
              {createTextInputTable('Deliver to:', 'formattedAddress', false, 800, 200, 600, entityRaw.current, entityErrors, entity, changeInput, null)}
              {createSelectInputTable('Warehouse:', 'warehouseId', warehouseData, isDisabled, 800, 200, 600, entityRaw.current, entityErrors, entity, changeInput, null)}
              <hr className="mar-t20"/>
              <h4 style={{ padding: 0, marginBottom: 5 }} className='mar-t15'>Device</h4>
              {createTextInputTable('Model:', 'description', false, 800, 200, 600, entityRaw.current, entityErrors, entity, changeInput, null)}
              {createTextInputTable('Location:', 'specificDeviceLocation', isDisabled, 800, 200, 600, entityRaw.current, entityErrors, entity, changeInput, null)}
              {createLookupTextInputTable('Serial #:', 'serialNo', isDisabled, 800, 'No Devices Found', 600, entityRaw.current.pdi[0], entityErrors, entity, getSerials, changeSerialInput)}
              <hr className="mar-t20"/>
              <div className="row mar-b15">
                <div className='col-xs-12 col-sm-12 col-md-12' style={{ padding: 0, margin: 0 }}>
                <h4 style={{ padding: 0, marginBottom: 5 }}>Peripherals List</h4>
                </div>
                <div className='col-xs-12 col-sm-12 col-md-12'>
                  {Boolean(entityRaw.current.attachments.length) && <BlockAttachmentsTable data={entityRaw.current.attachments} />}
                  {Boolean(!entityRaw.current.attachments.length) && 'No attachments added for this device'}
                </div>
              </div>
              <hr className="mar-t20"/>
              <div className="row mar-b15">
                <div className='col-xs-12 col-sm-12 col-md-12' style={{ padding: 0, margin: 0 }}>
                  <h4 className='col-xs-12 col-sm-12 col-md-6' style={{ padding: 0, marginBottom: 5 }}>Meter Readings</h4>
                </div>
                <div className='col-xs-12 col-sm-12 col-md-12'>
                  {createNumberInput(entityMeters.mono.meterName, 'meterReading', !isDisabled, entityMeters.mono.meterReading, (ev) => changeMeterInput(ev, 'mono'), 'width-100', 'flex-grow-1', null, null, 'width-100 fullBorder border-colour-grey-4')}
                  {createNumberInput(entityMeters.colour.meterName, 'meterReading', !isDisabled, entityMeters.colour.meterReading, (ev) => changeMeterInput(ev, 'colour'), 'width-100', 'flex-grow-1', null, null, 'width-100 fullBorder border-colour-grey-4')}
                </div>
              </div>
              <hr className="mar-t20"/>
              <div style={{ padding: 0, marginBottom: 5 }} className='row'><h4>Network Information</h4>{engStatus && <span className="pad-l10 pad-r10 text-small text-red center">{" - Awaiting Network Form"}</span>}</div>
              {createTextInputTable('Mac Address:', 'macAddress', isDisabled, 1000, 200, 600, entityRaw.current.pdi[0], entityErrors, entity, changeInput, null)}
              {createTextInputTable('Current Firmware:', 'currentFirmware', isDisabled, 1000, 200, 600, entityRaw.current.pdi[0], entityErrors, entity, changeInput, null)}
              {createTextInputTable('Firmware updated to:', 'firmWareUpdatedTo', isDisabled, 1000, 200, 600, entityRaw.current.pdi[0], entityErrors, entity, changeInput, null)}
              <h4 style={{ padding: 0, marginBottom: 5 }} className='mar-t15'>Licenses</h4>
              {createTextInputTable('Remote License:', 'remoteLicenceText', isDisabled, 1000, 200, 600, entityRaw.current.pdi[0], entityErrors, entity, changeInput, null)}
              {createTextInputTable('Additional License 1:', 'additionalLicencesText1', isDisabled, 1000, 200, 600, entityRaw.current.pdi[0], entityErrors, entity, changeInput, null)}
              {createTextInputTable('Additional License 2:', 'additionalLicencesText2', isDisabled, 1000, 200, 600, entityRaw.current.pdi[0], entityErrors, entity, changeInput, null)}
              <h4 style={{ padding: 0, marginBottom: 5 }} className='mar-t15'>Checklist</h4>
              {createSelectInputTable('PDI Completed:', 'pdiCompleted', yesNoBool, isDisabled, 1000, 200, 600, entityRaw.current.pdi[0], entityErrors, entity, changeCompleted, null)}
              {completedBy.current && entityRaw.current.pdi[0].pdiCompleted && createDateInput('Completed Date:', 'pdiCompletedDate', false, 1000, 200, 600, entityRaw.current.pdi[0], entityErrors, entity, changeCompleted, null)}
              {completedBy.current && entityRaw.current.pdi[0].pdiCompleted && createTextInputTable('Completed By:', 'displayName', false, 1000, 200, 600, completedBy.current, entityErrors, entity, changeCompleted, null)}
          </div>
        </div>
    </div>
}