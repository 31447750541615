import { useEffect, Fragment, memo, useState } from "react";
import Row from "../dnd/Row";
import Ungrouped from "../dnd/Ungrouped";
import Column from "../dnd/Column";
import useHandleDrop from '../hooks/useHandleDrop';
import useHandleHover from '../hooks/useHandleHover';
import useOrderProducts from '../hooks/useOrderProducts';
import useBuildCellStyle from '../hooks/useBuildCellStyle';
import useFlatternData from '../hooks/useFlatternData';
import { constants } from "../dnd/Constants";

const Products = memo((
{ 
  handleName,
  dndEnabled,
  data, 
  header, 
  onDrop
}) =>
{

  const [layout, setLayout] = useState([]);

  const { handleDrop } = useHandleDrop(layout, setLayout);
  const { handleHover } = useHandleHover(layout, setLayout);
  const { handleCalculateRowType, handleCalculateCellStyle } = useBuildCellStyle(layout, header);
  const { handleOrderProducts } = useOrderProducts(header, data);
  const { handleFlatternData } = useFlatternData();


  useEffect(() => {
    setLayout(
      handleFlatternData(
        handleOrderProducts()));
  }, 
    [
      header, 
      onDrop, 
      handleFlatternData,
      handleOrderProducts,
    ]
  );


  const renderRow = (row, Componenet) => {
    return <Fragment>
      <Componenet
        dndEnabled={dndEnabled}
        handleName={handleName}
        layout={layout}
        onDrop={onDrop}
        key={row.id}
        data={row}
        handleCalculateRowType={handleCalculateRowType}
        handleCalculateCellStyle={handleCalculateCellStyle}
        handleHover={handleHover}
        handleDrop={handleDrop}
      />
    </Fragment>
  };

  const selectType = (row) => {
    if(row.type === constants.ROW) return renderRow(row, Row); 
    if(row.type === constants.COLUMN && !row.parentDragging) return renderRow(row, Column); 
    if(row.type === constants.UNGROUPED) return renderRow(row, Ungrouped);
  };

  
  return <Fragment>
    {layout
      .map((row) => {
        return <Fragment key={row.id}>
          {selectType(row)}
        </Fragment>
    })}
  </Fragment>
})
export default Products
