import DraftsTable from './modules/draftsTable';
import QuotesTable from './modules/quotesTable';
import NotesAdd from '../../shared/drawers/notesAdd';
import NotesDrawer from '../../shared/drawers/notesDrawer';
import NotesView from '../../shared/drawers/notesView';

const exported = {
  DraftsTable,
  QuotesTable,
  NotesAdd,
  NotesDrawer,
  NotesView
}

export default exported;