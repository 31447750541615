import { useState, useEffect, Fragment } from 'react';
import Axios from 'classes/axios';
import UILIB from 'components';
import 'react-toastify/dist/ReactToastify.css';
import { getCustomerDrawerObject, getCustomerDrawerBaseObject } from '../functions'; 
import { regexValidation } from '../../../../classes/dataValidation'
import Modules from "../Modules"


export default function EditCustomerDrawer({ entity, sync, onClose }) {

  const defaultFormData = getCustomerDrawerBaseObject();
  const [formData, setFormData] = useState(getCustomerDrawerObject(entity));
  const defaultError = { error: false, message: '' };
  const [bannerError, setBannerError] = useState(defaultError);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const exceptions = [204,400,404,409];

  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while saving this record, please reload or contact support';


  const changeExistingAllowed = (Boolean(Number(formData.context) === 1) &&
    Boolean(Number(formData.userBaseSelected) !== -1) &&
    Boolean(Number(formData.userActionSelected) !== -1) &&
    formData.confirm);

  const changeNewAllowed = (Boolean(Number(formData.context) === 2) &&
    Boolean(formData.formCheck) &&
    Boolean(Number(formData.userActionSelected) !== -1) &&
    formData.confirm);

  const actionUpdateModeField = (event) => {
    setFormData({ ...formData, ...defaultFormData, [event.target.name]: Number(event.target.value) });
  }

  const actionUpdateField = (event) => {
    setFormData({ ...formData, [event.target.name]: Number(event.target.value) });
  }

  const actionUpdateCheckBox = (event) => {
    setFormData({ ...formData, [event.target.name]: !formData[event.target.name] });
  }

  const actionUpdateNewUserForm = (event) => {
    const baseData = { ...formData, [event.target.name]: event.target.value };
    setFormData({ 
      ...formData, 
      ...baseData, 
      formCheck: (baseData.firstName.length > 0 && baseData.lastName.length > 0 && regexValidation('emailPattern', baseData?.emailAddress)) });
  }

  const actionSaveForm = async () => {
    setSaving(true);
    setBannerError(defaultError);
    try { 
      const result = await Axios.put(`/entities/workflow/portals/actionChangeOwner`, formData); 
      if(exceptions.includes(result.status)) {
        if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
        if(result.status === 404) setBannerError({ error: true, message: notFoundError });
      } else {
        sync.set(!sync.get);
        onClose();
      }
    } catch (err) {
      console.log(err);
      setBannerError({ error: true, message: exceptionError });
    } finally {
      setSaving(false);
    }
  }

  useEffect(() => {
    const actionAsync = async () => {
      setLoading(true);
      const result = await Axios.get(`/entities/portalsV3_userbases/?&$filter=customerId eq ${formData.customer.id} and deletedAt is null&$order=firstName&$direction=ASC`); 
      const existingOwner = result.data.result.find(x => Number(x.autoGenerated)  === 1);
      const usersMapped = result.data.result
        .filter(x => Number(x.autoGenerated)  !== 1)
        .map(x => { return { label: `${x.firstName} ${x.lastName}`, value: x.id } });
      usersMapped.unshift({ label: "Please Select", value: -1 });
      setFormData({ 
        ...formData, 
        userBase: usersMapped,
        existingOwner: existingOwner });
      setLoading(false);
    };
    actionAsync();
  }, []);


  return <Fragment>
    <div className="flex-container row width-100 wrap">
      <div className="flex-container row width-100 wrap">
        <div className="flex-item start align-center font-weight-bold text-16 mar-r20">Change Portal Owner</div>
        {loading && <UILIB.Loading outerclassname="flex-item flex-grow-1 row align-center start mar-r10" className="-table" type={3} />}
      </div>
      <div className="pad-5">
        {Boolean(formData.existingOwner) && 
          <Modules.CurrentOwner 
            formData={formData} />}
        {Boolean(formData.existingOwner) && 
          <Modules.SelectMode 
            formData={formData} 
            saving={saving} 
            actionUpdateField={actionUpdateModeField} />}
        {Boolean(Number(formData.context) === 1) && 
          <div className='flex-container row start width-100 pad-t10'>
            <Modules.SelectUser 
              formData={formData} 
              saving={saving} 
              actionUpdateField={actionUpdateField} />
            {Boolean(Number(formData.userBaseSelected) !== -1) &&
              <div className='flex-container row start width-100 pad-t10'>
                <Modules.SelectAction 
                  formData={formData} 
                  saving={saving} 
                  actionUpdateField={actionUpdateField} />
                {Boolean(Number(formData.userActionSelected) !== -1) && 
                  <Modules.ConfirmAction 
                    formData={formData} 
                    saving={saving} 
                    allowed={changeExistingAllowed} 
                    bannerError={bannerError} 
                    actionUpdateCheckBox={actionUpdateCheckBox} 
                    actionSaveForm={actionSaveForm} />}
              </div>}
          </div>}
        {Boolean(Number(formData.context) === 2) &&
          <div className='flex-container row start width-100 pad-t10'>
            <Modules.AddNewOwner 
              formData={formData} 
              saving={saving} 
              actionUpdateNewUserForm={actionUpdateNewUserForm} />
            {Boolean(formData.formCheck) &&
              <div className='flex-container row start width-100 pad-t10'>
                <Modules.SelectAction 
                  formData={formData} 
                  saving={saving} 
                  actionUpdateField={actionUpdateField} />
                {Boolean(Number(formData.userActionSelected) !== -1) &&
                  <Modules.ConfirmAction 
                    formData={formData} 
                    saving={saving} 
                    allowed={changeNewAllowed} 
                    bannerError={bannerError} 
                    actionUpdateCheckBox={actionUpdateCheckBox} 
                    actionSaveForm={actionSaveForm} />}
              </div>}
          </div>}
      </div>
    </div>
  </Fragment>
}
