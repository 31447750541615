import { useCallback } from "react";

export default function useFindItem(items) {

  const handleFindItem = useCallback(
    (id) => {
      const item = items.find(x => Number(x?.id) === Number(id));
      return {
        item,
        index: items.indexOf(item),
      };
    },
    [items]
  );

  return { handleFindItem };
}