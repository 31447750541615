import { useEffect, useState, useRef, memo, Fragment } from 'react';
import { BsChevronCompactUp } from "react-icons/bs";
import classNames from "classnames";
import Modules from '../Modules';


const ExpandPricingStats = memo((props) => 
{

  const [over, setOver] = useState(false);
  const [closed, setClosed] = useState(true);
  const [padding, setPadding] = useState(false)
  const node = useRef(undefined);

  useEffect(() => {

    const currentScroll = (window.scrollY + window.innerHeight);
    const bodyHeight = document?.body?.scrollHeight;
    const nodeHeight = (node?.current) ? node?.current?.getBoundingClientRect()?.height : 0;
    const footerHeight = document.getElementById('hubs-footer')?.getBoundingClientRect()?.height;

    setOver(currentScroll < (bodyHeight - (nodeHeight + footerHeight)));

    setPadding(currentScroll >= (bodyHeight - footerHeight));
    
    const onscroll = () => {

      const currentScroll = (window.scrollY + window.innerHeight);
      const bodyHeight = document?.body?.scrollHeight;
      const nodeHeight = (node?.current) ? node?.current?.getBoundingClientRect()?.height : 0;
      const footerHeight = document.getElementById('hubs-footer')?.getBoundingClientRect()?.height;

      setOver(currentScroll < (bodyHeight - (nodeHeight + footerHeight)));

      setPadding(currentScroll >= (bodyHeight - footerHeight));
      
    };
    
    window.addEventListener("scroll", onscroll);
    return () => {
      window.removeEventListener("scroll", onscroll);
    };
  }, [props.currentTab]);


  return <Fragment>
    <div 
      ref={node} 
      className={classNames(
        "flex-container row center align-center width-100",
        "salesHub pricingStats container",
        { noPadding: !padding },
        { closed }
      )}
    >
      <div 
        className={classNames(
          "flex-item row center align-center width-100",
        )}
      >
        <div 
          onClick={() => setClosed(!closed)} 
          className={classNames(
            "flex-item row center align-center",
            "salesHub pricingStats arrow-up",
            { hidden: !over },
          )}
        >
          <BsChevronCompactUp 
            size={20}         
            style={{ 
              transform: `rotate(${closed ? 180 : 0}deg)`,
              transition: "transform 0.2s" 
            }}
          />
        </div>
      </div>
      <div      
        className={classNames(
          "flex-item row center align-center width-100"
        )}
      >
        <Modules.PricingStats {...props} />
      </div>
    </div>
  </Fragment>
}) 
export default ExpandPricingStats;