import Modules from '../Modules';
import { useEffect, Fragment } from 'react';
import useExpandRows from "../hooks/useExpandRows";

export default function TableSection({ 
  header, 
  row, 
  render, 
  moduleRender, 
  settings, 
  checked, 
  setChecked, 
  selectQuery, 
  rowIndex 
})
{

  const primaryKey = row[settings.current.expandData?.key]?.raw?.uniqueKey
    ? row[settings.current.expandData?.key]?.raw?.uniqueKey
    : undefined;

  const defaultState = Boolean(row[settings.current.expandData?.key]?.raw?.isOpen);

  const { isOpen, setOpenState, toggle } = useExpandRows(defaultState, primaryKey);

  useEffect(() => {
    (primaryKey) && setOpenState({ [primaryKey]: defaultState });
  }, [defaultState]);

  const canExpand = Boolean(
    settings.current.expandData?.active &
    row.hasOwnProperty(settings.current.expandData.key));

  const dataLength = row[settings.current.expandData.key]?.raw?.entityData?.length;

  const enabled = (primaryKey && canExpand && dataLength > 0);

  const expand = 
    <div 
      onClick={(enabled) ? toggle : function () { }} 
      className="width-100 height-100"
      style={{ display: (enabled) ? 'initial' : 'none' }}>
      <div 
        className={`expand-rows up-arrow`} 
        style={{ 
          transform: `rotate(${isOpen[primaryKey] ? 0 : 180}deg)`,
          transition: "all 0.25s" }}>
      </div>
    </div>

  row[settings.current.expandData.key] = { 
    value: expand, 
    raw: row[settings.current.expandData.key]?.raw 
  }; 

  return <Fragment>
    <Modules.DataRow 
      onClick={(enabled) ? toggle : function () { }}
      key={`row_${rowIndex}`} 
      header={header} 
      row={row} 
      settings={settings}
      render={render} 
      moduleRender={moduleRender} 
      rowIndex={rowIndex} 
      expanding={settings.current.expanding} 
      checked={checked} 
      setChecked={setChecked} 
      selectQuery={selectQuery} /> 
      {Boolean(canExpand) && 
        <tr>     
          <td colSpan={header.length} align="center">
            <div class={`mar-0 panel-expand ${isOpen[primaryKey] ? 'open' : 'close'}`}>
              <div class="panel-expand content">
                <Modules.DataRowNested 
                  localRender={isOpen}
                  entityData={row[settings.current.expandData.key]?.raw?.entityData} 
                  headerData={row[settings.current.expandData.key]?.raw?.headerData} 
                  tableSettings={row[settings.current.expandData.key]?.raw?.tableSettings}
                  className={row[settings.current.expandData.key]?.raw?.className} />
              </div>
            </div>
          </td>
        </tr>}
  </Fragment> 
}