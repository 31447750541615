import Button from '../../../../../components/Button/Button'
import { useState, Fragment } from 'react'
import UILIB from 'components';
import Axios from 'classes/axios';
import { pleaseSelectYesNo, createSelectInput } from 'classes/controls/formControls';



export default function OrderSubmitModal({ ourDeal, history, onCancel }) 
{

  const [formData, setFormData] = useState({ ...ourDeal });
  const [bannerError, setBannerError] = useState({ error: false, message: '' });
  const [saving, setSaving] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const exceptions = [400,404,409];

  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while saving this record, please reload or contact support';
  const versionError = 'A newer version of this record has already been saved. Please reload the data';

  const networkInfo = [
    { label: "Please Select", value: -1 }, 
    { label: "Not Required", value: 0 }, 
    { label: "Help Desk", value: 1 },
    { label: "Pre Sales", value: 2 },
    { label: "Sales Person", value: 3 }];

  const allowSubmit = (confirm && 
    Number(formData.siteSurveyFinalReq) !== -1 && 
    Number(formData.containsProServices) !== -1 && 
    Number(formData.proServicesWho) !== -1 &&
    Number(formData.printingRequired) !== -1);

  const actionUpdateDeal = async (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  }

  const actionSubmitOrder = async () => {
    setSaving(true);
    try { 
      const result = await Axios.put(`/entities/workflow/deals/actionSubmitDeal/${ourDeal.dealID}`, { data: formData });
      if(exceptions.includes(result.status)) {
        if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
        if(result.status === 404) setBannerError({ error: true, message: notFoundError });
        if(result.status === 409) setBannerError({ error: true, message: versionError });
      } else {
        history.push('/salesHub/accountmanagerview');
      }
    } catch (err) {
      console.log(err);
      setBannerError({ error: true, message: exceptionError });
    }
    setSaving(false);
  };


  return <Fragment>
    <div className="modalTimeout">
      <div className="modalContent salesHub submitDeal-modalWidth">
        <div className="flex-container row flex-grow-1 wrap">
          <div className="flex-item center mar-b20">
            <h2>Submit this Order for approval?</h2>
          </div>
          <div className="flex-item start">
            <UILIB.Checkbox className="mar-r20" name="confirm" checked={confirm} onChange={() => setConfirm(!confirm)} />
            <div className="flex-container column">
              <p className="mar-b20">I acknowledge that if the information in this order is incorrect my GP 
              figure may be reduced and that this may result in a delay to this order being completed.</p>
              <p><i><b>Please note:</b> You will not be able to make any changes to this order form after this point.</i></p>
              <div className="flex-container row width-100 mar-t5">
                <b className="flex-item salesHub submitDeal-options center text-13">Site Survey Required?</b>
                {createSelectInput('', 'siteSurveyFinalReq', false, Number(formData.siteSurveyFinalReq), pleaseSelectYesNo, (ev) =>  actionUpdateDeal(ev), null, 'salesHub submitDeal-options', null, null, 'fullBorder border-colour-grey-4')}
              </div>
              <div className="flex-container row width-100 mar-t5">
                <b className="flex-item salesHub submitDeal-options center text-13">Includes Pro Services?</b>
                {createSelectInput('', 'containsProServices', false, Number(formData.containsProServices), pleaseSelectYesNo, (ev) =>  actionUpdateDeal(ev), null, 'salesHub submitDeal-options ', null, null, 'fullBorder border-colour-grey-4')}
              </div>
              <div className="flex-container row width-100 mar-t5">
                <b className="flex-item salesHub submitDeal-options center text-13">Who is Gathering Network info?</b>
                {createSelectInput('', 'proServicesWho', false, Number(formData.proServicesWho), networkInfo, (ev) =>  actionUpdateDeal(ev), null, 'salesHub submitDeal-options ', null, null, 'fullBorder border-colour-grey-4')}
              </div>
              <div className="flex-container row width-100 mar-t5">
                <b className="flex-item salesHub submitDeal-options center text-13">Agilico to setup print / scan?</b>
                {createSelectInput('', 'printingRequired', false, Number(formData.printingRequired), pleaseSelectYesNo, (ev) =>  actionUpdateDeal(ev), null, 'salesHub submitDeal-options', null, null, 'fullBorder border-colour-grey-4')}
              </div>
            </div>
          </div>
        </div>
        <div className="flex-container salesHub submitDeal-button center mar-t20">
          <Button disabled={saving || !allowSubmit} saving={saving} className="primary mar-5" value="Submit" onClick={async () => await actionSubmitOrder()}/>
          <Button disabled={saving} className="primary mar-5" value="Go back" onClick={() => { onCancel()  }}/>
        </div>
        {bannerError.error && <div className="flex-container salesHub submitDeal-button center">
          <div className="errored message">{bannerError.message}</div>
        </div>}
      </div>
    </div>
  </Fragment>
}