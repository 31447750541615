import Axios from 'classes/axios';
import cloneDeep from 'lodash/cloneDeep';


export async function getUsers() {
    const results = await Axios.get('/users/360users');
    return results.data
}

export async function getAzureUserById(id) {
    const results = await Axios.post('/helpers/getAzureUserById', { id: id });
    return results.data
}

export async function getRegions() {
    const results = await Axios.get('/helpers/getRegions');
    let regions = results.data;
    return regions
}

export async function getRegionsByCustomer(id) {
    const results = await Axios.post('/helpers/getRegionsByCustomer', [id]);
    return results.data
}

export async function getRegionByCustomerAccount(account) {
  const results = await Axios.get('/helpers/getRegionByCustomerAccount/' + account);
  return results.data
}

export async function getOwningUser(internalemailaddress) {
  const results = await Axios.post('/helpers/getOwningUser', { internalemailaddress: internalemailaddress });
  return results.data
}


export function findRegion(regions, regionId) {

    const found = regions.find(re => Number(re.value) === Number(regionId));
    const oldRegions = [
        { value: 128, label: "Ringwood" },
        { value: 127, label: "South West" },
        { value: 3, label: "Agilico" },
        { value: 4, label: "North West" },
        { value: 178, label: "First Office" },
        { value: 182, label: "Devon and Cornwall" },
        { value: 196, label: "CBC" } ]

    if (!found) return oldRegions.find(re => Number(re.value) === Number(regionId));

    return found;
}


export async function getDevices(name) {
    let query = '?'
    if (name) query += '&name=' + name
    const results = await Axios.get(`/helpers/getDevices${query}`);
    return results.data;
}

export async function getDeviceDetails(id) {
    let query = '?'
    if (id) query += '&id=' + id
    const results = await Axios.get(`/helpers/getDeviceDetails${query}`);
    return results.data;
}

export async function updateAndSetOurDeals(name) {
    let query = '?'
    if (name) query += '&name=' + name
    const results = await Axios.get(`/helpers/updateAndSetOurDeals${query}`);
    return results.data;
}

export async function getAddresses(name, token = {}, accountId) {
  const results = await Axios.get(`/helpers/getSalesAddresses/${accountId}/${name}`, token).then(api => api.data);
  return results.map((x,i) => { return { raw: x, label: `${x.name}-(${x.accountnumber}) ${x.address1_composite} - `, value: i } });
}

export async function getAccountUsers(name, token = {}) {
  const results = await Axios.get(`/entities/serviceAdmin_users/?&$filter=fullName like ${name}&$select=userID and fullName`, token).then(api => api.data.result);
  return results.map(x => { return { ...x, label: x.fullName, value: x.userID } });
}

export async function getDeals(name, token = {}) {
  const results = await Axios.get(`/entities/deals/?&$filter=AltName like ${name}&$select=dealID and companyName`, token).then(api => api.data.result);
  return results.map(x => { return { ...x, label: x.companyName, value: x.dealID } });
}

export async function getCompanies(name, token = {}) {
  const results = await Axios.get(`/entities/customers/?&$filter=AltName like ${name} and deletedDate is null`, token).then(api => api.data.result);
  return results.map(x => { return { ...x, label: x.AltName, customerName: x.AltName, value: x.id } });
}

export async function getCompaniesGrouped(name, token = {}) {
  const results = await Axios.get(`/entities/customers/getCompaniesGrouped/?&$filter=AltName like ${name} and deletedDate is null and GroupCode isn null and GroupCode ne NA`, token).then(api => api.data.result);
  return results.map(x => { return { ...x, label: `${x.GroupCode} - ${x.Name}`, customerName: x.AltName, value: x.id } });
}

export async function getUserCompanies(name, token = {}) {
  const results = await Axios.get(`/helpers/getUserCompanies/?&name=${name}`, token).then(api => api.data.result);
  return results.map(x => { return { ...x, label: x.AltName, customerName: x.AltName, value: x.id } });
}

export async function getUserPostCodes(name, token = {}) {
  const results = await Axios.get(`/helpers/getUserPostCodes/?&name=${name}`, token).then(api => api.data.result);
  return results.map(x => { return { ...x, label: x.AltName, customerName: x.AltName, value: x.id } });
}

export async function getCompaniesExceptions(name, token = {}) {
  const results = await Axios.get(`/helpers/getCompaniesExceptions/?&name=${name}`, token).then(api => api.data.result);
  return results.map(x => { return { ...x, label: x.AltName, value: x.id } });
}

export async function getDeviceExceptions(name, token = {}) {
  const results = await Axios.get(`/helpers/getDeviceExceptions/?&name=${name}`, token).then(api => api.data.result);
  return results.map(x => { return { ...x, label: x.SerialNumber, value: x.SerialNumber } });
}

export async function getCompaniesVoAPI(name, token = {}) {
  const results = await Axios.get(`/helpers/getCompaniesVoAPI/?&name=${name}`, token).then(api => api.data);
  return results.map(x => { return { ...x, label: x.AltName, customerName: x.AltName, value: x.id } });
}

export async function getCompaniesPortalUsers(name, token = {}) {
  const results = await Axios.get(`/helpers/getCompaniesPortalUsers/?&name=${name}`, token).then(api => api.data);
  return results.map(x => { return { ...x, label: x.AltName, customerName: x.AltName, value: x.id } });
}

export async function getSalesCompaniesByPostCode(name, token = {}) {
  const results = await Axios.get(`/helpers/getUserPostCodes/?&name=${name}`, token).then(api => api.data.result);
  return results.map(x => { return { ...x, label: `${x.PostCode} - ${x.AltName}`, customerName: x.AltName, value: x.id } });
}
 
export async function getCompaniesByPostCode(name, token = {}) {
  const results = await Axios.get(`/entities/customers/?&$filter=PostCode like ${name} and deletedDate is null&$select=id and AltName and PostCode and Name and Reference`, token).then(api => api.data.result);
  return results.map(x => { return { ...x, label: `${x.PostCode} - ${x.AltName}`, customerName: x.AltName, value: x.id } });
}

export async function getCompaniesPortals(name, token = {}) {
  const results = await Axios.get(`/entities/workflow/portals/actionSearchCustomer/${name}`, token).then(api => api.data.result);
  return results.map(x => { return { ...x, label: x.AltName, customerName: x.AltName, value: x.id } });
}

export async function getSerials(name, token = {}) {
  const results = await Axios.get(`/entities/equipments/?&$filter=SerialNumber like ${name} and deletedDate is null&$select=id and SerialNumber and CustomerId&$expand=customer`, token).then(api => api.data.result);
  return results.map(x => { return { ...x, label: x.SerialNumber, value: x.id, customerName: (x.customer.length) ? x.customer[0].AltName : null } });
}

export async function getUserSerials(name, token = {}) {
  const results = await Axios.get(`/helpers/getUserSerials/?&name=${name}`, token).then(api => api.data.result);
  return results.map(x => { return { ...x, label: x.SerialNumber, customerName: x.AltName, value: x.id } });
}

export async function getSerialsByAssetNo(name, token = {}) {
  const results = await Axios.get(`/entities/equipments/?&$filter=AssetNumber like ${name} and deletedDate is null&$select=id and SerialNumber and CustomerId and AssetNumber&$expand=customer`, token).then(api => api.data.result);
  return results.map(x => { return { ...x, label: x.AssetNumber, value: x.id, companyName: (x.customer.length) ? x.customer[0].AltName : null } });
}

export async function getCompanyByAccount(name) {
    let query = '?'
    if (name) query += '&name=' + name
    const results = await Axios.get(`/helpers/getCompanyByAccount${query}`);
    return results.data;
}

export async function getCompanyDetails(id) {
    let query = '?'
    if (id) query += '&id=' + id
    const results = await Axios.get(`/helpers/getCompanyDetails${query}`);
    return results.data;
}

export async function getLogisticsUsers() {
    const results = await Axios.get('/helpers/getLogisticsUsers');
    return results.data;
}

export async function getSopOwners() {
  const results = await Axios.get('/helpers/getSopOwners');
  return results.data;
}

export async function getEngineerStatus(ids) {
    const results = await Axios.post(`/helpers/getEnginnerStatus${ids}`);
    return results.data
}

export async function getUsersByGroup(groupName) {
    const results = await Axios.get(`/users/groupusers?group=${groupName}`);
    return results.data;
}

export async function getMeterNames() {
  const results = await Axios.get(`/helpers/getMeterNames`);
  return results.data;
} 

export async function getDynamicMeters(partNo = undefined) {
  if(!partNo) return [];
  try {
    const response = await Axios.get(`/helpers/getDynamicMeters/${partNo}`);

    if (Number(response.status) !== 200) {
      console.error('Error fetching data: ', response.status);
      return [];
    }

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
}

export async function getStockHoldingCustomDefinition() {
  const results = await Axios.get(`helpers/getStockHoldingCustomDefinition/?$count=true&$top=20&$filter=(DeletedDate eq null and (EntityId eq null or Entity/Type eq 'Equipment'))&$orderby=Label asc,Description asc&$select=Id,Label,Description,EdmType,DropDownValues`);
  return results.data;
}

export function getLogisticsStatus(ids) {
    const vals = [
      { value: 0, status: "None" },
      { value: 1, status: "Awaiting Refurb" },
      { value: 2, status: "Parts on Order" },
      { value: 3, status: "In build progress" },
      { value: 4, status: "On Hold" },
      { value: 5, status: "Install Date Agreed" },
      { value: 6, status: "Awaiting Network Form" },
      { value: 25, status: "Invoice Only" },
      { value: 7, status: "Software" },
      { value: 30, status: "All stock in - Awaiting Build" },
      { value: 15, status: "Build Complete" },
      { value: 10, status: "Completed" }]
    if(!ids) return vals;
    return vals.filter(e => ids.indexOf(e.value) > -1);
}


export function getComsPaidSelect() {
  const vals = [ 
    { value: 0, label: "Please Select" },
    { value: 1, label: "No" },
    { value: 2, label: "Yes" }]
  return vals;
}


export function getLogisticsSelect() {
  const vals = [ 
    { value: 0, label: "None" },
    { value: 2, label: "Parts on Order" },
    { value: 3, label: "In build progress" },
    { value: 4, label: "On Hold" },
    { value: 5, label: "Install Date Agreed" },
    { value: 6, label: "Awaiting Network Form" },
    { value: 25, label: "Invoice Only" },
    { value: 7, label: "Software" },
    { value: 30, label: "All stock in - awaiting Build" },
    { value: 15, label: "Build Complete" },
    { value: 10, label: "Completed" }]
  return vals;
}


export function getContractStatus(ids) {
    const vals = [
      { value: 0, label: "Pending" },
      { value: 1, label: "Checked" },
      { value: 2, label: "Partially Setup" },
      { value: 4, label: "On Hold" },
      { value: 3, label: "Complete" },
      { value: -1, label: "No Contract" }]

    return vals.filter(e => ids.indexOf(e.value) > -2);
}


export async function getDealAddresses(dealID) {
    const results = await Axios.get(`/helpers/getDealAddresses/${dealID}`)
    return results.data
}


export function addressToString(address) {
  const addressItems = ["customerName", "houseNo", "address1", "address2", "address3", "town", "county", "country", "postcode"];
  if(typeof(address) === 'object') {
    const cloned = cloneDeep(address);
    Object.keys(cloned).forEach(x => addressItems.includes(x) ? x : delete cloned[x]);
    Object.keys(cloned).forEach(x => (!cloned[x] || !cloned[x].length) ? delete cloned[x] : x);
    return Object.values(cloned).join(', ');
  }
  return address;   
}

export function convertObject(data, acceptedTypes, conversion) {

  let newObject = {};
  
  if(typeof(data) === 'object') {
    Object.keys(data).forEach(x => acceptedTypes.includes(x) ? newObject[conversion[x]] = data[x] : delete data[x]);
  }
  return newObject;   
}

export function cleanCompanyName(companyName) {
    if (companyName.indexOf('(') > -1 && (companyName[companyName.length - 1] === ')' || companyName[companyName.length - 1] === '*')) {
        const nameSplit = companyName.split(' ')
        // the number is first so remove it an any brackets
        if (parseInt(nameSplit[0])) {
            companyName = nameSplit.slice(1, nameSplit.length).join(' ')
            if (companyName[companyName.length - 1] === '*') {
                companyName = companyName.substring(1, companyName.length - 3)
            } else {
                companyName = companyName.substring(1, companyName.length - 1)
            }
        }
    }
    companyName = companyName.split('\\t').join('').trim()
    return companyName
}

export async function getRemoteImageBase64(url, fileName) {
  const results = await Axios.post('/helpers/getRemoteImageBase64', { fileLocation: url, fileName });
  return results.data;
}

export async function getFileBase64(url) {
  const results = await Axios.post('/helpers/getFileBase64', { fileLocation: url });
  return results.data;
}

export async function getHostConfig(opts) {
  const results = await Axios.post(`/helpers/getHostConfig/`, { options: opts });
  return results.data;
}

export function searchArray(array, textArray, filterColumns) {
  textArray = textArray.map(t => t.toLowerCase());
  return array.filter((a) => {
    return textArray.every((t) => {
      return filterColumns.some((f) => {    
        return a[f] ? a[f].toString().toLowerCase().indexOf(t) !== -1 : null;
      });
    });
  });
}

export function csv2json(data) {

  if(!data || !data?.split("\n").length) return { success: false, result: 'no data supplied' };

  const lines = data?.split("\n");
  const header = lines[0].split(',').map(x => x?.replace(/[\n\r]/g, ''));
  const dataLines = lines.slice(1).filter(x => x.length);
  const dataOut = [];

  for(let i = 0; i < dataLines.length; i++) {
    const value = dataLines[i]?.split(',').map(x => x?.replace(/[\n\r]/g, '')?.trim());
    const reduced = header.reduce((acc, obj, index) => ({ ...acc, [obj?.replace(/[\n\r]/g, '')?.trim()]: value[index] }), {});
    dataOut.push(reduced);
  }

  const filtered = dataOut.filter(x => Object.values(x).some(y => y.length));

  return { success: true, result: filtered, header: header, lines: dataLines };
}

export function getOrganisations() {
  const vals = [
    { value: 0, label: "Pending" },
    { value: 1, label: "Checked" },
    { value: 2, label: "Partially Setup" },
    { value: 4, label: "On Hold" },
    { value: 3, label: "Complete" },
    { value: -1, label: "No Contract" }]

  return vals;
}

export async function getAgilicoStores(token = {}) {
  const results = await Axios.get(`/helpers/getAgilicoStores`, token).then(api => api.data.result);
  return results.map(store => { return { ...store, label: store.Town, value: store.Id } });
}