import React from 'react';

const TextArea = React.forwardRef((props, ref) =>
  {

    const handleChange = async (ev) => {
      await props.onChange(ev);
    };

    let outerclassname = (props.outerclassname) ? props.outerclassname : 'flex-container row width-100';
    let innerClassName = `flex-item width-100 ${(props.className) ? props.className : ''}`;
    let errored = (props?.errored) ? props.errored : { messageText: "", option: 0 };

    if (props.button) outerclassname += " withButton ";

    return <div className={outerclassname}>
        <input 
          {...props}
          ref={ref}
          value={props.value} 
          onChange={async (ev) => await handleChange(ev)} 
          disabled={props.disabled} 
          className={innerClassName + (errored.error && errored.option === 0 && !props.disabled ? " errored border " : "")} 
          />
        {errored.error && errored.option === 1 && <div className="errored message">{errored.messageText}</div>}
        {props.button && <button onClick={props.onClick}>{props.button}</button>}
    </div >
})
export default TextArea