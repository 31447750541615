import { memo, useRef, Fragment } from "react";
import { useDrop, useDrag } from "react-dnd";
import useFindNestedItem from '../hooks/useFindNestedItem';
import { constants } from "./Constants";
import Modules from '../Modules';

const Column = memo((
  {  
    handleName, 
    dndEnabled,
    data, 
    layout,
    handleCalculateRowType,
    handleCalculateCellStyle,
    onDrop,
    handleDrop
  }
) =>  
{

  handleCalculateRowType(data);

  const { handleFindNestedItem } = useFindNestedItem(layout);

  const dropNode = useRef(undefined);
  const node = useRef(undefined);

  const [probe, drop] = useDrop({
    accept: [
      constants.COLUMN
    ],
    drop: async (item) => {

      const foundSource = handleFindNestedItem(item.id);
      const foundTarget = handleFindNestedItem(data.id);

      if(!foundSource.item) return;

      if(Number(foundSource.item.id) !== Number(data.id)) {
        handleDrop(foundSource, foundTarget);
      }
 
      await onDrop(layout);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      opacity: monitor.isOver() ? 0 : 1
    }),
  });

  const [collect, drag] = useDrag({
    type: constants.COLUMN,
    item: {
      type: data.type,
      bind: data.bind,
      portalPartId: data.raw.portalPartId,
      id: data.id
    },
    async end() {
      await onDrop(layout);
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      item: monitor.getItem()
    }),
  });

  drag(drop(node));
  drop(dropNode);

  return <Fragment>
    <tr 
      key={data.id} 
      ref={dropNode}
      style={{ 
        opacity: probe.opacity }}
    >
      <Modules.DataRow 
        ref={node}
        dndEnabled={dndEnabled}
        handleName={handleName}
        row={data.table} 
        handleCalculateCellStyle={handleCalculateCellStyle}
        header={data.header} 
        data={data}
      />
    </tr>
  </Fragment>
})
export default Column
