import { useState, useEffect, useRef, Fragment, memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

const MultiSelect = memo((
{ 
  name, 
  data = [], 
  placeholder, 
  onChange = function () { },
  disabled, 
  outerstyle, 
  style = { }, 
  outerclassname = '', 
  className = '',
  errored = { messageText: "", option: 0 }, 
  selected  = []
}) => 
{

    const node = useRef(undefined);
    const [nodeWidth, setNodeWidth] = useState(0);
    const [DropDownOpen, setDropDownOpen] = useState(false);
    const [clicked, setClicked] = useState(false);

    library.add(fas);
    const fontAwesome = <FontAwesomeIcon icon="fas fa-chevron-down"/>

    const toggleDropDown = () => {
      if (DropDownOpen) {
        setDropDownOpen(false);
      }
      else {
        setDropDownOpen(true);
      }
    }
 
    const updateSelected = (val) => {
      setClicked(!clicked);
      if (selected.includes(val)) {
        selected.splice(selected.indexOf(val), 1);
      } else {
        selected.push(val);
      }
      onChange({ target: { name, value: selected } })
    }

    const handleClick = e => {
        if (node.current.contains(e.target)) return;
        // outside click 
        setDropDownOpen(false);
    };


    useEffect(() => {     
      document.addEventListener("mousedown", handleClick);
      // return function to be called when unmounted
      return () => {
        document.removeEventListener("mousedown", handleClick);
      };
    }, [clicked])

    useEffect(() => {
      setNodeWidth((node.current) ? node.current?.getBoundingClientRect()?.width : 0);
    }, [DropDownOpen])

    let outerClassNameF = "align-center multiSelect noBorder"
    if (className) className += " " + className;
    if (errored) className += " errored";
    if (outerclassname) outerClassNameF += " " + outerclassname;
    let initialValue = placeholder;

    if (data && data.length && selected && selected.length) {
        const count = data.filter(w => selected.indexOf(w.value) > -1);
        initialValue = `${count.length} Selected`;
    }

    if (errored?.error && errored?.option === 0) {
        style.border = "1px solid rgba(217, 30, 24, 0.8)";
        style.borderRadius = "5px";
        style.transition = "border-color 2s";
    }


    return <Fragment>
        <div className={outerClassNameF} style={{ ...outerstyle, minWidth: 200 }} ref={node} id={"multiSelect" + name}>
          <div className={`multiSelect fakeSelect ${className}`} style={style} onClick={!Boolean(disabled) ? toggleDropDown : null} >
            <div className={"content" + (Boolean(disabled) ? " colour text grey" : "")}>{initialValue}</div>
            <div className="fakeSelectIcon"><span>{fontAwesome}</span></div>
          </div>
            {DropDownOpen && <div style={{ width: nodeWidth }} className={"fakeSelect fakeSelectDropDown flex-container row flex-grow-1"}>
              {(data && data.length) && data.map((opt, index) => {
                const disabled = opt.hasOwnProperty('disabled') ? opt.disabled : false;
                const checked = (selected && selected.length) ? selected.indexOf(opt.value) > -1 : [];
                return <div className="flex-item start clickable" key={index}><input disabled={disabled} type="checkbox" checked={checked} onChange={() => updateSelected(opt.value)} /> {opt.label}</div> })}
            </div>}
            {errored?.error && errored?.option === 1 && <div className="errored message">{errored.messageText}</div>}
        </div>
      </Fragment>
})
export default MultiSelect
