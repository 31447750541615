import React from 'react';

const NumberInput = React.forwardRef((props, ref) => 
{
    let { allowDecimal, min, outerclassname: outerClassNameProp, className, button, errored, style, ...rest } = props;
    let outerclassname = (props.outerclassname) ? props.outerclassname : '';
    let innerClassName = `flex-item width-100 ${(props.className) ? props.className : ''}`;
    errored = props?.errored ? props.errored : { messageText: '', option: 0 };
    allowDecimal = props.allowDecimal ?? true;
    const minValue = (props?.min) ? Number(props.min) : 0;

    if (props.button) outerclassname += ' withButton ';

    const handleKeyDown = (e) => {
        const { key } = e;
        const isNumericKey = key >= '0' && key <= '9';
        const isControlKey = key === 'Backspace' || key === 'Delete' || key === 'ArrowLeft' || key === 'ArrowRight';
        const isAllowedDecimal = allowDecimal && key === '.';

        if (!isNumericKey && !isControlKey && !isAllowedDecimal) {
            e.preventDefault();
        }
    };

    const handleInput = (e) => {
        let value = e.target.value;

        if (allowDecimal) {
            value = value.replace(/[^0-9.]/g, '');
            const decimalCount = (value.match(/\./g) || []).length;
            if (decimalCount > 1) {
                value = value.replace(/(\.+)$/, '');
            }
        } else {
            value = value.replace(/[^0-9]/g, '');
        }

        if (Number(value) < minValue) value = minValue;
    };

    const handleChange = async (e) => {
        e.target.value = (Number(e.target.value) < minValue) 
          ? minValue 
          : e.target.value;

        const isValidInput = allowDecimal
            ? /^[0-9.]*$/.test(e.target.value)
            : /^[0-9]*$/.test(e.target.value);

        if (isValidInput && props.onChange) {
            await props.onChange(e);
        }
    };

    return (
        <div className={outerclassname}>
            <input
                {...rest}
                ref={ref}
                type="text"
                onWheel={(ev) => ev.currentTarget.blur()}
                disabled={props.disabled}
                className={innerClassName + (errored.error && errored.option === 0 && !props.disabled ? ' errored border ' : '')}
                value={props.value}
                onKeyDown={handleKeyDown}
                onInput={handleInput}
                onChange={handleChange}
                style={(style) ? style : {}} 
            />
            {errored.error && errored.option === 1 && <div className="errored message">{errored.messageText}</div>}
            {props.button && <button onClick={props.onClick}>{props.button}</button>}
        </div>
    );
})
export default NumberInput