export const appStrings = {
    BackToBackCPC: 'Back to Back - CPC',
    BackToBackEROY: 'Back to Back - EROY',
    Cash: 'Cash',
    CashCPC: 'Cash - CPC',
    CashTrade: 'Cash - Trade (SOP Only)',
    Copycare: 'Copycare',
    FOC: 'Free of Charge (FOC)',
    GPOnly: 'GP Only',
    InternalRentalCPC: 'Internal Rental – CPC',
    Lease: 'Lease',
    POC: 'Proof of Concept (POC)',
    ScottishProcurement: 'Scottish Procurement',
    ScottishProcurementLease: 'Scottish Procurement – Lease',
    ShortTermInternalRental: 'Short Term Internal Rental',
    Subscription: 'Subscription',
    PleaseSelect: '-1'
}

export const dealTypes = [
    { dmcType: "100000003", dealType: "BM-Audio Visual"},
    { dmcType: "100000001", dealType: "BM-Digital Mailroom (Off Premise)"},
    { dmcType: "100000000", dealType: "BM-Digital Mailroom (On Premise)"},
    { dmcType: "100000002", dealType: "BM-Document Management"},
    { dmcType: "173860007", dealType: "BM-Intelligent Document Capture"},
    { dmcType: "173860004", dealType: "BM-InVentry"},
    { dmcType: "173860000", dealType: "BM-MPS"},
    { dmcType: "173860003", dealType: "BM-MPS (Hardware Only)"},
    { dmcType: "173860005", dealType: "BM-One-off/Ad hoc Scanning"},
    { dmcType: "100000004", dealType: "BM-Outbound Mail"},
    { dmcType: "173860002", dealType: "IT Services"},
    { dmcType: "173860001", dealType: "Telephony"}
];

export const purchaseTypes = [
    appStrings.BackToBackCPC,
    appStrings.BackToBackEROY,
    appStrings.Cash,
    appStrings.CashCPC,
    appStrings.CashTrade,
    appStrings.Copycare,
    appStrings.FOC,
    appStrings.GPOnly,
    appStrings.InternalRentalCPC,
    appStrings.Lease,
    appStrings.POC,
    appStrings.ScottishProcurement,
    appStrings.ScottishProcurementLease,
    appStrings.ShortTermInternalRental,
    appStrings.Subscription
];

export function updateAndSetOurDealType(dmc_type) {
    let dealType = "";
    switch (dmc_type) {
        case 100000003:
            dealType = "BM-Audio Visual"
            break;
        case 100000001:
            dealType = "BM-Digital Mailroom (Off Premise)"
            break;
        case 100000000:
            dealType = "BM-Digital Mailroom (On Premise)"
            break;
        case 100000002:
            dealType = "BM-Document Management"
            break;
        case 173860007:
            dealType = "BM-Intelligent Document Capture"
            break;
        case 173860004:
            dealType = "BM-InVentry"
            break;
        case 173860000:
            dealType = "BM-MPS"
            break;
        case 173860003:
            dealType = "BM-MPS (Hardware Only)"
            break;
        case 173860005:
            dealType = "BM-One-off/Ad hoc Scanning"
            break;
        case 100000004:
            dealType = "BM-Outbound Mail"
            break;
        case 173860002:
            dealType = "IT Services"
            break;
        case 173860001:
            dealType = "Telephony"
            break;
        default:
            dealType = "Unknown"
            break;
    }

    return dealType;
}

export function updateAndSetOurDealNewExisting(new_neworexisting) {
    let newExisting = ""

    switch (new_neworexisting) {
        case 100000002:
            newExisting = "Existing - Net New"
            break;
        case 100000003:
            newExisting = "Existing - Renewal"
            break;
        case 100000001:
            newExisting = "New"
            break;
        default: 
            newExisting = "Unknown"
            break;
    }
    return newExisting;
}

export function updateAndSetOurDealCategory(dmc_category) {
    let accountGrade = "";
    switch (dmc_category) {
        case 100000002:
            accountGrade = "Bronze"
            break;
        case 173860001:
            accountGrade = "Silver"
            break;
        case 173860002:
            accountGrade = "Gold"
            break;
        case 173860004:
            accountGrade = "Platinum"
            break;
        case 100000003:
            accountGrade = "Diamond"
            break;
        default: 
            accountGrade = "Unknown"
            break;
    }
    return accountGrade;
}

export const settlementFieldValues = [
    { label: "Please Select", value: "-1" },
    { label: "Customer Settling & Invoicing Agilico", value: "Customer Settling & Invoicing Agilico" },
    { label: "Upgraded on Agreement", value: "Upgraded on Agreement" },
    { label: "Agilico to settle", value: "Agilico to settle" },
    { label: "Agreement naturally Ending", value: "Agreement naturally ending" }
]

export const settlementAgreemtntValues = [
  { value: "-1", label: "Please Select" },
  { value: "Lease", label: "Lease" },
  { value: "Service", label: "Service" }
];

export const equipmentReturnMethods = [
  { value: 0, label: "Please Select" },
  { value: 1, label: "Agilico Arranging" },
  { value: 2, label: "Customer Disposing" },
  { value: 3, label: "Customer Arranging" }
];

export const equipmentOwnerships = [
  { value: 0, label: "Please Select" },
  { value: 1, label: "Lease - Agilico to purchase title" },
  { value: 2, label: "Lease - Title TBC" },
  { value: 3, label: "Lease - Return to Lease Company" },
  { value: 4, label: "Customer - DMC to Dispose" },
  { value: 5, label: "Customer - DMC to Store"}
];

export const leaseCompanies = [
  { value: -1, label: "Please Select" },
  { value: 100000029, label: "Academy Leasing Ltd" },
  { value: 100000023, label: "Blue Stone Lease" },
  { value: 100000000, label: "BNP Paribas" },
  { value: 100000001, label: "CF Capital" },
  { value: 100000002, label: "CF Corporate" },
  { value: 100000019, label: "CIT Vendor Finance" },
  { value: 100000030, label: "Complete Leasing Solutions Ltd" },
  { value: 100000015, label: "Customer Arranged" },
  { value: 100000003, label: "DLL" },
  { value: 100000026, label: "Econocom" },
  { value: 100000005, label: "GE Capital" },
  { value: 100000038, label: "GE Capital Equipment Finance Ltd" },
  { value: 100000031, label: "Grant Development Ltd" },
  { value: 100000004, label: "Grenke" },
  { value: 100000035, label: "Highland Craft & Gifts" },
  { value: 100000032, label: "Highland Crafts and Gifts" },
  { value: 100000018, label: "HP" },
  { value: 100000040, label: "HP International Bank" },
  { value: 100000017, label: "ING" },
  { value: 100000020, label: "ING c/o Systems Rentals" },
  { value: 100000006, label: "Internal Rental" },
  { value: 100000022, label: "Investec Asset Finance plc" },
  { value: 100000021, label: "Investec c/o Systems Rentals" },
  { value: 100000025, label: "LOMBARD NORTH" },
  { value: 100000007, label: "Paragon Finance" },
  { value: 100000008, label: "PEAC (UK) Ltd" },
  { value: 100000027, label: "Propel Finance PLC" },
  { value: 100000009, label: "Red Arrow" },
  { value: 100000033, label: "Shire Leasing Plc" },
  { value: 100000010, label: "Siemens" },
  { value: 100000034, label: "Singer and Friedlander" },
  { value: 100000024, label: "Societe Generale" },
  { value: 100000011, label: "Systems Rentals" },
  { value: 100000016, label: "Systems Rentals (ING)" },
  { value: 100000028, label: "Time Finance" },
  { value: 100000042, label: "Time Finance Ltd" },
  { value: 100000012, label: "Tower" },
  { value: 100000013, label: "Tower (Grenke)" },
  { value: 100000014, label: "Tower (Societe Generale Equipment Finance)" },
  { value: 100000043, label: "Tower Leasing Ltd" },
  { value: 100000036, label: "BNP Paribas Leasing Solutions Limited" },
  { value: 100000037, label: "BNP Paribas Leasing Solutions Ltd" },
  { value: 100000039, label: "Grenke Leasing Ltd" },
  { value: 100000041, label: "Siemens Financial Services Ltd" },
  { value: "Other", label: "Other" }
];


export const fieldTypes =
  Object.freeze(
    {
      TICKED: "ticked",
      COSTEACH: "costEach",
      DESCRIPTION: "description",
      DISCOUNT: "discount",
      CPCCOLOUR: "cpcColour",
      CPCMONO: "cpcMono",
      PRICEEACH: "priceEach",
      QTY: "qty",
      SUPPLIER: "supplier",
      NEWUSED: "newUsed",
      PARTNO: "partNo",
      DEVICENO: "deviceNo",
      SHOWTOCUSTOMER: "showToCustomer",
      SITEADDRESS: "siteAddress",
      SPECIFICDEVICELOCATION: "specificDeviceLocation",
      LEASE: "lease",
      LEASECOMPANYINFO: "leaseCompanyInfo",
      LEASEPERIODPAYMENT: "leasePeriodPayment",
      OTHERLEASECOMPANY: "otherLeaseCompany",
      COMPANYPAYMENTLENGTH: "companyPaymentLength",
      LEASERATE: "leaseRate",
      DEALCURRENCY: "dealCurrency",
      RVINCLUDED: "rvIncluded",
      RVPERCENT: "rvPercent",
      REBATEVALUE: "rebateValue",
      REBAEPERCENT: "rebatePercent",
      ISMEMBER: "isMember",
      CPCLOCATION: "cpcLocation",
      CPCLOT: "cpcLot",
      SERVICETERM: "serviceTerm",
      MINICOMPORDIRECTBUY: "miniCompOrDirectBuy",
      TENDERORCONTRACTREF: "tenderOrContractRef",
      LARGERGROUPID: "largerGroupId",
      LARGERGROUPSELECT: "largerGroupSelect",
      DMCPCNO: "dmcPONo",
      LEASEORCACHE: "leaseorcash",
      COMPANYPAYMENTTERMS: "companyPaymentTerms",
      SERVICECONTRACT: "serviceContract",
      MAINDEAL: "mainDeal",
      ORGANISATION: "organisation",
      SECTOR: "sector",
      PRESALESUSER: "activeDirectoryObjectGuidPreSalesUserId",
      HIDEPANELRIGHT: "hidePanelRight",
      PROCUREMENTLEVEL: "procurementLevel",
      SCOTTISHPROCUREMENT: "scottishProcurement",
      SCOTTISHPROCUREMENTLEASE: "scottishProcurementLease",
      LEASEPERIOD: "leasePeriod"
    }
  )