import Axios from "classes/axios";
import { useCallback } from "react";

export default function useUpdateDealEntity(ourDeal, setWorking, timerObject) {

  const handleUpdateDealEntity = useCallback(
    async (deal, type, fields, timeout) => {
      return new Promise((resolve, reject) => {
        clearTimeout(timerObject.current[`${deal.dealID}-${type}`]);
        const timer = setTimeout(async () => {
          setWorking(true);
          const reduced = fields.reduce((acc, obj) => ({ ...acc, [obj]: deal[obj] }), {});
          await Axios.put(`/entities/workflow/deals/actionUpdateDealEntity/${ourDeal.dealID}/${type}`, reduced)
            .then(res => {
              setWorking(false);
              resolve(res.data);
            })
            .catch(error => {
              setWorking(false);
              reject(error.data);
            })
        }, timeout);
        timerObject.current[`${deal.dealID}-${type}`] = timer;
      });
    },
    [
      ourDeal.dealID,
      timerObject, 
      setWorking
    ]
  );

  return { handleUpdateDealEntity };
}