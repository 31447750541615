import { useCallback } from "react";

export default function useFlatternData() {

  const handleFlatternData = useCallback(
    (data) => {

      try {

        return data
          .map(x => { 
            return [x, ...x.children]; 
          })
          .flat();

      } catch (ex) {
        console.log(ex);
        return [];
      }
    },
    []
  );

  return { handleFlatternData };
}