import { useCallback } from "react";
import update from "immutability-helper";


export default function useHandleHover(layout, setLayout) {

  const handleHover = useCallback(
    (foundSource, foundTarget) => {

      if(Math.abs(foundSource.index - foundTarget.index) < 1) return;

      const downOffset = (foundSource.index < foundTarget.index) 
      ? foundTarget.item.children.length
      : 0;

      setLayout(
        update(layout, {
          $splice: [
            [foundSource.index, 1],
            [(foundTarget.index + downOffset), 0, foundSource.item],
          ],
        })
      );  

    },
    [
      layout, 
      setLayout
    ]
  );

  return { handleHover };
}