import { Fragment } from 'react'
import UILIB from 'components';
import Drawer from '../Drawers';
import { useDispatch } from 'react-redux';
import { setDrawer } from "store/actions";


export default function DeclineOrder(props) 
{

  const dispatch = useDispatch();

  const approveOrDecline = () => {
    dispatch(setDrawer({ 
      open: true, 
      content: <Drawer.OrderDeclineDrawer 
      ourDeal={props.dealData}
      setOurDeal={props.setDealData}
      history={props.history}
      onCancel={() => dispatch(setDrawer({ open: false, content: null }))} /> 
    }));
  }

  return <Fragment>
    <div className="flex-item width-100">
      <UILIB.Button
        disabled={!props.dealData.status === 1 || !props.dataLoaded.declineType}
        className="red width-100"
        value="Decline"
        onClick={() => approveOrDecline()} />
    </div>
  </Fragment>
}