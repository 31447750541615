import { useCallback } from "react";
import { constants } from "../dnd/Constants";


export default function useOrderProducts(header, data) {
  const handleOrderProducts = useCallback(
    () => {
      
      const parentRowType = (parent) => {
        if(Number(parent.rowType) === 1) return constants.TYPE_BLANKPART;
        if(Number(parent.rowType) === 0 && Number(parent.isADevice) === 1 && parent.hasCompats) return constants.TYPE_CONFIGURATOR;
        if(Number(parent.rowType) === 4 || (Number(parent.isADevice) === 1 && !parent.hasCompats)) return constants.TYPE_NOBIND;
      }

      const ungroupedRowType = (ungrouped) => {
        if(Number(ungrouped.rowType) === 1) return constants.TYPE_BLANKPART;
        if(Number(ungrouped.rowType) === 0 && Number(ungrouped.isADevice) === 0) return constants.TYPE_CONFIGURATOR;
        if(Number(ungrouped.rowType) === 4) return constants.TYPE_NOBIND;
      }

      return data
        .filter(f => Number(f.raw.parentId) === 0 || Number(f.raw.isADevice) === 1)
        .map(x => { 
          const attachments = data
            .filter(f => Number(f.raw.parentId) === Number(x.raw.prodID))
            .map(c => { return { 
              ...c,
              bind: ungroupedRowType(c.raw),
              parentDragging: false,
              id: Number(c.raw.prodID), 
              children: [],
              header: header,
              type: constants.COLUMN 
            } 
          })
          return { 
            id: Number(x.raw.prodID), 
            bind: (Number(x.raw.isADevice) === 1) 
              ? parentRowType(x.raw) : ungroupedRowType(x.raw),
            raw: x.raw,
            table: x.table,
            children: attachments || [],
            header: header,
            type: (Number(x.raw.parentId) === 0 && Number(x.raw.isADevice) === 0) ? constants.UNGROUPED : constants.ROW
          }
        })
    },
    [
      header, 
      data
    ]
  );

  return { handleOrderProducts };
}



