import { useEffect, useState, useRef, Fragment } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UILIB from 'components';
import { fieldWorkflow, getProcurementDropdownData } from '../functions';
import { companyPaymentTermsData, yesNoData, yesNoExistingData, procurementDropdownData } from './functions';
import { purchaseTypes, fieldTypes } from 'classes/quotesHelpers';
import { getCompanyDetails, getCompaniesGrouped } from 'classes/helpers';
import useUpdateDealEntity from '../hooks/useUpdateDealEntity';
import cloneDeep from 'lodash/cloneDeep';

export default function CoreDetails(props) 
{

  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while saving this record, please reload or contact support';
  const versionError = 'A newer version of this record has already been saved. Please reload the data';
  const exceptions = [400,404,409,500];

  const timerObject = useRef({});

  const [render, setRender] = useState(false);
  const [groupData, setGroupData] = useState(undefined);
  const [working, setWorking] = useState(false);

  const { handleUpdateDealEntity } = useUpdateDealEntity(props.ourDeal, setWorking, timerObject);

  function addTitle(title, mandatory) {
    return (
      <td width="200">
        <div className="flex-container row nowrap">
          <div className="flex-item flex-grow-1 start">{title}</div>
          <div className="flex-item end">{Boolean(mandatory) && <span className='text red'>*</span>}</div>
        </div>
      </td>);
  }

  useEffect(() => {     
    const asyncEffect = async () => {
      if(props.ourDeal.largerGroupId) {
        const result = await getCompanyDetails(props.ourDeal.largerGroupId);
        setGroupData((result.length) ? `${result[0]?.GroupCode} - ${result[0]?.companyName}` : '');
      } else {
        setGroupData('');
      }
    }
    asyncEffect();
  }, [props.ourDeal.largerGroupId])

  const showGroup = Number(props.ourDeal.largerGroupSelect) === 1;

  const updateLocalField = async (field, event, timeout) => {

    let localData = cloneDeep(props.ourDeal);

    try {

      switch (field) {
        case fieldTypes.DESCRIPTION:
          localData = { ...props.ourDeal, [field]: event.target.value };
          break;
        case fieldTypes.LARGERGROUPID:
          localData = { ...props.ourDeal, [field]: event.id };
          break;
        case fieldTypes.LARGERGROUPSELECT:
          localData = { 
            ...props.ourDeal, 
            [fieldTypes.LARGERGROUPID]: (Number(event.target.value) !== 1) ? -1 : props.ourDeal[fieldTypes.LARGERGROUPID],
            [field]: event.target.value };
          fieldWorkflow({ ...props, dealData: localData });
          break;
        case fieldTypes.DMCPCNO:
          localData = { ...props.ourDeal, [field]: event.target.value };
          break;
        case fieldTypes.LEASEORCACHE:
          localData = { ...props.ourDeal, [field]: event.target.value };
          fieldWorkflow({ ...props, dealData: localData });
          break;
        case fieldTypes.COMPANYPAYMENTTERMS:
          localData = { ...props.ourDeal, [field]: event.target.value };
          break;
        case fieldTypes.SERVICECONTRACT:

          if(Number(event.target.value) === 3 && props.ourDeal.product) {
            props.ourDeal.product.forEach(product => {
              product.cpcMono = product.cpcMono !== 0 ? 0 : product.cpcMono;
              product.cpcColour = product.cpcColour !== 0 ? 0 : product.cpcColour;
            });
      
            props.setCpcDisabled(true);
          }
          else {
            props.setCpcDisabled(false);
          }

          localData = { 
            ...props.ourDeal, 
            [field]: event.target.value, 
            product: props.ourDeal.product };

          break;
        case fieldTypes.MAINDEAL:
          localData = { ...props.ourDeal, [field]: event.target.value };
          break;
        case fieldTypes.PROCUREMENTLEVEL:
          localData = { ...props.ourDeal, [field]: event.target.value };
          break;
        case fieldTypes.ORGANISATION:
          localData = { ...props.ourDeal, [field]: event.target.value };
          break;
        case fieldTypes.SECTOR:
          localData = { ...props.ourDeal, [field]: event.target.value };
          break;
        default:
            break;
      }

      if(props?.handleCheckErrors) await props.handleCheckErrors(false);
      
      props.setOurDeal({ ...props.ourDeal, ...localData });
      setRender(!render);

      const response = await handleUpdateDealEntity(localData, field, [field], timeout);
      if(exceptions.includes(Number(response.status))) {  
        if(Number(response.status) === 400) toast.error(invalidConfig);
        if(Number(response.status) === 404) toast.error(notFoundError);
        if(Number(response.status) === 409) toast.error(versionError);  
      }

    } catch (error) {
      console.log(error);
      toast.error(exceptionError);
    }
  }


  return <Fragment>
    <UILIB.Paper className="flex-container row height-100">
      <div className="width-100">   
        <table width="100%" cellPadding="0" cellSpacing="0" className="subTable">
          <tbody>
            <tr>
              {addTitle('Description', props.fieldWorkflowObj[fieldTypes.DESCRIPTION])}
              <td>
                <UILIB.TextInput
                  className="textInput input"
                  name={fieldTypes.DESCRIPTION}
                  disabled={!props.isActive || working} 
                  onChange={async (event) => await updateLocalField(fieldTypes.DESCRIPTION, event, 500)} 
                  value={props.ourDeal.description}
                  errored={props?.formErrors && props.formErrors?.description} />
              </td>
            </tr>
            <tr>
              {addTitle('Part of a Trust/Group of Companies', props.fieldWorkflowObj[fieldTypes.LARGERGROUPSELECT])}
              <td>
                <div className="flex-container column nowrap width-100">
                  <UILIB.Select 
                    disabled={!props.isActive || working} 
                    name={fieldTypes.LARGERGROUPSELECT}
                    value={props.ourDeal.largerGroupSelect} 
                    onChange={async (event) => await updateLocalField(fieldTypes.LARGERGROUPSELECT, event, 500)} 
                    data={yesNoData()} 
                    errored={props?.formErrors && props.formErrors?.largerGroupSelect} />
                </div>
              </td>
            </tr>
            {showGroup && <tr>
              {addTitle('Group', props.fieldWorkflowObj[fieldTypes.LARGERGROUPID])}
              <td>
              <div className="flex-item start flex-grow-1">
                <UILIB.AutoComplete 
                  name={fieldTypes.LARGERGROUPID}
                  blank='No Customers Found'
                  defaultValue={groupData}
                  query={getCompaniesGrouped}
                  enableButton={false}
                  errored={props?.formErrors?.largerGroupId}
                  disabled={!Boolean(props.isActive)}
                  onClick={async (event) => await updateLocalField(fieldTypes.LARGERGROUPID, event, 500)} />
                </div>
              </td>
            </tr>}
            <tr>
              {addTitle('PO Number', props.fieldWorkflowObj[fieldTypes.DMCPCNO])}
              <td>
                <UILIB.TextInput
                  className="textInput input"
                  disabled={!props.isActive || working} 
                  name={fieldTypes.DMCPCNO}
                  value={props.ourDeal.dmcPONo}
                  onChange={async (event) => await updateLocalField(fieldTypes.DMCPCNO, event, 500)} 
                  errored={props?.formErrors && props.formErrors?.dmcPONo} />
              </td>
            </tr>
            <tr>
              {addTitle('Purchase Type', props.fieldWorkflowObj[fieldTypes.LEASEORCACHE])}
              <td>
                <UILIB.Select 
                  className="textInput input"
                  disabled={!props.isActive || working} 
                  name={fieldTypes.LEASEORCACHE}
                  type="number"
                  value={props.ourDeal.leaseorcash}
                  onChange={async (event) => await updateLocalField(fieldTypes.LEASEORCACHE, event, 500)} 
                  errored={props.formErrors && props?.formErrors?.leaseorcash} 
                  data={[{ label: 'Please Select', value: '-1' }, ...purchaseTypes.map(i => { return { label: i, value: i }})]} />
              </td>
            </tr>
            {props.fieldWorkflowObj.companyPaymentTerms && <tr>
              {addTitle('Sales Invoice Terms', props.fieldWorkflowObj[fieldTypes.COMPANYPAYMENTTERMS])}
              <td>
                <UILIB.Select 
                  name={fieldTypes.COMPANYPAYMENTTERMS}
                  disabled={!props.isActive || working} 
                  onChange={async (event) => await updateLocalField(fieldTypes.COMPANYPAYMENTTERMS, event, 500)} 
                  value={props.ourDeal.companyPaymentTerms}
                  errored={props?.formErrors && props?.formErrors?.companyPaymentTerms}
                  data={companyPaymentTermsData()} />
              </td>
            </tr>}
            {props.fieldWorkflowObj.serviceContract && <tr>
              {addTitle('Service Agreement', props.fieldWorkflowObj[fieldTypes.SERVICECONTRACT])}
              <td>
                <UILIB.Select 
                  toolTip="Is there a service included as part of this order"
                  name={fieldTypes.SERVICECONTRACT}
                  disabled={!props.isActive || working} 
                  onChange={async (event) => await updateLocalField(fieldTypes.SERVICECONTRACT, event, 500)} 
                  value={String(props.ourDeal.serviceContract)}
                  errored={props?.formErrors && props?.formErrors?.serviceContract}
                  data={yesNoExistingData()} />
              </td>
            </tr>}
            <tr>
              {addTitle('Main Deal', props.fieldWorkflowObj[fieldTypes.MAINDEAL])}
              <td>
                <UILIB.TextInput
                  className="textInput input"
                  name={fieldTypes.MAINDEAL}
                  disabled={!props.isActive || working} 
                  onChange={async (event) => await updateLocalField(fieldTypes.MAINDEAL, event, 500)} 
                  value={props.ourDeal.mainDeal}
                  errored={props?.formErrors && props?.formErrors?.mainDeal} />
              </td>
            </tr>
            {props.fieldWorkflowObj.scottishProcurement && <tr>
              {addTitle('Procurement Level', props.fieldWorkflowObj[fieldTypes.PROCUREMENTLEVEL])}
              <td>
                <UILIB.Select 
                  disabled={!props.isActive || working} 
                  name={fieldTypes.PROCUREMENTLEVEL}
                  value={props.ourDeal.procurementLevel}
                  onChange={async (event) => await updateLocalField(fieldTypes.PROCUREMENTLEVEL, event, 500)} 
                  type="number"
                  errored={props?.formErrors && props.formErrors?.procurementLevel}
                  data={procurementDropdownData()}  />
              </td>
            </tr>}
            {props.fieldWorkflowObj.scottishProcurement && <tr>
              {addTitle('Organisation', props.fieldWorkflowObj[fieldTypes.ORGANISATION])}
              <td>
                <UILIB.Select 
                  disabled={!props.isActive || working} 
                  name={fieldTypes.SECTOR}
                  value={props.ourDeal.organisation} 
                  onChange={async (event) => await updateLocalField(fieldTypes.ORGANISATION, event, 500)} 
                  type="number" 
                  errored={props?.formErrors && props?.formErrors?.organisation}
                  data={getProcurementDropdownData(30, props.ourDeal)}  />
              </td>
            </tr>}
            {props.fieldWorkflowObj.scottishProcurement && <tr>
              {addTitle('Sector', props.fieldWorkflowObj[fieldTypes.SECTOR])}
              <td>
                <UILIB.Select 
                  disabled={!props.isActive || working} 
                  name={fieldTypes.SECTOR}
                  value={props.ourDeal.sector} 
                  onChange={async (event) => await updateLocalField(fieldTypes.SECTOR, event, 500)} 
                  type="number" 
                  errored={props?.formErrors && props?.formErrors?.sector}
                  data={getProcurementDropdownData(29, props.ourDeal)}  />
              </td>
            </tr>}
          </tbody>
        </table>
      </div>
      <div className="flex-container column end flex-grow-1 width-100">
        <UILIB.BannerContainer classNameContent="text-12" background={'colour background-2 royalBlue'} title="Reminder" content={'Make sure the correct Purchase Type is selected.'} /> 
      </div>
    </UILIB.Paper>
  </Fragment>

}