import { useState, useEffect, Fragment } from 'react';
import { checkAccess } from '../functions';
import 'react-toastify/dist/ReactToastify.css'
import { toast } from 'react-toastify';
import UILIB from 'components';
import Axios from 'classes/axios';
import moment from 'moment';


export default function PatchCRM({ ourDeal, setOurDeal, account, isActive }) {

  const [permissions, setPermissions] = useState(false);
  const [loading, setLoading] = useState(false);
  const exceptions = [400,404];
 
  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while saving this record, please reload or contact support';


  useEffect(() => {
    const asyncEffect = async () => {
      const permissionResult = await checkAccess(account, ourDeal);
      setPermissions(permissionResult.users.isDevSop || permissionResult.users.isAdmin);
    }
    asyncEffect();
  }, [account, ourDeal]);

  const handlePatchCRM = async () => {
    try {
      setLoading(true);
      const response = await Axios.patch(`/entities/workflow/deals/actionDealUpdateCRM/${ourDeal.dealID}`);
      if(exceptions.includes(response.status)) {
        if(response.status === 400) toast.error(invalidConfig);
        if(response.status === 404) toast.error(notFoundError);
      } else {
        setOurDeal({ ...ourDeal, crmUpdatedAt: response.data.result[0].crmUpdatedAt });
      }
    } catch (error) {
      console.log(error);
      toast.error(exceptionError);
    } finally {
      setLoading(false);
    }
  }

  if(!permissions || Number(ourDeal.dynamicsStateCode) === 1) return <Fragment></Fragment>

  return <Fragment>
    <div className={`flex-container row width-100`}>
      <div className="flex-item start width-100">
        <UILIB.Button 
          title={ourDeal.crmUpdatedAt && `Last Saved: ${moment(ourDeal.crmUpdatedAt).utc().format("DD/MM/YYYY HH:mm:ss")}`}
          loading={loading} 
          disabled={loading || (Number(ourDeal.processStage) < 3) || (Number(ourDeal.archived) === 1) || !isActive} 
          value="Update CRM" 
          className="green width-100" 
          onClick={async () => await handlePatchCRM()} />
      </div>
    </div>
  </Fragment>
}