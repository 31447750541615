import { useState, useRef, useEffect, memo, Fragment } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setPagePreferences } from 'store/actions';
import UILIB from 'components';
import Axios from 'classes/axios';
import DealLinkTableCell from '../../shared/dealLink';
import BookInstallModal from "../modules/bookInstallModal";
import { getYesNo, actionOpenDrawer, tableContainer, getSelect, getDealRowColour, getNotesColour, getStatusChip, setMainDealFilterVars, calcResetEnable, calcLogisticsStatus } from '../functions';
import Modules from '../Modules';

const Deals = memo(({ permissionsObject, localPermissions, permissions, route, regions, filters, defaultfilters, setFilters, statsRender, setStatsRender }) => {

  const headerData = [
    { label: "#", value: "finalDealNo", type: 'number', filtering: true, minWidth: 50, maxWidth: 50 },
    { label: "Customer", value: "companyName", type: 'string', filtering: true, minWidth: 200, maxWidth: 200 },
    { label: "Group #", value: "groupCode", type: 'string', filtering: true, minWidth: 60, maxWidth: 60 },   
    { label: "Sales Person", value: "salesPersonName", type: 'string', filtering: true, minWidth: 100, maxWidth: 100 },
    { label: "SOP Owner", value: "sopOwnerFullName", type: 'string', filtering: true, minWidth: 100, maxWidth: 100 },
    { label: "Region", value: "salesRegionTxt", type: 'string', filtering: true, minWidth: 60, maxWidth: 60 },
    { label: "# Devices", value: "devicesOnDeal", type: 'number', filtering: true, minWidth: 40, maxWidth: 40 },
    { label: "Days Active", value: "daysActive", type: 'number', filtering: true, minWidth: 50, maxWidth: 50 },
    { label: "Logistics Status", value: "engStatusTxt", type: 'string', filtering: true, minWidth: 100, maxWidth: 100 },
    { label: "Status", value: "sopStatus", type: 'string', filtering: true, minWidth: 60, maxWidth: 60 },
    { label: "Devices Built", value: "devicesBuilt", type: 'number', filtering: true, minWidth: 50, maxWidth: 50 },
    { label: "Dept SLA", value: "deptSLA", type: 'number', filtering: true, minWidth: 50, maxWidth: 50 },
    { label: "Options", value: "options", filtering: false, minWidth: 35, maxWidth: 35, filterArray: getYesNo() },
    { label: "Notes", value: "salesNotes", filtering: false, minWidth: 35, maxWidth: 35, filterArray: getYesNo() }];
 
  const dispatch = useDispatch();
  const history = useHistory();
  const entityData = useRef({});
  const tablePageDefaults = { caching: true, paging: { pages: [10,20,50,100,200], pageSelected: 50, limit: 50, offset: 0, orderBy: 'finalDealNo', orderDir: 'DESC' } };
  const [deviceModalData, setDeviceModalData] = useState({ show: false, entity: null });
  const selectData = { regions: regions, engStatus: getSelect() };
  const [render, setRender] = useState(false);
  const [localRender, setLocalRender] = useState(false);
  const [processing, setProcessing] = useState(false);
  const preferences = useSelector((state) => state.pagePreferences);


  const handleChangeStatus = async (event, row) => {
    const engStatus = Number(event.target.value);
    setProcessing(true);
    const dealResult = await Axios.put(`/entities/deal/${row.dealID}`, { entity: { engStatus: engStatus } }).then(api => api.data);
    if(engStatus === 10) {
      entityData.current.result = entityData.current.result.filter(x => Number(x.dealID) !== Number(row.dealID));
    } else {
      const found = entityData.current.result.findIndex(x => Number(x.dealID) === Number(row.dealID));
      entityData.current.result[found] = { ...row, ...dealResult.result[0] }; }
    setProcessing(false);
    setLocalRender(!localRender);
    setStatsRender(!statsRender);
  };

  const handleResetfilters = () => {
    window.history.pushState({}, document.title, "/logisticsHub");
    setFilters(defaultfilters);
    setRender(!render);
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const qString = Object.fromEntries(urlSearchParams.entries());

    let injectFilter = {
      name: "finalDealNo",
      type: "number",
      value: "",
      filter: 1,
    }

    if (qString?.dealId) {
      injectFilter = {
        name: "finalDealNo",
        type: "number",
        value: qString.dealId,
        filter: 2,
      }
    }

    const found = preferences?.DealsLogistics?.filters.findIndex(x => x.name === 'finalDealNo');
    if(!isNaN(found) && found !== -1) {
      preferences.DealsLogistics.filters[found] = injectFilter;
      dispatch(setPagePreferences({...preferences, DealsLogistics: { ...preferences.DealsLogistics, filters: [...preferences.DealsLogistics.filters]}}));
      window.history.pushState({}, document.title, "/logisticsHub");
    }
  }, [render]);

  async function constructTable(dealData) {

    const permissionDealLink = permissionsObject('orderLink', localPermissions.current.permissions, permissions, route);

    const tableData = dealData.map(row => {

      const bgColour = getDealRowColour(row);
      
      const b_hasNotes = Boolean(row.dealNote && row.dealNote.length);
      const salesNotesColour = getNotesColour(row);
      const fn_SalesNotes = actionOpenDrawer.bind(null, 'ViewNotesDrawer', row, null, dispatch, true, { get: localRender, set: setLocalRender }, '400px');

      const options = <Modules.DeviceModalContainer row={row} setDeviceModalData={setDeviceModalData} />;
      const phaseTwo = <UILIB.Chip toolTip="Phase 2" className="table colour background grey text-small width-100" value="Phase 2" />;

      const selectControl = <UILIB.Select 
        disabled={processing}
        className="tableFilter tableInput mar-b0" 
        outerclassname="tableInput mar-b0" 
        value={row.engStatus} 
        data={calcLogisticsStatus(row)} 
        onChange={async (ev) => await handleChangeStatus(ev, row)} />;

      return {
        finalDealNo: DealLinkTableCell(row, history, route, permissionDealLink.entityEnable, bgColour, true, 'class'),
        companyName: { value: row.companyName, raw: row.companyName, class: bgColour },
        groupCode: { value: row.groupCode, raw: row.groupCode, class: bgColour },
        salesPersonName: { value: row.salesPersonName, raw: row.salesPersonName, class: bgColour },
        salesRegionTxt: { value: row.salesRegionTxt, raw: row.salesRegionTxt, class: bgColour },
        engStatusTxt: { value: selectControl, raw: row.engStatusTxt },
        devicesBuilt: { value: phaseTwo, raw: 0 },
        deptSLA: { value: phaseTwo, raw: 0 },
        devicesOnDeal: { value: row.devicesOnDeal, raw: row.devicesOnDeal, class: bgColour },
        daysActive: { value: row.daysActive, raw: row.daysActive, class: bgColour },
        sopOwnerFullName: { value: row.sopOwnerFullName, raw: row.sopOwnerFullName, class: bgColour },
        sopStatus: { value: getStatusChip(row), raw: row.sopStatus  },
        salesNotes : { value: tableContainer(fn_SalesNotes, 'icon-pencil5', 'View Notes', salesNotesColour, true), raw: b_hasNotes },
        options : { value: options, raw: 0 }
      }
    })
    return tableData;
  }

  const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken) => {

    const queryLocal = (query !== null) ? query : '';
    const pagingLocal = (limit !== null && offset !== null) ? `&$limit=${limit}&$offset=${offset}` : '';
    const orderLocal = (orderBy !== null && orderDir !== null) ? `&$order=${orderBy}&$direction=${orderDir}` : '';
    const calcFilters = setMainDealFilterVars(filters);
    const expandArray = ['dealNote'];

    setFilters({ ...filters, queryString: `${[calcFilters].join(' and ')}${queryLocal}` });

    entityData.current = await Axios.get(`/entities/deals/getSummary/?&$filter=${[calcFilters].join(' and ')}${queryLocal}&$expand=${expandArray.map(x => x).join(' and ')} ${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(api => api.data);

    if(!entityData.current || !entityData.current.result.length) return false;

    return { tableData: await constructTable(entityData.current.result), nonePaged: entityData.current.nonePaged };
  }


  return <Fragment>
    {deviceModalData.show && <BookInstallModal 
      history={history} 
      pdiModalData={deviceModalData} 
      setPdiModalData={setDeviceModalData} />}
    <UILIB.Paper className='width-100'>
      <div className='flex-container row mar-b10'>
        <div className='flex-item flex-grow-1 end'>
          <UILIB.Button disabled={calcResetEnable(filters, defaultfilters)} className={"mar-l20 mar-r5 small green"} name="resetFilters" value="Reset Filters" onClick={() => handleResetfilters()} />
          <UILIB.Button value="Filters" onClick={() => { actionOpenDrawer('DealFiltersDrawer', { ...selectData, filters: filters, setFilters: setFilters }, null, dispatch, true, { get: render, set: setRender }, '400px') }} />
        </div>
      </div>
      <UILIB.TableNew
        name={`DealsLogistics`}
        className='small'
        overflowX='auto'
        overflowY='hidden'    
        header={headerData}
        localQuery={() => constructTable((entityData.current && entityData.current.result) ? entityData.current.result : [])}
        localRender={[processing, localRender]}
        remoteQuery={getTableData}
        remoteRender={[filters.button, render]}
        defaultSettings={tablePageDefaults} />
    </UILIB.Paper>
  </Fragment>
})
export default Deals