import { Fragment } from 'react'
import UILIB from 'components';
import Drawer from '../Drawers';
import { useDispatch } from 'react-redux';
import { setDrawer } from "store/actions";


export default function CopyQuote(props) 
{

  const dispatch = useDispatch();

  const copyQuote = () => {
    dispatch(setDrawer({
      open: true,
      width: "1000px",
      content: <Drawer.QuoteCopyDrawer
        onFinished={() => dispatch(setDrawer({ open: false, content: null }))}
        account={props.account}
        ourDeal={props.ourDeal}
        isActive={props.isActive}
      />
    }));
  }

  return <Fragment>
    <div className="flex-item width-100">
      <UILIB.Button
        disabled={!props.isActive}
        className="small grey width-100"
        value="Clone Previous Quote"
        title='Clones non customer specific data including parts and pricing from a previous quote'
        onClick={() => copyQuote()} />
    </div>
  </Fragment>
}