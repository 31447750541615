import { Fragment } from 'react';
import UILIB from 'components';
import AddressesPage from './addresses';
import ContactsPage from './contacts';
import CRMDetails from './crmDetails';
import CoreDetails from './coreDetails';
import LeaseDetails from './leaseDetails';
import Settlement from './settlement';
import AddNotes from './addNotes';


export default function OrderDetailPage(props) 
{

    const externalNotes = 'External Notes';
    const internalNotes = 'Internal Notes';

    const headerClasses = {
        class: props.fieldWorkflowObj.hidePanelRight ? "col-xs-12 col-sm-12 col-md-4 mar-b15" : "col-xs-12 col-sm-12 col-md-4 mar-b15"
    }


    return <Fragment> 
      <div style={{ minHeight: "300px" }} className={headerClasses.class}>
        <CRMDetails  
          ourDeal={props.ourDeal}
          setOurDeal={props.setOurDeal}
          account={props.account}
          isActive={props.isActive}
          updateDealDataType={2} />
      </div>
      <div style={{ minHeight: "300px" }} className={headerClasses.class}>
        <CoreDetails  
          ourDeal={props.ourDeal}
          setOurDeal={props.setOurDeal}
          isActive={props.isActive}
          fieldWorkflowObj={props.fieldWorkflowObj}
          setFieldWorkflowObj={props.setFieldWorkflowObj}
          fieldWorkflow={props.fieldWorkflow}
          formErrors={props.formErrors}
          setFormErrors={props.setFormErrors}
          setCpcDisabled={props.setCpcDisabled}
          handleCheckErrors={props.handleCheckErrors} />
      </div>  
      <div style={{ minHeight: "300px" }} className={headerClasses.class}>                  
        <LeaseDetails  
          ourDeal={props.ourDeal}
          setOurDeal={props.setOurDeal}
          isActive={props.isActive}
          fieldWorkflowObj={props.fieldWorkflowObj}
          setFieldWorkflowObj={props.setFieldWorkflowObj}
          formErrors={props.formErrors}
          handleCheckErrors={props.handleCheckErrors} />
      </div>    
      <UILIB.Paper className="flex-container column width-100 mar-b15 mar-l8 mar-r8">
        <AddressesPage 
          ourDeal={props.ourDeal} 
          isActive={props.isActive}
          setOurDeal={props.setOurDeal} 
          dataLoaded={props.dataLoaded}
          setDealAddress={props.setDealAddress}
          formErrors={props.formErrors}
          addressType={0}
          maxRows={6} />
      </UILIB.Paper>
      <UILIB.Paper className="flex-container column width-100 mar-b15 mar-l8 mar-r8">
        <ContactsPage 
          ourDeal={props.ourDeal} 
          isActive={props.isActive}
          setOurDeal={props.setOurDeal} 
          dataLoaded={props.dataLoaded}
          additionalInfoErrors={props.additionalInfoErrors}
          refreshData={props.refreshData}
          handleCheckErrors={props.handleCheckErrors} />
      </UILIB.Paper>
      <UILIB.Paper className="flex-container column width-100 mar-b15 mar-l8 mar-r8">
        <Settlement dataLoaded={props.dataLoaded} ourDeal={props.ourDeal} setOurDeal={props.setOurDeal} isActive={props.isActive} />
      </UILIB.Paper>
      <UILIB.Paper className="flex-container column width-100 mar-b15 mar-l8 mar-r8">
        <AddNotes dataLoaded={props.dataLoaded} ourDeal={props.ourDeal} context={externalNotes} isActive={props.isActive} />
      </UILIB.Paper>
      <UILIB.Paper className="flex-container column width-100 mar-b15 mar-l8 mar-r8">
        <AddNotes dataLoaded={props.dataLoaded} ourDeal={props.ourDeal} context={internalNotes} isActive={props.isActive} />
      </UILIB.Paper>  
    </Fragment >
}