import { useCallback } from "react";
import useFindItem from '../hooks/useFindItem';

export default function useFindNestedParent(items) {

  const { handleFindItem } = useFindItem(items);

  const handleFindNestedParent = useCallback(
    (id) => {
      const nested = items.reduce((acc, obj) => acc.concat(obj?.children), []);
      const item = nested.find(x => Number(x?.id) === Number(id));
      const parent = handleFindItem(item?.raw?.parentId);
      return {
        ...parent
      };
    },
    [items, handleFindItem]
  );

  return { handleFindNestedParent };
}