import { Fragment, memo } from 'react';

const Checkbox = memo(({ 
  key = null,
  className, 
  name, 
  checked = false, 
  onChange = async () => { }, 
  disabled = false 
}) => 
{

  return <Fragment>
      <input
        key={key}
        name={name}
        className={`checkbox ${className} `}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={disabled} />
    </Fragment>
})
export default Checkbox