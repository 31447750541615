import { useCallback } from "react";
import useFindNestedParent from '../hooks/useFindNestedParent';
import useFindNestedItem from '../hooks/useFindNestedItem';
import { constants } from "../dnd/Constants";

export default function useBuildCellStyle(layout, header) {

  const { handleFindNestedParent } = useFindNestedParent(layout);
  const { handleFindNestedItem } = useFindNestedItem(layout);

  const handleCalculateRowType = useCallback(
    (row) => {

      try {

        const calculateContext = (item, parent, nested) => {

          if(!nested?.item && item.type === constants.ROW && item.children.length) {
            return constants.PARENT_WITH_CHILD;
          }
  
          if(!nested?.item && item?.type === constants.ROW && !item?.children?.length) {
            return constants.PARENT_WITHOUT_CHILD;
          }
  
          if(nested?.item && nested?.item?.type === constants.COLUMN && (nested.index + 1) < parent?.item?.children?.length) {
            return constants.CHILD_NESTED_BEFORE;
          }
  
  
          if(nested?.item && nested.item.type === constants.COLUMN && (nested.index + 1) === parent?.item?.children?.length) {
            return constants.CHILD_NESTED_AFTER;
          }
  
          if(item?.type === constants.UNGROUPED) {
            return constants.CHILD_UNGROUPED;
          }

          return '';
        }

        const parent = handleFindNestedParent(row.id);
        const nested = handleFindNestedItem(row.id);

        row.context = calculateContext(row, parent, nested);

        row.children.forEach(c => {

          const parent = handleFindNestedParent(c.id);
          const nested = handleFindNestedItem(c.id);

          c.context = calculateContext(c, parent, nested);
        })


      } catch (ex) {
        console.log(ex)
      }
    },
    [
      handleFindNestedItem,
      handleFindNestedParent
    ]
  );

  const handleCalculateCellStyle = useCallback(
    (item, cellIndex) => {

      try {

        const headFiltered = header.filter(x => x !== undefined);
        const cellIndexIncrement = Number((cellIndex + 1));

        switch(item.context) {
          case constants.PARENT_WITH_CHILD:
              if(cellIndexIncrement === 1) return 'dnd parent-with-child-cell-start';
              if(cellIndexIncrement !== 1 && cellIndexIncrement < headFiltered?.length) return 'dnd parent-with-child-cell-middle';
              if(cellIndexIncrement === headFiltered?.length) return 'dnd parent-with-child-cell-end';
            break;
          case constants.PARENT_WITHOUT_CHILD:
              if(cellIndexIncrement === 1) return 'dnd parent-without-child-cell-start';
              if(cellIndexIncrement !== 1 && cellIndexIncrement < headFiltered?.length) return 'dnd parent-without-child-cell-middle';
              if(cellIndexIncrement === headFiltered?.length) return 'dnd parent-without-child-cell-end';
            break;
          case constants.CHILD_NESTED_BEFORE:
              if(cellIndexIncrement === 1) return 'dnd child-nested-before-cell-start';
              if(cellIndexIncrement !== 1 && cellIndexIncrement < headFiltered?.length) return 'dnd child-nested-before-cell-middle';
              if(cellIndexIncrement === headFiltered?.length) return 'dnd child-nested-before-cell-end';
            break;
          case constants.CHILD_NESTED_AFTER:
              if(cellIndexIncrement === 1) return 'dnd child-nested-after-cell-start';
              if(cellIndexIncrement !== 1 && cellIndexIncrement < headFiltered?.length) return 'dnd child-nested-after-cell-middle';
              if(cellIndexIncrement === headFiltered?.length) return 'dnd child-nested-after-cell-end';
            break;
          case constants.CHILD_UNGROUPED:
              if(cellIndexIncrement === 1) return 'dnd child-ungrouped-cell-start';
              if(cellIndexIncrement !== 1 && cellIndexIncrement < headFiltered?.length) return 'dnd child-ungrouped-cell-middle';
              if(cellIndexIncrement === headFiltered?.length) return 'dnd child-ungrouped-cell-end';
            break;
          default:
            return '';
        }

      } catch (ex) {
        console.log(ex)
      }
    },
    [header]
  );

  return { handleCalculateRowType, handleCalculateCellStyle };
}