import { useEffect, useState, Fragment } from 'react';
import axios from 'classes/axios';
import UILIB from 'components';
import { formatCurrency } from 'classes/format';



export default function ProductSelected(props) {

    const [tdStyle, setTdStyle] = useState({});
    const [render, setRender] = useState(false);
    var [errorStyle, setErrorStyle] = useState({});
    var [totalPrice, setTotalPrice] = useState(0);
    var [costPrice, setCostPrice] = useState(0);
    var salesPartTypeIds = [];


    useEffect(() => {
      async function asyncEffect() {
        try {

          if (props.portalProductId && !props.finishedLoading) {

            props.setSelectedProduct(props.ourDeal.product);

            await props.functions.getCompatibles(props);

            let typeData = await axios.get(`/sales/parts/type`);

            typeData = typeData.data.map(t => { return { label: t.name, value: t.id, displayOrder: t.displayOrder } });
            typeData.unshift({ label: "All Types", value: 0, displayOrder: 0 })
            
            props.setPartTypes(typeData);
            
            if (props.compatibles && props.compatibles.length) {
              props.compatibles.forEach(x => x.selected = false); 
              props.compatibles.forEach(x => x.qty = 1); 
            }
            
            const existingProduct = props?.ourDeal?.product.find(x => Number(x.prodID) === Number(props.dealProductId));
            const attachments = props?.ourDeal?.product
              .filter(x => Number(x.prodID) !== Number(props.dealProductId) && Number(x.parentId) === Number(props.dealProductId))
              .map(attachment => {
                return {
                  ...attachment,
                  prodID: attachment.prodID,
                  name: attachment.description,
                  supplier: attachment.supplier,
                  discount: attachment.discount,
                  basePrice: attachment.costEach,
                  priceEach: attachment.priceEach,
                  qty: attachment.qty
                } });

            const compatIds = attachments.map(x => Number(x.portalPartId));
            const newExistingProduct = existingProduct.portalPart;

            newExistingProduct.prodID = existingProduct.prodID;
            newExistingProduct.name = existingProduct.description;
            newExistingProduct.supplier = existingProduct.supplier;
            newExistingProduct.discount = existingProduct.discount;
            newExistingProduct.cpcMono = (existingProduct?.cpcMono) ? existingProduct.cpcMono : '';
            newExistingProduct.cpcColour = (existingProduct?.cpcColour) ? existingProduct.cpcColour : '';
            newExistingProduct.priceEach = (existingProduct?.priceEach) ? existingProduct.priceEach : '';
            newExistingProduct.basePrice = (existingProduct?.costEach) ? existingProduct.costEach : existingProduct.origPriceEach;
            newExistingProduct.qty = (existingProduct?.qty) ? existingProduct.qty : '';

            if (props.compatibles && props.compatibles.length) {

              props.compatibles.forEach(x => {
                const found = attachments.find(f => Number(f.portalPartId) === Number(x.id));
                x.selected = compatIds.includes(Number(x.id));
                x.qty = (found) ? found.qty : 1; });

              const compatsMapped = props.compatibles.map(x => {
                const found = attachments.find(p => Number(p.portalPartId) === Number(x.id));
                if(found) return { ...x, ...found };
                return x;
              });

              props.setCompatibles(compatsMapped); 
            }

            props.setSelectedProduct({ ...props.product.portalPart, ...newExistingProduct });
          }
          else {
            if(Object.entries(props.selectedProduct).length > 0 && !props.finishedLoading) {

              props.setSelectedProduct(props.selectedProduct);

              await props.functions.getCompatibles(props);

              let typeData = await axios.get(`/sales/parts/type`);

              typeData = typeData.data.map(t => { return { label: t.name, value: t.id, displayOrder: t.displayOrder } });
              typeData.unshift({ label: "All Types", value: 0, displayOrder: 0 })
              
              props.setPartTypes(typeData);

              if (props.product && !props.product.specialPrice) props.product.specialPrice = 0;
              if (props.product && !props.product.discount) {
                  props.product.discount = Number((((parseInt(props.product.listPrice) - parseInt(props.product.basePrice)) / parseInt(props.product.listPrice)) * 100).toFixed(3));
              }
              if (props.product && !props.product.priceEach) props.product.priceEach = '';
              if (props.product && !props.product.supplier) props.product.supplier = props.product.SalesPartSupplier.name;
  
              setTotalPrice(Number.isNaN(parseInt(props.selectedProduct.priceEach)) ? 0 : parseInt(props.selectedProduct.priceEach) || 0);
              setCostPrice(props.selectedProduct.basePrice || 0);
              setErrorStyle({});
              setTdStyle({ borderBottom: "1px solid #DDDDDD" });

              if(props.selectedProduct.SalesPartTypeId !== 34) {
                await props.functions.checkAndSave(props, true);
              } 
              else if(props.selectedProduct.SalesPartTypeId === 34 && props.compatibles.length === 0 && props.compatiblesLoaded) {
                await props.functions.checkAndSave(props, true);
              }
            }
          }
        }
        catch (err) {
          console.log(err)
          props.pageErrors.saveAndExit = { error: true, message: <p className="text-red">&#9888; There was an error adding this part, please try again.</p> }
          props.setPageErrors(props.pageErrors);
          props.functions.showError(props)
        }
      }
      asyncEffect().then(() => props.setFinishedLoading(true))
    }, [props.selectedProduct, props.compatiblesLoaded])


    const updateCompat = (event, compat) => {

        let compatObj = JSON.parse(JSON.stringify(props.compatibles));
        let compatible = compatObj.find(c => Number(c.id) === Number(compat.id));
        let compatibleIndex = compatObj.findIndex(obj => Number(obj.id) === Number(compat.id));

        switch (event.target.name) {
            case "basePrice":
                if (!isNaN(event.target.value) && event.target.value !== '') {
                    const listPrice = Number(compatObj[compatibleIndex].listPrice);
                    const basePrice = Number(event.target.value);
                    const discount = basePrice > listPrice ? 0 : Math.abs((basePrice - listPrice) / listPrice * 100);
                    compatObj[compatibleIndex].discount = Number(discount).toFixed(2);
                    compatObj[compatibleIndex].basePrice = Number(basePrice);
                }
                else {
                    compatible.discount = '';
                    compatible.basePrice = '';
                }
                break;
            case "discount":
                if (!isNaN(event.target.value) && event.target.value !== '') {
                    const listPrice = Number(compatObj[compatibleIndex].listPrice);
                    const discount = Number(event.target.value);
                    const basePrice = listPrice - ((listPrice / 100) * discount);
                    compatObj[compatibleIndex].basePrice = Number(basePrice.toFixed(2));
                    compatObj[compatibleIndex].discount = Number(discount);
                }
                else {
                    compatible.discount = '';
                    compatible.basePrice = '';
                }
                break;
            case "priceEach":
                if (!isNaN(event.target.value) && event.target.value !== '') {
                    compatible.priceEach = Number(event.target.value);
                }
                else {
                    compatible.priceEach = '';
                }
                break;
            case "qty":
                if (!isNaN(event.target.value) && event.target.value !== '' && event.target.value !== '0') {
                    compatible.qty = Number(event.target.value);
                }
                else {
                    compatible.qty = 0;
                    compatible.selected = false;
                }
                break;
            default:
                break;
        }
        props.setCompatibles(compatObj);
    }

    const clearLocalField = (event, selectedP) => {
        let newSelectedProduct = JSON.parse(JSON.stringify(selectedP));
        newSelectedProduct[event.target.name] = '';
        props.setSelectedProduct(newSelectedProduct);
    }

    const updateSelectedProduct = (event, selectedP) => {

        let newSelectedProduct = JSON.parse(JSON.stringify(selectedP));

        switch (event.target.name) {
            case "basePrice":
                if (!isNaN(event.target.value) && event.target.value !== '') {
                    const listPrice = Number(newSelectedProduct.listPrice);
                    const basePrice = Number(event.target.value);
                    const discount = basePrice > listPrice ? 0 : Math.abs((basePrice - listPrice) / listPrice * 100);
                    newSelectedProduct.discount = Number(discount).toFixed(2);
                    newSelectedProduct.basePrice = Number(basePrice);
                }
                else {
                    newSelectedProduct.discount = '';
                    newSelectedProduct.basePrice = '';
                }
                break;
            case "discount":
                if (!isNaN(event.target.value) && event.target.value !== '') {
                    const listPrice = Number(newSelectedProduct.listPrice);
                    const discount = Number(event.target.value);
                    const basePrice = listPrice - ((listPrice / 100) * discount);
                    newSelectedProduct.basePrice = Number(basePrice.toFixed(2));
                    newSelectedProduct.discount = Number(discount);
                }
                else {
                    newSelectedProduct.discount = '';
                    newSelectedProduct.basePrice = '';
                }
                break;
            case "priceEach":
                if (!isNaN(event.target.value) && event.target.value !== '') {
                    newSelectedProduct.priceEach = Number(event.target.value);
                }
                else {
                    newSelectedProduct.priceEach = '';
                }
                break;
            case "cpcMono":
                if (!isNaN(event.target.value) && event.target.value !== '') {
                    newSelectedProduct.cpcMono = Number(event.target.value);
                }
                else {
                    newSelectedProduct.cpcMono = 0;
                }
                break;
            case "cpcColour":
                if (!isNaN(event.target.value) && event.target.value !== '') {
                    newSelectedProduct.cpcColour = Number(event.target.value);
                }
                else {
                    newSelectedProduct.cpcColour = 0;
                }
                break;
            default:
                break;
        }
        props.setSelectedProduct(newSelectedProduct);
    }


    props.compatibles.filter(c => c.selected === true).forEach(p => {
        if (p.selected) {
            totalPrice += isNaN(p.priceEach) ? 0 : Number(p.priceEach) * Number(p.qty);
            costPrice += isNaN(p.basePrice) ? 0 : Number(p.basePrice) * Number(p.qty);
        }
    })

    props.partTypes.map(pty => (
        props.compatibles.forEach(p => {
            if (pty.value === p.SalesPartTypeId && salesPartTypeIds.findIndex((obj => obj && obj.value === p.SalesPartTypeId)) < 0) salesPartTypeIds.push(pty)
        })
    ))

    
    totalPrice = parseFloat(totalPrice + props.selectedProduct.priceEach).toFixed(2);
    costPrice = parseFloat(costPrice + props.selectedProduct.basePrice).toFixed(2);


    const actionselectCompat = (props, compat) => {
      props.functions.selectCompat(props, compat);
      setRender(!render);
    }


    if (!props.finishedLoading || !props.compatiblesLoaded) {
      return <UILIB.Loading type={3} />
    }
    else {
      return <div className="productConfiguratorSelected  mar-l10" style={{ display: "flex" , flexDirection: "column", height: "100%" }}>
          <div className="flex-container mar-b20 flex-container between" style={{ flex: 0 }}>
              <h2 className="mar-b0 mar-r15">Configure your Product</h2>
              {props.saveError.current.error && <div style={{ backgroundColor: "red", color: "white", padding: "3px" }}>{props.saveError.current.message}</div>}
              <div className="flex-container row">
                  <UILIB.Button 
                    loading={props.saving}
                    disabled={props.saving}
                    value="Save and Exit" 
                    className="mar-r10" 
                    onClick={async () => { 
                      await props.functions.checkAndSave(props, true);
                    }}
                  />
                  <UILIB.Button 
                    disabled={props.saving} 
                    value="Cancel" 
                    className="red mar-r10" 
                    onClick={props.onCancel} />
              </div>
          </div>
          {props.pageErrors.saveAndExit.error && <div className="flex-container row" style={{ height: "20px", display: 'flex' , flexDirection: 'row-reverse', marginBottom: 10, paddingRight: 10 }}>
          {props.pageErrors.saveAndExit.message}
          </div>}
          <div className="flex-container row" style={{ height: "calc(100% - 80px)" }}>
              <div className="col-xs-12 col-sm-10 mar-b15" style={{ flex: 1, height: "100%" }}>
                  {!props.compatiblesLoaded && <UILIB.Loading type={3} />}
                  {props.compatiblesLoaded && salesPartTypeIds.length === 0 && <div className="rightCol mar-b15">
                      <h4 style={{ margin: "0", padding: "0" }} className="mar-b15" > Compatible Parts</h4>
                      No Compatible Parts Available for this product
                  </div>}
                  {props.compatiblesLoaded && salesPartTypeIds.length > 0 && <div className="rightCol" style={{ flex: 1, height: "100%" }}>
                      <div style={{ height: "calc(100% - 35px)", overflow: "auto" }}>
                          <table width="100%" height="100%" cellPadding="5" cellSpacing="0">
                              <tr>
                                <td width="2%" style={{ fontSize: "12px" }}>Include</td>
                                <td width="2%" style={{ fontSize: "12px" }}>Qty</td>
                                <td width="5%" style={{ fontSize: "12px" }}>Part No</td>
                                <td style={{ fontSize: "12px" }}>Product</td>
                                <td width="8%" style={{ fontSize: "12px" }}>Default Supplier</td>
                                <td width="5%" align="center" style={{ fontSize: "12px" }}>List Price</td>
                                <td width="5" align="center" style={{ fontSize: "12px" }}>Discount</td>
                                <td width="6%" align="center" style={{ fontSize: "12px" }}>Cost</td>
                                <td width="5%" align="center" style={{ fontSize: "12px" }}>Sale</td>
                              </tr>
                              <tr>
                                <td colSpan="9"><hr /></td>
                              </tr>
                              <tr>
                                <td colSpan="9" style={{ fontWeight: "bold", fontSize: "18px" }}>
                                    {props.selectedProduct.partNumber + " " + props.selectedProduct.name}
                                </td>
                              </tr>
                              <tr>
                                  <td colSpan="4">
                                    <div className="text-small mar-b5" style={{ ...errorStyle, maxHeight: "150px", overflow: "auto" }}>
                                      <div className="flex-container text-12">
                                        {props.selectedProduct.description}
                                      </div>
                                    </div>
                                  </td>
                                  <td width="5%" style={{ ...tdStyle, ...errorStyle }} valign="top" align="center">
                                      <div className="flex-container text-14">
                                        {props.selectedProduct.supplier}
                                      </div>
                                  </td>
                                  <td width="5%" style={{ ...tdStyle, ...errorStyle }} valign="top" align="center">
                                      <div className="flex-container text-14">
                                      {formatCurrency(props.selectedProduct.listPrice, 0)}
                                      </div>
                                  </td>
                                  <td width="5%" style={{ ...tdStyle, ...errorStyle }} valign="top" align="center">
                                      <div className="flex-container">
                                          <UILIB.TextInput
                                              name="discount"
                                              className="textInput input"
                                              placeholder="0"
                                              type="number"
                                              inputmode="numeric"
                                              value={props.selectedProduct.discount}
                                              style={{ textAlign: "left", width: "60px", height: "25px", padding: "0" }}
                                              onChange={(event) => { updateSelectedProduct(event, props.selectedProduct) }}
                                          />
                                          <div>%</div>
                                      </div>
                                  </td>
                                  <td width="5%" style={{ ...tdStyle, ...errorStyle }} valign="top" align="center">
                                      <div className="flex-container">
                                          <UILIB.TextInput
                                              name="basePrice"
                                              className="textInput input"
                                              placeholder="0"
                                              type="number"
                                              inputmode="numeric"
                                              value={props.selectedProduct.basePrice}
                                              style={{ textAlign: "left", width: "80px", height: "25px", padding: "0" }}
                                              onChange={(event) => { updateSelectedProduct(event, props.selectedProduct) }}
                                          />
                                      </div>
                                  </td>
                                  <td width="5%" style={{ ...tdStyle, ...errorStyle }} valign="top" align="center">
                                      <div className="flex-container text-small">
                                          <UILIB.TextInput
                                              name="priceEach"
                                              className="textInput input"
                                              placeholder="0"
                                              type="number"
                                              inputmode="numeric"
                                              value={props.selectedProduct.priceEach}
                                              style={{ textAlign: "left", width: "80px", height: "25px", padding: "0" }}
                                              onChange={(event) => { updateSelectedProduct(event, props.selectedProduct) }}
                                          />
                                      </div>
                                  </td>
                              </tr>
                              <tr>
                                <td colSpan="9"><hr /></td>
                              </tr>

                              {salesPartTypeIds.map((typeId, index) => {

                                  let partTypeName = "Misc";
                                  const thisPartType = props.partTypes.find(pt => Number(pt.value) === Number(typeId.value));
                                  if (thisPartType) partTypeName = thisPartType.label;

                                  const products = props.compatibles.filter(f => Number(f.SalesPartTypeId) === Number(typeId.value))

                                  return <Fragment>
                                      <tr>
                                          <td colSpan="10" style={{ paddingBottom: "15px" }}>
                                              <h4 style={{ margin: "0", padding: "0" }}>{partTypeName}</h4>
                                          </td>
                                      </tr>

                                      {products.map((compat, index) => {
                                          let supplier = compat.SalesPartSupplier.name;
                                          if (compat.selected) supplier = compat.supplier;

                                          let prodErrorStyle = {}
                                          let prodTdStyle = {};
                                          let selectedConflicts = products.filter(p => Number(p.id) !== Number(compat.id)).filter(p => {
                                              if (p.selected) {
                                                  let foundConflict = p.ConflictingParent.find(cp => Number(cp.id) === Number(compat.id));
                                                  if (foundConflict) return true
                                              }
                                              return false;
                                          }
                                          )
                                          let hasConflict = selectedConflicts.length > 0;
                                          let errored = compat.error.length;
                                          if (errored) {
                                              prodErrorStyle.backgroundColor = "red";
                                              prodErrorStyle.color = "white";
                                          }

                                          if (hasConflict) return undefined;
                                          
                                          return <tr>
                                              <td width="2%" style={{ width: "10px", ...prodTdStyle, ...prodErrorStyle }} valign="top">
                                                  {!hasConflict && <UILIB.Checkbox
                                                      name={"componentSelect_" + compat.id}
                                                      value="selected"
                                                      onChange={(event) => { if (!hasConflict) actionselectCompat(props, compat, event) }}
                                                      checked={compat.selected} />
                                                  }
                                              </td>
                                              <td width="2%" style={{ width: "10px", ...prodTdStyle, ...prodErrorStyle }} valign="top">
                                                  {compat.selected && <UILIB.TextInput
                                                      name="qty"
                                                      className="textInput input"
                                                      type="number"
                                                      value={compat.qty}
                                                      style={{ textAlign: "left", width: "80px", height: "25px", padding: "0" }}
                                                      onChange={(event) => { updateCompat(event, compat) }}
                                                  />}
                                              </td>
                                              <td width="5%" style={{ ...prodTdStyle, ...prodErrorStyle }} valign="top">
                                                  <div className="flex-container text-14">
                                                    {compat.partNumber}
                                                  </div>
                                              </td>
                                              <td width="45%" style={{ ...prodTdStyle, ...prodErrorStyle }} valign="top">
                                                  <div className="flex-container text-14">
                                                    {compat.name}
                                                  </div>
                                                  <div className="text-small mar-b5 text-11" style={{ ...prodErrorStyle, maxHeight: "100px", overflow: "auto" }}>{compat.description}</div>
                                              </td>
                                              <td width="5%" style={{ ...prodTdStyle, ...prodErrorStyle }} valign="top">
                                                  <div className="flex-container text-14">
                                                    {supplier}
                                                  </div>
                                              </td>
                                              <td width="5%" style={{ ...prodTdStyle, ...prodErrorStyle }} valign="top" align="center">
                                                  <div className="flex-container text-14">
                                                    &pound;{formatCurrency(compat.listPrice, 2)}
                                                  </div>
                                              </td>
                                              <td width="5%" style={{ ...prodTdStyle, ...prodErrorStyle }} valign="top" align="center">
                                                  <div className="flex-container">
                                                      <UILIB.TextInput
                                                          name="discount"
                                                          className="textInput input"
                                                          placeholder="0"
                                                          type="number"
                                                          inputmode="numeric"
                                                          value={compat.discount}
                                                          style={{ textAlign: "left", width: "60px", height: "25px", padding: "0" }}
                                                          onChange={(event) => { updateCompat(event, compat) }}
                                                      />
                                                      <div style={errorStyle}>%</div>
                                                  </div>
                                              </td>
                                              <td width="5%" style={{ ...prodTdStyle, ...prodErrorStyle }} valign="top" align="center">
                                                  <div className="flex-container">
                                                      <UILIB.TextInput
                                                          name="basePrice"
                                                          className="textInput input"
                                                          placeholder="0"
                                                          type="number"
                                                          inputmode="numeric"
                                                          value={compat.basePrice}
                                                          style={{ textAlign: "left", width: "80px", height: "25px", padding: "0" }}
                                                          onChange={(event) => { updateCompat(event, compat) }}
                                                      />
                                                  </div>
                                              </td>
                                              <td width="5%" style={{ ...prodTdStyle, ...prodErrorStyle }} valign="top" align="center">
                                                  <div className="flex-container">
                                                      <UILIB.TextInput
                                                          name="priceEach"
                                                          className="textInput input"
                                                          placeholder="0"
                                                          type="number"
                                                          inputmode="numeric"
                                                          value={compat.priceEach}
                                                          style={{ textAlign: "left", width: "80px", height: "25px", padding: "0" }}
                                                          onChange={(event) => { updateCompat(event, compat) }}
                                                      />
                                                  </div>
                                              </td>
                                          </tr>
                                      })
                                      }
                                  </Fragment>
                              })}
                          </table>
                      </div>
                  </div>
                  }
              </div>
              <div className="col-xs-12 col-sm-2 mar-b15" style={{ flex: 1, display: "flex" , flexDirection: "column" }}>
                  <div className="rightCol mar-b15">
                      <h4 style={{ marginTop: "0px" }}>Basket</h4>
                      <div className="flex-container row">
                          <div className="col-xs-12">
                              <div>Total</div>
                              <h2 style={{ fontSize: "24px" }}>&pound;{formatCurrency(totalPrice, 2)}</h2>
                          </div>
                          <div className="col-xs-12">
                              <div>Cost</div>
                              <h3>&pound;{formatCurrency(costPrice, 2)}</h3>
                          </div>
                          <div className="col-xs-12">
                              <div>GP</div>


                              <h3>{formatCurrency(totalPrice - costPrice, false, 2)}</h3>
                          </div>
                      </div>
                  </div>
                  <div className="rightCol mar-b15"  >
                      <h4 style={{ marginTop: "0px" }}>CPC</h4>
                      <div className="flex-container row">
                          <div className="col-xs-12">
                              <div>Mono CPC</div>
                              <div className="flex-container">
                                  <UILIB.TextInput
                                      name="cpcMono"
                                      className="textInput input"
                                      placeholder="0"
                                      type="number"
                                      inputMode="numeric"
                                      value={props.selectedProduct.cpcMono === 0 ? 0 : props.selectedProduct.cpcMono}
                                      style={{ textAlign: "right", width: "100%", maxWidth: "200px" }}
                                      onClick={(event) => { clearLocalField(event, props.selectedProduct) }}
                                      onChange={(event) => { updateSelectedProduct(event, props.selectedProduct) }}
                                  />
                              </div>
                          </div>
                          <div className="col-xs-12">
                              <div>Colour CPC</div>
                              <div className="flex-container">
                                  <UILIB.TextInput
                                      name="cpcColour"
                                      className="textInput input"
                                      placeholder="0"
                                      type="number"
                                      inputMode="numeric"
                                      value={props.selectedProduct.cpcColour === 0 ? 0 : props.selectedProduct.cpcColour}
                                      style={{ textAlign: "right", width: "100%", maxWidth: "200px" }}
                                      onClick={(event) => { clearLocalField(event, props.selectedProduct) }}
                                      onChange={(event) => { updateSelectedProduct(event, props.selectedProduct) }}
                                  />
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div >
      </div >
    }
}