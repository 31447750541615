export const constants = Object.freeze(
  {
    ROW: "row",
    COLUMN: "column",
    UNGROUPED: "ungrouped",
    DROPZONE: "dropZone",
    PARENT_WITH_CHILD: "parent-with-child",
    PARENT_WITHOUT_CHILD: "parent-without-child",
    CHILD_NESTED_BEFORE: "child-nested-before",
    CHILD_NESTED_AFTER: "child-nested-after",
    CHILD_UNGROUPED: "child-ungrouped",
    TYPE_CONFIGURATOR: "type_configurator",
    TYPE_BLANKPART: "type_blankpart",
    TYPE_NOBIND: "type_nobind"
  }
);
