import { useState, useRef, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { formatDateTime } from 'classes/format';
import Axios from 'classes/axios';
import UILIB from 'components';
import moment from 'moment';
import { loggingChip, tableContainer, actionOpenDrawer, handleSerialClicked } from '../functions';


export default function ProcessedDevicesTable({ setCurrentTab, setSerialNumber, dates, setDates }) {
    const headerData = [
      { label: 'Date', value: 'actionDate', type: 'datetime', filtering: true, minWidth: 100, maxWidth: 100 },
      { label: 'Customer', value: 'customer', type: 'string', filtering: true, minWidth: 400, maxWidth: 400 },
      { label: 'Serial #', value: 'serial', type: 'string', filtering: true, minWidth: 130, maxWidth: 130 },
      { label: 'Processed By', value: 'processedBy', type: 'string', filtering: true, minWidth: 140, maxWidth: 140 },
      { label: 'FOCJIT', value: 'focJit', type: 'datetime', filtering: true, minWidth: 140, maxWidth: 140 },
      { label: 'Toner Approved', value: 'tonerApproved', type: 'string', filtering: true, minWidth: 140, maxWidth: 140 },
      { label: 'Next Day', value: 'nextDayDelCharge', type: 'string', filtering: true, minWidth: 140, maxWidth: 140 },
      { label: 'Replen', value: 'replenish', type: 'string', filtering: true, minWidth: 140, maxWidth: 140 },
      { label: 'View Email', value: 'logging', type: 'string', filtering: false, minWidth: 60, maxWidth: 60 },
      { label: 'View Orders', value: 'orders', type: 'string', filtering: false, minWidth: 60, maxWidth: 60 }];

    const dispatch = useDispatch();
    const history = useHistory();
    const entityData = useRef({});
    const tablePageDefaults = { paging: { pages: [10,20,50,100,200], pageSelected: 20, limit: 20, offset: 0, orderBy: 'createdAt', orderDir: 'DESC' } };    
    const [localRender, setLocalRender] = useState(false);


    const updateDates = (newDate) => {
      setDates({ ...dates, [newDate.target.name] : newDate.target.value });
    }

    const actionOrdersTab = (entity) => {
      setCurrentTab(1);
      setSerialNumber(entity.serial);
    }

    async function constructTable(tonderData) {

        const tableData = tonderData.map(row => {
          
            const userName = (row.user && row.user[0]) ? row.user[0].fullname : 'N/A';

            const b_hasLogs = Boolean(row.logging && row.logging.length);
            const b_hasRobotLogs = Boolean(row.tonerOrderCount && row.tonerOrderCount > 0);

            const logsColour = (b_hasLogs) ? 'colour colour background-6 red' : 'colour lightGrey';
            const robotLogsColour = (b_hasRobotLogs) ? 'colour colour background-6 red' : 'colour lightGrey';

            const fn_logs = actionOpenDrawer.bind(null, 'ViewLogsDrawer', row, dispatch, true, { get: localRender, set: setLocalRender });
            const fn_robot = actionOrdersTab.bind(null, row);
  
            return {
              actionDate: { value: formatDateTime(row.actionDate, "DD/MM/YYYY HH:mm:ss"), raw: row.actionDate },
              customer: { value: row.customer, raw: row.customer },
              serial: { value: handleSerialClicked(row, history, null), raw: row.serial },
              processedBy: { value: userName, raw: userName },
              focJit: { value: loggingChip(row.focJit), raw: row.focJit },
              tonerApproved: { value: loggingChip(row.tonerApproved), raw: row.tonerApproved },
              nextDayDelCharge: { value: loggingChip(row.nextDayDelCharge), raw: row.nextDayDelCharge },
              replenish: { value: loggingChip(row.replenish), raw: row.replenish },
              logging: { value: tableContainer(fn_logs, 'icon-book', (b_hasLogs) ? 'View Logs' : 'Logs Unavailable', logsColour, true), raw: b_hasLogs },
              orders: { value: tableContainer(fn_robot, 'icon-reply', (b_hasRobotLogs) ? 'View Orders for this Device' : 'No Orders for this Device', robotLogsColour, true), raw: b_hasRobotLogs }
            }
        })
        return tableData;
    }

    const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken) => {

      const queryLocal = (query !== null) ? query : '';
      const expandArray = ['users'];
      const pagingLocal = (limit !== null && offset !== null) ? `&$limit=${limit}&$offset=${offset}` : '';
      const orderLocal = (orderBy !== null && orderDir !== null) ? `&$order=${orderBy}&$direction=${orderDir}` : '';
      const processedRange = ` ( actionDate gte ${moment(dates.startDate).utc(true).format('YYYY-MM-DD 00:00')} and actionDate lte ${moment(dates.endDate).utc(true).format('YYYY-MM-DD 23:59')} ) `;

      entityData.current = await Axios.get(`/entities/serviceAdmin_tonerLoggings/getSummary/?&$filter=${processedRange} ${queryLocal}&$expand=${expandArray.map(x => x).join(' and ')}${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(api => api.data);

      return { tableData: await constructTable(entityData.current.result), nonePaged: entityData.current.nonePaged };
    }

    return <Fragment>
      <UILIB.Paper className="width-100">
        <div className='flex-container row end mar-b10'>
          <div className='flex-item end'>
            <UILIB.TextInput className="textInput input flex-item align-center" type="datetime-local" name="startDate" outerclassname="mar-r10" value={dates.startDate} onChange={updateDates} />
            <UILIB.TextInput className="textInput input flex-item align-center" type="datetime-local" name="endDate" outerclassname="mar-r10" value={dates.endDate} onChange={updateDates} />
          </div>
        </div>
        <UILIB.TableNew
          name={`ConsumablesProcessedDevices`}
          className='small'
          overflowX='auto'
          overflowY='hidden'    
          header={headerData}
          remoteQuery={getTableData}
          remoteRender={[dates]}
          defaultSettings={tablePageDefaults} />
      </UILIB.Paper>
    </Fragment>
}