import { useCallback } from "react";
import update from "immutability-helper";
import useFindNestedParent from '../hooks/useFindNestedParent';
import useFindNestedItem from '../hooks/useFindNestedItem';
import { constants } from "../dnd/Constants";


export default function useHandleDrop(layout, setLayout) {

  const { handleFindNestedParent } = useFindNestedParent(layout);
  const { handleFindNestedItem } = useFindNestedItem(layout);

  const handleDrop = useCallback(
    (foundSource, foundTarget) => {

      //1a move a ROW or UNGROUPED excluding a move to COLUMN
      if([constants.ROW, constants.UNGROUPED].includes(foundSource.item.type) 
        && [constants.ROW, constants.UNGROUPED].includes(foundTarget.item.type)) 
      {

        //1b replace a ROW with a ROW
        if(foundSource.item.type === constants.ROW && foundTarget.item.type === constants.ROW) {

          const downOffset = (foundSource.index < foundTarget.index) 
            ? foundTarget.item.children.length
            : 0;
       
          return setLayout(
            update(layout, {
              $splice: [
                [foundSource.index, 1],
                [(foundTarget.index + downOffset), 0, layout[foundSource.index]],
              ],
            })
          );

        }
        
        //1c bind an UNGROUPED to a ROW
        if(foundSource.item.type === constants.UNGROUPED && foundTarget.item.type === constants.ROW) {

          foundSource.item.type = constants.COLUMN;

          foundTarget.item.children.push(foundSource.item);
          layout.splice(foundSource.index, 1);

          return setLayout(layout);

        }
        
        //1d replace a UNGROUPED with an ROW
        if(foundSource.item.type === constants.ROW && foundTarget.item.type === constants.UNGROUPED) {

          const downOffset = (foundSource.index < foundTarget.index) 
          ? foundTarget.item.children.length
          : 0;

          return setLayout(
            update(layout, {
              $splice: [
                [foundSource.index, 1],
                [(foundTarget.index + downOffset), 0, layout[foundSource.index]],
              ],
            })
          );

        }

      }

      //1f replace a COLUMN with a COLUMN
      if(foundSource.item.type === constants.COLUMN && foundTarget.item.type === constants.COLUMN) {

        const sourceParent = handleFindNestedParent(foundSource.item.id);
        const targetParent = handleFindNestedParent(foundTarget.item.id);

        const source = handleFindNestedItem(foundSource.item.id);
        const target = handleFindNestedItem(foundTarget.item.id);

        if(sourceParent.item.id === targetParent.item.id) {

          if(Math.abs(foundSource.index - foundTarget.index) < 1) return;

          targetParent.item.children = update(targetParent.item.children, {
            $splice: [
              [source.index, 1],
              [target.index, 0, targetParent.item.children[source.index]],
            ],
          });

        }

        return setLayout(layout);

      }

    },
    [
      layout, 
      setLayout,
      handleFindNestedParent, 
      handleFindNestedItem
    ]
  );

  return { handleDrop };
}