import { Fragment, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UILIB from 'components';
import Axios from 'classes/axios';
import { updateAndSetOurDealType, updateAndSetOurDealNewExisting, updateAndSetOurDealCategory, fieldTypes } from 'classes/quotesHelpers';
import useUpdateDealEntity from '../hooks/useUpdateDealEntity';
import { checkAccess } from '../functions';
import cloneDeep from 'lodash/cloneDeep';
import moment from "moment"

export default function CRMDetails(props) 
  {

    const invalidConfig = 'The options selected are invalid, please try again or contact support';
    const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
    const exceptionError = 'There was an exception while saving this record, please reload or contact support';
    const versionError = 'A newer version of this record has already been saved. Please reload the data';
    const failureMessage = "Data unavailable";
    const exceptions = [400,404,409,500];

    const timerObject = useRef({});

    const [render, setRender] = useState(false);
    const [refreshLoading, setRefreshLoading] = useState(null);
    const [permissions, setPermissions] = useState(null);
    const [working, setWorking] = useState(false);

    const { handleUpdateDealEntity } = useUpdateDealEntity(props.ourDeal, setWorking, timerObject);

    function addTitle(title) {
      return (
        <td width="200">
          <div className="flex-container row nowrap">
            <div className="flex-item flex-grow-1 start">{title}</div>
          </div>
        </td>);
    }

    function mapPSUsers() {
      const pleaseSelect = { label: "Please Select", value: "-1" };
      if(!props?.ourDeal?.psUsers) return [{ label: "No Users Available", value: "-1" }];
      return [pleaseSelect, ...props.ourDeal.psUsers.map(x => { return { value: x.activeDirectoryObjectGuid, label: x.fullName } })];
    }

    async function refreshCRMData() {
      try {
        setRefreshLoading(true);
        const result = await Axios.patch(`/entities/workflow/deals/actionRefreshData/${props.ourDeal.dealID}/${props.ourDeal.dynamicsOpportunityNumber}`);
        if(exceptions.includes(result.status)) {
          if(result.status === 400) toast.error(invalidConfig);
          if(result.status === 404) toast.error(notFoundError);
        } else {
          props.setOurDeal({ ...props.ourDeal, ...result?.data?.result });
          setRefreshLoading(false);
        }
      }
      catch (error){
        console.log(error);
        toast.error(exceptionError);
        setRefreshLoading(false);
      }
    }

    useEffect(() => {
      const asyncEffect = async () => {
        const permissionResult = await checkAccess(props.account, props.ourDeal);
        setPermissions(permissionResult.users);
      }
      asyncEffect();
    }, [props.isActive, props.ourDeal.activeDirectoryObjectGuidPreSalesUserId, props.account, props.ourDeal]);

    const updateLocalField = async (field, event, timeout) => {

      let localData = cloneDeep(props.ourDeal);
      
      try {
        
        localData = { ...props.ourDeal, [field]: event.target.value };
        props.setOurDeal({ ...props.ourDeal, ...localData });
        setRender(!render);
  
        const response = await handleUpdateDealEntity(localData, field, [field], timeout);
        if(exceptions.includes(Number(response.status))) {  
          if(Number(response.status) === 400) toast.error(invalidConfig);
          if(Number(response.status) === 404) toast.error(notFoundError);
          if(Number(response.status) === 409) toast.error(versionError);  
        }
  
      } catch (error) {
        console.log(error);
        toast.error(exceptionError);
      }
    }

    const preSalesUserIDControl = 
        <UILIB.Select 
            outerclassname="width-100"
            disabled={!props.isActive || working}
            data={mapPSUsers()} 
            name={fieldTypes.PRESALESUSER}
            value={props.ourDeal.activeDirectoryObjectGuidPreSalesUserId}
            errored={props?.formErrors && props?.formErrors?.activeDirectoryObjectGuidPreSalesUserId} 
            onChange={async (event) => await updateLocalField(fieldTypes.PRESALESUSER, event, 500)} />;

    const refreshDataControl = <div>
          <UILIB.Button 
              disabled={!props.isActive || working} 
              loading={refreshLoading}
              style={{ width: "110px", height: "28px", padding: 0 }} 
              value="Refresh" 
              onClick={async () => await refreshCRMData()} />
        </div>;

    const customerLink = (props?.ourDeal?.crm?.primaryAccount?.accountid) 
      ? <UILIB.Link 
          href={`${props?.ourDeal?.links?.dynamics_base_url}/main.aspx?app=d365default&forceUCI=1&pagetype=entityrecord&etn=account&id=${props.ourDeal?.crm?.primaryAccount?.accountid}`} 
          rel="noreferrer" 
          target="_blank">
          <div className="text-12 font-weight-bold">{props?.ourDeal?.crm?.primaryAccount?.name}</div>
        </UILIB.Link>
      : <strong>{(props.ourDeal?.crm?.primaryAccount?.name) ? props.ourDeal.crm.primaryAccount.name : failureMessage}</strong>
    
    const accountLink = (props?.ourDeal?.customerID && props?.ourDeal?.crm?.primaryAccount?.accountnumber) 
      ? <UILIB.Link 
          href={`https://go.vantage.online/#customer/view/${props.ourDeal.customerID}`} 
          rel="noreferrer" 
          target="_blank">
          <div className="text-12 font-weight-bold">{props.ourDeal.crm.primaryAccount.accountnumber}</div>
        </UILIB.Link>
      : <UILIB.Link 
          href={'https://go.vantage.online/#customers'} 
          rel="noreferrer" 
          target="_blank">
          <div className="text-12 font-weight-bold">Create Customer record on VO</div>
        </UILIB.Link>

    const accountLinkFinal = (permissions && !permissions.isSales && !permissions.isSalesAdmin) 
      ? accountLink
      : <strong>{(props.ourDeal?.crm?.primaryAccount?.accountnumber) ? props.ourDeal.crm.primaryAccount.accountnumber : failureMessage}</strong>

    return <Fragment>
      <UILIB.Paper className="flex-container row height-100">
        <div className="width-100">   
          <table width="100%" cellPadding="0" cellSpacing="0" className="subTable">
            <tbody>
              <tr>
                {addTitle('Customer')}
                <td>
                  <div className='flex-container row start nowrap'>
                    <strong className='flex-item start'>
                      {customerLink}
                    </strong>
                  </div>
                </td>
              </tr>
              <tr>
                {addTitle('Account Number')}
                <td>
                  <div className='flex-container row start nowrap'>
                    <strong className='flex-item start'>
                      {accountLinkFinal}
                    </strong>
                  </div>
                </td>
              </tr>
              <tr>
                {addTitle('Customer Grade')}
                <td>
                  <div className='flex-container row start nowrap'>
                    <strong className='flex-item start'>
                      {(props?.ourDeal?.crm?.primaryAccount?.dmc_category) 
                        ? updateAndSetOurDealCategory(props.ourDeal.crm.primaryAccount.dmc_category) 
                        : failureMessage}
                    </strong>
                  </div>
                </td>
              </tr>
              <tr>
                {addTitle('Salesperson')}
                <td>
                  <div className='flex-container row nowrap'>
                    <strong className='flex-item start'>
                      {(props?.ourDeal?.crm?.systemusers?.fullname) 
                        ? props.ourDeal.crm.systemusers.fullname 
                        : failureMessage}
                    </strong>
                  </div>
                </td>
              </tr>
              <tr>
                {addTitle('Business Unit')}
                <td>
                  <strong className='flex-item start'>
                    {(props?.ourDeal?.crm?.businessunits?.name) 
                      ? props.ourDeal.crm.businessunits.name 
                      : failureMessage}
                  </strong>
                </td>
              </tr>
              <tr>
                {addTitle('Service Region')}
                <td>
                  <div className='flex-container row start nowrap'>
                    <strong className='flex-item start'>
                      {(props?.ourDeal?.crm?.vantageSales?.ServiceRegion) 
                        ? props.ourDeal.crm.vantageSales.ServiceRegion 
                        : failureMessage}
                    </strong>
                  </div>
                </td>
              </tr>
              <tr>
                {addTitle('New/Existing')}
                <td>
                  <div className='flex-container row start nowrap'>
                    <strong className='flex-item start'>
                      {(props?.ourDeal?.crm?.opportunities?.new_neworexisting) 
                        ? updateAndSetOurDealNewExisting(props.ourDeal.crm.opportunities.new_neworexisting) 
                        : failureMessage}
                    </strong>
                  </div>
                </td>
              </tr>
              <tr>
                {addTitle('Deal Type')}
                <td>
                  <div className='flex-container row start nowrap'>
                    <strong className='flex-item start'>
                      {updateAndSetOurDealType((props?.ourDeal?.crm.opportunities?.dmc_type) 
                        ? props.ourDeal.crm.opportunities.dmc_type 
                        : failureMessage)}
                    </strong>
                  </div>
                </td>
              </tr>
              <tr>
                {addTitle('Opportunity Status')}
                <td>
                  <div className='flex-container row start nowrap'>
                    <strong className='flex-item start'>
                      {(props?.ourDeal?.crm?.opportunities?.stepname) 
                        ? props.ourDeal.crm.opportunities.stepname 
                        : failureMessage}
                    </strong>
                  </div>
                </td>
              </tr>
              <tr>
                {addTitle('Booked Date')}
                <td>
                  <div className='flex-container row start nowrap'>
                    <strong className='flex-item start'>
                      {(props?.ourDeal?.crm?.opportunities?.new_bookedconfirmation) 
                        ? moment(props.ourDeal.crm.opportunities.new_bookedconfirmation).utc().format('DD/MM/YYYY') 
                        : failureMessage}
                    </strong>
                  </div>
                </td>
              </tr>
              <tr>
                {addTitle('PS Quote Required')}
                <td>
                  <div className='flex-container row start nowrap'>
                    <strong className='flex-item start'>
                      {(props?.ourDeal?.crm?.opportunities?.new_quotefrompsteamrequired) 
                        ? "Yes" 
                        : "No"}
                    </strong>
                  </div>
                </td>
              </tr>
              {props?.ourDeal?.crm?.opportunities?.new_quotefrompsteamrequired && <tr>
                {addTitle('PS Team Member')}
                <td>
                  <div className='flex-container row start nowrap width-100'>
                    <div className='flex-item start width-100'>
                      {preSalesUserIDControl}
                    </div>
                  </div>
                </td>
              </tr>}
              <tr>
                {addTitle('Refresh Data')}
                <td>
                  <div className='flex-container row start nowrap'>
                    {refreshDataControl}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </UILIB.Paper>
    </Fragment>
}