import { BsGripVertical } from "react-icons/bs";
import React, { Fragment } from 'react';

export default React.forwardRef(({ 
  header, 
  rowIndex,
  dndEnabled, 
  handleName
}, ref) => 
{ 

  const foundHeaderElement = header.find(x => (x?.value) && x.value === handleName);

  return <Fragment>
    <div 
      key={rowIndex}
      ref={(dndEnabled) ? ref : null} 
      className="flex-container row center align-center height-100"
    > 
      <BsGripVertical size={
        (foundHeaderElement && foundHeaderElement?.width) 
          ? foundHeaderElement?.width : 18} 
      /> 
    </div>
  </Fragment>
})