import { useState, useEffect, useRef, Fragment } from 'react';
import UILIB from 'components';
import Axios from 'classes/axios';
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux'
import { permissionsRouter, permissionsObject, permissionDecider } from 'classes/permissions';
import { getLogisticsStatus, getRegions } from "classes/helpers";
import { statCard } from 'classes/statsCard'
import { getDefaultFilters } from './functions';
import LogisticsDealTable from './tables/logisticsDealTable';
import DeviceInstallTable from './tables/deviceInstallTable';
import DeviceMovementTable from './tables/deviceMovementTable';
import InstallChecklistTable from './tables/installChecklistTable';


export default function LogisticsHub() {

  const tabs = [
    { label: "Active Deals", value: 0 },
    { label: "Install Checklist", value: 1  },
    { label: "Device Install", value: 2 },
    { label: "Device Movement", value: 3 }];

  const location = useLocation();
  const account = useSelector((state) => state.account);
  const history = useHistory();
  const permissions = useSelector((state) => state.permissions);
  const localPermissions = useRef({});
  const permissionsData = useRef([{ name: 'logisticsHub', hubName: 'Logistics Hub', routeName: location.pathname }]);
  const [topStats, setTopStats] = useState({ inBuildProgress: 0, partsOnOrder: 0, buildComplete: 0, installDateAgreed: 0, devicesInPdi: 0, dealsOverSLA: 0 });
  const [currentTab, setCurrentTab] = useState(history.location.state ? history.location.state.tab : 0);
  const defaultfilters = getDefaultFilters(location);
  const [filters, setFilters] = useState(defaultfilters);
  const [statsRender, setStatsRender] = useState(false);
  const [regions, setRegions] = useState([]);
  const [logistics, setLogistics] = useState([]);
  const statContainerClass = 'cardGeneric';
  const statChildClass = { top: 'cardGeneric cardChild', bottom: 'cardGeneric cardChild' };;

  const handleChangefilters = (tabId) => {
    setCurrentTab(tabId);
    setFilters(defaultfilters); };

  useEffect(() => {    
    const actionAsync = async () => { 

      await permissionDecider(permissions, account, permissionsData.current, localPermissions.current);
      await permissionsRouter(history, permissions, account, null, location.pathname, 'General Access');

      const regionsResult = await getRegions();
      const logisticsResult = getLogisticsStatus([0,1,2,3,4,5,6,7,10,15,30]);

      const regionsMapped = regionsResult.map(region => { return { label: region.name, value: region.id } });
      const logisticsMapped = logisticsResult.map(status => { return { label: status.status, value: status.value } });

      setRegions(regionsMapped);
      setLogistics(logisticsMapped);

    }
    actionAsync();
  }, [])

  useEffect(() => {
    const actionAsync = async () => {
      if(!filters.queryString) return;
      const quickStats = await Axios.get(`/entities/workflow/deals/actionGetTopStats/logistics/?&$filter=${filters.queryString}`);
      setTopStats({ ...topStats, ...quickStats.data.result });
    }
    actionAsync();
  }, [filters, statsRender]);


  return <Fragment>
    <div className="flex-container row center width-100 pad-l5 pad-r5">
      <div className="flex-item row width-100 mar-t5 mar-b10">
        {statCard(false, false, false, 'Deals in Build', topStats.inBuildProgress, statContainerClass, statChildClass)}
        {statCard(false, false, false, 'Deals Awaiting Stock', topStats.partsOnOrder, statContainerClass, statChildClass)}
        {statCard(false, false, false, 'Deals Build Complete', topStats.buildComplete, statContainerClass, statChildClass)}
        {statCard(false, false, false, 'Deals Install Date Agreed', topStats.installDateAgreed, statContainerClass, statChildClass)}
        {statCard(false, false, false, 'Devices in PDI', topStats.devicesInPdi, statContainerClass, statChildClass)}
        {statCard(false, false, false, 'Active Deals over SLA', topStats.dealsOverSLA, statContainerClass, statChildClass)}
      </div>
      <div className="flex-container row wrap center width-100">
        <div className="flex-item start flex-grow-1 wrap mar-b5">
          <UILIB.Button value="Deals" className="mar-l5 small green" href="https://360.agilico.co.uk/serviceadmin/engineers/salesinstallations/dealLogistics.asp" target="_blank" />
          <UILIB.Button value="PDI" className="mar-l5 small green" href="https://360.agilico.co.uk/serviceadmin/engineers/salesinstallations/workshopLogistics.asp" target="_blank" />
          <UILIB.Button value="Logistics Dashboard" className="mar-l5 small green" disabled={true} />
        </div>
      </div>
      <div className="flex-container row width-100">
        <UILIB.SubNav tabs={tabs} currentTab={currentTab} onClick={(newId) => handleChangefilters(newId)}  />
        {currentTab === 0 && <LogisticsDealTable
          filters={filters}
          setFilters={setFilters}
          statsRender={statsRender}
          setStatsRender={setStatsRender}
          defaultfilters={defaultfilters}
          localPermissions={localPermissions}
          permissionsObject={permissionsObject}
          permissions={permissions}
          route={location.pathname} 
          currentTab={currentTab}
          regions={regions}
          logistics={logistics} />}
        {currentTab === 1 && <InstallChecklistTable
          regions={regions}
          route={location.pathname}
          localPermissions={localPermissions}
          permissionsObject={permissionsObject}
          permissions={permissions}
          location={location}
          dealId={0} />}
        {currentTab === 2 && <DeviceInstallTable
          tab={currentTab} 
          localPermissions={localPermissions}
          permissionsObject={permissionsObject}
          permissions={permissions} 
          location={location} 
          route={location.pathname} />}
        {currentTab === 3 && <DeviceMovementTable
          tab={currentTab} />}
      </div>
    </div>
  </Fragment>
}