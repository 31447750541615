import { useState, useReducer, useEffect, useRef, memo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import { useParams } from 'react-router';
import { store } from 'store/store'
import UILIB from 'components';
import Axios from 'classes/axios'
import Modules from './Modules'
import * as errorFunctions from '../../../../classes/formErrors/formErrors';
import * as errorRepository from '../../../../classes/formErrors/repository';
import OrderDetailPage from './details/detailPage';
import ProductsPage from './products/productsPage';
import DocumentsPage from './documents/documentsPage';
import InstallChecklistTable from '../../logisticsHub/tables/installChecklistTable';
import { permissionsObject } from 'classes/permissions';
import useCheckErrors from './hooks/useCheckErrors';
import { DATA_TO_LOAD, reducer, reduceArray } from './sideload/reducer';
import * as functions from './functions';
import * as sideLoad from './sideload/sideLoad';

const BlockOrders = memo(() => 
{
  const state = store.getState();
  const account = state.account;
  const history = useHistory({ forceRefresh: true });
  const params = useParams();
  const location = useLocation();
  const route = location.pathname;
  const userAccess = useRef([]);
  const permissions = useSelector((state) => state.permissions);
  const localPermissions = useRef({});
  const [isActive, setisActive] = useState(false);
  const [submitModal, setSubmitModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [dealLoaded, setDealLoaded] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [dealAddress, setDealAddress] = useState([]);
  const [tabErrors, setTabErrors] = useState([]);
  const [productErrors, setProductErrors] = useState([]);
  const [invoiceErrors, setInvoiceErrors] = useState(errorRepository.getBlockOrderInvoiceErrors());
  const [formErrors, setFormErrors] = useState(errorRepository.getBlockOrderFormErrors());
  const [additionalInfoErrors, setAdditionalInfoErrors] = useState(errorRepository.getBlockOrderAdditionalInfoErrors());
  const [documentErrors, setDocumentErrors] = useState(errorRepository.getBlockOrderDocumentErrors());
  const processData = useRef('');
  const [dealData, setDealData] = useState(undefined);
  const [dataLoaded, setDataLoaded] = useState(reduceArray(DATA_TO_LOAD)); 
  const [dataToLoad, reducerDispatch] = useReducer(reducer, { dataLoaded: [] }); 
  const [productConfigurationModal, setProductConfigurationModal] = useState(functions.getProductConfigurationModal());
  const [fieldWorkflowObj, setFieldWorkflowObj] = useState(functions.getFieldWorkflowObj());
  const [cpcDisabled, setCpcDisabled] = useState(false);
  const actionButtons = [];


  const loadOrder = async (dealID) => {

    try {

      setDealLoaded(false);

      let finalDealId = undefined;
      if (dealID) {
        finalDealId = dealID;
      } else if (dealLoaded && dealData && dealData.dealID) {
        finalDealId = dealData.dealID;
      } else {
        throw new Error('error loading deal, no deal id');
      }

      //load deal data
      const baseData = await Axios.get(`/entities/workflow/deals/actionGetDealData/${finalDealId}`); 

      //redirect if not an order
      if (baseData.data.result.processStage < 1) history.push(route.replace('/order/', '/quote/'));

      //check group specific access
      userAccess.current = await functions.checkAccess(account, baseData.data.result);

      if (baseData.data.result.processStage < 2) {
        setisActive(baseData.data.result.isActive && Object.values(userAccess.current.read).indexOf(false) > -1);
      }
      if (baseData.data.result.processStage === 2) {
        setisActive(baseData.data.result.isActive && userAccess.current.users.isAdmin && (Object.values(userAccess.current.read).indexOf(false) > -1));
      }
      if (baseData.data.result.processStage > 2) {
        setisActive(false);
      }
      if (Object.values(userAccess.current.users).indexOf(true) === -1) {
        history.push({
          pathname: '/salesHub/accountmanagerview/draftview',
          state: {
            message: {
              showPermissionMessage: true
            }
          }
        })
      }

      processData.current = functions.getQuoteProcessData(baseData.data.result);

      const documentErrorsNew = {...documentErrors};
      documentErrorsNew.documents.fields = functions.getDoctype(baseData?.data.result?.leaseorcash, Number(baseData?.data?.result?.serviceContract));
      setDocumentErrors(documentErrorsNew);
      setCpcDisabled(Boolean(Number(baseData?.data?.result?.serviceContract) === 3));

      setDealData(baseData.data.result);
      setDealLoaded(true);
    }
    catch (error) {
      console.log(error);
      if (history && history.location && history.location.key) {
        history.goBack();
      }
      else {
        history.push('/');
      }
    }
  }

  const { handleCheckErrors } = useCheckErrors({
    dealData, 
    fieldWorkflowObj,
    formErrors,
    setFormErrors,
    invoiceErrors,
    setInvoiceErrors,
    setProductErrors,
    additionalInfoErrors,
    setAdditionalInfoErrors,
    documentErrors,
    setDocumentErrors,
    setTabErrors,
    setSubmitModal
  });

  const sideLoadData = (dealID, oppNumber, accountNo) => {
    sideLoad.getProducts(reducerDispatch, dealID);
    sideLoad.getCrm(reducerDispatch, oppNumber);
    sideLoad.getDocuments(reducerDispatch, oppNumber);
    sideLoad.getCompatibles(reducerDispatch);
    sideLoad.getPSUsers(reducerDispatch);
    sideLoad.getHostConfig(reducerDispatch);
    sideLoad.getOtherDeals(reducerDispatch, oppNumber);
    sideLoad.getRemovals(reducerDispatch, dealID);
    sideLoad.getRemovalStores(reducerDispatch, accountNo);
    sideLoad.updateDealData(reducerDispatch, dealID, oppNumber);
    sideLoad.getProcurmentData(reducerDispatch);
    sideLoad.getDealPricing(reducerDispatch, dealID);
    sideLoad.getKeyContacts(reducerDispatch, dealID);
    sideLoad.getNotesData(reducerDispatch, dealID);
    sideLoad.getSettlementData(reducerDispatch, dealID);
    sideLoad.getAddressData(reducerDispatch, dealID);
    sideLoad.getAddressTypes(reducerDispatch);
    sideLoad.getAddressSources(reducerDispatch);
    sideLoad.getEventDeclineType(reducerDispatch);
  }

  useEffect(() => {
    if(!dealLoaded) return;
    sideLoadData(dealData.dealID, dealData.dynamicsOpportunityNumber, dealData.accountNo);
  }, [dealLoaded])

  useEffect(() => {
    if(!dealLoaded) return;
    setDealData({ 
      ...dealData, 
      ...dataToLoad,
      addressDropdown: functions.getAddressDropdown({ ...dealData, ...dataToLoad })
    });
  }, [dataToLoad])

  useEffect(() => {
    if(!dealLoaded) return;
    setDataLoaded({ ...dataLoaded, ...dataToLoad.dataLoaded });
  }, [dataToLoad])

  useEffect(() => {
    functions.fieldWorkflow({  
      dealData, 
      setFormErrors, 
      additionalInfoErrors, 
      setAdditionalInfoErrors,
      setFieldWorkflowObj
    });
  }, [dealData])

  useEffect(() => {
    const asyncEffect = async () => {
      if (params && params.dealID) {
        await loadOrder(params.dealID);
      }
      else {
        history.push("/");
      }
    }
    asyncEffect();
  }, [])

  const tabs = [
    { label: `${processData.current.typeText} Details`, value: 0 },
    { label: "Products", value: 1 },
    { label: "Documents", value: 2 },
    { label: "Install Checklist", value: 3 }
  ];

  if (!dealLoaded) return <UILIB.Loading type={3} />

  if (submitModal) {
    return <Modules.OrderSubmitModal 
      history={history} 
      ourDeal={dealData}
      setOurDeal={setDealData}
      onCancel={() => setSubmitModal(false)} />
  }
  
  if (showApproveModal) {
    return <Modules.OrderApproveModal
      history={history} 
      ourDeal={dealData}
      setOurDeal={setDealData}
      onCancel={() => setShowApproveModal(false)} />
  }

  if (showArchiveModal) {
    return <Modules.OrderArchiveModal 
      ourDeal={dealData}
      history={history}
      onCancel={() => setShowArchiveModal(false)} />
  }

  if(dealData.status === 1 && dealData.processStage >= 3 && userAccess.current.users.isAdmin) {
    actionButtons.push(<UILIB.Button 
      className="green width-100" 
      value={(isActive) ? 'Lock Order' : 'Amend Order'} 
      onClick={() => setisActive(!isActive)} />);
  }

  if(Number(isActive) === 1 && dealData.processStage === 1) {
    actionButtons.push(<Modules.CopyOrder 
      dealData={dealData} 
      setDealData={setDealData} 
      isActive={isActive} />);
  }

  actionButtons.push(<Modules.PatchCRM 
    ourDeal={dealData} 
    setOurDeal={setDealData} 
    account={account}
    isActive={isActive} />)

  if(Number(isActive) === 1 && dealData.processStage === 1) {
    actionButtons.push(<Modules.SubmitDeal
      dealData={dealData}
      fieldWorkflowObj={fieldWorkflowObj}
      setFieldWorkflowObj={setFieldWorkflowObj}
      errorFunctions={errorFunctions}
      formErrors={formErrors}
      setFormErrors={setFormErrors}
      invoiceErrors={invoiceErrors}
      setInvoiceErrors={setInvoiceErrors}
      setTabErrors={setTabErrors}
      setProductErrors={setProductErrors}
      additionalInfoErrors={additionalInfoErrors}
      setAdditionalInfoErrors={setAdditionalInfoErrors}
      documentErrors={documentErrors}
      setDocumentErrors={setDocumentErrors}
      setSubmitModal={setSubmitModal}
      dataLoaded={dataLoaded}
      isActive={isActive}
      handleCheckErrors={handleCheckErrors}
    />)
  }

  if(Number(isActive) === 1 && dealData.status === 1 && dealData.processStage === 2) {
    actionButtons.push(<UILIB.Button
      disabled={!dealData.status === 1 || Object.values(dataLoaded).some(x => !Boolean(x))}
      className="mar-b10 width-100"
      value="Approve"
      onClick={() => setShowApproveModal(true)} />);
  }

  if(isActive && dealData.status === 1 && dealData.processStage === 2) {
    actionButtons.push(<Modules.DeclineOrder 
      dealData={dealData} 
      setDealData={setDealData} 
      dataLoaded={dataLoaded}
      history={history} />);
  }

  if(!userAccess.current.users.isSales && dealData.engStatus === 10) {
    actionButtons.push( <UILIB.Button 
     className="orange width-100"
     value={"Archive"}
     disabled={!isActive}
     onClick={() => setShowArchiveModal(true)} />);
  }

  if(Number(isActive) === 1 && dealData.processStage === 1) {
    actionButtons.push(<Modules.DeleteOrder 
      dealData={dealData}
      setDealData={setDealData}
      history={history}
      route={route}
      isActive={isActive} />)
  }

  actionButtons.push(<Modules.DownloadOrderSheet 
    ourDeal={dealData} 
    isActive={isActive} />)


  return <div className="flex-container row wrap mar-t10 mar-r5 mar-l5">
    <Modules.DealHeader ourDeal={dealData} processData={processData} otherQuotes={dealData.otherDeals} />
    <div className="flex-container row end between width-100 mar-l8 mar-r8">
      <div className="flex-item flex-grow-1 end">
        <UILIB.CustomSelect data={actionButtons} outerclassname="width-200-px" clickTitle={{ title: 'Select Action' }} />
      </div>
    </div>
    <UILIB.SubNav
      tabs={tabs}
      currentTab={currentTab}
      outerClasses="mar-t15 mar-b15"
      onClick={(newId) => setCurrentTab(newId)}
      errored={{ option: 1, tabs: tabErrors }} />
    {currentTab === 0 &&
      <OrderDetailPage
        ourDeal={dealData}
        setOurDeal={setDealData}
        account={account}
        isActive={isActive}
        additionalInfoErrors={additionalInfoErrors}
        fieldWorkflowObj={fieldWorkflowObj}
        setFieldWorkflowObj={setFieldWorkflowObj}
        setDealAddress={setDealAddress}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
        dataLoaded={dataLoaded}
        setCpcDisabled={setCpcDisabled}
        handleCheckErrors={handleCheckErrors} /> }
    {currentTab === 1 &&
      <ProductsPage
        dealLoaded={dealLoaded}
        ourDeal={dealData}
        setOurDeal={setDealData}
        isActive={isActive}
        dealAddress={dealAddress}
        processData={processData}
        setDealAddress={setDealAddress}
        productErrors={productErrors}
        fieldWorkflowObj={fieldWorkflowObj}
        setFieldWorkflowObj={setFieldWorkflowObj}
        dataLoaded={dataLoaded}
        cpcDisabled={cpcDisabled}
        setCpcDisabled={setCpcDisabled}
        productConfigurationModal={productConfigurationModal}
        setProductConfigurationModal={setProductConfigurationModal}
        handleCheckErrors={handleCheckErrors} /> }
    {currentTab === 2 &&
      <DocumentsPage
        dealLoaded={dealLoaded}
        ourDeal={dealData}
        setOurDeal={setDealData}
        isActive={isActive}
        dataLoaded={dataLoaded} /> }
    {currentTab === 3 &&
      <InstallChecklistTable
        route={location.pathname}
        localPermissions={localPermissions}
        permissionsObject={permissionsObject}
        permissions={permissions}
        location={location}
        dealId={dealData.dealID} /> }
    <Modules.ExpandPricingStats 
      ourDeal={dealData} 
      processData={processData} 
      otherQuotes={dealData.otherDeals} 
      currentTab={currentTab} />
  </div >
})
export default BlockOrders
