import { useEffect, useState, memo } from 'react';
import Modules from '../Modules';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const TableBodyLocal = memo(({ 
  handleName,
  header, 
  localRender,
  localQuery,
  onDrop,
  dndEnabled,
  module: Component
}) => 
{ 

  const [tableDataLocal, setTableDataLocal] = useState([]);

  useEffect(() => {    
    if(!localQuery) return;
    const actionAsync = async () => { 
      setTableDataLocal(await localQuery());
    }
    actionAsync();
  }, [localRender, header, localQuery])


  return <tbody>
    {Boolean(tableDataLocal.length)
      ? <DndProvider backend={HTML5Backend}>
          <Component
            dndEnabled={dndEnabled}
            handleName={handleName}
            header={header} 
            data={tableDataLocal}
            onDrop={onDrop} />
        </DndProvider>
      : <Modules.DataRowEmpty header={header} />}
  </tbody>
})
export default TableBodyLocal