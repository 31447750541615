import { useState, useEffect } from 'react';
import { formatAddress } from '../../../../classes/dataValidation';
import BlockSelectProductTable from './blockSelectProductTable';
import UILIB from 'components';
import Axios from 'classes/axios';


export default function BookInstall({ pdiModalData, setPdiModalData, history }) {   

  const selectAddressMessage = 'Please select an address to load available products.';
  const assignedAddressMessage = 'All products have been assigned for this deal.';
  const pleaseSelect = { value: -1, label: 'Please Select' };
  const [selected, setSelected] = useState(-1);
  const [selectedProducts, setSelectedProducts] = useState(false);
  const [loading, setLoading] = useState(false);
  const [productsLoading, setProductsLoading] = useState(false);
  const [dealData, setDealData] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [products, setProducts] = useState([]);
  const [dealNote, setDealNote] = useState();
  const [productsAvailable, setProductsAvailable] = useState(false);


  useEffect(() => {
    const actionAsync = async () => { 
      setLoading(true);  
      const addresses = await Axios.get(`/entities/addresses?$filter=dealId eq ${pdiModalData.entity.dealID}`);
      const productResult = await Axios.get(`/entities/products?$filter=dealID eq ${pdiModalData.entity.dealID}&$expand=singletons`);
      const productsFiltered = productResult.data.result.filter(x => x.installSelected === 0)
      const addressIds = new Set(productsFiltered.map(x => { return Number(x.siteAddress) }));
      const addressesMapped = addresses.data.result.map(x => { return { value: x.id, label: formatAddress(x) } });
      const addressesFiltered = addressesMapped.map(x => Array.from(addressIds).includes(Number(x.value)) ? { ...x, consumed: false }  : { ...x, consumed: true });
      setProductsAvailable(addressesFiltered.some(x => !x.consumed));
      setAddresses([ pleaseSelect, ...addressesFiltered]);
      setLoading(false);
    }
    actionAsync();
  }, []);

  const loadProducts = async (selectedLocal) => {
    setProductsLoading(true);  
    setSelected(selectedLocal);
    const productResult = await Axios.get(`/entities/products?$filter=dealID eq ${pdiModalData.entity.dealID} and siteAddress eq ${selectedLocal} and installSelected eq 0&$expand=Attachments and singletons`);
    const notesResult = await Axios.get(`/entities/serviceAdmin_salesDealsNotes?$filter=dealID eq ${pdiModalData.entity.dealID} and noteSource eq External Notes`);
    setProducts(productResult.data.result);
    setSelectedProducts(false);
    setProductsLoading(false);
    setDealNote(notesResult.data.result);
  }

  const loadForm = async () => {
    const productIds = new Set();
    products.filter(f => f.installSelected).forEach(x => productIds.add(x.prodID));
    history.push({
      pathname: '/logisticsHub/deviceInstall', 
      state: { dealData: { ...dealData, productIds: Array.from(productIds), dealNote: dealNote }, existingForm: false, tab: 1 }
    })
  }

  const updateSeleted = async (event) => {
    await loadProducts(event.target.value);
    setDealData({ dealId: pdiModalData.entity.dealID, addressId: event.target.value });
  }

  return <div className="genericModalContainer">
    <div className="genericModalContent" style={{ width: "50%", height: "60%", overflowY: 'auto' }}>
        <div className="flex-container row width-100 mar-b15">
          <div className='flex-item start flex-grow-1'>
            <h2>Book Install</h2>
          </div>
          <div className='flex-item end'>
            <UILIB.Button className='red mar-r10' disabled={!pdiModalData.entity.isActive || loading} value='Close' onClick={() => { setPdiModalData({ ...pdiModalData, show: false }) }} />
            <UILIB.Button className='green mar-r10' disabled={!pdiModalData.entity.isActive || Number(selected) === -1 || !Boolean(selectedProducts)} loading={loading} value={'Continue'} onClick={() => loadForm()} />
          </div>
        </div>
        <h4 style={{ padding: 0, marginBottom: 5 }}>Please select an Address</h4>
        <UILIB.Select
          behaviourType={1} 
          disabled={!pdiModalData.entity.isActive || !productsAvailable} 
          style={{ height: "25px", padding: "0" }}
          value={selected}
          data={addresses}
          onChange={async (ev) => await updateSeleted(ev)} 
        />
        <div className="row mar-t10">
          <div className='flex-item start width-100' style={{ padding: 0, margin: 0 }}>
            <h4 style={{ padding: 0, marginBottom: 5 }}>Please select required Products</h4>
          </div>
          <div className='flex-item start width-100'>
            {Boolean(products.length && productsAvailable) && 
              <BlockSelectProductTable 
                data={products} 
                setData={setProducts} 
                selectedProducts={selectedProducts} 
                setSelectedProducts={setSelectedProducts}
                loading={productsLoading} />}
            {Boolean(!products.length && productsAvailable) && selectAddressMessage}
            {Boolean(!productsAvailable) && assignedAddressMessage}
          </div>
        </div>
    </div>
  </div>
}