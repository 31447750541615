import { Fragment } from 'react'
import UILIB from 'components';
import Drawer from '../Drawers';
import { useDispatch } from 'react-redux';
import { setDrawer } from "store/actions";


export default function SwapQuote(props) 
{

  const dispatch = useDispatch();

  const swappedQuote = async (dealID) => {
    dispatch(setDrawer({ open: false, content: null }));
    props.history.push(`/salesHub/accountmanagerview/quote/${dealID}`);
  }

  const swapQuote = () => {
    dispatch(setDrawer({
      open: true,
      width: "450px",
      content: <Drawer.SwapQuoteDrawer
        onFinished={async (ev) => await swappedQuote(ev)}
        otherQuotes={props.ourDeal.otherDeals}
        ourDeal={props.ourDeal}
        isActive={props.isActive}
        dealID={props.dealID}
      />
    }));
  }

  return <Fragment>
    <div className="flex-item width-100">
      <UILIB.Button
        disabled={!props.isActive}
        className="small grey width-100"
        value="Swap Quote"
        title='View a different quote for this opportunity'
        onClick={() => swapQuote()} />
    </div>
  </Fragment>
}