import { useCallback } from "react";
import { constants } from '../../../../../components/Table/draggable/dnd/Constants';
import cloneDeep from 'lodash/cloneDeep';

export default function useUpdateOnDrop(ourDeal, setOurDeal) {

  const handleUpdateOnDrop = useCallback(
    (onDrop) => {

      const cloned = cloneDeep(ourDeal);

      try {

        const devices = onDrop
        .filter(f => f.type !== constants.COLUMN)
        .map((x,i) => { 
          const deviceNumber = Number(i = i + 1);
          return {
            ...x.raw, 
            attachments: 
              x.children.map((c, j) => { return {
                ...c.raw,
                parentId: Number(x.id),
                isADevice: 0, 
                subDeviceNo: Number(j = j + 1),
                deviceNo: deviceNumber
              } 
            }),
            parentId: 0, 
            deviceNo: deviceNumber } 
          }
        );
  
        const attachments = devices.reduce((acc, obj) => acc.concat(obj.attachments), []);
  
        setOurDeal({ 
          ...ourDeal, 
          product: [...devices, ...attachments]
        });

        return [...devices, ...attachments];

      } catch (error) {
        console.error(error);
        setOurDeal(cloned);
      }
    },
    [
      ourDeal, 
      setOurDeal
    ]
  );

  return { handleUpdateOnDrop };
}