import { useState, useRef, memo, Fragment } from 'react';
import UILIB from 'components';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fieldTypes } from 'classes/quotesHelpers';
import { productOrigin } from '../functions';
import Products from '../../../../../components/Table/draggable/types/Products';
import useUpdateProductEntity from '../hooks/useUpdateProductEntity';
import useUpdateProductOnDrop from '../hooks/useUpdateProductOnDrop';
import useUpdateOnDrop from '../hooks/useUpdateOnDrop';
import SelectAll from './selectAll';


const QuoteProductsTable = memo((props) => 
{
    const invalidConfig = 'The options selected are invalid, please try again or contact support';
    const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
    const exceptionError = 'There was an exception while saving this record, please reload or contact support';
    const versionError = 'A newer version of this record has already been saved. Please reload the data';
    const exceptions = [400,404,409,500];

    const timerObject = useRef({});
    const [working, setWorking] = useState(false);
    const [render, setRender] = useState(false);

    const addresses = props.ourDeal.addressDropdown.filter(x => [-1,2].includes(Number(x.type)));

    const { handleUpdateProductEntity } = useUpdateProductEntity(props.ourDeal, props.setOurDeal, setWorking);
    const { handleUpdateProductOnDrop } = useUpdateProductOnDrop(props.ourDeal, timerObject);
    const { handleUpdateOnDrop } = useUpdateOnDrop(props.ourDeal, props.setOurDeal);

    const headerData = [
      { label: <SelectAll {...props} working={working} />, value: "ticked", type: 'string', minWidth: 30, maxWidth: 30 },
      { label: "Origin", value: "origin", type: 'string', minWidth: 50, maxWidth: 50 },
      { label: "Device", value: "deviceNo", type: 'string', minWidth: 50, maxWidth: 50 },
      { label: "Quantity", value: "qty", type: 'string', minWidth: 50, maxWidth: 50 },
      { label: "Product", value: "product", type: 'string', minWidth: 400, maxWidth: 400 },
      { label: "Part Number", value: "partNumber", type: 'string', minWidth: 120, maxWidth: 120 },
      { label: "Supplier", value: "supplier", type: 'string', minWidth: 120, maxWidth: 120 },
      { label: "New/Used", value: "newUsed", type: 'string', minWidth: 80, maxWidth: 80 },
      { label: "Address & Location", value: "location", type: 'string', minWidth: 400, maxWidth: 400 },
      { label: "CPC's", value: "cpcsVols", type: 'string', minWidth: 80, maxWidth: 80 },
      { label: "Discount %", value: "discount", type: 'string', minWidth: 90, maxWidth: 90 },
      { label: "Cost", value: "cost", type: 'string', minWidth: 80, maxWidth: 80 },
      { label: "Sale", value: "sale", type: 'string', minWidth: 80, maxWidth: 80 },
      props.fieldWorkflowObj.scottishProcurementLease 
        ? { label: "Lease per Qtr", value: "lease", type: 'string', minWidth: 50, maxWidth: 50 } 
        : undefined,
      { label: "Hide", value: "hide", type: 'string', minWidth: 30, maxWidth: 30 },
      { label: "", value: "options", type: 'string', minWidth: 30, maxWidth: 30 }
    ]

    const getProducts = (product = []) => {

        let bgColour = ''
        let tableData = [];

        product
          .filter(p => p.parentId === 0)
          .sort(function(a, b){
            if(Number(a.deviceNo) < Number(b.deviceNo)) { return -1; }
            if(Number(a.deviceNo) > Number(b.deviceNo)) { return 1; }           
            return 0; })
          .forEach(row => {

            row.hasCompats = Boolean(row?.portalPart?.Parent?.length);

            bgColour = (row.isADevice === 1) 
              ? 'colour background-6 lightGrey' 
              : 'colour background-2 grey';

            if (!row.specificDeviceLocation || row.specificDeviceLocation === null || row.specificDeviceLocation === undefined) row.specificDeviceLocation = ""

            const cpcMonoError = (props?.productErrors) ? props.productErrors.filter(obj => obj.rowId === row.prodID && obj.name === "cpcMono") : [];
            const cpcColourError = (props?.productErrors) ? props.productErrors.filter(obj => obj.rowId === row.prodID && obj.name === "cpcColour") : [];

            let cpcsVols = <div style={{ display: "flex", alignItems: "center" }}></div>
            if (((row.portalPart && row.portalPart.SalesPartTypeId === 34) || ([1,2].includes(row.rowType) && row.isADevice === 1)) && props.ourDeal.leaseorcash !== 'Cash - Trade (SOP Only)') cpcsVols = <div>
                <div className="flex-container row start nowrap text-small">Mono CPC</div>
                <div className="flex-container row start nowrap text-small">
                  <UILIB.TextInput
                    className="textInput input"
                    disabled={!props.isActive || props.cpcDisabled || working}
                    placeholder="0"
                    type="number"
                    inputMode="numeric"
                    value={row.cpcMono}
                    name={fieldTypes.CPCMONO}
                    onChange={async (event) => await updateLocalField(fieldTypes.CPCMONO, row, event, 500)}
                    errored={cpcMonoError[0]}
                  />p
                </div>
                <div className="flex-container row start nowrap text-small">Colour CPC</div>
                <div className="flex-container row start nowrap text-small">
                  <UILIB.TextInput
                    className="textInput input"
                    disabled={!props.isActive || props.cpcDisabled || working}
                    placeholder="0"
                    type="number"
                    inputMode="numeric"
                    name={fieldTypes.CPCCOLOUR}
                    value={row.cpcColour}
                    onChange={async (event) => await updateLocalField(fieldTypes.CPCCOLOUR, row, event, 500)}
                    errored={cpcColourError[0]}
                  />p
                </div>
            </div>

            const foundAddress = addresses.find(f => Number(f.value) === Number(row.siteAddress));

            const siteAddressError = (props.productErrors) ? props.productErrors.filter(obj => obj.rowId === row.prodID && obj.name === "siteAddress") : [];
            const specificDeviceLocationError = (props.productErrors) ? props.productErrors.filter(obj => obj.rowId === row.prodID && obj.name === "specificDeviceLocation") : [];

            const addressLocation = <div className="flex-container row width-100 pad-l2 pad-r2">
                <div className="flex-item start text-small width-100">
                  <UILIB.Select
                    outerclassname="width-100"
                    disabled={!props.isActive || working}
                    name={fieldTypes.SITEADDRESS}
                    data={addresses}
                    value={row.siteAddress}
                    onChange={async (event) => await updateLocalField(fieldTypes.SITEADDRESS, row, event, 500)}
                    errored={siteAddressError[0]}
                  />
                </div>
                {(((row.portalPart && row.portalPart.SalesPartType && row.portalPart.SalesPartType.name === "MFP - Equipment") || ([1,2].includes(row.rowType) && row.isADevice === 1)) && props.ourDeal.leaseorcash !== 'Cash - Trade (SOP Only)') && <Fragment>
                    <div className="flex-item start text-small width-100">
                      <UILIB.TextInput
                        outerclassname="width-100"
                        className="textInput input table"
                        disabled={!props.isActive || working}
                        name={fieldTypes.SPECIFICDEVICELOCATION}
                        value={row.specificDeviceLocation}
                        placeholder="Enter a location"
                        onChange={async (event) => await updateLocalField(fieldTypes.SPECIFICDEVICELOCATION, row, event, 500)}
                        errored={specificDeviceLocationError[0]}
                      />
                    </div>
                </Fragment>}
            </div>

            tableData.push({
              raw: row,
              table: {
                origin: {
                    value:  <div className="flex-container row start nowrap text-small mar-r2 mar-l2">
                              <div className="flex-item align-center mar-t4">
                                {productOrigin(row)}
                              </div>
                            </div>,
                    raw: row.rowType,
                    class: bgColour
                },
                ticked: {
                  value:  <div className="flex-container row start nowrap text-small mar-r2 mar-l2">
                            <div className="flex-item flex-grow-1 align-center">
                              <UILIB.Checkbox
                                  disabled={!props.isActive || working}
                                  name={fieldTypes.TICKED}
                                  checked={Boolean(row.ticked)}
                                  onChange={async (event) => await updateLocalField(fieldTypes.TICKED, row, event, 500)} 
                              />
                            </div>
                          </div>,
                  raw: row.ticked,
                  class: bgColour
                },
                product: {
                    value:  <div className="flex-container row start nowrap text-small mar-r2 mar-l2">
                              <div className="flex-item flex-grow-1 align-center">
                                <UILIB.TextInput
                                    outerclassname="width-100"
                                    className="textInput input table"
                                    disabled={!props.isActive || working}
                                    placeholder="Enter Product Name"
                                    value={row.description}
                                    name={fieldTypes.DESCRIPTION}
                                    onChange={async (event) => await updateLocalField(fieldTypes.DESCRIPTION, row, event, 500)}
                                />
                              </div>
                            </div>,
                    raw: row.description,
                    class: bgColour
                },
                partNumber: {
                    value:  <div className="flex-container row start nowrap text-small mar-r2 mar-l2">
                              <div className="flex-item flex-grow-1 align-center text-12">
                                {row.partNo}
                              </div>
                            </div>,
                    raw: row.partNo,
                    class: bgColour
                },
                supplier: {
                    value:  <div className="flex-container row start nowrap text-small mar-r2 mar-l2">
                              <div className="flex-item align-center">
                                <UILIB.TextInput
                                  className="textInput input table height-100"
                                  disabled={!props.isActive || working}
                                  placeholder="Enter Supplier"
                                  value={row.supplier}
                                  name={fieldTypes.SUPPLIER}
                                  onChange={async (event) => await updateLocalField(fieldTypes.SUPPLIER, row, event, 500)} 
                                />
                              </div>
                            </div>,
                    raw: row.supplier,
                    class: bgColour
                },
                productType: {
                    value: <div>
                        {row.portalPart && row.portalPart.SalesPartType ? row.portalPart.SalesPartType.name : "New Part"}
                    </div>,
                    raw: row.portalPart && row.portalPart.SalesPartType ? row.portalPart.SalesPartType.name : "New Part",
                    class: bgColour
                },
                newUsed: {
                    value:  <div className="flex-container row start nowrap text-small mar-r2 mar-l2">
                              <div className="flex-item flex-grow-1 align-center text-12">
                                  <UILIB.Select
                                  outerclassname='width-100'
                                  disabled={!props.isActive || working}
                                  data={[{ label: "New", value: "New" }, { label: "Used", value: "Used" }, { label: "Retained", value: "Retained" }]}
                                  value={row.newUsed}
                                  name={fieldTypes.NEWUSED}
                                  onChange={async (event) => await updateLocalField(fieldTypes.NEWUSED, row, event, 500)}
                                />
                              </div>
                            </div>,
                    raw: row.newUsed,
                    class: bgColour
                },
                discount: {
                    value:  <div className="flex-container row start nowrap text-small mar-r2 mar-l2">
                              <div className="flex-item align-center">
                                <UILIB.TextInput
                                  className="textInput input table"
                                  disabled={!props.isActive || working}
                                  placeholder="0"
                                  type="number"
                                  inputMode="numeric"
                                  name={fieldTypes.DISCOUNT}
                                  value={Number(row.discount) === 0 ? "" : Number(row.discount)}
                                  onChange={async (event) => await updateLocalField(fieldTypes.DISCOUNT, row, event, 500)}
                                />
                              </div>
                              <div className="flex-item flex-grow-1 align-center">%</div>
                            </div>,
                    raw: Number(row.discount) ? row.discount : 0,
                    class: bgColour
                },
                cost: {
                    value:  <div className="flex-container row start nowrap text-small mar-r2 mar-l2">
                              <div className="flex-item align-center">£</div>
                              <div className="flex-item flex-grow-1 align-center">
                                <UILIB.TextInput
                                    className="textInput input table"
                                    disabled={!props.isActive || working}
                                    placeholder="0"
                                    type="number"
                                    inputMode="numeric"
                                    name={fieldTypes.COSTEACH}
                                    value={Number(row.costEach) === 0 ? "" : Number(row.costEach)}
                                    onChange={async (event) => await updateLocalField(fieldTypes.COSTEACH, row, event, 500)}
                                />
                              </div>
                          </div>,
                    raw: Number(row.costEach) ? row.costEach : 0,
                    class: bgColour
                },
                sale: {
                    value:  <div className="flex-container row start nowrap text-small mar-r2 mar-l2">
                              <div className="flex-item align-center">£</div>
                              <div className="flex-item flex-grow-1 align-center">
                                <UILIB.TextInput
                                    className="textInput input table"
                                    disabled={!props.isActive || working}
                                    placeholder="0"
                                    type="number"
                                    inputMode="numeric"
                                    name={fieldTypes.PRICEEACH}
                                    value={Number(row.priceEach) === 0 ? "" : Number(row.priceEach)}
                                    onChange={async (event) => await updateLocalField(fieldTypes.PRICEEACH, row, event, 500)}
                                />
                              </div>
                            </div>,
                    raw: Number(row.priceEach) ? row.priceEach : 0,
                    class: bgColour
                },
                lease: {
                    value:  <div className="flex-container row start nowrap text-small mar-r2 mar-l2">
                              <div className="flex-item align-center">£</div>
                              <div className="flex-item flex-grow-1 align-center">
                                <UILIB.TextInput
                                    className="textInput input table"
                                    disabled={!props.isActive || working}
                                    placeholder="0"
                                    type="number"
                                    inputMode="numeric"
                                    name={fieldTypes.LEASE}
                                    value={Number(row.lease) === 0 ? "" : Number(row.lease)}
                                    onChange={async (event) => await updateLocalField(fieldTypes.LEASE, row, event, 500)}
                                    errored={props.formErrors && props.formErrors.lease}
                                />
                              </div>
                          </div>,
                    raw: Number(row.lease) ? row.lease : 0,
                    class: bgColour
                },
                qty: {
                  value: (row.isADevice === 0 && row.parentId === 0 && row.partNo !== 'TOTALINVVAL' && Number(row.rowType) !== 4) 
                      ?   <div className="flex-container row start nowrap text-small mar-r2 mar-l2">
                            <div className="flex-item flex-grow-1 align-center">
                              <UILIB.NumberInput
                                  className="textInput input"
                                  disabled={!props.isActive || working}
                                  placeholder="1"
                                  step="1"
                                  min="1"
                                  name={fieldTypes.QTY}
                                  allowDecimal={false}
                                  value={Number(row.qty)}
                                  onChange={async (event) => await updateLocalField(fieldTypes.QTY, row, event, 500)}
                                />
                            </div>
                          </div>
                        : <div className="flex-container row start nowrap text-small mar-r2 mar-l2">
                            <div className="flex-item flex-grow-1 align-center text-12">
                              {row.qty}
                            </div>
                          </div>,
                  raw: row.qty,
                  class: bgColour
                },
                deviceNo: { 
                  value:  <div className="flex-container row start nowrap text-small mar-r2 mar-l2">
                            <div className="flex-item flex-grow-1 align-center text-12">
                              {row.deviceNo}
                            </div>
                          </div>, 
                  raw: row.deviceNo, 
                  class: bgColour 
                },
                location: {  
                  value: addressLocation, 
                  raw: `${(foundAddress ? `${foundAddress.label} -` : '')} ${row.specificDeviceLocation}`,
                  class: bgColour 
                },
                cpcsVols: { 
                  value: cpcsVols, 
                  raw: Number(row.cpcsVols) ? row.cpcsVols : 0 ,
                  class: bgColour 
                },
                hide: {
                  value:  <div title="Hide on Invoice" alt="Hide on Invoice" className="flex-container row start nowrap text-small mar-r2 mar-l2">
                            <div className="flex-item flex-grow-1 align-center">
                              <UILIB.Checkbox
                                disabled={!props.isActive || working}
                                name={fieldTypes.SHOWTOCUSTOMER}
                                checked={!row.showToCustomer}
                                onChange={async (event) => await updateLocalField(fieldTypes.SHOWTOCUSTOMER, row, event, 500)}
                              />
                            </div>
                          </div>,
                  raw: Boolean(!row.showToCustomer),
                  class: bgColour
                },
                options: {
                    value:  <div className="flex-container column start nowrap text-small mar-t4 mar-b4 mar-r2 mar-l2">
                              <div className="flex-item width-100 align-center text-12">
                                {props.isActive && (Number(row.rowType) === 0 && row.isADevice === 1)
                                    ? <div disabled={!props.isActive || working} className="icon icon-pencil mar-b5" title="Edit" alt="Edit" onClick={() => 
                                        working ? function () { } : props.openProductConfigurator(1, row, row.prodID, row.portalPartId)} style={{ cursor: "pointer" }} /> 
                                    : null} 
                              </div>
                              <div className="flex-item width-100 align-center text-12">
                                {props.isActive && row.partNo !== "TOTALINVVAL" 
                                  ? <div className="icon icon-copy mar-b5" title="Clone" alt="Clone" onClick={() => working ? function () { } 
                                  : props.openCloneDrawer(row)} style={{ cursor: "pointer" }} /> : <div></div>}
                              </div>
                            </div>,
                    raw: "",
                    class: bgColour
                }
              }
            })

            product
              .filter(p => Number(p.parentId) === Number(row.prodID))
              .sort(function(a, b){
                if(Number(a.subDeviceNo) < Number(b.subDeviceNo)) { return -1; }
                if(Number(a.subDeviceNo) > Number(b.subDeviceNo)) { return 1; }           
                return 0; })
              .forEach(child => {

                child.hasCompats = false;

                bgColour = 'colour background-2 grey'

                tableData.push({
                  raw: child,
                  table: {
                    qty: { 
                      value:  <div className="flex-container row start nowrap text-small mar-r2 mar-l2">
                                <div className="flex-item flex-grow-1 align-center text-12">
                                  <UILIB.NumberInput
                                    className="textInput input"
                                    placeholder="1"
                                    step="1"
                                    min="1"
                                    name={fieldTypes.QTY}
                                    disabled={!props.isActive || working}
                                    onChange={async (event) => await updateLocalField(fieldTypes.QTY, child, event, 500)}
                                    value={Number(child.qty)}
                                    allowDecimal={false} 
                                  />
                                </div>
                              </div>,
                      raw: Number(child.qty),
                      class: bgColour
                    },
                    origin: {
                      value:  <div className="flex-container row start nowrap text-small mar-r2 mar-l2">
                                <div className={`flex-item center align-center text-12 mar-t4`}>
                                  {productOrigin(child)}
                                </div>
                              </div>,
                      raw: child.rowType,
                      class: bgColour
                    },
                    ticked: {
                      value:  <div className={`flex-container row start nowrap text-small mar-r2 mar-l2`}>
                                <div className={`flex-item flex-grow-1 align-center text-12`}>
                                  <UILIB.Checkbox
                                    disabled={!props.isActive || working}
                                    name={fieldTypes.TICKED}
                                    checked={Boolean(child.ticked)}
                                    onChange={async (event) => await updateLocalField(fieldTypes.TICKED, child, event, 500)}
                                  />
                                </div>
                              </div>,
                      raw: child.ticked,
                      class: bgColour
                    },
                    product: {
                      value:  <div className={`flex-container row start nowrap text-small mar-r2 mar-l2`}>
                                <div className={`flex-item flex-grow-1 align-center`}>
                                  <UILIB.TextInput
                                      outerclassname="width-100"
                                      className="textInput input table"
                                      disabled={!props.isActive || working}
                                      placeholder="Enter Product Name"
                                      name={fieldTypes.DESCRIPTION}
                                      value={child.description}
                                      onChange={async (event) => await updateLocalField(fieldTypes.DESCRIPTION, child, event, 500)}
                                  />
                                </div>
                              </div>,
                      raw: child.description,
                      class: bgColour
                    },
                    partNumber: { 
                      value:  <div className="flex-container row start nowrap text-small mar-r2 mar-l2">
                                <div className={`flex-item flex-grow-1 align-center text-12`}>
                                  {child.partNo}
                                </div>
                              </div>, 
                      raw: child.partNo, 
                      class: bgColour 
                    },
                    supplier: {
                      value:  <div className="flex-container row start nowrap text-small mar-r2 mar-l2">
                                <div className={`flex-item flex-grow-1 align-center text-12 ${bgColour}`}>
                                  <UILIB.TextInput
                                    className="textInput input table"
                                    disabled={!props.isActive || working}
                                    name={fieldTypes.SUPPLIER}
                                    placeholder="Enter Supplier"
                                    value={child.supplier}
                                    onChange={async (event) => await updateLocalField(fieldTypes.SUPPLIER, child, event, 500)}
                                  />
                                </div>
                              </div>,
                      raw: child.supplier,
                      class: bgColour
                    },
                    productType: {
                      value:  <div className="flex-container row start nowrap text-small mar-r2 mar-l2">
                                <div className={`flex-item flex-grow-1 align-center text-12 ${bgColour}`}>
                                  {child.portalPart && child.portalPart.SalesPartType ? child.portalPart.SalesPartType.name : ""}
                                </div>
                              </div>,
                      raw: child.portalPart && child.portalPart.SalesPartType ? child.portalPart.SalesPartType.name : "",
                      class: bgColour
                    },
                    newUsed: {
                      value:  <div className="flex-container row start nowrap text-small mar-r2 mar-l2">
                                <div className={`flex-item flex-grow-1 align-center text-12 ${bgColour}`}>
                                  <UILIB.Select 
                                    outerclassname='width-100'
                                    disabled={!props.isActive || working} 
                                    data={[{ label: "New", value: "New" }, { label: "Used", value: "Used" }]} 
                                    value={child.newUsed} 
                                    name={fieldTypes.NEWUSED}
                                    onChange={async (event) => await updateLocalField(fieldTypes.NEWUSED, child, event, 500)} 
                                  />
                                </div>
                              </div>,
                      raw: child.newUsed,
                      class: bgColour
                    },
                    cpcsVols: { 
                      value: "", 
                      raw: "", 
                      class: bgColour 
                    },
                    deviceNo: { 
                      value: "", 
                      raw: "", 
                      class: bgColour 
                    },
                    discount: {
                      value:  <div className={`flex-container row start nowrap text-small mar-r2 mar-l2`}>
                                <div className="flex-item align-center">
                                  <UILIB.TextInput
                                    className="textInput input"
                                    disabled={!props.isActive || working}
                                    placeholder="0"
                                    type="number"
                                    inputMode="numeric"
                                    name={fieldTypes.DISCOUNT}
                                    value={Number(child.discount) === 0 ? "" : Number(child.discount)}
                                    onChange={async (event) => await updateLocalField(fieldTypes.DISCOUNT, child, event, 500)}
                                  />
                                </div>
                                <div className="flex-item flex-grow-1 align-center">%</div>
                              </div>,
                      raw: Number(row.discount) ? row.discount : 0,
                      class: bgColour
                  },
                  cost: {
                      value: <div className={`flex-container row start nowrap text-small mar-r2 mar-l2`}>
                          <div className="flex-item align-center">£</div>
                          <div className="flex-item flex-grow-1 align-center">
                            <UILIB.TextInput
                                className="textInput input"
                                disabled={!props.isActive || working}
                                placeholder="0"
                                type="number"
                                inputMode="numeric"
                                name={fieldTypes.COSTEACH}
                                value={Number(child.costEach) === 0 ? "" : Number(child.costEach)}
                                onChange={async (event) => await updateLocalField(fieldTypes.COSTEACH, child, event, 500)}
                            />
                          </div>
                      </div>,
                      raw: Number(child.costEach) ? child.costEach : 0,
                      class: bgColour
                  },
                  sale: {
                      value:  <div className={`flex-container row start nowrap text-small mar-r2 mar-l2`}>
                                <div className="flex-item align-center">£</div>
                                <div className="flex-item flex-grow-1 align-center">
                                  <UILIB.TextInput
                                      className="textInput input table"
                                      disabled={!props.isActive || working}
                                      placeholder="0"
                                      type="number"
                                      inputMode="numeric"
                                      name={fieldTypes.PRICEEACH}
                                      value={Number(child.priceEach) === 0 ? "" : Number(child.priceEach)}
                                      onChange={async (event) => await updateLocalField(fieldTypes.PRICEEACH, child, event, 500)}
                                  />
                                </div>
                              </div>,
                      raw: Number(row.priceEach) ? row.priceEach : 0,
                      class: bgColour
                  },
                  location: { 
                    value: <div className="width-100"></div>, 
                    raw: "", 
                    class: bgColour 
                  },
                  hide: { 
                    value: <div className="width-100"></div>, 
                    raw: "", 
                    class: bgColour 
                  },
                  options: { 
                    value: <div className="width-100"></div>, 
                    raw: "", 
                    class: bgColour 
                  }
                  }
                })
            })
        })
        return tableData;
    }

    const updateLocalField = async (field, product, event, timeout) => {

      try {

        let discount = null;
        let costEach = null;
        let origPriceEach = null;

        switch (field) {
            case fieldTypes.TICKED:
                const foundChildProducts = props.ourDeal.product.filter(p => Number(p.parentId) === Number(product.prodID)) || [];
                foundChildProducts.forEach(f => f.ticked = event.target.checked);
                product.ticked = event.target.checked;
                const selectedProducts = props.ourDeal.product.filter(x => Boolean(x.ticked));
                props.setProductTicked(selectedProducts);
                break;
            case fieldTypes.COSTEACH:
                if (!isNaN(event.target.value) && event.target.value !== '') {
                    origPriceEach = Number(product.origPriceEach);
                    discount = Number(event.target.value) > origPriceEach 
                      ? 0 : Number(((Number(origPriceEach) - Number(event.target.value)) / Number(origPriceEach)) * 100).toFixed(2);
                    product.discount = isNaN(discount) || !isFinite(discount) ? 0 : Number(discount);
                    product.costEach = isNaN(event.target.value) || !isFinite(event.target.value) ? 0 : Number(event.target.value);
                 }
                else {
                    product.discount = 0;
                    product.costEach = 0;
                }
                break;
            case fieldTypes.DESCRIPTION:
                if (event.target.value !== '') {
                    product.description = event.target.value;
                }
                else {
                    product.description = '';
                }
                break;
            case fieldTypes.DISCOUNT:
                if (!isNaN(event.target.value) && event.target.value !== '') {
                    origPriceEach = Number(product.origPriceEach);
                    costEach = Number(Number(origPriceEach) - (Number(origPriceEach) / 100 * Number(event.target.value))).toFixed(2);
                    product.costEach = isNaN(costEach) || !isFinite(costEach) ? 0 : Number(costEach);
                    product.discount = isNaN(event.target.value) || !isFinite(event.target.value) ? 0 : Number(event.target.value);
                }
                else {
                    product.costEach = 0;
                    product.discount = 0;
                }
                break;
            case fieldTypes.CPCCOLOUR:
                if (!isNaN(event.target.value) && event.target.value !== '') {
                    product.cpcColour = isNaN(event.target.value) ? 0 : Number(event.target.value);
                }
                else {
                    product.cpcColour = 0;
                }
                break;
            case fieldTypes.CPCMONO:
                if (!isNaN(event.target.value) && event.target.value !== '') {
                    product.cpcMono = isNaN(event.target.value) ? 0 : Number(event.target.value);
                }
                else {
                    product.cpcMono = 0;
                }
                break;
            case fieldTypes.PRICEEACH:
                if (!isNaN(event.target.value) && event.target.value !== '') {
                    product.priceEach = isNaN(event.target.value) ? 0 : Number(event.target.value);
                }
                else {
                    product.priceEach = 0;
                }
                break;
            case fieldTypes.QTY:
                product.qty = Number(event.target.value);
                break;
            case fieldTypes.SUPPLIER:
                if (event.target.value !== '') {
                    product.supplier = event.target.value;
                }
                else {
                    product.supplier = '';
                }
                break;
            case fieldTypes.NEWUSED:
                if (event.target.value !== '') {
                    product.newUsed = event.target.value;
                }
                else {
                    product.newUsed = '';
                }
                break;
            case fieldTypes.PARTNO:
                if (event.target.value !== '') {
                    product.partNo = event.target.value;
                }
                else {
                    product.partNo = '';
                }
                break;
            case fieldTypes.SHOWTOCUSTOMER:
              if (event.target.type === "checkbox") {
                  product.showToCustomer = Number(!Boolean(product.showToCustomer));
                }
                break;
            case fieldTypes.SITEADDRESS:
                product.siteAddress = event.target.value;
                if (event.target.value !== '') {
                    product.siteAddress = event.target.value;
                }
                else {
                    product.siteAddress = '';
                }
                break;
            case fieldTypes.SPECIFICDEVICELOCATION:
                if (event.target.value !== '') {
                    product.specificDeviceLocation = event.target.value;
                }
                else {
                    product.specificDeviceLocation = '';
                }
                break;
            case fieldTypes.LEASE:
                if (event.target.value !== '') {
                    product.lease = event.target.value;
                }
                else {
                    product.lease = '';
                }
                break;
            default:
                break;
        }

        setRender(!render);

        const groupedUpdate = [
          fieldTypes.PRICEEACH,
          fieldTypes.COSTEACH,
          fieldTypes.DISCOUNT
        ];

        if(props?.handleCheckErrors) await props.handleCheckErrors(false);

        if(field !== fieldTypes.TICKED) {
          
          if (groupedUpdate.includes(field)) {
            const response = await handleUpdateProductEntity(product, field, groupedUpdate, timeout);
            if(exceptions.includes(Number(response.status))) {  
              if(Number(response.status) === 400) toast.error(invalidConfig);
              if(Number(response.status) === 404) toast.error(notFoundError);
              if(Number(response.status) === 409) toast.error(versionError);  
            }
          } else {
            const response = await handleUpdateProductEntity(product, field, [field], timeout);
            if(exceptions.includes(Number(response.status))) {  
              if(Number(response.status) === 400) toast.error(invalidConfig);
              if(Number(response.status) === 404) toast.error(notFoundError);
              if(Number(response.status) === 409) toast.error(versionError);  
            }
            return;
          }

        }
      } catch (error) {
        console.log(error);
        toast.error(exceptionError);
      }
    }

    const updateDeviceOrder = async (onDrop) => {
      try {

        const data = handleUpdateOnDrop(onDrop);

        const response = await handleUpdateProductOnDrop(data, 100);
        if(exceptions.includes(Number(response.status))) {  
          if(Number(response.status) === 400) toast.error(invalidConfig);
          if(Number(response.status) === 404) toast.error(notFoundError);
          if(Number(response.status) === 409) toast.error(response.message);  
        }
      } catch (error) {
        console.log(error);
        toast.error(exceptionError);
      }
    }


    return <Fragment>
      <UILIB.TableDraggable
        header={headerData}
        module={Products}
        dndEnabled={props.isActive}
        onDrop={(res) => updateDeviceOrder(res)}
        localQuery={() => getProducts(props.ourDeal.product)}
        localRender={[
          render, 
          props.refreshData, 
          props.ourDeal, 
          props.cpcDisabled, 
          props.isActive]} />
    </Fragment>
})
export default QuoteProductsTable