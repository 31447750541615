import React, { Fragment } from 'react';
import Modules from './Modules';


export default function Draggable({ 
  header = [], 
  localRender = [],
  localQuery = undefined,
  onDrop = function () { }, 
  overflowX = 'auto',
  overflowY = 'auto',
  className = "",  
  dndEnabled = true,
  module = React.Component 
}) 
{

  const handleName = "DRAGGABLE_HANDLE";

  const headerAppend = [
    { label: "", value: handleName, type: 'string', width: 18 },
    ...header
  ];

  return <Fragment>
    <div className={`tableContainer width-100 ${className}`} style={{ overflowX: overflowX, overflowY: overflowY }}>
      <table valign="center" className={`mainTable width-100`} cellSpacing="0" cellPadding="0">
        <Modules.ColumnHeader header={headerAppend} />
        <Modules.TableBody 
          dndEnabled={dndEnabled}
          handleName={handleName}
          module={module}
          header={headerAppend} 
          onDrop={onDrop}
          localQuery={localQuery} 
          localRender={localRender}
        />
      </table>
    </div>
  </Fragment>
}