import { memo } from "react";
import classNames from "classnames";

const DataColumn = memo(({ 
  columnData, 
  style,
  columnIndex,
  handleBuildCellStyle,
  isOver = false
}) => {

  const allowedTypes = ['string','boolean','number','bigint'];
  const allowTooltip = allowedTypes.includes(typeof(columnData.raw));
  const align = (style && style.align) ? style.align : '';
  const divClass = `item height-100 ${align}`;
  const divTitle = (allowTooltip) ? columnData.raw : '';

  return <div 
    key={columnIndex}
    title={divTitle} 
    className={classNames(
      "table tableCell",
      "dnd dropZone",
      handleBuildCellStyle,
      { active: isOver },
      divClass,
      columnData.class
    )}
    style={style}>
      {columnData.value}
  </div>
})
export default DataColumn


