export function companyPaymentTermsData() {
  return [
    { label: 'Please Select', value: '-1' },
    { label: 'Cash On Delivery', value: 'Cash On Delivery' },
    { label: 'Free of Charge', value: 'Free of Charge' },
    { label: 'Payment in Advance', value: 'Payment in Advance' },
    { label: '7 days', value: '7 days' },
    { label: '30 days', value: '30 days' },
    { label: '45 days', value: '45 days' },
    { label: '60 days', value: '60 days' }
  ];
}

export function yesNoData() {
  return [
    { label: 'Please Select', value: -1 },
    { label: 'Yes', value: 1 },
    { label: 'No', value: 0 } 
  ];
}

export function yesNoExistingData() {
  return [
    { label: 'Please Select', value: -1 },
    { label: 'Yes', value: 1 } ,
    { label: 'No', value: 0 },
    { label: 'No - T&M', value: 3 },
    { label: 'Existing', value: 2 }
  ];
}

export function procurementDropdownData() {
  return [
    { label: 'Please Select', value: '-1' },
    { label: 'Proc 4 - SP-21-046', value: 'Proc 4 - SP-21-046' },
    { label: 'Proc 3 - SP-16-013', value: 'Proc 3 - SP-16-013' },
    { label: 'Proc 2 - PS-12-008', value: 'Proc 2 - PS-12-008' },
    { label: 'Proc 1 - PS-08-003', value: 'Proc 1 - PS-08-003' },
  ];
}