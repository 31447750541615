import { useState, useEffect, Fragment } from 'react';
import { createSelectInput, createTextInput, createNumberInput } from 'classes/controls/formControls';
import Axios from 'classes/axios';
import UILIB from 'components';


export default function AddNewProductDrawer(props) 
{

  const typeSelect = [
    { label: "Select Type", value: -1 },
    { label: "No", value: 0 },
    { label: "Yes", value: 1 }];

  const [formData, setFormData] = useState({ 
    description: '', 
    partNo: '', 
    isADevice: -1, 
    rowType: 1,
    partTypeId: -1, 
    supplier: '', 
    qty: 1, 
    parentId: 0, 
    siteAddress: -1,
    deviceNo: undefined 
  });

  const addresses = props.ourDeal.addressDropdown.filter(x => [-1,2].includes(Number(x.type)));
  const submitEnable = (Number(formData.isADevice) !== -1 && Number(formData.partTypeId) !== -1 && formData.partNo.length);
  const defaultError = { error: false, message: '' };
  const [bannerError, setBannerError] = useState(defaultError);
  const [partTypes, setPartTypes] = useState([]);
  const [saving, setSaving] = useState(false);
  const exceptions = [400,404];

  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while processing the record(s), please reload or contact support';


  const updateFieldEvent = (ev) => {
    setFormData({ ...formData, [ev.target.name]: ev.target.value });
  }

  const handleFilter = (parts) => {
    return parts.filter(f => (Number(formData.isADevice) === 0) ? Number(f.value) !== 34 : (Number(f.value) === 34 || Number(f.value) === -1));
  }

  const generateDevices = (data) => {
    if(Number(data.qty) === 1 || Number(data.isADevice) !== 1) return [data];
    const devices = [];
    for(let i = 0; i < data.qty; i++) {
      devices.push({ ...data, qty: 1 });
    }
    return devices;
  }

  const actionsubmitForm = async () => {
    setSaving(true);
    try {
      const result = await Axios.post(`/entities/workflow/deals/actionAddBlankProduct/${props.ourDeal.dealID}`, { devices: generateDevices(formData) });
      if(exceptions.includes(result.status)) {
        if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
        if(result.status === 404) setBannerError({ error: true, message: notFoundError });
        setSaving(false);
      } else {
        props.setOurDeal({ 
          ...props.ourDeal, 
          product: result.data.result.product, 
          compatibles: result.data.result.compatibles
        });
        props.onFinish();
      }
    } catch (error) {
      console.log(error);
      setBannerError({ error: true, message: exceptionError });
      setSaving(false);
    }
  };

  useEffect(() => {
    const asyncEffect = async () => {
      const result = await Axios.get(`/sales/parts/type`);
      const partTypeResult = [{ label: "Please Select", value: -1 }, ...result.data.map(type => { return { label: type.name, value: type.id } })];
      if(formData.isADevice === -1) setPartTypes(partTypeResult);
      if(formData.isADevice !== -1) setPartTypes(handleFilter(partTypeResult));
    }
    asyncEffect();
  }, [formData.isADevice])


  return <Fragment>
    <div className='flex-container row'>
      <div className="flex-item start align-center flex-grow-1 text-16 font-weight-bold width-100">Add New Product</div>
      <p className="flex-item start width-80 text-11 mar-b10"><i>Please note, if this is a device to be installed at site, you must set "Is a device?" to <b>Yes</b> below.</i></p>
      <div className='flex-container row start wrap evenly width-100 mar-t2'>
        {createSelectInput('Site Address', 'siteAddress', (!props.isActive || saving), formData.siteAddress, addresses, (ev) => updateFieldEvent(ev), null, 'width-80 flex-grow-1', null, null, 'width-100 fullBorder border-colour-grey-4')}
        {createTextInput('Product Name', 'description', (!props.isActive || saving), formData.description, (ev) => updateFieldEvent(ev), null, 'width-100', null, null, 'fullBorder border-colour-grey-4')}
        {createTextInput('Product Supplier', 'supplier', (!props.isActive || saving), formData.supplier, (ev) => updateFieldEvent(ev), null, 'width-100', null, null, 'fullBorder border-colour-grey-4')}
        {createTextInput('Part Number *', 'partNo', (!props.isActive || saving), formData.partNo, (ev) => updateFieldEvent(ev), null, 'width-100', null, null, 'fullBorder border-colour-grey-4')}
        {createNumberInput('Quantity', 'qty', (!props.isActive || saving), formData.qty, (ev) => updateFieldEvent(ev), 'width-100', 'flex-grow-1', null, null, 'width-100 fullBorder border-colour-grey-4')}
        {createSelectInput('Is a Device? *', 'isADevice', (!props.isActive || saving), formData.isADevice, typeSelect, (ev) => updateFieldEvent(ev), null, 'width-80 flex-grow-1', null, null, 'width-100 fullBorder border-colour-grey-4')}
        {createSelectInput('Product Type *', 'partTypeId', (!props.isActive || saving), formData.partTypeId, partTypes, (ev) => updateFieldEvent(ev), null, 'width-80 flex-grow-1', null, null, 'width-100 fullBorder border-colour-grey-4')}
      </div>
      <div className='flex-container row start width-100 mar-t20 mar-r5'>
        <UILIB.Button className='green' disabled={(!props.isActive || saving || !submitEnable)} loading={saving} value='Add Product' onClick={async () => await actionsubmitForm()} />
        <UILIB.Button className='grey' disabled={saving} value='Cancel' onClick={() => props.onCancel()} />
        {bannerError.error && <div className="flex-item start wrap width-100">
          <div className="errored message">{bannerError.message}</div>
        </div>}
      </div>
    </div>
  </Fragment>
}
