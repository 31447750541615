import { checkFormErrors, checkProductErrors, checkAdditionalInfoErrors, checkDocumentErrors } from 'classes/formErrors/formErrors';
import 'react-toastify/dist/ReactToastify.css'
import { toast } from 'react-toastify';
import { useCallback } from "react";

export default function useCheckErrors(props) {

  const handleCheckErrors = useCallback(
    async (submitting = false) => {

      const tabErrorsLocal = [];
      const productErrorsNew = [];

      props.dealData.product.filter(p => p.parentId === 0).forEach(row => {
        const disabledDecider = (((row.portalPart && row.portalPart.SalesPartType && row.portalPart.SalesPartType.name === "MFP - Equipment") || (row.rowType === 1 && row.isADevice === 1)) && props.dealData.leaseorcash !== 'Cash - Trade (SOP Only)') ? false : true;
        productErrorsNew.push({ name: "siteAddress", rowId: row.prodID, value: row.siteAddress, option: 0, subNav: 1, disabled: false, error: false })
        productErrorsNew.push({ name: "specificDeviceLocation", rowId: row.prodID, value: row.specificDeviceLocation, option: 0, subNav: 1, disabled: disabledDecider, error: false })
        productErrorsNew.push({ name: "cpcMono", rowId: row.prodID, value: row.cpcMono, option: 0, subNav: 1, disabled: disabledDecider, error: false })
        productErrorsNew.push({ name: "cpcColour", rowId: row.prodID, value: row.cpcColour, option: 0, subNav: 1, disabled: disabledDecider, error: false })
        props.fieldWorkflowObj.scottishProcurementLease && productErrorsNew.push({ name: "lease", rowId: row.prodID, value: row.lease, option: 0, subNav: 1, disabled: disabledDecider, error: false })
      })

      const crmAddress = (props.dealData.address.some(x => Number(x.addressTypeId) === 1));

      const formResult = await checkFormErrors(props.formErrors, props.setFormErrors, tabErrorsLocal, props.setTabErrors, props.dealData, null);
      const invoiceResult = await checkFormErrors(props.invoiceErrors, props.setInvoiceErrors, tabErrorsLocal, props.setTabErrors, { crmAddress: crmAddress }, null);
      const productResult = await checkProductErrors(productErrorsNew, props.setProductErrors, tabErrorsLocal, props.setTabErrors, props.dealData.product, null);
      const additionalResult = await checkAdditionalInfoErrors(props.additionalInfoErrors, props.setAdditionalInfoErrors, tabErrorsLocal, props.setTabErrors, props.dealData, null);
      const documentResult = await checkDocumentErrors(props.documentErrors, props.setDocumentErrors, tabErrorsLocal, props.setTabErrors, props.dealData.document, null);

      if (formResult.errorCount === 0 && invoiceResult.errorCount === 0 && productResult.errorCount === 0 && additionalResult.errorCount === 0 && documentResult.errorCount === 0) {
        if(submitting) props.setSubmitModal(true);
        return true;
      }
      else {
        if (submitting && formResult.errorCount > 0) toast.error(formResult.errorCount + "/" + formResult.totalCount + " Form fields require attention before saving");
        if (submitting && invoiceResult.errorCount > 0) toast.error("Please add an Invoice Address before saving");
        if (submitting && additionalResult.errorCount > 0) toast.error(additionalResult.errorCount + "/" + additionalResult.totalCount + " Contacts require attention before saving");
        if (submitting && productResult.errorCount > 0) toast.error(productResult.errorCount + "/" + productResult.totalCount + " Product fields require attention before saving");
        if (submitting && documentResult.errorCount > 0) toast.error(documentResult.errorCount + "/" + documentResult.totalCount + " Documents require attention before saving");
        return false;
      }

    },
    [props]
  );

  return { handleCheckErrors };
}