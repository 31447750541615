import { useState, Fragment } from 'react';
import 'react-toastify/dist/ReactToastify.css'
import { toast } from 'react-toastify';
import UILIB from 'components';
import Axios from 'classes/axios';
import { downloadFileHandler } from 'classes/download';


export default function DownloadDealSheet(props) {

  const [saving, setSaving] = useState(false);
  const exceptions = [400,404,204];

  const noContent = 'The options selected returned no results, please try again';
  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while fetching this data, please reload or contact support';

  const handleGenerateCSV = async () => {
    try { 
      setSaving(true);
      const response = await Axios.get(`/entities/workflow/deals/actionGenerateDealSheet/${props.ourDeal.dealID}`);
      if(exceptions.includes(response.status)) {
        if(response.status === 204) toast.error(noContent);
        if(response.status === 400) toast.error(invalidConfig);
        if(response.status === 404) toast.error(notFoundError);
      } else {
        downloadFileHandler(response.data.result.filename, 'pdf', response.data.result.content);
      }
    } catch (err) {
      console.log(err);
      toast.error(exceptionError);
    } finally {
      setSaving(false);
    }
  }


  return <Fragment>         
    <UILIB.Button 
      className="small green width-100" 
      name="Downloal Deal Sheet" 
      value="Download Deal Sheet" 
      loading={saving} 
      disabled={saving} 
      onClick={async () => await handleGenerateCSV()} 
    />
  </Fragment>   
}
