import { useState, Fragment } from 'react';
import { createSelectInput, createTextInput } from 'classes/controls/formControls';
import { getSettlementAdditionalDrawerFormErrors } from 'classes/formErrors/repository';
import Axios from 'classes/axios';
import UILIB from 'components';


export default function AddSettlementLine(props) 
{

  const settlements = [
    { label: 'Please Select', value: -1 },
    ...props.ourDeal.settlement.map(x => { return { label: x.agreementNumber, value: Number(x.id) } })];
  const [formData, setFormData] = useState({ settlementId: -1, costEach: 0, qty: 1 });
  const submitEnable = (Number(formData.settlementId) !== -1);
  const defaultError = { error: false, message: '' };
  const [bannerError, setBannerError] = useState(defaultError);
  const [settlementError, setSettlementError] = useState(defaultError);
  const [saving, setSaving] = useState(false);
  const exceptions = [400,404];

  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while processing the record(s), please reload or contact support';
  const settlementInvalid = 'Some information is missing from the settlement line, please amend then try this action again';


  const updateFieldEvent = async (ev, settlement = false) => {
    if(settlement) {
      const found = props.ourDeal.settlement.find(x => Number(x.id) === Number(ev.target.value));
      if(found) {
        const formErrors = getSettlementAdditionalDrawerFormErrors(found);
        if(Object.values(formErrors).some(x => x.condition)) {
          setSettlementError({ error: true, message: settlementInvalid });
        } else {
          setSettlementError(defaultError);
        }
      } else {
        setSettlementError(defaultError);
      }
    }
    setFormData({ ...formData, [ev.target.name]: ev.target.value });
  }

  const generateDevices = (data) => {
    if(Number(data.qty) === 1) return [data];
    const devices = [];
    for(let i = 0; i < data.qty; i++) {
      devices.push({ ...data, qty: 1 });
    }
    return devices;
  }

  const actionsubmitForm = async () => {
    setSaving(true);
    try {
      const found = props.ourDeal.settlement.find(x => Number(x.id) === Number(formData.settlementId));
      const result = await Axios.post(`/entities/workflow/deals/actionAddSettlementProduct/${props.ourDeal.dealID}`, { settlement: found, devices: generateDevices(formData) });
      if(exceptions.includes(result.status)) {
        if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
        if(result.status === 404) setBannerError({ error: true, message: notFoundError });
        setSaving(false);
      } else {
        props.setOurDeal({ 
          ...props.ourDeal, 
          leasePeriodPayment: result.data.result.lease,
          pricing: result.data.result.pricing, 
          product: result.data.result.product, 
          compatibles: result.data.result.compatibles
        });
        props.onFinish();
      }
    } catch (error) {
      console.log(error);
      setBannerError({ error: true, message: exceptionError });
      setSaving(false);
    }
  };


  return <Fragment>
    <div className='flex-container row'>
      <div className="flex-item start align-center flex-grow-1 text-16 font-weight-bold width-100">Add New Product</div>
      <p className="flex-item start width-80 text-11 mar-b10"><i>Please note, at least one settlement record needs to exist in the 'Additional Info' tab before you can bind a product line.</i></p>
      <div className='flex-container row start wrap evenly width-100 mar-t2'>
        {createSelectInput('Select a Settlement *', 'settlementId', (!props.isActive || saving), formData.settlementId, settlements, async (ev) => await updateFieldEvent(ev, true), null, 'width-80 flex-grow-1', null, null, 'width-100 fullBorder border-colour-grey-4')}
        {settlementError.error && <div className="flex-item start wrap width-100 mar-l5">
          <div className="errored message">{settlementError.message}</div>
        </div>}
        {createTextInput('Cost', 'costEach', (!props.isActive || saving), formData.costEach, async (ev) => await updateFieldEvent(ev), null, 'width-100', null, null, 'fullBorder border-colour-grey-4')}
      </div>
      <div className='flex-container row start width-100 mar-t20 mar-r5'>
        <UILIB.Button className='green' disabled={(!props.isActive || saving || !submitEnable || settlementError.error)} loading={saving} value='Add Product' onClick={async () => await actionsubmitForm()} />
        <UILIB.Button className='grey' disabled={saving} value='Cancel' onClick={() => props.onCancel()} />
        {bannerError.error && <div className="flex-item start wrap width-100">
          <div className="errored message">{bannerError.message}</div>
        </div>}
      </div>
    </div>
  </Fragment>
}
