import { Fragment } from 'react';
import UILIB from 'components';


export default function KitFinderDrawer({ kitData, onClose, isActive }) 
{
  const headerData = [
    { label: 'Qty', value: 'quantity', type: 'string', filtering: false, minWidth: 30, maxWidth: 30 },
    { label: 'Item', value: 'item', type: 'string', filtering: false, minWidth: 130, maxWidth: 130 },
    { label: 'Part No', value: 'partNo', type: 'string', filtering: false, minWidth: 130, maxWidth: 130 }];

  const defaultSettings =  {   
    expanding: false,
    filtering: false,
    sortable: false,
    caching: false,
    filterEnabled: false, 
    refreshEnabled: false,
    paginationEnabled: false,
    showRecords: false,
    exportEnabled: false,
    expandEnabled: false,
    deleteEnabled: false }

  async function constructTable(data) {
    const tableData = data.map(row => {
      return {
        quantity: { value: row.Quantity, raw: row.Quantity },
        item: { value: row.Description, raw: row.Description },
        partNo: { value: row.PartNumber, raw: row.PartNumber } }
    })
    return tableData;
  }
  

  return <Fragment>
    <div className='flex-container row wrap mar-b10'>
      <div className="flex-item start align-center flex-grow-1 text-16 font-weight-bold width-100">Kit</div>
      <UILIB.TableNew
        name={'KitFinder'}
        outerclassname='max-height-600-px'
        className='small'
        overflowX='auto'
        overflowY='auto'    
        header={headerData}
        localQuery={() => constructTable((kitData.length) ? kitData : [])}
        localRender={[kitData]} 
        defaultSettings={defaultSettings} />
    </div>
  </Fragment>
}