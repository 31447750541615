import Axios from "classes/axios";
import { useCallback } from "react";

export default function useUpdateProductOnDrop(ourDeal, timerObject) {

  const handleUpdateProductOnDrop = useCallback(
    async (onDrop, timeout) => {
      return new Promise((resolve, reject) => {
        clearTimeout(timerObject.current);
        const timer = setTimeout(async () => {
          await Axios.put(`/entities/workflow/deals/actionSaveProductDnD/${ourDeal.dealID}`, onDrop)
            .then(res => {
              resolve(res.data);
            })
            .catch(error => {
              reject(error.data);
            })
        }, timeout);
        timerObject.current = timer;
      });
    },
    [
      ourDeal.dealID,
      timerObject
    ]
  );

  return { handleUpdateProductOnDrop };
}