import { store } from 'store/store'
import { setDrawer } from 'store/actions'
import { appStrings, fieldTypes } from 'classes/quotesHelpers';
import DeleteProductDrawer from './drawer/deleteProductDrawer';


export function fieldWorkflow(props) {

  if(!props.dealData) return;

  const fieldWorkflowObj = getFieldWorkflowObj();
  const selected = props.dealData.leaseorcash;

  fieldWorkflowObj[fieldTypes.DMCPCNO] = true;
  fieldWorkflowObj[fieldTypes.LEASEORCACHE] = true;
  fieldWorkflowObj[fieldTypes.LARGERGROUPSELECT] = true;

  switch (selected) {
    case appStrings.Lease:
      fieldWorkflowObj[fieldTypes.LEASECOMPANYINFO] = true;
      fieldWorkflowObj[fieldTypes.LEASERATE] = true;
      fieldWorkflowObj[fieldTypes.RVINCLUDED] = true;
      fieldWorkflowObj[fieldTypes.COMPANYPAYMENTLENGTH] = true;
      fieldWorkflowObj[fieldTypes.DEALCURRENCY] = true;
      fieldWorkflowObj[fieldTypes.SERVICECONTRACT] = true;
      break;
    case appStrings.InternalRentalCPC:
      fieldWorkflowObj[fieldTypes.LEASECOMPANYINFO] = true;
      fieldWorkflowObj[fieldTypes.LEASERATE] = true;
      fieldWorkflowObj[fieldTypes.RVINCLUDED] = true;
      fieldWorkflowObj[fieldTypes.COMPANYPAYMENTLENGTH] = true;
      fieldWorkflowObj[fieldTypes.DEALCURRENCY] = true;
      fieldWorkflowObj[fieldTypes.SERVICECONTRACT] = true;
      fieldWorkflowObj[fieldTypes.REBAEPERCENT] = true;
      fieldWorkflowObj[fieldTypes.ISMEMBER] = true;
      fieldWorkflowObj[fieldTypes.CPCLOCATION] = true;
      fieldWorkflowObj[fieldTypes.CPCLOT] = true;
      break;
    case appStrings.ScottishProcurement: 
      fieldWorkflowObj[fieldTypes.SCOTTISHPROCUREMENT] = true;
      fieldWorkflowObj[fieldTypes.PROCUREMENTLEVEL] = true;
      fieldWorkflowObj[fieldTypes.SECTOR] = true;
      fieldWorkflowObj[fieldTypes.ORGANISATION] = true;
      fieldWorkflowObj[fieldTypes.MINICOMPORDIRECTBUY] = true;
      break;
    case appStrings.ScottishProcurementLease:
      fieldWorkflowObj[fieldTypes.LEASECOMPANYINFO] = true;
      fieldWorkflowObj[fieldTypes.LEASERATE] = true;
      fieldWorkflowObj[fieldTypes.RVINCLUDED] = true;
      fieldWorkflowObj[fieldTypes.COMPANYPAYMENTLENGTH] = true;
      fieldWorkflowObj[fieldTypes.DEALCURRENCY] = true;
      fieldWorkflowObj[fieldTypes.SERVICECONTRACT] = true;
      fieldWorkflowObj[fieldTypes.SCOTTISHPROCUREMENT] = true;
      fieldWorkflowObj[fieldTypes.SCOTTISHPROCUREMENTLEASE] = true;
      fieldWorkflowObj[fieldTypes.PROCUREMENTLEVEL] = true;
      fieldWorkflowObj[fieldTypes.SECTOR] = true;
      fieldWorkflowObj[fieldTypes.ORGANISATION] = true;
      fieldWorkflowObj[fieldTypes.MINICOMPORDIRECTBUY] = true;
      break;
    case appStrings.Cash:
      fieldWorkflowObj[fieldTypes.HIDEPANELRIGHT] = true;
      fieldWorkflowObj[fieldTypes.SERVICECONTRACT] = true;
      break;
    case appStrings.CashCPC:
      fieldWorkflowObj[fieldTypes.COMPANYPAYMENTTERMS] = true;
      fieldWorkflowObj[fieldTypes.SERVICECONTRACT] = true;
      fieldWorkflowObj[fieldTypes.REBAEPERCENT] = true;
      fieldWorkflowObj[fieldTypes.ISMEMBER] = true;
      fieldWorkflowObj[fieldTypes.CPCLOCATION] = true;
      fieldWorkflowObj[fieldTypes.SERVICETERM] = true;
      fieldWorkflowObj[fieldTypes.CPCLOT] = true;
      break;
    case appStrings.ShortTermInternalRental:
      fieldWorkflowObj[fieldTypes.COMPANYPAYMENTLENGTH] = true;
      fieldWorkflowObj[fieldTypes.DEALCURRENCY] = true;
      fieldWorkflowObj[fieldTypes.SERVICECONTRACT] = true;
      break;
    case appStrings.Copycare:
      fieldWorkflowObj[fieldTypes.COMPANYPAYMENTLENGTH] = true;
      fieldWorkflowObj[fieldTypes.DEALCURRENCY] = true;
      break;
    case appStrings.BackToBackCPC:
      fieldWorkflowObj[fieldTypes.LEASERATE] = true;
      fieldWorkflowObj[fieldTypes.RVINCLUDED] = true;
      fieldWorkflowObj[fieldTypes.COMPANYPAYMENTLENGTH] = true;
      fieldWorkflowObj[fieldTypes.REBATEVALUE] = true;
      fieldWorkflowObj[fieldTypes.REBAEPERCENT] = true;
      fieldWorkflowObj[fieldTypes.ISMEMBER] = true;
      fieldWorkflowObj[fieldTypes.CPCLOCATION] = true;
      fieldWorkflowObj[fieldTypes.CPCLOT] = true;
      break;
    case appStrings.BackToBackEROY:
      fieldWorkflowObj[fieldTypes.LEASERATE] = true;
      fieldWorkflowObj[fieldTypes.RVINCLUDED] = true;
      fieldWorkflowObj[fieldTypes.COMPANYPAYMENTLENGTH] = true;
      fieldWorkflowObj[fieldTypes.REBATEVALUE] = true;
      break;
    case appStrings.Subscription:
      fieldWorkflowObj[fieldTypes.COMPANYPAYMENTLENGTH] = true;
      fieldWorkflowObj[fieldTypes.DEALCURRENCY] = true;
      fieldWorkflowObj[fieldTypes.SERVICECONTRACT] = true;
      break;
    case appStrings.FOC:
    case appStrings.POC:
    case appStrings.GPOnly:
    case appStrings.PleaseSelect:
      fieldWorkflowObj[fieldTypes.HIDEPANELRIGHT] = true;
      break;
  default:
      break;
  }

  if (props.dealData.rvIncluded === "No" || Number(props.dealData.rvIncluded) === -1 || !fieldWorkflowObj[fieldTypes.RVINCLUDED]) {
    fieldWorkflowObj[fieldTypes.RVPERCENT] = false;
  } else {
    fieldWorkflowObj[fieldTypes.RVPERCENT] = true;
  }

  if (Number(props.dealData.largerGroupSelect) === -1 || Number(props.dealData.largerGroupSelect) === 0) {
    fieldWorkflowObj[fieldTypes.LARGERGROUPID] = false;
  } else {
    fieldWorkflowObj[fieldTypes.LARGERGROUPID] = true;
  }

  if (props.dealData.procurementLevel === null) {
    fieldWorkflowObj[fieldTypes.PROCUREMENTLEVEL] = false;
  } else {
    fieldWorkflowObj[fieldTypes.PROCUREMENTLEVEL] = true;
  }

  if(props.setFormErrors) {
    const reduced = Object
      .keys(fieldWorkflowObj)
      .filter(x => fieldWorkflowObj[x])
      .filter(x => x !== fieldTypes.HIDEPANELRIGHT)
      .reduce((acc, obj) => ({ 
        ...acc, [obj]: { option: 0, subNav: 0, disabled: false, error: false } }), {});
    props.setFormErrors(reduced);
  }

  if(props.additionalInfoErrors) {
    const additionalInfoErrorsNew = {...props.additionalInfoErrors};
    if(selected === appStrings.CashTrade) {
      Object.values(additionalInfoErrorsNew).forEach(obj => obj.disabled = true);
    }
    else {
      Object.values(additionalInfoErrorsNew).forEach(obj => obj.disabled = false);
    }
    props.setAdditionalInfoErrors(additionalInfoErrorsNew);
  }

  props.setFieldWorkflowObj(fieldWorkflowObj);

}

export function getQuoteProcessData(ourDeal) {

  let topText = null
  let procStage = null
  let typeText = null
  let tableStatus = null
  let status = null
  let submitted = null

  
  switch(true)
  {
    case (ourDeal.archived === 0 && ourDeal.processStage === 0):
      status = 'Quote';
      break;
    case (ourDeal.archived === 0 && ourDeal.dynamicsStateCode === 1):
      status = 'Won';
      break;
    case (ourDeal.archived === 0 && ourDeal.dynamicsStateCode === 2):
      status = 'Lost';
      break;
    case (!ourDeal.ordered && !ourDeal.closed && ourDeal.archived === 0):
      status = 'Active'
      break;
    case (ourDeal.ordered && ourDeal.closed && ourDeal.archived === 0):
      status = 'Ordered'
      break;
    case (!ourDeal.ordered && ourDeal.closed && ourDeal.archived === 0):
      status = 'Closed'
      break;
    case (ourDeal.archived === 1):
      status = 'Archived'
      break;
    default: // Do nothing
  }

  switch(true)
  {
    
    case (ourDeal.processStage === 2 && !ourDeal.declineSubmitted && ourDeal.archived === 0):
      submitted = 'Submitted'
      break;
    case (ourDeal.processStage === 2 && ourDeal.declineSubmitted && ourDeal.archived === 0):
      submitted = 'Declined'
      break;
    case (ourDeal.processStage >= 3 && !ourDeal.declineSubmitted && ourDeal.archived === 0):
      submitted = 'Approved'
      break;
    case (ourDeal.archived === 1):
      submitted = 'Archived'
      break;
    default: // Do nothing
  }

  if(ourDeal.processStage === 0 && !ourDeal.archived) {
    console.log("HERE")
    procStage = status
    typeText = `Quote`
    tableStatus = `${status}`
  }

  if(ourDeal.processStage === 1 && !ourDeal.archived) {
    procStage = 'Draft'
    typeText = "Order"
    tableStatus = `Draft`
  }

  if(ourDeal.processStage === 2 && !ourDeal.archived) {
    procStage = status
    typeText = "Order"
    tableStatus = `${submitted}`
  }

  if(ourDeal.processStage === 3 && !ourDeal.archived) {
    procStage = submitted
    typeText = "Order"
    tableStatus = `${submitted}`
  }

  if(ourDeal.processStage === 4 && Number(ourDeal.engStatus) !== 10 && !ourDeal.archived) {
    procStage = 'Logistics'
    typeText = "Order"
    tableStatus = `Logistics`
  }

  if(ourDeal.processStage === 4 && (Number(ourDeal.engStatus) === 10 && !ourDeal.invoiceDate) && !ourDeal.archived) {
    procStage = 'Complete - Awaiting Invoice'
    typeText = "Order"
    tableStatus = `Complete - Awaiting Invoice`
  }

  if(ourDeal.processStage === 4 && (Number(ourDeal.engStatus) === 10 && ourDeal.invoiceDate) && !ourDeal.archived) {
    procStage = 'Complete - Invoiced'
    typeText = "Order"
    tableStatus = `Complete - Invoiced`
  }

  if(ourDeal.archived) {
    procStage = 'Archived'
    typeText = "Order"
    tableStatus = `Archived`
  }

  return { topText, procStage, typeText, tableStatus, status }
}

export function getAddressDropdown(data) {
  
  if(!data) return [];

  const addresses = (data?.address) ? data?.address : [];
  const uniqueData = Array.from(new Set(addresses.map(x => JSON.stringify(x))))
  const parsedData = uniqueData.map(x => JSON.parse(x));

  const AddressData = parsedData
    .sort(function(a, b){
      if(String(a.accountNumber) < String(b.accountNumber)) { return -1; }
      if(String(a.accountNumber) > String(b.accountNumber)) { return 1; }
      return 0; })
    .map(address => {
      let addressFull = [];
      let addressString = "";
      if (address.accountNumber) addressFull.push(address.accountNumber);
      if (address.customerName) addressFull.push(address.customerName);
      if (address.address1) addressFull.push(address.address1);
      if (address.address2) addressFull.push(address.address2);
      if (address.address3) addressFull.push(address.address3);
      if (address.town) addressFull.push(address.town);
      if (address.county) addressFull.push(address.county);
      if (address.postcode) addressFull.push(address.postcode);
      addressString = String(addressFull.map(ad => { return ad + ', ' }))
      addressString = addressString.substring(0, addressString.length - 2).replace(/, ,/g, ', ');
      return { label: addressString, value: address.id, type: Number(address.addressTypeId) }
  })
  AddressData.unshift({ label: "Please Select", value: -1, type: -1 });
  return AddressData;
}

export async function checkAccess(account, ourDeal) {

  let adminUser = false;
  let sopOwnerUser = false;
  let devSop = false;
  let isManager = false;
  let isLogistics = false;
  let isSales = false;
  let isSalesAdmin = false;
  let isFinance = false;
  let isProServices = false;

  let adminUserRead = false;
  let sopOwnerUserRead = false;
  let devSopRead = false;
  let isManagerRead = false;
  let isLogisticsRead = false;
  let isSalesRead = false;
  let isSalesAdminRead = false;
  let isFinanceRead = false;
  let isProServicesRead = false;


  if(account.groups.includes('Apps Dept')) { devSop = true; devSopRead = false; }
  if(account.groups.includes('DevSop')) { devSop = true; devSopRead = false; }
  if(account.groups.includes('Senior SOP')) { adminUser = true; adminUserRead = false; }
  if(account.groups.includes('SOP')) { adminUser = true; adminUserRead = false; }
  if(account.groups.includes('Sales')) { isSales = true; isSalesRead = false; }
  if(account.groups.includes('Sales Admin')) { isSalesAdmin = true; isSalesAdminRead = false; }
  if(account.azureId === ourDeal.activeDirectoryObjectGuidSopOwner) { sopOwnerUser = true; sopOwnerUserRead = false; }
  if(account.teamAzureIds.includes(ourDeal.activeDirectoryObjectGuid) && account.teamAzureIds.includes(account.azureId)) { isManager = true; isManagerRead = false; }
  if(account.groups.includes('Warehouse & Logistics')) { isLogistics = true; isLogisticsRead = true; }
  if(account.groups.includes('Finance')) { isFinance = true; isFinanceRead = true; }
  if(account.groups.includes('PS')) { isProServices = true; isProServicesRead = true; }


  return { 
    users: {
      isDevSop: devSop,
      isSopOwner: sopOwnerUser,
      isAdmin: adminUser, 
      isManager: isManager,
      isLogistics: isLogistics,
      isSales: isSales,
      isSalesAdmin: isSalesAdmin,
      isFinance: isFinance,
      isProServices: isProServices
    },
    read: {
      isDevSop: devSopRead,
      isSopOwner: sopOwnerUserRead,
      isAdmin: adminUserRead, 
      isManager: isManagerRead,
      isLogistics: isLogisticsRead,
      isSales: isSalesRead,
      isSalesAdmin: isSalesAdminRead,
      isFinanceRead: isFinanceRead,
      isProServicesRead: isProServicesRead
    }
  }
}

export function deleteProduct(props, row) {

  const { dispatch } = store; 

  const attachments = props.ourDeal.product.filter(x => Number(x.parentId) === Number(row.prodID));
  const baseData = { ...props, productTickedEvent: { selectedProducts: [row, ...attachments] } };

  dispatch(setDrawer({
      open: true,
      content: <DeleteProductDrawer {...baseData} 
                onFinish={() => dispatch(setDrawer({ open: false, content: null }))} 
                onCancel={() => dispatch(setDrawer({ open: false, content: null }))} />,
      width: ""
  })); 
}

export function productOrigin(row) {

  let toolTip = 'Unable to determine Origin';
  if(Number(row.rowType) === 0) toolTip = 'Created in Product Configurator';
  if(Number(row.rowType) === 1) toolTip = 'Created as Blank Part';
  if(Number(row.rowType) === 2) toolTip = 'Created as Refinanced Part';
  if(Number(row.rowType) === 3) toolTip = 'Created as PS import';
  if(Number(row.rowType) === 4) toolTip = 'Created as Settlement Line';

  let colour = 'red';
  if(Number(row.rowType) === 0) colour = 'green';
  if(Number(row.rowType) === 1) colour = 'orange';
  if(Number(row.rowType) === 2) colour = 'purple';
  if(Number(row.rowType) === 3) colour = 'batched';
  if(Number(row.rowType) === 4) colour = 'royalblue';

  const productCircle = <div style={{ align: 'center' }} className={`circleProduct ${colour}`} title={toolTip} alt={toolTip} />;

  return productCircle;
}

export function getDoctype(leaseorcash, serviceContract) {
  const dd = 'Direct Debit';
  const bmsas = 'BM Sales & Service agreement';
  const bmsa = 'BM Service Addendum';
  const ssa = 'Software Support Agreement';
  const cso = 'Credit Safe Output';
  const lad = 'Lease additional documentation';
  const la = 'Lease Agreement';
  const lra = 'Lease / Rental Acceptance';
  const ldd = 'Lease Direct Debit';
  const p = 'Proposal';
  const bmpsq = 'BM PS Quote';
  const nf = 'Network Form';
  const nsa = 'Network Support Agreement';
  const od = 'Other Document';
  const a = 'Approval';
  const po = 'Purchase Order';
  const btbo = 'Back to Back Order';
  const ca = 'Copycare agreement';
  const ess = 'e-Signature Summary';
  const no = 0;
  const yes = 1;
  const existing = 2;
  
  if (leaseorcash) {
      switch (leaseorcash) {
          case appStrings.Lease:
              if(serviceContract === no) { return [dd, bmsas, bmsa, ssa, cso, lad, la, `${lra}*`, ldd, p, bmpsq, nf, nsa, od, a]; }
              if(serviceContract === yes) { return [dd, `${bmsas}*`, bmsa, ssa, cso, lad, `${la}*`, `${lra}*`, ldd, p, bmpsq, nf, nsa, od, a]; }
              if(serviceContract === existing) { return [dd, bmsas, bmsa, ssa, cso, lad, `${la}*`, `${lra}*`, ldd, p, bmpsq, nf, nsa, od, a]; }
              break;
          case appStrings.InternalRentalCPC:
              if(serviceContract === no) { return [dd, bmsas, bmsa, ssa, cso, lad, la, `${lra}*`, ldd, p, bmpsq, nf, nsa, od, a]; }
              if(serviceContract === yes) { return [dd, `${bmsas}*`, bmsa, ssa, cso, lad, `${la}*`, `${lra}*`, ldd, p, bmpsq, nf, nsa, od, a]; }
              if(serviceContract === existing) { return [dd, bmsas, bmsa, ssa, cso, lad, `${la}*`, `${lra}*`, ldd, p, bmpsq, nf, nsa, od, a]; }
              break;
          case appStrings.Cash:
              if(serviceContract === no) { return [dd, bmsas, bmsa, ssa, cso, p, bmpsq, nf, nsa, `${po}*`, od, a]; }
              if(serviceContract === yes) { return [dd, `${bmsas}*`, bmsa, ssa, cso, p, bmpsq, nf, nsa, po, od, a]; }
              if(serviceContract === existing) { return [dd, bmsas, bmsa, ssa, cso, p, bmpsq, nf, nsa, po, od, a]; }
              break;
          case appStrings.CashCPC:
            if(serviceContract === no) { return [dd, bmsas, bmsa, ssa, cso, p, bmpsq, nf, nsa, `${po}*`, od, a]; }
            if(serviceContract === yes) { return [dd, `${bmsas}*`, bmsa, ssa, cso, p, bmpsq, nf, nsa, po, od, a]; }
            if(serviceContract === existing) { return [dd, bmsas, bmsa, ssa, cso, p, bmpsq, nf, nsa, po, od, a]; }
            break;
          case appStrings.CashTrade: return [dd, bmsas, bmsa, ssa, cso, p, bmpsq, nf, nsa, `${po}*`, od, a];
          case appStrings.BackToBackCPC: return [dd, `${btbo}*`, ssa, cso, p, bmpsq, nf, nsa, od, a];
          case appStrings.BackToBackEROY: return [dd, `${btbo}*`, ssa, cso, p, bmpsq, nf, nsa, od, a];
          case appStrings.ShortTermInternalRental:
              if(serviceContract === no) { return [dd, bmsas, bmsa, ssa, cso, lad, la, `${lra}*`, p, bmpsq, nf, nsa, od, a]; }
              if(serviceContract === yes) { return [dd, `${bmsas}*`, bmsa, ssa, cso, lad, la, `${lra}*`, p, bmpsq, nf, nsa, od, a]; }
              if(serviceContract === existing) { return [dd, bmsas, bmsa, ssa, cso, lad, la, `${lra}*`, p, bmpsq, nf, nsa, od, a]; }
              break;
          case appStrings.Subscription:
              if(serviceContract === no) { return [dd, `${bmsas}*`, bmsa, ssa, cso, lad, la, ldd, p, bmpsq, nf, nsa, od, a]; }
              if(serviceContract === yes) { return [dd, `${bmsas}*`, bmsa, ssa, cso, lad, la, ldd, p, bmpsq, nf, nsa, od, a]; }
              if(serviceContract === existing) { return [dd, bmsas, bmsa, ssa, cso, lad, la, ldd, p, bmpsq, nf, nsa, od, a]; }
              break;
          case appStrings.Copycare: return [dd, btbo, ssa, `${ca}*`, cso, p, bmpsq, nf, nsa, od, a];
          case appStrings.FOC: break;
          case appStrings.POC: return [dd, bmsas, bmsa, ssa, cso, ldd, p, bmpsq, nf, nsa, od, a];
          case appStrings.ScottishProcurement: return [`${po}*`, od];
          case appStrings.ScottishProcurementLease: return [`${bmsas}*`, od];
          default:  return [dd, btbo, bmsas, bmsa, ssa, ca, cso, lad, la, ldd, p, bmpsq, nf, nsa, od, a, ess];
      }
  }
  
  return [dd, btbo, bmsas, bmsa, ssa, ca, cso, lad, la, ldd, p, bmpsq, nf, nsa, od, a, ess];
}

export function getProcurementDropdownData(id, ourDeal) {
  
  if (ourDeal.procurementData) {

    const procurementData = ourDeal.procurementData.find(data => data.Id === id);

    if(!procurementData || !procurementData?.DropDownValues) return [];

    const dropdownArray = procurementData.DropDownValues.split(';').map(data => ({
      label: data,
      value: data
    }));

    dropdownArray.unshift({
      label: "Please Select",
      value: "-1"
    });

    return dropdownArray;
  } else {
    return [];
  }
}

export function getFieldWorkflowObj() {
  return Object
    .keys(fieldTypes)
    .reduce((acc, obj) => ({ ...acc, [obj]: false }), {});
}

export function getProductConfigurationModal() {
  return {
    open: false,
    type: null,
    product: null,
    dealProductId: null,
    portalProductId: null
  }
}