import { useEffect, useState, useRef } from 'react';
import UILIB from 'components';
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux'
import { useParams } from 'react-router';
import axios from 'classes/axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { checkFormErrors } from '../../../../../classes/formErrors/formErrors';
import { getAddProductFormErrors } from '../../../../../classes/formErrors/repository';
import { setDrawer } from "store/actions";
import { useDispatch } from 'react-redux';
import DrawerProductCats from './drawerProductCats';
import AddAccessoryDrawer from './addAccessoryDrawer';
import AddConflictingDrawer from './addConflictingDrawer';
import { getHostConfig } from '../../../../../classes/helpers';
import { permissionsRouter, permissionsGroupExporter, permissionAccount, groupsObject } from 'classes/permissions';


const conflictingTableHeader = [
    { label: "Part #", value: "partNumber", type: "string", vAlign: "top" },
    { label: "Manufacturer Part #", value: "manufacturerPartNumber", type: "string", vAlign: "top" },
    { label: "Product Name", value: "name", type: "string", vAlign: "top" },
    { label: "Manufacturer", value: "manufacturer", type: "string", vAlign: "top" },
    { label: "Default Supplier", value: "defaultSupplier", type: "string", vAlign: "top" },
    { label: " Price List Part Type", value: "partType", type: "string", vAlign: "top" },
    { label: "", value: "options", width: "20px", vAlign: "top" }];

const compatibleTableHeader = [
    { label: "Part #", value: "partNumber", type: "string", vAlign: "top" },
    { label: "Manufacturer Part #", value: "manufacturerPartNumber", type: "string", vAlign: "top" },
    { label: "Product Name", value: "name", type: "string", vAlign: "top" },
    { label: "Manufacturer", value: "manufacturer", type: "string", vAlign: "top" },
    { label: "Default Supplier", value: "defaultSupplier", type: "string", vAlign: "top" },
    { label: "Price List Part Type", value: "partType", type: "string", vAlign: "top" },
    { label: "Required", value: "required", width: "20px", align: "center", vAlign: "top" },
    { label: "", value: "options", width: "20px", vAlign: "top" }];

const serviceMeterData = [
  { label: "Please Select", value: -1 },
  { label: "Colour", value: 1 },
  { label: "Mono", value: 2 },
  { label: "None", value: 3 }];

let compatibleTimer;

export default function AddProduct() {    
    const dispatch = useDispatch();
    const params = useParams();
    const history = useHistory();
    const location = useLocation();
    const account = useSelector((state) => state.account);
    const permissions = useSelector((state) => state.permissions);
    const permissionHubs = useRef([{ name: 'partsManagementHub', hubName: 'Parts Management Hub', routeName: '/saleshub/partsManagement/addProduct' }]);
    const localPermissions = useRef({});
    const [permissionsLoaded, setPermissionsLoaded] = useState(false);
    const [productData, setProductData] = useState({});
    const [compatibleData, setCompatibleData] = useState([]);
    const [compatibleTableData, setCompatibleTableData] = useState([]);
    const [configData, setConfigData] = useState([]);
    const [conflictingData, setConflictingData] = useState([]);
    const [conflictingTableData, setConflictingTableData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [uploadingImage, setUploadingImage] = useState(false);
    const [rndImageNo, setRndImageNo] = useState(Math.round(Math.random() * 9999999));
    const [uploadingError, setUploadingError] = useState("");
    const [manufacturers, setManufacturers] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [ranges, setRanges] = useState([]);
    const [rangesRaw, setRangesRaw] = useState([]);
    const [rangeSelect, setRangeSelect] = useState([]);
    const [categories, setCategories] = useState([]);
    const [types, setTypes] = useState([]);
    const [votypes, setVoTypes] = useState([]);
    const [deleting, setDeleting] = useState(false);
    const [formErrors, setFormErrors] = useState(getAddProductFormErrors(productData));
    const [saveMessage, setSaveMessage] = useState({ save: { option: 1, messageText: '', disabled: false, error: false } });

    const clearErrors = () => {
      const formErrorsNew = {...formErrors};
      Object.values(formErrorsNew).forEach(e => { e.error = false; });
      setFormErrors(formErrorsNew);
    }

    const saveProduct = async () => {
      try {
        clearErrors();
        const formResult = await checkFormErrors(structuredClone(formErrors), setFormErrors, null, null, productData, null);
        if (formResult.errorCount === 0) {
          await axios.put('/sales/parts/' + productData.id, productData);
          history.push('/saleshub/partsManagement');
        }
        else {
          if(formResult.errorCount > 0) { toast.error(formResult.errorCount + "/" + formResult.totalCount + " Form fields require attention before saving"); }
        }
      }
      catch (error) {
        const message = (error) ? error : 'There was an error saving the part, please contact support.';
        setSaveMessage({ ...saveMessage, save: { ...saveMessage.save, messageText: message, error: true } });
      }
    }

    const updateValue = (event) => {
        const productDataNew = JSON.parse(JSON.stringify(productData));
        switch(event.target.name) {
          case "SalesPartRanges":
            const range = rangesRaw.find(r => Number(r.id) === Number(event.target.value));
            if (range) { productDataNew.SalesPartRanges.push(range) }
            break;
          case "basePrice" || "partnerPrice":
            productDataNew[event.target.name] = event.target.value;
            break;
          default:
            productDataNew[event.target.name] = event.target.value;
            if (event.target.name === "SalesPartManufacturerId") {
                productDataNew.SalesPartRanges = [];
            }
            break;
        }
        setProductData(productDataNew)
    }

    const setRange = (currentRange) => {
        const productDataNew = JSON.parse(JSON.stringify(productData));
        productDataNew.SalesPartRanges = [];

        currentRange.forEach(element => {
            const range = rangesRaw.find(r => Number(r.id) === Number(element));
            if (range) { productDataNew.SalesPartRanges.push(range) }
        })

        setProductData(productDataNew);
    }

    const removeRange = (rangeId) => {
        const productDataNew = JSON.parse(JSON.stringify(productData));
        productDataNew.SalesPartRanges = productDataNew.SalesPartRanges.filter(r => r.id !== rangeId);

        let rangeSelectNew = JSON.parse(JSON.stringify(rangeSelect));
        rangeSelectNew = rangeSelectNew.filter(r => r !== rangeId);

        setProductData(productDataNew);
        setRangeSelect(rangeSelectNew);
    }

    const clearRange = () => {
        productData.SalesPartRanges = [];
        setProductData(JSON.parse(JSON.stringify(productData)));
        setRangeSelect([]);
    }

    const deleteProduct = () => {
        axios.delete('/sales/parts/' + productData.id).then(() => {
            history.push('/saleshub/partsManagement/');
        })
    }

    const closeSelectionDrawer = async (value, type) => {
        const productDataNew = JSON.parse(JSON.stringify(productData));
        switch (type) {
            case "manufacturer": productDataNew.SalesPartManufacturerId = value; break;
            case "supplier": productDataNew.SalesPartSupplierId = value; break;
            case "range":
                let range = rangesRaw.find(r => Number(r.id) === Number(value));
                if (range) productDataNew.SalesPartRanges.push(range);
                break;
            case "category": productDataNew.SalesPartCategoryId = value; break;
            case "type": productDataNew.SalesPartTypeId = value; break;
            case "votype": productDataNew.SalesPartVoTypeId = value; break;
            default: break; //nothing
        }

        setProductData(productDataNew);
        await getCatsAndStuff();
        dispatch(setDrawer({ open: false, content: null }));
    }

    const openSelectionDrawer = (type) => {
        const drawerContent = <DrawerProductCats
            minLength={2}
            drawerType={type}
            finished={closeSelectionDrawer}
            manufacturers={manufacturers}
            suppliers={suppliers}
            ranges={ranges}
            categories={categories}
            types={types} />
        dispatch(setDrawer({ open: true, content: drawerContent }));
    }

    const openAddAccessoryDrawer = () => {
        dispatch(setDrawer({
            open: true, content: <AddAccessoryDrawer
                finished={closeAddAccessoryDrawer}
                manufacturers={manufacturers}
                suppliers={suppliers}
                ranges={ranges}
                categories={categories}
                types={types}
                partId={productData.id}
            />,
            width: "600px"
        }));
    }

    const closeAddAccessoryDrawer = async () => {
        dispatch(setDrawer({ open: false, content: null }));
        getCompatibles();
        getConflicting();
    }

    const openAddConflictingDrawer = async () => {
        dispatch(setDrawer({
            open: true, content: <AddConflictingDrawer
                finished={closeAddAccessoryDrawer}
                manufacturers={manufacturers}
                suppliers={suppliers}
                ranges={ranges}
                categories={categories}
                types={types}
                partId={productData.id}
            />,
            width: "600px"
        }));
    }

    const getCatsAndStuff = async () => {
        let [manufacturers, categories, ranges, suppliers, types, votypes] = await Promise.all([
            axios.get(`/sales/parts/manufacturer`),
            axios.get(`/sales/parts/category`),
            axios.get(`/sales/parts/range`),
            axios.get(`/sales/parts/supplier`),
            axios.get(`/sales/parts/type`),
            axios.get(`/sales/parts/votype`)
          ]);

        manufacturers = manufacturers.data.map(row => { return { label: row.name, value: row.id } });
        types = types.data.map(row => { return { label: row.name, value: row.id } });
        categories = categories.data.map(row => { return { label: row.name, value: row.id } });
        suppliers = suppliers.data.map(row => { return { label: row.name, value: row.id } });
        votypes = votypes.data.map(row => { return { label: row.name, value: row.id } });

        manufacturers.unshift({ label: "Please Select", value: -1 });
        types.unshift({ label: "Please Select", value: -1 });
        categories.unshift({ label: "Please Select", value: -1 });
        suppliers.unshift({ label: "Please Select", value: -1 });
        votypes.unshift({ label: "Please Select", value: -1 });

        setManufacturers(manufacturers);
        setCategories(categories);
        setRangesRaw(ranges.data);
        setRanges(ranges.data.map(row => ({ label: row.name, value: row.id, SalesPartManufacturerId: row.SalesPartManufacturerId })));
        setSuppliers(suppliers);
        setTypes(types);
        setVoTypes(votypes);
    }

    const getCompatibles = async () => {
        const response = await axios.get('/sales/parts/compatible/' + params.productId);
        setCompatibleData(response.data);
    }

    const getConflicting = async () => {
        const response = await axios.get('/sales/parts/conflicting/' + params.productId);
        setConflictingData(response.data);
    }

    const generateCompatibleTable = () => {
        const tableData = compatibleData.map(row => {
            const manufacturer = manufacturers.find(m => Number(m.value) === Number(row.SalesPartManufacturerId));
            let manufacturerName = "";
            if (manufacturer && manufacturer.label) { manufacturerName = manufacturer.label; }

            const supplier = suppliers.find(m => Number(m.value) === Number(row.SalesPartSupplierId));
            let supplierName = "";
            if (supplier && supplier.label) { supplierName = supplier.label; }

            const type = types.find(m => Number(m.value) === Number(row.SalesPartTypeId));
            let typeName = "";
            if (type && type.label) { typeName = type.label; }
            return {
                partNumber: { 
                    value: <UILIB.Link onClick={() => { window.open(`/saleshub/partsManagement/addProduct/` + row.id, "_blank") }} style={{ textDecoration: "underline" }}>{row.partNumber}</UILIB.Link>,
                    raw: row.partNumber },
                manufacturerPartNumber: { value: row.manufacturerPartNumber, raw: row.manufacturerPartNumber },
                name: { value: row.name || <div></div>, raw: row.name },
                manufacturer: { value: manufacturerName, raw: manufacturerName },
                defaultSupplier: { value: supplierName, raw: supplierName },
                partType: { value: typeName, raw: typeName },
                required: { value: <UILIB.Checkbox checked={!!row.SalesPartCompatible.required} name="required" onChange={(event) => { updateCompatible(event, row.id) }} /> },
                options: { value: <div><span className="icon icon-trash2 primary" style={{ cursor: "pointer" }} onClick={() => { deleteCompatibleBind(row) }}></span></div> }
            }
        });

        setCompatibleTableData(tableData);
    }

    const generateConflictingTable = () => {
        const tableData = conflictingData.map(row => {
            let manufacturer = manufacturers.find(m => Number(m.value) === Number(row.SalesPartManufacturerId))
            let manufacturerName = "";
            if (manufacturer && manufacturer.label) { manufacturerName = manufacturer.label; }

            let supplier = suppliers.find(m => Number(m.value) === Number(row.SalesPartSupplierId))
            let supplierName = "";
            if (supplier && supplier.label) { supplierName = supplier.label; }

            let type = types.find(m => Number(m.value) === Number(row.SalesPartTypeId))
            let typeName = "";
            if (type && type.label) { typeName = type.label; }
            return {
                partNumber: { 
                    value: <UILIB.Link onClick={() => { window.open(`/saleshub/partsManagement/addProduct/` + row.id, "_blank") }} style={{ textDecoration: "underline" }}>{row.partNumber}</UILIB.Link>,
                    raw: row.partNumber },
                manufacturerPartNumber: { value: row.manufacturerPartNumber, raw: row.manufacturerPartNumber },
                name: { value: row.name || <div></div>, raw: row.name },
                manufacturer: { value: manufacturerName, raw: manufacturerName },
                defaultSupplier: { value: supplierName, raw: supplierName },
                partType: { value: typeName, raw: typeName },
                options: { value: <div> <span className="icon icon-trash2 primary" style={{ cursor: "pointer" }} onClick={() => { deleteConflictingBind(row) }}></span></div> }
            }
        });

        setConflictingTableData(tableData);
    }

    const updateCompatible = (event, compatibleId) => {
        let value = event.target.value;
        if (event.target.type === "checkbox") {
            value = event.target.checked;
        }

        let compatible = compatibleData.find(c => c.id === compatibleId);
        compatible.SalesPartCompatible[event.target.name] = value;
        setCompatibleData(compatibleData);
        generateCompatibleTable();

        if (compatibleTimer) { clearTimeout(compatibleTimer); }
        compatibleTimer = setTimeout(() => {
            axios.put('/sales/parts/compatible/' + params.productId + '/' + compatibleId, compatible.SalesPartCompatible)
        }, 1000);
    }

    const deleteCompatibleBind = async (compatible) => {
        await axios.delete('/sales/parts/compatible/' + params.productId + '/' + compatible.id);
        await getCompatibles();
    }

    const deleteConflictingBind = async (compatible) => {
        await axios.delete('/sales/parts/conflicting/' + params.productId + '/' + compatible.id);
        await getConflicting();
    }

    const uploadImageClick = async () => {
        document.getElementById("avatarUploadInput").click();
    }

    const uploadImageGo = async (event) => {
        setUploadingImage(true);
        const thisFile = event.currentTarget.files[0];
        const data = new FormData();
        data.append('file', thisFile);
        try {
            await axios.post('/sales/parts/' + productData.id + '/image', data)
            setUploadingError("");
            setUploadingImage(false);
            setRndImageNo(Math.round(Math.random() * 9999999));
            getProduct();
        } catch (err) {
            let error = "Error uploading file";
            if (err && err.data && err.data.err) { error = err.data.err }
            setUploadingError(error);
            setUploadingImage(false);
        }
    }

    const getProduct = async () => {
        const data = await axios.get(`/sales/parts/${params.productId}`);
        setFormErrors(getAddProductFormErrors(data.data));
        setProductData(data.data);
    }

    const getHostConfigObj = async () => {
      const config = await getHostConfig(1);
      setConfigData(config);
    }
    
    useEffect(() => {
        (async () => {
            if (params.productId) {
                await Promise.all([getProduct(), getCatsAndStuff(), getCompatibles(), getConflicting(), getHostConfigObj(1)]);
                setLoading(false);
            } else {
                const result = await axios.post('/sales/parts/createPart', productData.name ? { name: productData.name, postType: 1 } : { name: '', postType: 1 });
                const prodId = result.data.id;
                history.push('/saleshub/partsManagement/addProduct/' + prodId);
            }
        })()
        // eslint-disable-next-line
    }, [params.productId]);

    useEffect(() => {
        generateCompatibleTable();
        // eslint-disable-next-line
    }, [compatibleData])

    useEffect(() => {
        generateConflictingTable();
        // eslint-disable-next-line
    }, [conflictingData])

    useEffect(() => {
      async function fetchData() {
        setPermissionsLoaded(false);
        await permissionsRouter(history, permissions, account, null, '/saleshub/partsManagement/addProduct', 'General Access');
        await permissionsGroupExporter(permissions, account, permissionHubs.current, localPermissions.current);
        await permissionAccount(account, localPermissions.current);
        setPermissionsLoaded(true);
      }
      fetchData();
    }, []);

    useEffect(() => {
        if (Number(productData.SalesPartTypeId) !== 34) {
          formErrors.colourBasePrice.disabled = true;
          formErrors.monoBasePrice.disabled = true;
          formErrors.serviceMeters.disabled = true;
          setFormErrors(structuredClone(formErrors));
        }
        else {
          formErrors.colourBasePrice.disabled = false;
          formErrors.monoBasePrice.disabled = false;
          formErrors.serviceMeters.disabled = false;
          setFormErrors(structuredClone(formErrors));
        }
    }, [productData.SalesPartTypeId])

    useEffect(() => {
        if ((ranges && ranges.filter(f => Number(f.SalesPartManufacturerId) === Number(productData.SalesPartManufacturerId)).length < 1) || productData.SalesPartManufacturerId === null) {
          formErrors.SalesPartRanges.disabled = true;
        }
        else {
          formErrors.SalesPartRanges.disabled = false;
        }

        if (productData.SalesPartRanges && productData.SalesPartRanges.length) {
          formErrors.SalesPartRanges.disabled = false;
          setRangeSelect(productData.SalesPartRanges.map(pr => pr.id));
        }

        setFormErrors(structuredClone(formErrors));
        // eslint-disable-next-line
    }, [productData.SalesPartManufacturerId, productData.SalesPartRanges, ranges])


    if (loading || !permissionsLoaded) return <UILIB.Loading type={3} />

    return <div className="flex-container row end height-100 mar-t10 mar-l5 mar-r5">
        <div className="flex-container row">
            <div className="col-xs-12 col-sm-6 mar-b15">
                <UILIB.Button value="Back" className="grey" onClick={() => { history.push("/saleshub/partsManagement") }} />
            </div>
            <div className="col-xs-12 col-sm-6 text-right">
                <UILIB.Button value="Save" errored={saveMessage.save} onClick={async () => await saveProduct()} />
                <UILIB.PermissionsWrapperNew 
                  departments={groupsObject('generalAccess', localPermissions.current, permissions, location.pathname)} 
                  account={localPermissions.current.account}
                >
                <UILIB.Button 
                  value="Delete"
                  disabled={productData.hasPartConflictingBind || productData.hasPartCompatibleBind}
                  onClick={() => setDeleting(true)}
                  className="red mar-l10" 
                />
                </UILIB.PermissionsWrapperNew>
            </div>
            <div className="col-xs-12 mar-b15">
                <div className="flex-container start">
                    <UILIB.Paper style={{ width: "100%" }}>
                        <div className="row">
                            <div className="col-xs-12 col-sm-6 col-md-4">
                                <label style={{ fontSize: 14 }}>Product Name</label>
                                <UILIB.TextInput
                                    className="textInput input"
                                    name="name"
                                    outerstyle={{ width: "100%" }}
                                    outerclassname="mar-b10"
                                    value={productData.name}
                                    onChange={(event) => { updateValue(event); }}
                                    errored={formErrors.name}
                                />
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-4">
                                <label style={{ fontSize: 14 }}>Part #</label>
                                <UILIB.TextInput
                                    className="textInput input"
                                    name="partNumber"
                                    outerstyle={{ width: "100%" }}
                                    outerclassname="mar-b10"
                                    value={productData.partNumber}
                                    onChange={(event) => { updateValue(event); }}
                                    errored={formErrors.partNumber}
                                />
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-4">
                                <label style={{ fontSize: 14 }}>Manufacturer Part #</label>
                                <UILIB.TextInput
                                    type="text"
                                    className="textInput input"
                                    name="manufacturerPartNumber"
                                    outerstyle={{ width: "100%" }}
                                    outerclassname="mar-b10"
                                    value={productData.manufacturerPartNumber}
                                    onChange={(event) => { updateValue(event); }}
                                    errored={formErrors.manufacturerPartNumber}
                                />
                            </div>
                            <div className="col-xs-12">
                                <label style={{ fontSize: 14 }}>Product Description</label>
                                <UILIB.TextArea
                                    className="textInput input"
                                    name="description"
                                    style={{ width: "100%", resize: 'none', height: '100px' }}
                                    outerstyle={{ width: "100%" }}
                                    outerclassname="mar-b10"
                                    value={productData.description}
                                    onChange={(event) => {
                                        updateValue(event);
                                    }}
                                />
                            </div>
                        </div>
                    </UILIB.Paper>
                    <div style={{ width: "250px" }} className="mar-l15">
                        <div style={{
                            width: "250px", height: "230px", border: "1px solid grey", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center",
                            backgroundImage: `url(${configData.cdnFtp.host + "/files/images/sales/sopparts/" + productData.id + "/" + productData.imageName + "?rnd=" + rndImageNo})`,
                            backgroundSize: "cover"
                        }} >
                            <UILIB.Button value="Change" className="primary" onClick={uploadImageClick} disabled={uploadingImage} />
                            <input type="file" id="avatarUploadInput" style={{ display: 'none' }} onChange={uploadImageGo} />
                            {uploadingError && <div style={{ color: "red", fontSize: "11px", textAlign: "center" }}>{uploadingError}</div>}
                        </div>

                    </div>

                </div>
            </div>
            <div className="col-xs-12 col-sm-6 mar-b15" >
                <UILIB.Paper style={{ height: "100%" }}>
                    <div className="row">
                        <div className="col-xs-12 col-sm-6 col-md-3">
                            <label style={{ paddingLeft: 10, fontSize: 14 }}>List Price (RRP)</label>
                            <div className="flex-container">
                                <div style={{ margin: 'auto', align: 'center', fontSize: 12, paddingRight: 4 }}>£</div>
                                <UILIB.TextInput
                                    className="textInput input"
                                    type="number"
                                    name="listPrice"
                                    placeholder="0.00"
                                    outerstyle={{ width: "100%" }}
                                    outerclassname="mar-b10"
                                    value={productData.listPrice}
                                    onChange={(event) => { updateValue(event); }}
                                    errored={formErrors.listPrice}
                                />
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-3">
                            <label style={{ paddingLeft: 10, fontSize: 14 }}>Partner Price</label>
                            <div className="flex-container">
                                <div style={{ margin: 'auto', align: 'center', fontSize: 12, paddingRight: 4 }}>£</div>
                                <UILIB.TextInput
                                    className="textInput input"
                                    type="number"
                                    name="partnerPrice"
                                    placeholder="0.00"
                                    outerstyle={{ width: "100%" }}
                                    outerclassname="mar-b10"
                                    value={productData.partnerPrice}
                                    onChange={(event) => { updateValue(event); }}
                                    errored={formErrors.partnerPrice}
                                />
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6 col-md-3">
                            <label style={{ paddingLeft: 10, fontSize: 14 }}>Sales Base Price</label>
                            <div className="flex-container">
                                <div style={{ margin: 'auto', align: 'center', fontSize: 12, paddingRight: 4 }}>£</div>
                                <UILIB.TextInput
                                    className="textInput input"
                                    type="number"
                                    name="basePrice"
                                    placeholder="0.00"
                                    outerstyle={{ width: "100%" }}
                                    outerclassname="mar-b10"
                                    value={productData.basePrice}
                                    onChange={(event) => { updateValue(event); }}
                                    errored={formErrors.basePrice}
                                />
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-3">
                            <label style={{ paddingLeft: 10, fontSize: 14 }}>Special Price (CPC, LGEM)</label>
                            <div className="flex-container">
                                <div style={{ margin: 'auto', align: 'center', fontSize: 12, paddingRight: 4 }}>£</div>
                                <UILIB.TextInput
                                    className="textInput input"
                                    type="number"
                                    name="specialPrice"
                                    placeholder="0.00"
                                    outerstyle={{ width: "100%" }}
                                    outerclassname="mar-b10"
                                    value={productData.specialPrice}
                                    onChange={(event) => { updateValue(event); }}
                                    errored={formErrors.specialPrice}
                                />
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-4 col-md-4">
                            <label style={{ fontSize: 14 }}>Service Meters</label>
                            <UILIB.Select
                                type="number"
                                name="serviceMeters"
                                data={serviceMeterData}
                                outerstyle={{ width: "100%" }}
                                outerclassname="mar-b10"
                                value={productData.serviceMeters}
                                onChange={event => { updateValue(event); }}
                                errored={formErrors.serviceMeters}
                                disabled={formErrors.serviceMeters.disabled}
                            />
                        </div>
                        <div className="col-xs-12 col-sm-4 col-md-4">
                            <label style={{ fontSize: 14 }}>Colour CPC Base (p)</label>
                            <UILIB.TextInput
                                className="textInput input"
                                type="number"
                                name="colourBasePrice"
                                placeholder={
                                  !formErrors.colourBasePrice.disabled
                                  ? "0.00" : "Not Required"
                                }
                                outerstyle={{ width: "100%" }}
                                outerclassname="mar-b10"
                                value={
                                  !formErrors.colourBasePrice.disabled
                                  ? productData.colourBasePrice : "Not Required"
                                }
                                onChange={event => { updateValue(event); }}
                                errored={formErrors.colourBasePrice}
                                disabled={formErrors.colourBasePrice.disabled}
                            />
                        </div>
                        <div className="col-xs-12 col-sm-4 col-md-4">
                            <label style={{ fontSize: 14 }}>Mono CPC Base (p)</label>
                            <UILIB.TextInput
                                className="textInput input"
                                type="number"
                                name="monoBasePrice"
                                placeholder={
                                  !formErrors.monoBasePrice.disabled
                                  ? "0.00" : "Not Required"
                                }
                                outerstyle={{ width: "100%" }}
                                outerclassname="mar-b10"
                                value={
                                  !formErrors.monoBasePrice.disabled
                                  ? productData.monoBasePrice : "Not Required"
                                }
                                onChange={event => {updateValue(event);}}
                                errored={formErrors.monoBasePrice}
                                disabled={formErrors.monoBasePrice.disabled}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-6">
                            <label style={{ fontSize: 14 }}>Available On</label>
                            <UILIB.TextArea
                                name="availableOn"
                                style={{ width: "100%", height: "220px", resize: 'none'  }}
                                outerclassname="mar-b0"
                                value="Coming Phase 2"
                                disabled={true}
                                onChange={(event) => { updateValue(event); }}
                            />
                        </div>
                        <div className="col-xs-12 col-sm-6 ">
                            <label style={{ fontSize: 14 }}>Product Notes</label>
                            <UILIB.TextArea
                                name="productNotes"
                                style={{ width: "100%", height: "220px", resize: 'none'  }}
                                outerclassname="mar-b0"
                                value={productData.productNotes}
                                onChange={(event) => { updateValue(event); }}
                            />
                        </div>
                    </div>
                </UILIB.Paper>
            </div>
            <div className="col-xs-12 col-sm-6 mar-b15">
                <UILIB.Paper style={{ height: "100%" }}>
                    <div className="row">
                        <div className="col-xs-12 col-sm-6 ">
                            <label style={{ fontSize: 14 }}>Manufacturer</label>
                            <div className="flex-container">
                                <UILIB.Select
                                    data={manufacturers}
                                    name="SalesPartManufacturerId"
                                    outerstyle={{ width: "100%", position: "relative" }}
                                    outerclassname="mar-b10"
                                    value={productData.SalesPartManufacturerId}
                                    onChange={event => { clearRange(); updateValue(event); }}
                                    errored={formErrors.SalesPartManufacturerId}
                                />
                                <div className="icon icon-plus-circle mar-l10 primary" onClick={() => { openSelectionDrawer("manufacturer") }} />
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 ">
                            <label style={{ fontSize: 14 }}>Range(s)</label>
                            <div className="flex-container">
                                <UILIB.MultiSelect
                                    data={ranges.filter(f => Number(f.SalesPartManufacturerId) === Number(productData.SalesPartManufacturerId))}
                                    selected={rangeSelect}
                                    name="SalesPartRanges"
                                    placeholder={(formErrors.SalesPartRanges.disabled === false) ? "Please select" : "No Ranges"}
                                    outerstyle={{ width: "100%", position: "relative" }}
                                    outerclassname="mar-b10"
                                    onChange={event => { setRange(event.target.value); }}
                                    errored={formErrors.SalesPartRanges}
                                    disabled={formErrors.SalesPartRanges.disabled}
                                />
                                <div className="icon icon-plus-circle mar-l10 primary" onClick={() => { openSelectionDrawer("range") }} />
                            </div>
                            {productData.SalesPartRanges.map((range, index) => {
                                return <UILIB.Chip key={"range_" + index} className="table mar-r5 mar-b15 font-10 border-radius-5 padding-5">
                                    {range.name} <span className="icon icon-cross" onClick={() => { removeRange(range.id) }}></span>
                                </UILIB.Chip>
                            })}
                        </div>
                        <div className="col-xs-12 col-sm-6 ">
                            <label style={{ fontSize: 14 }}>Price List Part Type</label>
                            <div className="flex-container">
                                <UILIB.Select
                                    data={types}
                                    name="SalesPartTypeId"
                                    outerstyle={{ width: "100%", position: "relative" }}
                                    outerclassname="mar-b10"
                                    value={productData.SalesPartTypeId}
                                    onChange={event => { updateValue(event); }}
                                    errored={formErrors.SalesPartTypeId}
                                />
                                <div className="icon icon-plus-circle mar-l10 primary" onClick={() => { openSelectionDrawer("type") }} />
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 ">
                            <label style={{ fontSize: 14 }}>VO Category</label>
                            <div className="flex-container">
                                <UILIB.Select
                                    data={categories}
                                    name="SalesPartCategoryId"
                                    outerstyle={{ width: "100%", position: "relative" }}
                                    outerclassname="mar-b10"
                                    value={productData.SalesPartCategoryId}
                                    onChange={(event) => { updateValue(event); }}
                                    errored={formErrors.SalesPartCategoryId}
                                />
                                <div className="icon icon-plus-circle mar-l10 primary" onClick={() => { openSelectionDrawer("category") }} />
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 ">
                            <label style={{ fontSize: 14 }}>VO Type</label>
                            <div className="flex-container">
                                <UILIB.Select
                                    data={votypes}
                                    name="SalesPartVoTypeId"
                                    outerstyle={{ width: "100%", position: "relative" }}
                                    outerclassname="mar-b10"
                                    value={productData.SalesPartVoTypeId}
                                    onChange={(event) => { updateValue(event); }}
                                    errored={formErrors.SalesPartVoTypeId}
                                />
                                <div className="icon icon-plus-circle mar-l10 primary" onClick={() => { openSelectionDrawer("votype") }} />
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 ">
                            <label style={{ fontSize: 14 }}>Default Supplier</label>
                            <div className="flex-container">
                                <UILIB.Select
                                    data={suppliers}
                                    name="SalesPartSupplierId"
                                    outerstyle={{ width: "100%", position: "relative" }}
                                    outerclassname="mar-b10 "
                                    value={productData.SalesPartSupplierId}
                                    onChange={(event) => { updateValue(event); }}
                                    errored={formErrors.SalesPartSupplierId}
                                />
                                <div className="icon icon-plus-circle mar-l10 primary" onClick={() => { openSelectionDrawer("supplier") }} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-6">
                            <label style={{ fontSize: 14 }}>Dependancies</label>
                            <UILIB.TextArea
                                name="dependancies"
                                className="Please select"
                                style={{ width: "100%", height: "150px", resize: 'none'  }}
                                outerclassname="mar-b0"
                                value={productData.dependancies}
                                readonly={true}
                                onChange={(event) => { updateValue(event); }}
                            />
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <label style={{ fontSize: 14 }}>Incompatibles</label>
                            <UILIB.TextArea
                                name="incompatibles"
                                className="Please select"
                                style={{ width: "100%", height: "150px", resize: 'none'  }}
                                outerclassname="mar-b0"
                                value={productData.incompatibles}
                                readonly={true}
                                onChange={(event) => { updateValue(event); }}
                            />
                        </div>
                    </div>

                </UILIB.Paper>
            </div>

            <div className="col-xs-12 mar-b15">
                <UILIB.Paper>
                    <div className="flex-container between mar-b15">
                        <h2 className="text-16 mar-t5 float-left">Available Accessories</h2>
                        <UILIB.Button value="Add Accessory" onClick={openAddAccessoryDrawer} />
                    </div>
                    {!compatibleTableData.length && <div className="text-14">No accessories added yet</div>}
                    {compatibleTableData.length > 0 &&
                        <UILIB.Table className="small" loading={false} header={compatibleTableHeader} data={compatibleTableData} sortable={true} filter={true} height={300} defaultOrderBy='partType' />

                    }
                </UILIB.Paper>
            </div>
            <div className="col-xs-12 mar-b15">
                <UILIB.Paper>
                    <div className="flex-container between mar-b15">
                        <h2 className="text-16 mar-t5 float-left">Conflicting Products</h2>
                        <UILIB.Button value="Add Conflicting Product" onClick={openAddConflictingDrawer} />
                    </div>
                    {!conflictingTableData.length && <div className="text-14">No conflicting products added yet</div>}
                    {conflictingTableData.length > 0 &&
                        <UILIB.Table className="small" loading={false} header={conflictingTableHeader} data={conflictingTableData} sortable={true} filter={true} height={200} defaultOrderBy='partType' />

                    }
                </UILIB.Paper>
            </div>
        </div>


        {Boolean(deleting) && <UILIB.Confirm onConfirm={deleteProduct} onCancel={() => setDeleting(null)} header="Delete Part" text={'Are you sure you want to delete this product?'} />}
    </div>

}