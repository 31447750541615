import { Fragment } from 'react';
import camelCase from 'lodash/camelCase';
import UILIB from 'components';

export default function StatContainer({ data, dataSecondary, subData, title, multiSelect, style, parentClass, childClass, selected, setSelected, canClick, loading, onClick }) {

  const renderState = Boolean(typeof(setSelected) === 'function');
  const selectedRef = Boolean(selected && typeof(selected) === 'object');

  const selectedColour = ((renderState && selectedRef) && selected[camelCase(title)]) ? 'lightGrey' : '';

  const controls = {
    className: `${parentClass} ${canClick ? ' clickable ' : ''}`,
    style: { ...style, backgroundColor: selectedColour, pointerEvents : canClick ? 'pointer' : 'none', cursor: canClick ? 'pointer' : 'auto' }
  }

  const cardHeadings = () => {
    if(dataSecondary) {
      return (
        <div className='cardDataRow'>
          <div className='cardDataColumn'>
            <h4 className={childClass.top}><b>{data.data}</b></h4>
            <p>{data.description}</p>
          </div>
          <div className='cardDataColumn'>
            <h4 className={childClass.bottom}><b>{dataSecondary.data}</b></h4>
            <p>{dataSecondary.description}</p>
          </div>
        </div>
      )
    }
    else {
      return (
        <h2 className={childClass.bottom}>{data}</h2>
      )
    }
  }
  
  const handleClick = (ev) => {
    if(typeof(onClick) === 'function') onClick(camelCase(title), ev);
    if(renderState && selectedRef) {
      if(title && !multiSelect) {
        Object.keys(selected).forEach(k => { if(camelCase(title) !== k) selected[k] = false });
        setSelected({ ...selected, [camelCase(title)]: !selected[camelCase(title)] })
      }
      if(title && multiSelect) {
        setSelected({ ...selected, [camelCase(title)]: !selected[camelCase(title)] });
      }
    }
  }

  return <Fragment>
          <div className={controls.className} style={controls.style} onClick={(ev) => handleClick(ev)}>
            <p className={childClass.top}>{title}</p>
            {loading && <UILIB.Loading type={3} />}
            {!loading && cardHeadings()}
            {!loading && subData && <p className={childClass.bottom}>{subData}</p>}
          </div>
        </Fragment>
}
