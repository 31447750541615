import { memo, useRef, Fragment } from "react";
import { useDrop, useDrag } from "react-dnd";
import { constants } from "./Constants";
import useFindNestedItem from '../hooks/useFindNestedItem';
import useParentDropZone from '../hooks/useParentDropZone';
import useCheckCompatiables from '../hooks/useCheckCompatiables';
import useFindItem from '../hooks/useFindItem';
import Modules from '../Modules';

const Row = memo(({ 
  handleName,
  dndEnabled,
  data, 
  layout, 
  onDrop, 
  handleCalculateRowType,
  handleCalculateCellStyle,
  handleHover,
  handleDrop
}) => 
{

  handleCalculateRowType(data);

  const { handleCheckCompatiables } = useCheckCompatiables();
  const { handleParentDropZone } = useParentDropZone(layout);
  const { handleFindNestedItem } = useFindNestedItem(layout);
  const { handleFindItem } = useFindItem(layout);

  const hoverItem = useRef(undefined);
  const node = useRef(undefined);
  const dropNode = useRef(undefined);

  const [probe, drop] = useDrop({
    accept: [
      constants.ROW, 
      constants.COLUMN, 
      constants.UNGROUPED
    ],
    async hover(item, monitor) {

      hoverItem.current = item;

      const foundSource = ([constants.UNGROUPED, constants.ROW].includes(item.type)) 
        ? handleFindItem(item.id) 
        : handleFindNestedItem(item.id);
      const foundTarget = handleFindItem(data.id);

      if(!foundSource.item) return;
      if(foundSource.index === foundTarget.index) return;

      const hoverBoundingRect = dropNode.current?.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top

      // Dragging downwards
      if (foundSource.index < foundTarget.index && hoverClientY < hoverMiddleY) return;

      // Dragging upwards
      if (foundSource.index > foundTarget.index && hoverClientY > hoverMiddleY) return;

      //1a - handleDrop entry logic to move UNGROUPED only
      if([constants.UNGROUPED].includes(foundSource.item.type)) {
        handleHover(foundSource, foundTarget);
      } 

      //1b - handleDrop entry logic to move everything else, unless a COLUMN moving out from a ROW
      if(foundSource.item.type !== constants.ROW && foundTarget.item.type === constants.ROW) return;

      handleDrop(foundSource, foundTarget);
      
    },
    drop: async (item) => {
      
      if((data.bind !== item.bind) || data.bind === constants.TYPE_NOBIND) return;

      const foundSource = ([constants.UNGROUPED, constants.ROW].includes(item.type)) 
        ? handleFindItem(item.id) 
        : handleFindNestedItem(item.id);
      const foundTarget = handleFindItem(data.id);

      if(!foundSource.item) return;

      handleDrop(foundSource, foundTarget);

      await onDrop(layout);

    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      item: monitor.getItem()
    }),
  });

  const [isDragging, drag] = useDrag({
    type: constants.ROW,
    item: {
      type: data.type,
      bind: data.bind,
      id: data.id
    },
    async end() {
      data.children.forEach(x => x.parentDragging = false);
      await onDrop(layout);
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      opacity: monitor.isDragging() ? 0 : 1,
      item: monitor.getItem()
    }),
  });

  drag(drop(node));
  drop(dropNode);

  if(isDragging.isDragging) {
    data.children.forEach(x => x.parentDragging = true);
  }

  return <Fragment>
    <tr 
      key={`${data.id}_row`}
      ref={dropNode}
      style={{ opacity: isDragging.opacity }}
    >
      <Modules.DataRow 
        ref={node}
        dndEnabled={dndEnabled}
        handleName={handleName}
        row={data.table} 
        header={data.header} 
        data={data}
        handleCalculateCellStyle={handleCalculateCellStyle}
        isOver={probe.canDrop 
          && (handleParentDropZone(probe).isOver && handleCheckCompatiables(data, hoverItem.current))}
      />
    </tr>
  </Fragment>
})
export default Row
