import { useState, useRef, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { setDrawer } from 'store/actions';
import RemoveDevices from './removeEquipment';
import ProductsTable from './productsTable';
import UILIB from 'components';
import Drawer from '../Drawers';
import Modules from '../Modules';


export default function ProductsPage(props) {

  const dispatch = useDispatch();
  const productTickedEvent = useRef([])
  const [updateNo, setUpdateNo] = useState(0);
  const [productTicked, setProductTicked] = useState([]);

  const bannerMessage = 'When selecting multiple quantities of a product, the system will account for this in the total figures.';
  const hasQuantity = (props?.ourDeal?.product) ? props.ourDeal.product.some(x => Number(x.qty) > 1) : false;

  const closeDeleteMulti = async () => { 
    dispatch(setDrawer({ open: false, content: null })); 
  }

  const closeCloneDrawer = async () => { 
    dispatch(setDrawer({ open: false, content: null })); 
  }

  const actionCloseDrawer = async () => { 
    dispatch(setDrawer({ open: false, content: null })); 
  }

  const openDeleteMulti = () => { 
    console.log("here")
    dispatch(setDrawer({ 
      open: true, 
      content: 
        <Drawer.DeleteProductDrawer 
          ourDeal={props.ourDeal} 
          setOurDeal={props.setOurDeal}
          refreshData={props.refreshData}
          productTicked={productTicked}
          setProductTicked={setProductTicked}
          isActive={props.isActive}
          onCancel={closeDeleteMulti}
          onFinish={() => closeDeleteMulti()} />,
      width: "18w" }));
  }

  const addBlankProduct = () => {
    dispatch(setDrawer({ 
      open: true, 
      content: 
        <Drawer.AddNewProductDrawer 
          dealID={props.ourDeal.dealID} 
          ourDeal={props.ourDeal} 
          setOurDeal={props.setOurDeal} 
          isActive={props.isActive}
          onCancel={() => dispatch(setDrawer({ open: false, content: null }))}
          onFinish={() => actionCloseDrawer()} />,
      width: "18w" }));
  }

  const addSettlementProducts = () => {
    dispatch(setDrawer({ 
      open: true, 
      content: 
        <Modules.AddSettlementLine 
          dealID={props.ourDeal.dealID} 
          ourDeal={props.ourDeal} 
          setOurDeal={props.setOurDeal} 
          isActive={props.isActive}
          onCancel={() => dispatch(setDrawer({ open: false, content: null }))}
          onFinish={() => actionCloseDrawer()} />,
      width: "18w" }));
  }

  const addRefinancedProducts = () => {
    dispatch(setDrawer({ 
      open: true, 
      content: 
        <Modules.AddRefinanced 
          ourDeal={props.ourDeal}
          setOurDeal={props.setOurDeal} 
          isActive={props.isActive}
          onFinish={() => actionCloseDrawer()} />,
      width: "1200px" }));
  }

  const addPSProducts = () => {
    dispatch(setDrawer({ 
      open: true, 
      content: 
        <Drawer.ImportPSDrawer
          ourDeal={props.ourDeal}
          setOurDeal={props.setOurDeal}
          isActive={props.isActive}
          onClose={() => actionCloseDrawer()} />,
      width: "1000px" }));
  }

  
  const openCloneDrawer = (product) => { 
    dispatch(setDrawer({ 
      open: true, 
      content: 
        <Drawer.CloneProductDrawer 
          ourDeal={props.ourDeal} 
          setOurDeal={props.setOurDeal} 
          product={product}
          isActive={props.isActive}
          onCancel={closeCloneDrawer}
          onFinish={() => closeCloneDrawer()} />,
      width: "18w" }));
  }

  const closeProductConfigurator = async (opts) => {
    switch(opts) {
      case 0:
        setUpdateNo(i => i + 1);
        props.setProductConfigurationModal({ open: false, type: null, product: null, dealProductId: null });
        document.body.style.overflow = 'auto';
        break;
      case 1:
        props.setProductConfigurationModal({ open: false, type: null, product: null, dealProductId: null });
        document.body.style.overflow = 'auto';
        break;
      default:
        break;
    }
    document.body.style.overflow = 'auto';
  }

  const openProductConfigurator = (opts, product, dealProductId, portalProductId) => { 
    props.setProductConfigurationModal({ open: true, type: opts, product: product, dealProductId: dealProductId, portalProductId: portalProductId });
    document.body.style.overflow = 'hidden';
  }


  return <Fragment>
    {props.productConfigurationModal.open && <Modules.ProductConfigurationModal 
      onFinish={() => closeProductConfigurator(0)} 
      onCancel={() => closeProductConfigurator(1)}
      ourDeal={props.ourDeal}
      setOurDeal={props.setOurDeal}
      hostConfig={props.ourDeal.hostConfig}
      product={props.productConfigurationModal.product}
      options={props.productConfigurationModal.type}
      dealProductId={props.productConfigurationModal.dealProductId}
      portalProductId={props.productConfigurationModal.portalProductId} />}
    <UILIB.Paper className="flex-container column width-100 mar-b15 mar-l8 mar-r8">
      <div className="flex-container column nowrap width-100 mar-5">
        <h2 className="flex-item start width-100 align-center text-14 mar-5">Products</h2>
      </div>
      {hasQuantity && <div className="width-100 mar-2 mar-b5 pad-r5">
        <UILIB.BannerContainer background={'colour background-2 royalBlue'} title={'Reminder'} content={bannerMessage} /> 
      </div>}
      <div className="flex-container row start width-100 mar-b5">
        <div className="flex-item start align-center flex-grow-1">
          <UILIB.Button 
            disabled={!props.isActive || !props?.dataLoaded?.product || !productTicked.length} 
            className="button small red" 
            value="Delete" 
            onClick={() => openDeleteMulti(productTickedEvent.current)}
          />
          <Modules.ExportProducts {...props} />
        </div>
        <div className="flex-container row end flex-grow-1">
          <UILIB.Button disabled={!props.isActive || !props?.dataLoaded?.product} className="small" value="Product Configurator" onClick={() => openProductConfigurator(0, null, null)} />
          <UILIB.Button disabled={!props.isActive || !props?.dataLoaded?.product}  className="small" value="Add PS Lines" onClick={addPSProducts} />
          <UILIB.Button disabled={!props.isActive || !props?.dataLoaded?.product} className="small" value="Add Refinanced" onClick={addRefinancedProducts} />
          <UILIB.Button disabled={!props.isActive || !props?.dataLoaded?.settlement || !props?.dataLoaded?.product}  className="small" value="Add Settlement" onClick={addSettlementProducts} />
          <UILIB.Button disabled={!props.isActive || !props?.dataLoaded?.product} className="small" value="Add Blank Product" onClick={addBlankProduct} />
        </div>
      </div>
      {String(props.ourDeal.compatibles.length) && 
      <Fragment>
        <ProductsTable 
          dealID={props.ourDeal.dealID} 
          ourDeal={props.ourDeal}
          setOurDeal={props.setOurDeal}
          isActive={props.isActive}
          updateNo={updateNo} 
          refreshData={props.refreshData} 
          dealAddress={props.dealAddress}
          productTicked={productTicked}
          setProductTicked={setProductTicked}
          openProductConfigurator={openProductConfigurator}
          dealLoaded={props.dealLoaded}
          productErrors={props.productErrors}
          productValue={props.productValue}
          productTickedEvent={productTickedEvent}
          openCloneDrawer={openCloneDrawer}
          openDeleteMulti={openDeleteMulti}
          hostConfig={props.ourDeal.hostConfig} 
          cpcDisabled={props.cpcDisabled} 
          fieldWorkflowObj={props.fieldWorkflowObj} 
          formErrors={props.formErrors}
          handleCheckErrors={props.handleCheckErrors} />
      </Fragment>}
    </UILIB.Paper>
    <UILIB.Paper className="flex-container column width-100 mar-b15 mar-l8 mar-r8">
      <RemoveDevices 
        dataLoaded={props.dataLoaded} 
        ourDeal={props.ourDeal} 
        setOurDeal={props.setOurDeal} 
        isActive={props.isActive} />
    </UILIB.Paper>         
  </Fragment>
}

