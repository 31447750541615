import { useState, useRef, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import Axios from 'classes/axios';
import UILIB from 'components';
import { formatCurrency, formatDateTime } from 'classes/format';
import { getYesNo, setQuoteFilterVars, getDefaultQuoteFilters, getStatusChip, getNotesColour, actionOpenDrawer, tableContainer } from '../functions';
import DealLinkTableCell from '../../../shared/dealLink';


export default function QuotesTable({ history, account }) {

  const headerData = [
    { label: '', value: 'selected', type: '', filtering: false, minWidth: 20 },
    { label: "Quote #", value: "finalDealNo", type: 'number', filtering: true, minWidth: 40, maxWidth: 40 },
    { label: "Opportunity #", value: "opportunityNumber", type: 'string', filtering: true, minWidth: 60, maxWidth: 60 },   
    { label: "Group #", value: "groupCode", type: 'string', filtering: true, minWidth: 60, maxWidth: 60 },   
    { label: "Customer", value: "companyName", type: 'string', filtering: true, minWidth: 200, maxWidth: 200 },
    { label: "Quote Desc", value: "description", type: 'string', filtering: true, minWidth: 100, maxWidth: 100 },
    { label: "Deal Type", value: "salesType", type: 'string', filtering: true, minWidth: 120, maxWidth: 120 },
    { label: "Purchase Type", value: "leaseorcash", type: "string", filtering: true, minWidth: 90, maxWidth: 90 },
    { label: "GP", value: "gp", type: 'number', filtering: true, minWidth: 60, maxWidth: 60 },
    { label: "Invoice", value: "invoice", type: 'number', filtering: true, minWidth: 80, maxWidth: 80 },
    { label: "Date Created", value: "createdAt", type: 'date', filtering: true, minWidth: 80, maxWidth: 80 },
    { label: "Status", value: "status", type: 'string', filtering: true, minWidth: 100, maxWidth: 100 },
    { label: "Notes", value: "salesNotes", filtering: false, minWidth: 35, maxWidth: 35, filterArray: getYesNo() }];


  const dispatch = useDispatch();
  const entityData = useRef({});
  const requestCheckbox = useRef({});
  const defaultFilters = getDefaultQuoteFilters();
  const defaultDialogue = { count: 0, show: false };
  const defaultBanner = { error: false, message: '' };
  const [bannerError, setBannerError] = useState(defaultBanner);
  const [showDeleteDialogue, setShowDeleteDialoge] = useState(defaultDialogue);
  const [showArchiveDialogue, setShowArchiveDialoge] = useState(defaultDialogue);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [filters, setFilters] = useState(defaultFilters);
  const [loading, setLoading] = useState(false);
  const [localRender, setLocalRender] = useState(false);
  const tablePageDefaults = { paging: { pages: [10,20,50,100,200], pageSelected: 50, limit: 50, offset: 0, orderBy: 'finalDealNo', orderDir: 'DESC' } };
  const exceptions = [400,404];

  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while saving this record, please reload or contact support';
  const actionDisclaimer = 'Some quotes are unable to be deleted or archived as they still have active drafts associated with them, these can be actioned once the draft(s) have progressed to approved status.'
  

  const handleButtonsChanged = (buttonName) => {
    const newTableFilter = { ...filters };
    if (newTableFilter.button === buttonName) {
        newTableFilter.button = null;
    } else {
        newTableFilter.button = buttonName;
    }
    setFilters(newTableFilter);
  };

  const tableDeleteRequest = () => {
    setBannerError(defaultBanner);
    setShowDeleteDialoge({ count: Object.values(requestCheckbox.current).filter(x => x).length, show: true });
  };

  const tableArchiveRequst = () => {
    setBannerError(defaultBanner);
    setShowArchiveDialoge({ count: Object.values(requestCheckbox.current).filter(x => x).length, show: true });
  };

  const actionDelete = async (event) => {
    try {
      setLoading(true);
      const requestIdArray = Array.from(new Set(Object.keys(event).filter(x => event[x]))).map(x => Number(x));
      const filtered = entityData.current.result.filter(x => requestIdArray.includes(x.dealID));
      const result = await Axios.delete('/entities/deals/', { data: { entity: filtered } });
      if(exceptions.includes(result.status)) {
        if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
        if(result.status === 404) setBannerError({ error: true, message: notFoundError });
      } else {
        entityData.current.result = entityData.current.result.filter(x => !filtered.map(f => Number(f.dealID)).includes(Number(x.dealID)));
        requestCheckbox.current = {};
        setShowDeleteDialoge(defaultDialogue);
        setBannerError(defaultBanner);
        setLocalRender(!localRender);
      }
    } catch (err) {
      console.log(err);
      setBannerError({ error: true, message: exceptionError });
    }
    setLoading(false);
  };

  const actionArchive = async (event) => {
    try {
      setLoading(true);
      const requestIdArray = Array.from(new Set(Object.keys(event).filter(x => event[x]))).map(x => Number(x));
      const filtered = entityData.current.result.filter(x => requestIdArray.includes(x.dealID));
      const postData = filtered.map(x => { return { ...x, archived: 1, isActive: 0 }});
      const result = await Axios.put('/entities/deals/', { entity: postData });
      if(exceptions.includes(result.status)) {
        if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
        if(result.status === 404) setBannerError({ error: true, message: notFoundError });
      } else {
        if(!filters.button) {
          entityData.current.result = entityData.current.result.filter(x => !filtered.map(f => Number(f.dealID)).includes(Number(x.dealID))); }
        filtered.forEach(x => x.archived = 1);
        requestCheckbox.current = {};
        setShowArchiveDialoge(defaultDialogue);
        setBannerError(defaultBanner);
        setLocalRender(!localRender);
      }
    } catch (err) {
      console.log(err);
      setBannerError({ error: true, message: exceptionError });
    }
    setLoading(false);
  };

  const updateCheckbox = (dealID) => {
    requestCheckbox.current = { ...requestCheckbox.current, [dealID]: !requestCheckbox.current[dealID] };
    setLocalRender(!localRender);
  };


  async function constructTable(dealData) {

    setShowDisclaimer(dealData.some(x => x.hasDraft));

    const tableData = dealData.map(row => {

      const b_hasNotes = Boolean(row.dealNote && row.dealNote.length);
      const salesNotesColour = getNotesColour(row);
      const fn_SalesNotes = actionOpenDrawer.bind(null, 'ViewNotesDrawer', row, dispatch, true, { get: localRender, set: setLocalRender }, '400px');

      const leaseOrCache = (Number(row.leaseorcash) === -1) 
        ? 'Unknown'
        : row.leaseorcash;

      return {
        selected: { value: <UILIB.TableContainer data={<UILIB.Checkbox disabled={row.hasDraft} checked={requestCheckbox.current[row.dealID]} type='checkbox' onChange={() => updateCheckbox(row.dealID, row)}/>} /> },
        finalDealNo: DealLinkTableCell(row, history, '/salesHub/accountmanagerview', true, null, true, 'class'),
        opportunityNumber: { value: row.opportunityNumber, raw: row.opportunityNumber },
        groupCode: { value: row.groupCode, raw: row.groupCode },
        companyName: { value: row.companyName, raw: row.companyName },
        description: { value: row.description, raw: row.description },
        salesType: { value: row.salesType, raw: row.salesType },
        leaseorcash: { value: leaseOrCache, raw: leaseOrCache },
        gp: { value: formatCurrency(row.pricing.gp), raw: row.pricing.gp },
        invoice: { value: formatCurrency(row.pricing.total), raw: row.pricing.total },
        createdAt: { value: formatDateTime(row.createdAt), raw: row.createdAt },
        status: { value: getStatusChip(row), raw: row.status },
        salesNotes : { value: tableContainer(fn_SalesNotes, 'icon-pencil5', 'View Notes', salesNotesColour, true), raw: b_hasNotes }
      }
    })
    return tableData;
  }

  const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken) => {

    const queryLocal = (query !== null) ? query : '';
    const pagingLocal = (limit !== null && offset !== null) ? `&$limit=${limit}&$offset=${offset}` : '';
    const orderLocal = (orderBy !== null && orderDir !== null) ? `&$order=${orderBy}&$direction=${orderDir}` : '';
    const calcFilters = setQuoteFilterVars(filters, account);
    const expandArray = ['pricing', 'dealNote'];

    setFilters({ ...filters, queryString: `${[calcFilters].join(' and ')}${queryLocal}` });

    entityData.current = await Axios.get(`/entities/deals/getSummary/?&$filter=${[calcFilters].join(' and ')}${queryLocal}&$expand=${expandArray.map(x => x).join(' and ')} ${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(api => api.data);

    if(!entityData.current || !entityData.current.result.length) return false;

    return { tableData: await constructTable(entityData.current.result), nonePaged: entityData.current.nonePaged };
  }


  return <Fragment>
      {showDeleteDialogue.show && <UILIB.MessageBox
        header={'Delete Quotes'}
        text={`Click OK to DELETE the selected Quotes`}
        loading={loading}
        errored={bannerError}
        onConfirm={async () => await actionDelete(requestCheckbox.current)}
        onCancel={() => setShowDeleteDialoge({ ...showDeleteDialogue, show: false })} />}
     {showArchiveDialogue.show && <UILIB.MessageBox
        header={'Archive Quotes'}
        text={`Click OK to APPROVE the selected Quotes`}
        loading={loading}
        errored={bannerError}
        onConfirm={async () => await actionArchive(requestCheckbox.current)}
        onCancel={() => setShowArchiveDialoge({ ...showArchiveDialogue, show: false })} />}
    <UILIB.Paper className='width-100'>
      <div className='flex-container row'>
        <div className='flex-item flex-grow-1 start wrap'>
          <UILIB.Button className={'small ' + (!filters.button || filters.button === 'Active' ? 'primary' : 'secondary')} name='Active' value='Active' onClick={(ev) => handleButtonsChanged(ev.target.name, setFilters)} />
          <UILIB.Button className={'mar-l10 small ' + (!filters.button || filters.button === 'Ordered' ? 'primary' : 'secondary')} name='Ordered' value='Ordered' onClick={(ev) => handleButtonsChanged(ev.target.name, setFilters)} />
          <UILIB.Button className={'mar-l10 small ' + (!filters.button || filters.button === 'Closed' ? 'primary' : 'secondary')} name='Closed' value='Closed' onClick={(ev) => handleButtonsChanged(ev.target.name, setFilters)} />
          <UILIB.Button className={'mar-l10 small ' + (!filters.button || filters.button === 'Lost' ? 'primary' : 'secondary')} name='Lost' value='Lost' onClick={(ev) => handleButtonsChanged(ev.target.name, setFilters)} />
          <UILIB.Button className={'mar-l10 small ' + (!filters.button || filters.button === 'Archived' ? 'primary' : 'secondary')} name='Archived' value='Archived' onClick={(ev) => handleButtonsChanged(ev.target.name, setFilters)} />
        </div>
        <div className='flex-item flex-grow-1 end'>
          <UILIB.Button 
            value="Delete" 
            className="button red" 
            disabled={!Object.values(requestCheckbox.current).some(x => x)} 
            onClick={() => tableDeleteRequest()} />
          <UILIB.Button 
            value="Archive" 
            className="button secondary" 
            disabled={!Object.values(requestCheckbox.current).some(x => x)} 
            onClick={() => tableArchiveRequst()} />
        </div>
        <div className='flex-item width-100 mar-t10'>
          {showDisclaimer && <p><i><b>Please note: </b>{actionDisclaimer}</i></p>}
        </div>
      </div>
      <UILIB.TableNew
        name={`MyQuotes`}
        className='small'
        overflowX='auto'
        overflowY='hidden'    
        header={headerData}
        localQuery={() => constructTable((entityData.current && entityData.current.result) ? entityData.current.result : [])}
        localRender={[localRender]}
        remoteQuery={getTableData}
        remoteRender={[filters.button]}
        defaultSettings={tablePageDefaults} />
    </UILIB.Paper>
  </Fragment>
}