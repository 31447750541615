
import { useState, useRef, Fragment } from 'react';
import UILIB from 'components';
import Axios from 'classes/axios';
import { createSelectInput } from 'classes/controls/formControls';


export default function CloneNewProductDrawer(props) 
{    
    const selectData = [];
    let counter = 1;
    while (counter < 100) { 
      selectData.push({ label: counter, value: counter });
      counter++; 
    };

    const selectQuantity = useRef([{ label: 'Please Select...', value: -1 }, ...selectData]);
    const defaultBanner = { error: false, message: '' };
    const [bannerError, setBannerError] = useState(defaultBanner);
    const [selected, setSelected] = useState(-1);
    const [loading, setLoading] = useState(false);

    const invalidConfig = 'The options selected are invalid, please try again or contact support';
    const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
    const exceptionError = 'There was an exception while saving this record, please reload or contact support';
    const exceptions = [400,404];


    const updateSeleted = (event) => {
      setSelected(event.target.value);
    }

    const actionCloneData = async () => {
      try {
        setLoading(true);
        const baseData = { prodId: props.product.prodID, deviceNo: props.product.deviceNo, isADevice: props.product.isADevice, quantity: selected };
        const result = await Axios.post(`/entities/workflow/deals/actionCloneDevice/${props.ourDeal.dealID}`, baseData);
        if(exceptions.includes(result.status)) {
          if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
          if(result.status === 404) setBannerError({ error: true, message: notFoundError });
        } else {
          setBannerError(defaultBanner);
          props.setOurDeal({ 
            ...props.ourDeal, 
            leasePeriodPayment: result.data.result.lease,
            pricing: result.data.result.pricing, 
            product: result.data.result.product, 
            compatibles: result.data.result.compatibles
          });
          await props.onFinish();
        }
      } catch (err) {
        console.log(err);
        setBannerError({ error: true, message: exceptionError });
      }
      setLoading(false);
    };


    return <Fragment>

      <div className="flex-container row width-100">
        <div className="flex-item center align-center font-weight-bold text-16 mar-b10 width-100">Clone Product</div>
        <div className="flex-container row start width-100 mar-b5">
          {createSelectInput('Clone Quantity', 'cloneQuantity', loading, selected, selectQuantity.current, (ev) => updateSeleted(ev), null, 'flex-grow-1', null, null, 'fullBorder border-colour-grey-4')}
        </div>
        <div className="flex-container start flex-grow-1">
          <UILIB.Button className="green" disabled={!props.isActive || Number(selected) === -1 || loading} loading={loading} value={"Confirm"} onClick={async () => await actionCloneData()} />
          <UILIB.Button className="grey" disabled={!props.isActive || loading} value="Cancel" onClick={() => props.onCancel()} />
        </div>
        {bannerError.error && <div className="flex-container start wrap width-100">
          <div className="errored message">{bannerError.message}</div>
        </div>}
      </div>
    </Fragment>
}
