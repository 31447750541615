import { useCallback } from "react";
import { constants } from "../dnd/Constants";

export default function useCheckCompatiables() {

  const handleCheckCompatiables = useCallback(
    (data, item) => {

      if (
        data.raw.rowType === 1 
        && data.bind === item?.bind
      ) return true;

      return (
        data.bind === item?.bind 
        && data.bind !== constants.TYPE_NOBIND 
        && data.raw.hasCompats 
        && data.raw.portalPart.Parent.find(x => Number(x.id) === Number(item.portalPartId))
      );

    },
    []
  );

  return { handleCheckCompatiables };
}