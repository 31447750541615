import { Fragment } from 'react'
import UILIB from 'components';
import Drawer from '../Drawers';
import { useDispatch } from 'react-redux';
import { setDrawer } from "store/actions";


export default function CreateOrder(props) 
{

  const dispatch = useDispatch();

  const createOrder = () => {
    dispatch(setDrawer({
      open: true,
      content: <Drawer.ConfirmCreateOrderDrawer
        onConfirm={async () => dispatch(setDrawer({ open: false, content: null }))}
        ourDeal={props.ourDeal}
        isActive={props.isActive}
        route={props.route}
        history={props.history}
        onCancel={() => dispatch(setDrawer({ open: false, content: null }))}
      />
    }));
  }

  return <Fragment>
    <div className="flex-item width-100">
      <UILIB.Button
        disabled={!props.isActive}
        className="small green width-100"
        value="Create Draft Order"
        title='Create Draft Order'
        onClick={() => createOrder()} />
    </div>
  </Fragment>
}