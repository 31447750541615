import Modules from '../Modules';
import React, { Fragment } from 'react';
import classNames from "classnames";
import { constants } from "../dnd/Constants";

export default React.forwardRef(({ 
  header, 
  rowIndex, 
  row, 
  dndEnabled, 
  handleName,
  handleCalculateCellStyle,
  isOver,
  data
}, ref) => 
{ 


  const rowAppend = {
    [handleName]: {
      class: (Object.keys(row).length && row[Object.keys(row)[0]]?.class) 
        ? row[Object.keys(row)[0]]?.class 
        : '',
      value: <Modules.DragHandle 
                rowIndex={rowIndex}
                header={header} 
                dndEnabled={dndEnabled}  
                handleName={handleName} 
                ref={(ref) ? ref : null}
              />,
      raw: null
    },
    ...row
  };

  return <Fragment>
    {header
      .filter(x => x !== undefined)
      .map((col, columnIndex) => {

        const columnData = { 
          value: rowAppend[col.value].hasOwnProperty('value') ? rowAppend[col.value].value : '',
          raw: rowAppend[col.value].hasOwnProperty('raw') ? rowAppend[col.value].raw : '',
          class: rowAppend[col.value].hasOwnProperty('class') ? rowAppend[col.value].class : ''  
        };

        const align = (col?.align) ? col?.align : '';
        const styleFinal = { whiteSpace: 'nowrap', textAlign: align };

        return <td 
          className={classNames(
            `${([constants.ROW, constants.UNGROUPED].includes(data.type)) 
              ? 'mainTable td dnd-parent' 
              : 'mainTable td dnd-child'}`
          )}
          key={Number(`${data.id}${columnIndex}`)}
        >
          {<Modules.DataColumn 
            columnData={columnData}
            style={styleFinal} 
            columnIndex={columnIndex} 
            handleBuildCellStyle={handleCalculateCellStyle(data, columnIndex)}
            isOver={isOver}
          />}
        </td>
      })}
  </Fragment>
})