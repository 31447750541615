import { useCallback } from "react";
import { constants } from "../dnd/Constants";
import useFindNestedParent from '../hooks/useFindNestedParent';

export default function useParentDropZone(layout) {

  const { handleFindNestedParent } = useFindNestedParent(layout);

  const handleParentDropZone = useCallback(
    (probe) => {

      const isOver = Boolean(probe.isOver 
          && [constants.COLUMN, constants.UNGROUPED].includes(probe?.item?.type));
      
      const parent = handleFindNestedParent(probe?.item?.id);

      return {
        isOver,
        isNested: Boolean(parent.item),
        nestedParent: parent
      }

    },
    [handleFindNestedParent]
  );

  return { handleParentDropZone };
}