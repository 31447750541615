import { useState, useEffect, useRef, Fragment } from 'react';
import * as functions from './shared/functions';
import UILIB from 'components';
import Axios from 'classes/axios';
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux'
import { permissionsRouter, permissionsObject, permissionDecider } from 'classes/permissions';
import BlockGroupPage from './tabs/blockGroupPage';
import BlockUsersPage from './tabs/blockUsersPage';
import BlockWorkflowPage from './tabs/blockWorkflowPage';
import BlockReleasePage from './tabs/blockReleasePage';
import BlockAuditPage from './tabs/blockAuditPage';
import SpecialsPage from './tabs/specialsPage';
import DeviceNumberMigration from './tabs/deviceMigration';
import WorkflowMigration from './tabs/workflowMigration';
import UserGroupUpdate from './tabs/userGroupModel';
import DealGuidUpdate from './tabs/dealGuidMigration';
import AddPermissionModal from './modal/addPermission';
import UploadFileContainer from './modal/uploadFileContainer';
import MissingPDI from './tabs/addMissingPDI';
import AddUserRoles from './tabs/addUserRoles';
import MFilesDocuments from './tabs/documentAdd';
import MigratePortalPermissions from './tabs/migratePortalPermissions';
import AddressRefactor from './tabs/addressRefactor';



export default function AdminHub() {

  const pageStartup = useRef({ updateField: true, permissions: true, users: true, release: true });
  const location = useLocation();
  const account = useSelector((state) => state.account);
  const userPermissions = useSelector((state) => state.permissions);
  const history = useHistory();
  const permissionsData = useRef([{ name: 'adminHub', hubName: 'Admin Hub', routeName: location.pathname }]);
  const localPermissions = useRef({});
  const [loading, setLoading] = useState(true);
  const [groupsImportLoading, setGroupsImportLoading] = useState(false);
  const [dealMigrationLoading, setDealMigrationLoading] = useState(false);
  const [workflowLoading, setWorkflowLoading] = useState(false);
  const [renderLoading, setRenderLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [localEvent, setLocalEvent] = useState({ timeout: 200 });
  const [selectedUserIndex, setSelectedUserIndex] = useState({ group: 0, user: 0 });
  const [selectedGroupIndex, setSelectedGroupIndex] = useState({ entity: 0, group: 0, permission: 0 });
  const [selectedHubIndex, setselectedHubIndex] = useState({ hub: 0 });
  const [entities, setEntities] = useState([]); 
  const [permissions, setPermissions] = useState([]); 
  const [releases, setReleases] = useState([]); 
  const [users, setUsers] = useState([]); 
  const [groups, setGroups] = useState([]); 
  const [groupRender, setGroupRender] = useState(0); 
  const [releaseRender, setReleaseRender] = useState(0); 
  const [userRender, setUserRender] = useState(0); 
  const [nestOpen, setNestOpen] = useState({}); 
  const [hubTableData, setHubTableData] = useState([]); 
  const [userTableSortHandler, setUserTableSortHandler] = useState({ active: false, direction: 'asc', headName: null, page: 0, rowsPerPage: 25, filter: '' }); 
  const [groupTableSortHandler, setGroupTableSortHandler] = useState({ active: false, direction: 'asc', headName: null, page: 0, rowsPerPage: 25, filter: '' }); 
  const [groupChildTableSortHandler, setGroupChildTableSortHandler] = useState({ active: false, direction: 'asc', headName: null, filter: '' }); 
  const [releaseTableSortHandler, setReleaseTableSortHandler] = useState({ active: false, direction: 'asc', headName: null, page: 0, rowsPerPage: 25, filter: '' });
  const [releaseChildTableSortHandler, setReleaseChildTableSortHandler] = useState({ active: false, direction: 'asc', headName: null, filter: '' });  
  const [hubTableDataOpen, setHubTableDataOpen] = useState(false);
  const [permissionsTableData, setPermissionsTableData] = useState([]); 
  const [entityTableSearch, setEntityTableSearch] = useState(null); 
  const [showPermissionsModal, setShowPermissionsModal] = useState(false);
  const [uploadFileHandler, setUploadFileHandler] = useState({ show: false, stage: 1 });
  const [downloadingRelease, setDownloadingRelease] = useState(false);
  const [uploadingRelease, setUploadingRelease] = useState(false);
  const [publishRelease, setPublishRelease] = useState(false);
  const [enableUser, setEnableUser] = useState(false);
  const [logoutUser, setLogoutUser] = useState(false);
  const [fileUploadJobActive, setFileUploadJobActive] = useState({ jobId: null, isActive: 0, complete: 0, trigger: 0, timeout: null, progress: null });
  const [selectedFile, setSelectedFile] = useState(null);
  const [groupExpandRow, setGroupExpandRow] = useState([]);
  const [releaseExpandRow, setReleaseExpandRow] = useState([]);


  useEffect(() => {
    const asyncEffect = async () => {
      setLoading(true);
      await permissionsRouter(history, userPermissions, account, null, location.pathname, 'General Access');
      await permissionDecider(userPermissions, account, permissionsData.current, localPermissions.current);
      const resultReleaseNotes = await Axios.get(`/admin/releaseNotes/getReleaseNotes`);
      const resultEntities = await Axios.get(`/admin/groups/readEntities`);
      const resultPermissions = await Axios.get(`/admin/groups/readEntityGroupPermissions`);
      const resultUser = await Axios.get(`/admin/users/getUsers`);
      setReleases(resultReleaseNotes.data && resultReleaseNotes.data.result);
      setEntities(resultEntities.data && resultEntities.data.result);
      setPermissions(resultPermissions.data && resultPermissions.data.result);
      setUsers(resultUser.data && resultUser.data.result.users);
      setGroups(resultUser.data && resultUser.data.result.groups);
      setLoading(false);
    }
    asyncEffect();
  }, [])


  useEffect(() => {
    if (pageStartup.current.permissions) { 
      pageStartup.current.permissions = false; 
      return;
    }
    else {
      const asyncEffect = async () => {
        setRenderLoading(true);
        const resultEntities = await Axios.get(`/admin/groups/readEntities`);
        const resultPermissions = await Axios.get(`/admin/groups/readEntityGroupPermissions`);
        setEntities(resultEntities.data && resultEntities.data.result);
        setPermissions(resultPermissions.data && resultPermissions.data.result);
        setRenderLoading(false);
      }
      asyncEffect();
    }
  }, [groupRender])


  useEffect(() => {
    if (pageStartup.current.release) { 
      pageStartup.current.release = false; 
      return;
    }
    else {
      const asyncEffect = async () => {
        setRenderLoading(true);
        const resultReleaseNotes = await Axios.get(`/admin/releaseNotes/getReleaseNotes`);
        setReleases(resultReleaseNotes.data && resultReleaseNotes.data.result);
        setRenderLoading(false);
      }
      asyncEffect();
    }
  }, [releaseRender])


  
  useEffect(() => {
    if (pageStartup.current.users) { 
      pageStartup.current.users = false; 
      return;
    }
    else {
      const asyncEffect = async () => {
        setRenderLoading(true);
        const resultUser = await Axios.get(`/admin/users/getUsers`);
        setUsers(resultUser.data && resultUser.data.result.users);
        setGroups(resultUser.data && resultUser.data.result.groups);
        setRenderLoading(false);
      }
      asyncEffect();
    }

  }, [userRender])


  useEffect(() => {
    if (pageStartup.current.updateField) { 
      pageStartup.current.updateField = false; 
      return;
    }
    else {
      const timer = setTimeout(async () => {
        await Axios.put(`/admin/releaseNotes/updateField`, {
          type: localEvent.type,
          raw: localEvent.raw,
          fieldName: localEvent.fieldName,
          fieldValue: localEvent.fieldValue
        })
      }, localEvent.timeout);
      return () => clearTimeout(timer);
    }
  }, [localEvent])


  useEffect(() => {
    const asyncEffect = async () => {
      if (fileUploadJobActive.isActive === 1 && fileUploadJobActive.complete === 0) {
        await functions.getJob(setFileUploadJobActive, fileUploadJobActive);
        await functions.timerGetJobs(setFileUploadJobActive, fileUploadJobActive);
      }
    }
    asyncEffect();
  }, [fileUploadJobActive.trigger])



  const tabs = [ 
    { label: "Group Admin", value: 0, }, 
    { label: "User Admin", value: 1,  }, 
    { label: "Workflow Admin", value: 2 }, 
    { label: "Audit Logs", value: 3 },
    { label: "Release Notes", value: 4 },
    { label: "Add Booked Date", value: 5 },
    { label: "Add User Roles", value: 6 },
    { label: "Migrate Portal Permissions", value: 7 },
    { label: "Deal Address Refactor", value: 8 }
  ]

  if (loading || !permissions) return <UILIB.Loading type={3} />

  return <Fragment> 
            <UILIB.SubNav 
              tabs={tabs} 
              currentTab={currentTab} 
              outerClasses="mar-t15 mar-b15" 
              onClick={(newId) => setCurrentTab(newId)} 
            />
            {currentTab === 0 &&
              <BlockGroupPage 
                selectedGroupIndex={selectedGroupIndex}
                setSelectedGroupIndex={setSelectedGroupIndex}
                selectedHubIndex={selectedHubIndex}
                setselectedHubIndex={setselectedHubIndex}
                permissions={permissions}
                nestOpen={nestOpen}
                setNestOpen={setNestOpen}
                hubTableData={hubTableData}
                setHubTableData={setHubTableData}
                loading={loading}
                setLoading={setLoading}
                hubTableDataOpen={hubTableDataOpen}
                setHubTableDataOpen={setHubTableDataOpen}
                showPermissionsModal={showPermissionsModal}
                setShowPermissionsModal={setShowPermissionsModal}
                groupTableSortHandler={groupTableSortHandler}
                setGroupTableSortHandler={setGroupTableSortHandler}
                groupChildTableSortHandler={groupChildTableSortHandler}
                setGroupChildTableSortHandler={setGroupChildTableSortHandler}
                entityTableSearch={entityTableSearch}
                setEntityTableSearch={setEntityTableSearch}
                groupRender={groupRender}
                setGroupRender={setGroupRender}
                groupExpandRow={groupExpandRow}
                setGroupExpandRow={setGroupExpandRow}
                renderLoading={renderLoading}
                setRenderLoading={setRenderLoading}
              />}
            {currentTab === 1 &&
              <BlockUsersPage 
                userRender={userRender}
                setUserRender={setUserRender}
                users={users} 
                setUsers={setUsers}
                groups={groups} 
                setGroups={setGroups}
                userTableSortHandler={userTableSortHandler} 
                setUserTableSortHandler={setUserTableSortHandler}
                loading={loading}
                setLoading={setLoading}
                selectedUserIndex={selectedUserIndex}
                setSelectedUserIndex={setSelectedUserIndex}
                enableUser={enableUser} 
                setEnableUser={setEnableUser}
                logoutUser={logoutUser}
                setLogoutUser={setLogoutUser}
                renderLoading={renderLoading}
                setRenderLoading={setRenderLoading}
              />}
            {currentTab === 2 &&
              <BlockWorkflowPage 

              />}
            {currentTab === 3 &&
              <BlockAuditPage 

              />}
            {currentTab === 4 &&
              <BlockReleasePage 
                loading={loading}
                setLoading={setLoading}
                releaseTableSortHandler={releaseTableSortHandler}
                setReleaseTableSortHandler={setReleaseTableSortHandler}
                downloadingRelease={downloadingRelease}
                setDownloadingRelease={setDownloadingRelease}
                uploadingRelease={uploadingRelease}
                setUploadingRelease={setUploadingRelease}
                publishRelease={publishRelease}
                setPublishRelease={setPublishRelease}
                releases={releases}
                setReleases={setReleases}
                releaseRender={releaseRender}
                setReleaseRender={setReleaseRender}
                localEvent={localEvent}
                setLocalEvent={setLocalEvent}
                fileUploadJobActive={fileUploadJobActive}
                setFileUploadJobActive={setFileUploadJobActive}
                uploadFileHandler={uploadFileHandler}
                setUploadFileHandler={setUploadFileHandler}
                releaseChildTableSortHandler={releaseChildTableSortHandler}
                setReleaseChildTableSortHandler={setReleaseChildTableSortHandler}
                setSelectedFile={setSelectedFile}
                releaseExpandRow={releaseExpandRow}
                setReleaseExpandRow={setReleaseExpandRow}
                renderLoading={renderLoading}
                setRenderLoading={setRenderLoading}
              />}
            {currentTab === 5 && 
              <MissingPDI 
                localPermissions={localPermissions.current}
                permissionsObject={permissionsObject}
                location={location}
                permissions={userPermissions}
              />}
            {currentTab === 6 && 
              <AddUserRoles 
                localPermissions={localPermissions.current}
                permissionsObject={permissionsObject}
                location={location}
                permissions={userPermissions}
              />}
            {currentTab === 7 && 
              <MigratePortalPermissions 
                localPermissions={localPermissions.current}
                permissionsObject={permissionsObject}
                location={location}
                permissions={userPermissions}
              />}
             {currentTab === 8 && 
              <AddressRefactor 
                localPermissions={localPermissions.current}
                permissionsObject={permissionsObject}
                location={location}
                permissions={userPermissions}
              />}
            {showPermissionsModal &&
              <AddPermissionModal
                entities={entities}
                permissions={permissions}
                selectedGroupIndex={selectedGroupIndex}
                setSelectedGroupIndex={setSelectedGroupIndex}
                permissionsTableData={permissionsTableData}
                setPermissionsTableData={setPermissionsTableData} 
                showPermissionsModal={showPermissionsModal}
                setShowPermissionsModal={setShowPermissionsModal}
                groupRender={groupRender}
                setGroupRender={setGroupRender}
                loading={loading}
                setLoading={setLoading}
                setGroupExpandRow={setGroupExpandRow}
              />}
              <UploadFileContainer
                selectedFile={selectedFile} 
                setSelectedFile={setSelectedFile}
                fileUploadJobActive={fileUploadJobActive}
                setFileUploadJobActive={setFileUploadJobActive}
                setUploadFileHandler={setUploadFileHandler}
                uploadFileHandler={uploadFileHandler}
                releaseRender={releaseRender}
                setReleaseRender={setReleaseRender}
              />
          </Fragment> 
          
}