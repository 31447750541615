import { useState, useReducer, useEffect, useRef, memo } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { useParams } from 'react-router';
import { store } from 'store/store'
import UILIB from 'components';
import Axios from 'classes/axios';
import Modules from './Modules';
import QuoteDetailPage from './details/detailPage';
import ProductsPage from './products/productsPage';
import DocumentsPage from './documents/documentsPage';
import { DATA_TO_LOAD, reducer, reduceArray } from './sideload/reducer';
import * as functions from './functions';
import * as sideLoad from './sideload/sideLoad';


const BlockQuotes = memo(() => 
{
  const state = store.getState();
  const account = state.account;
  const history = useHistory({ forceRefresh: true });
  const params = useParams();
  const location = useLocation();
  const route = location.pathname;
  const processData = useRef('');
  const userAccess = useRef([]);
  const [isActive, setisActive] = useState(false);
  const [opportunityNumber, setOpportunityNumber] = useState(0);
  const [dealLoaded, setDealLoaded] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [dealAddress, setDealAddress] = useState([]);
  const [showAddQuoteModal, setShowAddQuoteModal] = useState(false);
  const [dealData, setDealData] = useState(undefined);
  const [dataLoaded, setDataLoaded] = useState(reduceArray(DATA_TO_LOAD)); 
  const [dataToLoad, reducerDispatch] = useReducer(reducer, { dataLoaded: [] }); 
  const [productConfigurationModal, setProductConfigurationModal] = useState(functions.getProductConfigurationModal());
  const [fieldWorkflowObj, setFieldWorkflowObj] = useState(functions.getFieldWorkflowObj());
  const [cpcDisabled, setCpcDisabled] = useState(false);
  const actionButtons = [];

  
  const loadQuote = async (dealID) => {
    try {

      setDealLoaded(false);

      let finalDealId = undefined;
      if (dealID) {
        finalDealId = dealID;
      } else if (dealLoaded && dealData && dealData.dealID) {
        finalDealId = dealData.dealID;
      } else {
        throw new Error('error loading deal, no deal id');
      }

      //load deal data
      const baseData = await Axios.get(`/entities/workflow/deals/actionGetDealData/${finalDealId}`); 

      //redirect if not a quote
      if (baseData.data.result.processStage > 0) history.push(route.replace('/quote/', '/order/'));

      //check group specific access
      userAccess.current = await functions.checkAccess(account, baseData.data.result);

      //check if deal is active
      setisActive(baseData.data.result.isActive && baseData.data.result.processStage < 2 && Object.values(userAccess.current.read).indexOf(false) > -1);

      //check for user permissions
      if (Object.values(userAccess.current.users).indexOf(false) === -1) {
        history.push({
          pathname: `/salesHub/accountmanagerview/quoteview`,
          state: {
            route: route,
            newQuote: history.location.pathname.indexOf(`${route}/quotes/add`) > -1 ? true : false,
            message: {
              showPermissionMessage: history.location.pathname.indexOf(`${route}/quotes/add`) === -1 ? true : false,
              dealNumber: baseData.data.result.dealID,
              crmOwner: baseData.data.result.crm?.systemusers?.fullname,
              processStage: baseData.data.result.processStage
            }
          }
        })
      }

      processData.current = functions.getQuoteProcessData(baseData.data.result);

      setCpcDisabled(Boolean(Number(baseData?.data?.result?.serviceContract) === 3));

      setDealData(baseData.data.result);
      setDealLoaded(true);
    }
    catch (error) {
      console.log(error);
      if (history && history.location && history.location.key) {
        history.goBack();
      }
      else {
        history.push('/');
      }
    }
  }

  const sideLoadData = (dealID, oppNumber, accountNo) => {
    sideLoad.getProducts(reducerDispatch, dealID);
    sideLoad.getCrm(reducerDispatch, oppNumber);
    sideLoad.getDocuments(reducerDispatch, oppNumber);
    sideLoad.getCompatibles(reducerDispatch);
    sideLoad.getPSUsers(reducerDispatch);
    sideLoad.getHostConfig(reducerDispatch);
    sideLoad.getOtherDeals(reducerDispatch, oppNumber);
    sideLoad.getRemovals(reducerDispatch, dealID);
    sideLoad.getRemovalStores(reducerDispatch, accountNo);
    sideLoad.updateDealData(reducerDispatch, dealID, oppNumber);
    sideLoad.getProcurmentData(reducerDispatch);
    sideLoad.getDealPricing(reducerDispatch, dealID);
    sideLoad.getKeyContacts(reducerDispatch, dealID);
    sideLoad.getNotesData(reducerDispatch, dealID);
    sideLoad.getSettlementData(reducerDispatch, dealID);
    sideLoad.getAddressData(reducerDispatch, dealID);
    sideLoad.getAddressTypes(reducerDispatch);
    sideLoad.getAddressSources(reducerDispatch);
    sideLoad.getEventDeclineType(reducerDispatch);
  }

  useEffect(() => {
    if(!dealLoaded) return;
    sideLoadData(dealData.dealID, dealData.dynamicsOpportunityNumber, dealData.accountNo);
  }, [dealLoaded])

  useEffect(() => {
    if(!dealLoaded) return;
    setDealData({ 
      ...dealData, 
      ...dataToLoad,
      addressDropdown: functions.getAddressDropdown({ ...dealData, ...dataToLoad })
    });
  }, [dataToLoad])

  useEffect(() => {
    if(!dealLoaded) return;
    setDataLoaded({ ...dataLoaded, ...dataToLoad.dataLoaded });
  }, [dataToLoad])
  
  useEffect(() => {
    functions.fieldWorkflow({ 
      dealData, 
      setFieldWorkflowObj 
    });
  }, [dealData])

  useEffect(() => {
    const asyncEffect = async () => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const qString = Object.fromEntries(urlSearchParams.entries());

      if ((qString && qString.OpportunityNumber) && typeof (qString.OpportunityNumber) !== 'undefined') {
        setOpportunityNumber(qString.OpportunityNumber);
      }
      else if (params && params.dealID) {
        await loadQuote(params.dealID);
      }
      else {
        history.push("/");
      }
    }
    asyncEffect();
  }, [])

  useEffect(() => {
    const asyncEffect = async () => {
      if (opportunityNumber !== 0) await lookupCRMOpportunity();
    }
    asyncEffect();
  }, [opportunityNumber])

  const lookupCRMOpportunity = async (addQuote) => {
    try {

      const oppNumber = opportunityNumber || dealData.data.result.dynamicsOpportunityNumber;
      const postData = { addAdditional: addQuote ? addQuote : false };

      const ourDealData = await Axios.post(`/sales/deals/byOpportunity/${oppNumber}`, postData);

      history.push(`/salesHub/accountmanagerview/quote/${ourDealData.data.dealID}`);

      if (ourDealData.data.duplicate === false) await loadQuote(ourDealData.data.dealID);
      if (ourDealData.data.duplicate === true) {
        history.push({
          pathname: `/salesHub/accountmanagerview/quoteview`,
          state: { message: { duplicate: true, opportunityNumber: ourDealData.data.opp } }
        })
      }
    }
    catch (error) {
      console.log(error);
      history.push({
        pathname: `/salesHub/accountmanagerview/quoteview`,
        state: { message: { response: error.data.result, error: true } }
      })
    }
  }

  const tabs = [
    { label: `${processData.current.typeText} Details`, value: 0 }, 
    { label: "Products", value: 1 }, 
    { label: "Documents", value: 2 }
  ];
  
  if (!dealLoaded) return <UILIB.Loading type={3} />

  if(dealData.dynamicsOpportunityNumber && dealData.dynamicsOpportunityNumber !== "" && dealData.dynamicsOpportunityNumber !== "0") {
    if(Number(isActive) === 0) {
      actionButtons.push(<UILIB.Button
        disabled={!dealData.parentId}
        className="small grey width-100"
        value="View Order"
        onClick={() => 
          history.push(`/salesHub/accountmanagerview/order/${dealData.parentId}`)} />);
    }
    if(Number(isActive) === 1) {
      actionButtons.push(<UILIB.Button
        disabled={!isActive}
        className="small grey width-100"
        value="Add Additional Quote"
        title='Adds an additional quote for this opportunity'
        onClick={() => setShowAddQuoteModal(true)} />);
    }
    if(dealData.otherDeals && Number(isActive) === 1) {
      actionButtons.push(<Modules.SwapQuote 
        history={history} 
        otherQuotes={dealData.otherDeals} 
        ourDeal={dealData} 
        isActive={isActive} 
        dealID={dealData.dealID} />);
    }
    if(Number(isActive) === 1) {
      actionButtons.push(<Modules.CopyQuote 
        account={account} 
        ourDeal={dealData} 
        isActive={isActive} />);
    }
    if(Number(isActive) === 1) {
      actionButtons.push(<Modules.CreateOrder 
        ourDeal={dealData} 
        isActive={isActive} 
        route={route} 
        history={history} />);
    }
  }

  actionButtons.push(<Modules.DownloadQuoteSheet 
    ourDeal={dealData} 
    isActive={isActive} />)


  return <div className="flex-container row wrap mar-t10 mar-r5 mar-l5">
    {showAddQuoteModal && 
      <Modules.AddAdditionalQuote 
        ourDeal={dealData} 
        history={history}
        route={route}
        setShowAddQuoteModal={setShowAddQuoteModal} />}
    <Modules.DealHeader ourDeal={dealData} processData={processData} otherQuotes={dealData.otherDeals} />
    <div className="flex-container row end between width-100 mar-l8 mar-r8">
      <div className="flex-item flex-grow-1 end">
        <UILIB.CustomSelect data={actionButtons} outerclassname="width-200-px" clickTitle={{ title: 'Select Action' }} />
      </div>
    </div>
    <UILIB.SubNav tabs={tabs} currentTab={currentTab} outerClasses="mar-t15 mar-b15" onClick={(newId) => setCurrentTab(newId)} />
    {currentTab === 0 &&
      <QuoteDetailPage
        ourDeal={dealData}
        setOurDeal={setDealData}
        account={account}
        isActive={isActive}
        fieldWorkflowObj={fieldWorkflowObj}
        setFieldWorkflowObj={setFieldWorkflowObj}
        setDealAddress={setDealAddress}
        dataLoaded={dataLoaded}
        setCpcDisabled={setCpcDisabled} />}
    {currentTab === 1 &&
      <ProductsPage
        dealLoaded={dealLoaded}
        ourDeal={dealData}
        setOurDeal={setDealData}
        isActive={isActive}
        processData={processData}
        dealAddress={dealAddress}
        setDealAddress={setDealAddress}
        fieldWorkflowObj={fieldWorkflowObj}
        setFieldWorkflowObj={setFieldWorkflowObj}
        dataLoaded={dataLoaded}
        cpcDisabled={cpcDisabled}
        setCpcDisabled={setCpcDisabled}
        productConfigurationModal={productConfigurationModal}
        setProductConfigurationModal={setProductConfigurationModal} />}
    {currentTab === 2 &&
      <DocumentsPage
        ourDeal={dealData}
        setOurDeal={setDealData}
        isActive={isActive}
        dataLoaded={dataLoaded} />}
    <Modules.ExpandPricingStats 
      ourDeal={dealData} 
      processData={processData} 
      otherQuotes={dealData.otherDeals} 
      currentTab={currentTab} />
  </div >
})
export default BlockQuotes