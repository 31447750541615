import AutoComplete from "components/AutoComplete"

export default function SalesAddressLookup({ 
  className, 
  style, 
  width,
  name, 
  accountId,
  defaultValue, 
  errored, 
  disabled,
  state = { get: null, set: null },
  onClick = () => { },
  onChange = () => { } 
}) {

  return <AutoComplete
          className={className}
          defaultValue={defaultValue}
          width={width}
          state={state}
          onChange={onChange}      
          onClick={onClick}   
          disabled={disabled}
          style={style}
          componentName={name}
          errored={errored}
          url={`/helpers/getSalesAddresses/${accountId}/{{name}}`}
          parser={data => data.results.map((c,i) => ({ raw: c, label: `${c.name}-(${c.accountnumber}) ${c.address1_composite} - `, value: i }))}
          placeholder = "Type to Start Searching"
          blank="No Addresses Found" />
}