import React, { Fragment } from 'react'
import Modules from '../Modules';


export default React.forwardRef(({ 
  props
}, ref) => 
{ 
  return <Fragment>
    <div ref={ref} className="salesHub pricingStats stat-card container width-100">
      <div className="salesHub pricingStats stat-card item mar-4">
        <Modules.SingleStatCard header={'Sale Price'} stat={props?.dealData?.pricing?.total} />
      </div>
      <div className="salesHub pricingStats stat-card item mar-4">
        <Modules.SingleStatCard header={'Cost'} stat={props?.dealData?.pricing?.cost} />
      </div>
      <div className="salesHub pricingStats stat-card item mar-4">
        <Modules.SingleStatCard header={'GP'} stat={props?.dealData?.pricing?.gp} />
      </div>
    </div>
  </Fragment>
})