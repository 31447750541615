export default function ColumnHeader({ header }) 
{
  return <thead className="mainTable headerBorders">
    <tr>
      {header
        .filter(x => x !== undefined)
        .map((col, index) => {

          const minWidth = (col?.minWidth && !col?.width) ? { minWidth: col?.minWidth } : {};
          const maxWidth = (col?.maxWidth && !col?.width) ? { maxWidth: col?.maxWidth } : {};
          const width = (isNaN(minWidth?.minWidth) && isNaN(maxWidth?.maxWidth)) ? { width: 'auto' } : {};
          const widthFinal = ((isNaN(minWidth?.minWidth) && isNaN(maxWidth?.maxWidth) && isNaN(width?.width))) ? { width: 'auto' } : {};
          const align = (col?.align) ? col?.align : '';
          const style = { textAlign: align, ...minWidth, ...maxWidth, ...width, ...widthFinal };

          return <th key={index} style={style} className="mainTable headerBorders">
            {col.label}
          </th>
        })}
    </tr>
  </thead>
}