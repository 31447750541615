import { useEffect, useState, Fragment } from 'react';
import axios from 'classes/axios';
import UILIB from 'components';
import Modules from '../Modules';



export default function ProductConfigurator(props) 
{

    const [manufacturers, setManufacturers] = useState([]);
    const [manufacturersLoading, setManufacturersLoading] = useState(true);
    const [productId, setProductId] = useState(0);
    const [typeId, setTypeId] = useState(0)
    const [tableData, setTableData] = useState([])
    const [loadingData, setLoadingData] = useState(false)
    const [disableRange, setDisableRange] = useState(false)
    const [filtersLoading, setFiltersLoading] = useState(false);
    const [filterData, setFilterData] = useState({
        manufacturers: [],
        ranges: [],
        types: [],
        suppliers: []
    })
    const [filters, setFilters] = useState({
        manufacturerId: "all",
        rangeId: "all",
        typeId: "all",
        text: "",
        timerActive: false
    })
    const [expandResultsOptions, setExpandResultsOptions] = useState({
        postType: 1
    })


    const getManufacturers = async () => {
      let manuData = await axios.get(`/sales/parts/manufacturer`);
      manuData = manuData.data.map(row => { return { label: row.name, value: row.id } });
      manuData.push({ label: "Refurbs", value: "refurbs" })
      setManufacturers(manuData);
      setManufacturersLoading(false);
    }

    const buildQuery = () => {

        let qry = "?"
        if (filters.text !== "" && filters.text.length > 2) qry += `&searchText=${filters.text}`
        if (filters.manufacturerId !== "all") qry += `&manufacturerId=${filters.manufacturerId}`
        if (filters.rangeId !== "all") qry += `&rangeId=${filters.rangeId}`
        if (filters.typeId !== "all") qry += `&typeId=${filters.typeId}`

        return qry
    }


    const getParts = async (opts) => {

        setLoadingData(true);

        let qry = buildQuery();

        axios.get(`/sales/parts${qry}`, opts).then(response => {
            setTableData(response.data)
            setLoadingData(false);
        }).catch(() => { })

    }

    const getFilterData = async () => {
        setFiltersLoading(true)
        const results = await Promise.all([
            axios.get("/sales/parts/manufacturer"),
            axios.get("/sales/parts/range"),
            axios.get("/sales/parts/type"),
            axios.get("/sales/parts/supplier")
        ])
        setFilterData({
            manufacturers: [{ label: "Select a Manufacturer to continue", value: "all" }].concat(results[0].data.map(r => ({ value: r.id, label: r.name }))),
            ranges: [{ label: "Select a Range to continue", value: "all" }].concat(results[1].data.map(r => ({ value: r.id, label: r.name, manufacturerId: r.SalesPartManufacturerId }))),
            types: [{ label: "All Types (Optional)", value: "all" }].concat(results[2].data.map(r => ({ value: r.id, label: r.name }))),
            suppliers: [{ label: "All Suppliers", value: "all" }].concat(results[3].data.map(r => ({ value: r.id, label: r.name })))
        })
        setFiltersLoading(false)
    }


    const changeFilter = (event) => {

        const filtersNew = { ...filters };
        switch (event.target.name) {
            case "manufacturers":
                filtersNew.manufacturerId = event.target.value;
                filtersNew.rangeId = "all";
                break;
            case "ranges":
                filtersNew.rangeId = event.target.value;
                break;
            case "types":
                filtersNew.typeId = event.target.value;
                break;
            default:
                break;
        }
        setFilters(filtersNew);
    }


    const rangeData = Boolean(filterData) ? filterData.ranges.filter(r => filters.manufacturerId === 'all' || r.value === "all" ? true : Number(r.manufacturerId) === Number(filters.manufacturerId)) : [];


    useEffect(() => {
      const asyncEffect = async () => {
        await getFilterData();
        await getManufacturers();
        props.functions.getTypes(props);
      }
      asyncEffect();
    }, [])


    useEffect(() => {
      const asyncEffect = async () => {
        await props.functions.getRanges(props);
      }
      asyncEffect();
        
    }, [props.manufacturerId])

    useEffect(() => {
      const asyncEffect = async () => {
        await props.functions.getProducts(props);
      }
      asyncEffect();
    }, [props.rangeId])


    useEffect(() => {
        if (rangeData.length === 1) {
            setDisableRange(true);
        }
        else {
            setDisableRange(false);
        }
    }, [filters.manufacturerId])


    if (manufacturersLoading || loadingData || filtersLoading) return <UILIB.Loading type={3} />

    if (productId === 0  && (!props.dealProductId)) {
        return <Fragment>
            <div className="flex-container row">
                <h2 className="red col-xs-12 col-sm-6 col-md-6 mar-b15" >Product Selection</h2>
                <div className="col-xs-12 col-sm-6 col-md-6 mar-b15 flex-container end">
                  <UILIB.Button value="Cancel" className="red" onClick={props.onCancel}/>
                </div>
            </div> 
            <div className="flex-container row">
                <div className="col-xs-12 col-sm">
                    <UILIB.Select
                        name="manufacturers"
                        value={filters.manufacturerId}
                        data={filterData.manufacturers}
                        outerstle={{ width: "100%" }}
                        onChange={(event) => { changeFilter(event); props.setManufacturerId(event.target.value); props.setRangeId(0); setTypeId(0); }}
                    />
                </div>
                <div className="col-xs-12 col-sm">
                    <UILIB.Select
                        name="ranges"
                        placeholder={rangeData.length > 1 ? "" : "No Ranges"}
                        data={filters.manufacturerId === "all" ? rangeData : (rangeData.length > 1 ? rangeData : [{ label: "No Ranges", value: "none" }])}
                        value={filters.rangeId}
                        outerstyle={{ width: "100%" }}
                        onChange={(event) => { changeFilter(event); props.setRangeId(event.target.value); setTypeId(0); }}
                        disabled={filters.manufacturerId === "all"}
                    />
                </div>
                <div className="col-xs-12 col-sm">
                    <UILIB.Select
                        name="types"
                        data={filterData.types}
                        value={filters.typeId}
                        outerstyle={{ width: "100%" }}
                        onChange={changeFilter}
                        disabled={filters.manufacturerId === "all"}
                    />
                </div>
                <div className="col-xs-2 col-sm-2">
                    <UILIB.Button
                        name="apply"
                        value="Filter"
                        disabled={filters.manufacturerId === "all" || filters.rangeId === "all"}
                        onClick={() => getParts(expandResultsOptions)}
                    />
                </div>
            </div> 
            {(tableData && tableData.length > 0) && <UILIB.Paper className="mar-t20" style={{ height: "calc(100% - 120px)" }}>
              <div style={{ height: "100%", overflow: "auto" }}>
                <table width="100%" cellPadding="5" cellSpacing="0">
                    <tbody>
                        <tr>
                            <td style={{ fontSize: "12px" }}>
                                Part Number
                            </td>
                            <td style={{ fontSize: "12px" }}>
                                Manufacturer
                            </td>
                            <td style={{ fontSize: "12px" }}>
                                Default Supplier
                            </td>
                            <td style={{ fontSize: "12px" }}>
                                Product
                            </td>
                            <td style={{ fontSize: "12px" }}>
                                Select
                            </td>
                        </tr>

                        {filterData.types && filterData.types.map((row, index) => {
                            let errorStyle = {};
                            let tdStyle = {
                                borderBottom: "1px solid #DDDDDD"
                            };
                            return <Fragment key={index}>
                                {tableData.length > 0 && tableData.filter(f => Number(f.SalesPartTypeId) === Number(row.value)).length > 0 &&
                                
                                    <tr>
                                        <td colSpan="6" style={{ paddingBottom: "15px" }}>
                                            <h4 style={{ margin: "0", padding: "0" }}>{row.label}</h4>
                                        </td>
                                    </tr>}
                                    
                                {tableData.length > 0 && tableData.filter(f => Number(f.SalesPartTypeId) === Number(row.value) && !f.partNumber.toLowerCase().includes('settlement')).map((r) => {
                                    const manufacturer = filterData.manufacturers.find(mf => mf.value === r.SalesPartManufacturerId);
                                    const supplier = filterData.suppliers.find(mf => mf.value === r.SalesPartSupplierId);
                                    
                                    return <tr>
                                        <td width="10%" style={{ width: "10px", ...tdStyle, ...errorStyle }} valign="top"><a onClick={() => setProductId(r.id)}><div className="text-14">{r.partNumber}</div></a></td>
                                        <td width="10%" style={{ width: "10px", ...tdStyle, ...errorStyle }} valign="top">{manufacturer ? <div className="text-14">{manufacturer.label}</div> : <div></div>}</td>
                                        <td width="10%" style={{ ...tdStyle, ...errorStyle }} valign="top">{supplier ? <div className="text-14">{supplier.label}</div> : <div></div>}</td>
                                        <td width="65%" style={{ ...tdStyle, ...errorStyle }} valign="top">
                                            <a onClick={() => { setProductId(r.id) }}><div className="text-14">{r.name}</div></a>
                                            <div className="text-small mar-b5 text-12" style={{ ...errorStyle, maxHeight: "100px", overflow: "auto" }}>{r.description}</div>
                                        </td>
                                        <td width="5%" style={{ ...tdStyle, ...errorStyle }} valign="center">
                                            <UILIB.Button
                                                className={"small"}
                                                style={{ width: "100%" }}
                                                disabled={!props.rangeId || props.rangeId === 0}
                                                value={"Select"}
                                                onClick={() => { setProductId(r.id) }}
                                            />
                                        </td>
                                    </tr>
                                })}
                            </Fragment>
                        })}
                    </tbody>
                </table>
              </div>
            </UILIB.Paper>
            }
        </Fragment>
    }

    let prodDetails = {
        productId: 0,
        product: {}
    }

    if (!props.dealProductId && props.products && props.products.length) {
      prodDetails.productId = productId
      prodDetails.product = props.products.find(p => Number(p.id) === Number(productId))
      props.setSelectedProduct(prodDetails.product);
    }
    else {
      prodDetails.productId = productId
      prodDetails.product = props.products.find(p => Number(p.id) === Number(productId))
    }


    if (!props.selectedProduct || (!props.rangeId || Number(props.rangeId) === 0)) return <UILIB.Loading type={3} />

    return <Modules.ProductSelected
        onFinish={props.onFinish}
        onCancel={props.onCancel}
        productId={prodDetails.productId || productId}
        product={prodDetails.product}
        productDataRaw={props.productDataRaw}  
        setProductDataRaw ={props.setProductDataRaw} 
        partTypesConfigurator={props.types}
        changeProduct={() => { setProductId(0) }}
        ourDeal={props.ourDeal}
        setOurDeal={props.setOurDeal}
        hostConfig={props.hostConfig}
        deviceNumberObj={props.deviceNumberObj}
        setDeviceNumberObj={props.setDeviceNumberObj}
        portalProductId={props.portalProductId}
        dealProductId={props.dealProductId}
        selectedProduct={props.selectedProduct}
        setSelectedProduct={props.setSelectedProduct}
        compatibles={props.compatibles}
        setCompatibles={props.setCompatibles}
        compatiblesLoaded={props.compatiblesLoaded} 
        setCompatiblesLoaded={props.setCompatiblesLoaded}
        setProductsLoading={props.setProductsLoading}
        setProducts={props.setProducts}
        productsLoading={props.productsLoading}
        saving={props.saving}
        setSaving={props.setSaving}
        saveError={props.saveError}
        partTypes={props.partTypes}
        setPartTypes={props.setPartTypes}
        pageErrors={props.pageErrors}
        setPageErrors={props.setPageErrors}
        manufacturerId={props.manufacturerId}
        setManufacturerId={props.setManufacturerId}
        refurbsLoading={props.refurbsLoading}
        setRefurbsLoading={props.setRefurbsLoading}
        refurbs={props.refurbs}
        setRefurbs={props.setRefurbs}
        ranges={props.ranges}
        setRanges={props.setRanges}
        rangesLoading={props.rangesLoading}
        setRangesLoading={props.setRangesLoading}
        types={props.types}
        setTypes={props.setTypes}
        functions={props.functions}
        options={props.options}
        finishedLoading={props.finishedLoading}
        setFinishedLoading={props.setFinishedLoading}
    />

}