import { useState, useEffect, memo, Fragment } from 'react';
import { statCard } from 'classes/statsCard'
import { getRegions } from "classes/helpers";
import { permissionsObject } from 'classes/permissions';
import { setPagePreferences } from "store/actions";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import UILIB from 'components';
import TonerLookupTable from './tonerLookup/tonerLookup';
import TonerProcessedTable from './tonerLookup/tonerProcessed';


const ConsumablesHub = memo((props) => {

  const tabs = [
    { label: "Inclusive Requests", value: 0, context: 'inclusive' },
    { label: "Chargeable Requests", value: 1, context: 'chargable' },
    { label: "On Stop", value: 2, context: 'onStop' },
    { label: "Processed", value: 3, context: 'processed' }];

  const location = useLocation();
  const dispatch = useDispatch();
  const preferences = useSelector((state) => state.pagePreferences);
  const account = useSelector((state) => state.account);
  const permissions = useSelector((state) => state.permissions);
  const history = useHistory();
  const [regions, setRegions] = useState([]);
  const [topStats, setTopStats] = useState({ openRequests: 0, processedToday: 0, recievedToday: 0, over3Days: 0, urgentOrders: 0 });
  const [currentTab, setCurrentTab] = useState((preferences.consumablesHub.tab) ? preferences.consumablesHub.tab : 0);
  const statContainerClass = 'cardGeneric';
  const statChildClass = { top: 'cardGeneric cardChild', bottom: 'cardGeneric cardChild' };;

  useEffect(() => {
    const actionAsync = async () => {
      await props.permissions.generalAccess();
      const regionsResult = await getRegions();
      const regionsMapped = regionsResult.map(region => { return { label: region.name, value: region.id } })
      setRegions(regionsMapped);
    }
    actionAsync();
  }, [])

  const actionCurrentTab = async (tabId) => {
    dispatch(setPagePreferences({ ...preferences, consumablesHub: { ...preferences.consumablesHub, ...{ tab: tabId } } }));
    setCurrentTab(tabId)
  }

  return <Fragment>
    <div className="flex-container row center width-100 pad-l5 pad-r5">
      <div className="flex-item row width-100 mar-t5 mar-b10">
        {statCard(false, false, false, 'Open Requests', topStats.openRequests, statContainerClass, statChildClass)}
        {statCard(false, false, false, 'Processed Today', topStats.processedToday, statContainerClass, statChildClass)}
        {statCard(false, false, false, 'Recieved Today', topStats.recievedToday, statContainerClass, statChildClass)}
        {statCard(false, false, false, 'Orders Over 3 Days', topStats.over3Days, statContainerClass + ' colour background-2 purple', statChildClass)}
        {statCard(false, false, false, 'Urgent Orders', topStats.urgentOrders, statContainerClass + ' colour background-4 limeGreen', statChildClass)}
        {statCard(false, false, false, 'Pending Site Stock', 'Phase 2', statContainerClass + ' colour background-2 grey', statChildClass)}
      </div>
      <div className="flex-item start width-100 wrap mar-b10">
        <UILIB.Button value="Failed Bot" onClick={() => history.push({ pathname: '/consumablesHub/failedBot' })} className="mar-r5 mar-l5 small green" />
        <UILIB.Button value="VO API Requests" onClick={() => history.push({ pathname: '/consumablesHub/sendToVantageOnline' })} className="mar-r5 mar-l5 small green" />
        <UILIB.Button value="Process Logs" onClick={() => history.push({ pathname: '/consumablesHub/tonerLogging' })} className="mar-r5 mar-l5 small green" />
        <UILIB.Button value="Lookup" onClick={() => history.push({ pathname: '/consumablesHub/consumablesLookup' })} className="mar-r5 mar-l5 small green" />
        <UILIB.Button value="Customer Site Stock" onClick={() => history.push({ pathname: '/consumablesHub/customerSiteStock' })} className="mar-r5 mar-l5  small green" />
        <UILIB.Button value="New Stock Requests" onClick={() => history.push({ pathname: '/consumablesHub/customerSiteStockRequests' })} className="mar-r5 mar-l5  small green" />
        <UILIB.Button value="Queued Orders" onClick={() => history.push({ pathname: '/consumablesHub/queuedOrders' })} className="mar-r5 mar-l5  small green" />
        <UILIB.Button value="Device Exceptions" onClick={() => history.push({ pathname: '/consumablesHub/deviceExceptions' })} className="mar-r15 mar-b15 small green" />
        <UILIB.Button value={<UILIB.Link href={`https://360.agilico.co.uk/serviceadmin/tonerlookup/tonerRequests.asp`} rel="noreferrer" target="_blank">360 Consumable Archive</UILIB.Link>} className="mar-r5 mar-l5 small green" />
      </div>
      <div className="flex-item center width-100 mar-t10 mar-b10">
        <UILIB.SubNav tabs={tabs} currentTab={currentTab} onClick={(newId) => actionCurrentTab(newId)} />
      </div>
      {[0, 1, 2].includes(currentTab) && <TonerLookupTable
        topStats={topStats}
        setTopStats={setTopStats}
        regions={regions}
        account={account}
        location={location}
        context={tabs[currentTab]}
        localPermissions={permissions}
        permissionsObject={permissionsObject}
        permissions={permissions} />}
      {currentTab === 3 && <TonerProcessedTable
        topStats={topStats}
        setTopStats={setTopStats}
        regions={regions}
        account={account}
        location={location}
        context={tabs[currentTab]}
        localPermissions={permissions}
        permissionsObject={permissionsObject}
        permissions={permissions} />}
    </div>
  </Fragment>
})
export default ConsumablesHub