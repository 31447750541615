import UILIB from 'components';
import Axios from 'classes/axios';
import 'react-toastify/dist/ReactToastify.css'
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import DocumentUpload from './documentUpload';
import Link from 'components/Link';
import moment from 'moment';

const headerData = [
    { label: "Document Name", value: "name", align: "left", type: 'string' },
    { label: "Document Type", value: "docType", align: "left", type: 'string' },
    { label: "Modified Date", value: "updatedAt", align: "left", type: 'string' },
]

export default function DocumentList(props) {

    const [mapped, setMapped] = useState([]);
    const exceptions = [400,404];
  
    const invalidConfig = 'The options selected are invalid, please try again or contact support';
    const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
    const exceptionError = 'There was an exception while saving this record, please reload or contact support';

    const getDocuments = async () => {
      try {
        const response = await Axios.get(`/documents/opportunityDocs/${props.ourDeal.dynamicsOpportunityNumber}`);
        if(exceptions.includes(response.status)) {
          if(response.status === 400) toast.error(invalidConfig);
          if(response.status === 404) toast.error(notFoundError);
        } else {
          props.setOurDeal({ ...props.ourDeal, document: response.data });
        }
      } catch (error) {
        console.log(error);
        toast.error(exceptionError);
      }
    }

    useEffect(() => { 
      if(!props.dataLoaded.document) return;
      const asyncEffect = async () => {
        const mapped = props.ourDeal.document.map(doc => {
          return { 
            name: <Link href={doc.Link}><div>{doc.EscapedName}</div></Link>, 
            docType: doc.Class, 
            updatedAt: moment(doc.LastModified).locale('en').add(1, 'hours').utc(true).format("DD-MM-YYYY HH:mm") 
          }
        })
        setMapped(mapped);
      }
      asyncEffect();
    }, [props.ourDeal, props.dataLoaded.document])


    return <UILIB.Paper style={{ height: "100%" }}>
        <DocumentUpload 
          className={"mar-b20"} 
          isActive={props.isActive} 
          ourDeal={props.ourDeal} 
          dataLoaded={props.dataLoaded}
          onUpload={getDocuments} 
        />
        <UILIB.Table
            className="small" 
            loading={!props.dataLoaded.document}
            header={headerData}
            data={mapped}
            sortable={false}
            filter={false}
            maxRows={30}
            defaultOrderBy=""
            defaultOrderDir=""
        />
    </UILIB.Paper>
}