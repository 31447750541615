import { useState, Fragment } from 'react';
import UILIB from 'components';
import Axios from 'classes/axios';
import { checkFormErrors } from 'classes/formErrors/formErrors';
import { getDeclineDealErrors } from 'classes/formErrors/repository';
import { createMultiSelectInput,  createTextArea } from 'classes/controls/formControls';


export default function OrderDeclineDrawer({ ourDeal, history, onCancel }) 
{

  const options = [
    ...ourDeal.declineType.map(x => { return { label: x.name, value: Number(x.id) } })];

  const getEmptyFormObject = { select: [-1], notes: '' };
  const defaultError = { error: false, message: '' };
  const [bannerError, setBannerError] = useState(defaultError);
  const [formData, setFormData] = useState(getEmptyFormObject);
  const [formErrors, setFormErrors] = useState(getEmptyFormObject);
  const [saving, setSaving] = useState(false);
  const exceptions = [400,404,409];

  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while saving this record, please reload or contact support';
  const versionError = 'A newer version of this record has already been saved. Please reload the data';
  const heading = 'You are about to decline this deal';


  const actionUpdatedSelected = async (ev) => { 
    setFormData({ ...formData, [ev.target.name]: ev.target.value });
  }

  const actionDeclineOrder = async () => {
    try { 
      setSaving(true);
      setBannerError(defaultError);
      setFormErrors(getEmptyFormObject);
      const formResult = await checkFormErrors(getDeclineDealErrors(formData), setFormErrors, null, null, formData, null);
      if(formResult.errorCount === 0) {
        const result = await Axios.put(`/entities/workflow/deals/actionDeclineDeal/${ourDeal.dealID}`, { data: ourDeal, declineData: formData }); 
        if(exceptions.includes(result.status)) {
          if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
          if(result.status === 404) setBannerError({ error: true, message: notFoundError });
          if(result.status === 409) setBannerError({ error: true, message: versionError });
        } else {
          history.push('/sopHub');
          window.location.reload();
        }
      }
    } catch (err) {
      console.log(err);
      setBannerError({ error: true, message: exceptionError });
    } finally {
      setSaving(false);
    }
  }


  return <Fragment>
    <div className="flex-container row width-100 wrap">
      <div className="flex-item center align-center font-weight-bold text-18 mar-b10 width-100">{heading}</div>
      <div className="flex-container column start width-100 pad-5">
        <div className="flex-container row width-100">
          {createMultiSelectInput('Decline Reason', 'select', saving, formData.select, options, null, (ev) => actionUpdatedSelected(ev), formErrors, 'sopHub sopDrawer-options-right', null, null, 'fullBorder border-colour-grey-4')}
        </div>
        <div className="flex-container row width-100">
          {createTextArea('Notes', 'notes', saving, formData.notes, (ev) => actionUpdatedSelected(ev), formErrors, 'flex-grow-1', null, null, 'height-100-px')}
        </div>
      </div>
      <div className='flex-container row start width-100 mar-t20 mar-r5'>
        <UILIB.Button className='red' disabled={saving} value='Cancel' onClick={() => onCancel()} />
        <UILIB.Button className='green' disabled={saving || Number(formData.select) === -1} loading={saving} value='Decline' onClick={async () => await actionDeclineOrder()} />
        {bannerError.error && <div className="flex-container start wrap width-100">
          <div className="errored message">{bannerError.message}</div>
        </div>}
      </div>
    </div>
  </Fragment>
}