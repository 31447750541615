import { memo } from 'react';

const TextArea = memo((
{ 
  outerclassname = '', 
  className = '',
  value = undefined, 
  placeholder, 
  errored = { messageText: "", option: 0 }, 
  onChange, 
  disabled, 
  style, 
  name, 
  outerstyle 
}) =>
{

    const errorObject = (typeof(errored) === 'object') ? errored : undefined;
    const errorClass = (errorObject && errorObject?.error && errorObject?.option === 0)
      ? "errored border" 
      : "";

    const handleOnChange = async (ev) => {
      await onChange(ev);
    };

    return <div className={outerclassname} style={outerstyle}>
        <textarea 
          className={`textInput textArea fullBorder overflow-auto pad-3 ${className} ${errorClass}`} 
          placeholder={placeholder} 
          onChange={handleOnChange} 
          disabled={disabled} 
          value={value} 
          style={(style) ? style : {}} 
          name={name} >
        </textarea>
        {(errorObject && errorObject.error && errorObject.option === 1) && <div className="errored message">{(errorObject) && errorObject.messageText}</div>}
    </div>

  })
  export default TextArea