import { Fragment } from 'react'
import UILIB from 'components';
import { store } from 'store/store';
import Drawer from '../Drawers';
import { useDispatch } from 'react-redux';
import { setDrawer } from "store/actions";


export default function CopyOrder(props) 
{

  const dispatch = useDispatch();
  const state = store.getState();
  const account = state.account;

  const copyOrder = () => {
    dispatch(setDrawer({
      open: true,
      width: "1000px",
      content: <Drawer.OrderCopyDrawer
        onFinished={() => dispatch(setDrawer({ open: false, content: null }))}
        account={account}
        ourDeal={props.dealData}
        setOurDeal={props.setDealData}
        isActive={props.isActive}
      />
    }));
  }

  return <Fragment>
    <div className="flex-item width-100">
      <UILIB.Button
        disabled={!props.isActive}
        className="width-100"
        value="Clone Previous Order"
        title='Clones non customer specific data including parts and pricing from a previous order'
        onClick={() => copyOrder()} />
    </div>
  </Fragment>
}