import { useState, Fragment } from 'react';
import UILIB from 'components';
import Axios from 'classes/axios';
import moment from "moment";
import { downloadFileHandler } from 'classes/download';


export default function ExportProducts(props) {

  const defaultBanner = { error: false, message: '' };
  const [bannerError, setBannerError] = useState({ error: false, message: '' });
  const [saving, setSaving] = useState(false);
  const exceptions = [400,404,204];

  const noContent = 'The options selected returned no results, please try again';
  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while fetching this data, please reload or contact support';

  const handleGenerateCSV = async () => {

    setSaving(true);
    setBannerError(defaultBanner);

    try { 
      const response = await Axios.get(`/entities/workflow/deals/actionGetProductExport/${props.ourDeal.dealID}`);
      if(exceptions.includes(response.status)) {
        if(response.status === 204) setBannerError({ error: true, message: noContent });
        if(response.status === 400) setBannerError({ error: true, message: invalidConfig });
        if(response.status === 404) setBannerError({ error: true, message: notFoundError });
      } else {
        setBannerError(defaultBanner);
        downloadFileHandler(`${props.ourDeal.dealID}_Products_${moment().utc().format('YYYYMMDD_HHMMss')}`, 'csv', response.data.result);
      }
    } catch (err) {
      console.log(err);
      setBannerError({ error: true, message: exceptionError });
    }

    setSaving(false);
  }


  return <Fragment>         
    <UILIB.Button 
      className="button small secondary" 
      name="Export" 
      value="Export" 
      loading={saving} 
      disabled={saving} 
      errored={bannerError} 
      onClick={async () => await handleGenerateCSV()} 
    />
    {bannerError.error && <div className="flex-container row start">
      <div className="errored message">{bannerError.message}</div>
    </div>}
  </Fragment>   
}
