import { useState, Fragment } from 'react';
import { useSelector } from 'react-redux'
import UILIB from 'components';
import Axios from 'classes/axios';
import moment from 'moment';


export default function OnSnoozeClicked({ 
  entityData,
  groupCheckbox,
  renderRemote,
  setRenderRemote,
  setSnoozeToDate, 
  resetSnooze, 
  snoozeToDate, 
  snoozeButtonClassName
}) {

  const account = useSelector((state) => state.account);
  const [saving, setSaving] = useState(false);
  const defaultError = { error: false, message: '' };
  const [bannerError, setBannerError] = useState(defaultError);
  const exceptions = [400,404];

  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while saving this record, please reload or contact support';

  const onSnoozeConfirmed = async () => {

    try {
      setSaving(true);
      
      const checked = Array.from(new Set(Object.keys(groupCheckbox.current).filter(x => groupCheckbox.current[x]))).map(x => Number(x));
      const filtered = entityData.current.result.filter(x => checked.includes(Number(x.tonerID)));
      const notes = { data: [] };

      for (let item of filtered) {
  
        const dateNow = moment().utc(true).format("YYYY-MM-DD HH:mm:ss");
    
        const snoozeBase = {
            isSnoozed: 1,
            snoozeType: 1,
            daysLeft: moment(snoozeToDate).diff(moment().subtract(10, 'minutes'), 'days'),
            snoozeBotLastCheck: dateNow,
            snoozeUntilDate: snoozeToDate,
            snoozedBy: account.azureId,
            isSnoozedQueued: 1
        };
    
        const notesText = `Record Snoozed until date: ${snoozeToDate}`;
    
        const found = filtered.findIndex(x => Number(x.tonerID) === Number(item.tonerID));
    
        filtered[found] = { ...item, ...snoozeBase };
        notes.data.push({ tonerID: item.tonerID, notes: notesText });
    
        filtered.forEach(x => delete x.notes);
    }
      
      const result = await Axios.put(`/entities/workflow/actionSetSnoozed`, { toner: filtered, notes: notes });
      if(exceptions.includes(result.status)) {
        if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
        if(result.status === 404) setBannerError({ error: true, message: notFoundError });
      } else {
        Object.keys(groupCheckbox.current).forEach(x => groupCheckbox.current[x] = true);
        setRenderRemote(!renderRemote);
        resetSnooze();
      }

      setSaving(false);
    
    } catch (err) {
      console.log(err);
      setSaving(false);
      setBannerError({ error: true, message: exceptionError });
    }
  }

  const setSnoozeByDuration = (count, period) => {
    setSnoozeToDate(moment().add(count, period).format("YYYY-MM-DD HH:mm:ss"));
  };

  return <Fragment>
    <div className='modalBackground'>
      <div className='modalContent'>
        <div className='text-xlarge text-center mar-b15'>Snooze Options</div>

        <div className='mar-b15 flex-container align-center gap-10'>
          <label className='flex-item no-wrap mar-r10'>Select date to snooze until</label>
          <UILIB.TextInput 
            type='datetime-local' 
            className='textInput input min-width-180' 
            value={snoozeToDate} 
            onChange={(e) => {
              const inputValue = e.target.value;
              setSnoozeToDate(inputValue ? moment(inputValue).format("YYYY-MM-DD HH:mm:ss") : '');
            }} 
          />
        </div>

        <div className='flex-container evenly mar-b15'>
          <UILIB.Button 
            className="button primary" 
            value='1 Day'
            onClick={() => setSnoozeByDuration(1, 'days')}
          />
          <UILIB.Button 
            className="button primary" 
            value='1 Week'
            onClick={() => setSnoozeByDuration(1, 'weeks')}
          />
          <UILIB.Button 
            className="button primary" 
            value='2 Weeks'
            onClick={() => setSnoozeByDuration(2, 'weeks')}
          />
          <UILIB.Button 
            className="button primary" 
            value='1 Month' 
            onClick={() => setSnoozeByDuration(1, 'months')}
          />
        </div>

        <div className='flex-container end'>
          <UILIB.Button 
            loading={saving} 
            disabled={saving} 
            className={snoozeButtonClassName} 
            value='Snooze' 
            onClick={async () => await onSnoozeConfirmed()} 
          />
          <UILIB.Button 
            disabled={saving} 
            className='flex-item evenly grey mar-l5' 
            value='Cancel' 
            onClick={() => resetSnooze()} 
          />
        </div>

        {bannerError.error && (
          <div className="flex-container end width-100">
            <div className="errored message">{bannerError.message}</div>
          </div>
        )}
      </div>
    </div>
  </Fragment>
}