import { useState, useEffect, useRef, Fragment, memo } from 'react';
import { BsGearFill } from "react-icons/bs";
import classNames from "classnames";

const MultiSelect = memo((
{ 
  name = '', 
  data = [], 
  widget = { enabled: true, rotation: 90, Component: <BsGearFill size={18} /> },
  clickTitle = { title: 'Select Action', class: undefined }, 
  onClick = () => { }, 
  disabled = false, 
  style = { }, 
  outerclassname = '', 
  className = '',
  errored = { messageText: "", option: 0 }
}) => 
{

  const node = useRef();
  const selectNode  = useRef();
  const [nodeWidth, setNodeWidth] = useState(0);
  const [DropDownOpen, setDropDownOpen] = useState(false);

  const handleClick = e => {
    if (selectNode.current.contains(e.target)) return;
    return setDropDownOpen(false);
  };

  useEffect(() => {     
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [])

  useEffect(() => {
    setNodeWidth((node.current) ? node.current?.getBoundingClientRect()?.width : 0);
  }, [DropDownOpen])

  let outerClassNameF = "align-center customSelect noBorder"
  if (className) className += " " + className;
  if (errored) className += " errored";
  if (outerclassname) outerClassNameF += " " + outerclassname;


  if (errored?.error && errored?.option === 0) {
      style.border = "1px solid rgba(217, 30, 24, 0.8)";
      style.borderRadius = "5px";
      style.transition = "border-color 2s";
  }


  return <Fragment>
    <div id={name} ref={selectNode} className={outerClassNameF}>
      <div onClick={() => !disabled && setDropDownOpen(!DropDownOpen)} className={`customSelect fakeSelect flex-item`} style={style}>
        <div 
          ref={node} 
          className={`${(clickTitle.class) ? clickTitle.class : 'content colour text grey clickable'}`}
        >
          {clickTitle.title}
        </div>
        <div 
          style={{ 
            transform: `rotate(${DropDownOpen ? widget.rotation : 0}deg)`,
            transition: "all 0.6s" }}
          className="fakeSelectIcon flex-container center align-center">
            {widget.enabled && widget.Component}
        </div>
      </div>
      <div 
          style={{ width: nodeWidth }} 
          className={classNames(
            "fakeSelect fakeSelectDropDown",
            { active: (DropDownOpen) },
            "flex-container row flex-grow-1"
          )}
        >
        {data.map((opt, index) => {
          return <div onClick={(ev) => onClick(ev)} className="flex-item start clickable" key={index}>{opt}</div> })}
      </div>
      {errored?.error && errored?.option === 1 && <div className="errored message">{errored.messageText}</div>}
    </div>
  </Fragment>
})
export default MultiSelect
