import { Fragment } from 'react'
import UILIB from 'components';


export default function SubmitDeal(props) 
{

  return <Fragment>
    <div className="flex-item width-100">
      <UILIB.Button
        disabled={!props.isActive || Object.values(props.dataLoaded).some(x => !Boolean(x))}
        className="width-100"
        value={`Submit`}
        onClick={async () => await props.handleCheckErrors(true)} />
    </div>
  </Fragment>
}