
import { memo, Fragment } from 'react';
import UILIB from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";


const InputContainer = memo(({ data, field, placeholder = '', disabled = false, onChange = () => { } }) => 
{

  const inputClass = 'textInput fullBorder tableFilter tableInput mar-b0 ';
  const inputClassAlert = 'textInput tableFilter tableInput mar-b0 borderLeft borderTop borderBottom';

  const fontAwesome = { data: null };

  const generateQuantity = () => {
    const qty = [];
    for(let i = 0; i < 11; i++) {
      qty.push({ value: i, label: i });
    }
    return qty;
  }

  const pending = (Number(data[`pending_${field}`]) > 0 )
    ? { count: Number(data[`pending_${field}`]), message: `${Number(data[`pending_${field}`])} Pending Request(s)` } 
    : { count: 0, message: `No Pending Request(s)` };

  if(pending.count > 0) {
    library.add(fas);
    fontAwesome.data = <FontAwesomeIcon title={pending.message} icon="fa-solid fa-triangle-exclamation" beat style={{color: "#a3b4c7",}} /> };

  const generateInput = (data, fieldName, inputClass, outerClass, defaultValue) => {
    return <UILIB.Select 
      className={inputClass}
      outerclassname={outerClass}
      placeholder={defaultValue} 
      name={fieldName} 
      data={generateQuantity()} 
      value={(data[fieldName]) ? Number(data[fieldName]) : 0} 
      disabled={disabled}
      onChange={(ev) => handleUserInput(ev, data)} />
  };

  const handleUserInput = (event, row) => {
    row[event.target.name] = event.target.value;
    onChange(event, row);
  };

  return <Fragment>
      <div className="flex-container row flex width-100">
        {pending.count === 0 && <div className="flex-item start width-100">{generateInput(data, field, inputClass, 'width-100', placeholder)}</div>}
        {pending.count > 0 && <div className="flex-container row around nowrap width-100">
          <div className="flex-item start width-55">{generateInput(data, field, inputClassAlert, 'flex-item start align-center width-100', placeholder)}</div>
          <div className="flex-item start width-45 align-center center colour height-100 background-4 red borderRight borderTop borderBottom">{fontAwesome.data}</div>
        </div>}
      </div>
    </Fragment>
})
export default InputContainer
