import { useState, useRef, useEffect, Fragment } from 'react';
import UILIB from 'components';
import Axios from 'classes/axios';
import moment from "moment";
import { getLogisticsSelect, getComsPaidSelect } from 'classes/helpers';
import { createStaticField, createSelectInput, createTextInput, createNumberInput, createDateInput, createButton } from 'classes/controls/formControls';


export default function EditSop({ deal, sync, onFinished }) {

  const sopName = useRef('');
  const [formData, setFormData] = useState({ ...deal, sopOwnerCurrent: deal.activeDirectoryObjectGuidSopOwner });
  const defaultError = { error: false, message: '' };
  const defaultFormErrors = { syncInvNo: defaultError, syncInvDate: defaultError };
  const [bannerError, setBannerError] = useState(defaultError);
  const [formErrors, setFormErrors] = useState(defaultFormErrors);
  const [loading, setLoading] = useState(true);
  const [syncing, setSyncing] = useState({ syncInvNo: false, syncInvDate: false });
  const [saving, setSaving] = useState(false);
  const exceptions = [204,400,404,409];

  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const noContent = 'There was no data available in VO to Sync';
  const versionError = 'A newer version of this record has already been saved. Please reload the data';
  const exceptionError = 'There was an exception while saving this record, please reload or contact support';


  const actionUpdateDeal = (event) => {
    console.log(moment(event.target.value).utc(true).format('YYYY-MM-DD')) 
    console.log(moment(event.target.value).format('YYYY-MM-DD')) 
    if(event.target.name === 'activeDirectoryObjectGuidSopOwner') {
      const found = formData.sopTeamMembers.find(x => x.value === event.target.value)
      sopName.current = (found) ? found.label : ''; }
    setFormData({  ...formData, [event.target.name]: event.target.value });
  };

  const actionSyncVO = async (event, source, target) => {
    try {
      setSyncing({ ...syncing, [event.target.name]: true });
      const result = await Axios.get(`/entities/workflow/deals/actionGetInvoiceVO/${deal.finalDealNo}`); 
      if(exceptions.includes(result.status)) {
        if(result.status === 400) setFormErrors({ ...defaultFormErrors, [event.target.name]: { error: true, message: invalidConfig } });
        if(result.status === 404) setFormErrors({ ...defaultFormErrors, [event.target.name]: { error: true, message: notFoundError } });
        if(result.status === 204) setFormErrors({ ...defaultFormErrors, [event.target.name]: { error: true, message: noContent } });
        if(result.status === 409) setFormErrors({ ...defaultFormErrors, [event.target.name]: { error: true, message: versionError } });
      } else {
        setFormErrors({ ...formErrors, [event.target.name]: defaultError });
        setFormData({  ...formData, [target]: result.data.result[source] });
      }
    } catch (error) {
      console.log(error);
      setFormErrors({ ...defaultFormErrors, [event.target.name]: { error: true, message: exceptionError } });
    }
    setSyncing({ ...syncing, [event.target.name]: false });
  };
  

  const actionSaveForm = async () => {
    setSaving(true);
    setBannerError(defaultError);
    try { 
      const result = await Axios.put(`/entities/workflow/deals/actionSaveSopHubDrawer/${deal.dealID}`, { formData: formData }); 
      if(exceptions.includes(result.status)) {
        if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
        if(result.status === 404) setBannerError({ error: true, message: notFoundError });
        if(result.status === 409) setBannerError({ error: true, message: versionError });
      } else {
        Object.keys(result.data.result).forEach(x => deal[x] = result.data.result[x]);
        const found = formData.sopTeamMembers.find(x => x.value === result?.data?.result?.activeDirectoryObjectGuidSopOwner);
        if(found) deal.sopOwnerFullName = found.label;
        sync.set(!sync.get);
        onFinished();
        setSaving(false);
      }
    } catch (err) {
      console.log(err);
      setSaving(false);
      setBannerError({ error: true, message: exceptionError });
    }
    setSaving(false);
  }

  useEffect(() => {
    const actionAsync = async () => {
      setLoading(true);
      const sopUsersResult = await Axios.get(`/helpers/getSopOwners`);
      const sopUsersMapped = sopUsersResult.data.map(r => { 
        return { value: String(r.activeDirectoryObjectGuid).toLocaleLowerCase(), label: r.fullName } });
      setFormData({ ...formData, sopTeamMembers: formData.sopTeamMembers = [{ label: "Please Select", value: "NULL" }, ...sopUsersMapped] })
      setLoading(false);
    }
    actionAsync();
  }, [])
  
  return <Fragment>
    <div className="flex-container row width-100 wrap">
      <div className="flex-item center align-center font-weight-bold text-16 mar-t20 width-100">SOP Hub Drawer</div>
      <div className="flex-container column start width-100 pad-5">
        <div className="flex-container row width-100">
          <b className="flex-item start sopHub sopDrawer-options-left center text-13">Order #</b>
          {createStaticField('', deal.finalDealNo, 'sopHub sopDrawer-options-right', null, null, null)}
        </div>
        <div className="flex-container row width-100">
          <b className="flex-item start sopHub sopDrawer-options-left center text-13">Approved By</b>
          {createStaticField('', deal.approvedByTxt, 'sopHub sopDrawer-options-right', null, null, null)}
        </div>
        <div className="flex-container row width-100">
          <b className="flex-item sopHub sopDrawer-options-left center text-13">Logistics Status</b>
          {createSelectInput('', 'engStatus', false, formData.engStatus, getLogisticsSelect(), (ev) =>  actionUpdateDeal(ev), null, 'sopHub sopDrawer-options-right', null, null, 'fullBorder border-colour-grey-4')}
        </div>
        <div className="flex-container row width-100">
          <b className="flex-item sopHub sopDrawer-options-left center text-13">SOP Owner</b>
          {createSelectInput('', 'activeDirectoryObjectGuidSopOwner', loading, String(formData.activeDirectoryObjectGuidSopOwner).toLocaleLowerCase(), formData.sopTeamMembers, (ev) =>  actionUpdateDeal(ev), null, 'sopHub sopDrawer-options-right', null, null, 'fullBorder border-colour-grey-4')}
        </div>
        <div className="flex-container row width-100">
          <b className="flex-item sopHub sopDrawer-options-left center text-13">Booked Date</b>
          {createDateInput('', 'bookedDate', false, moment(formData.bookedDate).utc(true).format('YYYY-MM-DD'), (ev) =>  actionUpdateDeal(ev), null, 'sopHub sopDrawer-options-right', null, null, 'fullBorder border-colour-grey-4')}
        </div>
      </div>
      <div className="flex-item center align-center font-weight-bold text-16 mar-t20 width-100">Invoicing</div>
      <p className="mar-b5 "><i>Pressing Sync will attempt to fetch the given value from Vantage Online.</i></p>
      <div className="flex-container column start width-100 pad-5">
        <div className="flex-container row width-100">
          <b className="flex-item sopHub sopDrawer-options-left center text-13">Invoice No</b>
          <div className="flex-item row sopHub sopDrawer-options-right pad-r10">
            {createTextInput('', 'invoiceNo', false, formData.invoiceNo, (ev) => actionUpdateDeal(ev), null, 'sopHub sopDrawer-options-innerControl', null, null, 'fullBorder border-colour-grey-4')}
            {createButton('', 'syncInvNo', 'Sync', (syncing.syncInvNo || saving), syncing.syncInvNo, async (ev) => await actionSyncVO(ev, 'Reference', 'invoiceNo'), null, 'sopHub sopDrawer-options-innerButton', null, null, null, 'Attempt to Sync with VO')}
        </div>
        {formErrors.syncInvNo.error && <div className="flex-container end width-100 pad-r5">
          <div className="errored message">{formErrors.syncInvNo.message}</div>
        </div>}
        </div>
        <div className="flex-container row width-100">
          <b className="flex-item sopHub sopDrawer-options-left center text-13">Invoice Date</b>
          <div className="flex-item row sopHub sopDrawer-options-right pad-r10">
            {createDateInput('', 'invoiceDate', false, moment(formData.invoiceDate).utc(true).format('YYYY-MM-DD'), (ev) =>  actionUpdateDeal(ev), null, 'sopHub sopDrawer-options-innerControl', null, null, 'fullBorder border-colour-grey-4')}
            {createButton('', 'syncInvDate', 'Sync', (syncing.syncInvDate || saving), syncing.syncInvDate, async (ev) => await actionSyncVO(ev, 'InvoiceDate', 'invoiceDate'), null, 'sopHub sopDrawer-options-innerButton', null, null, null, 'Attempt to Sync with VO')}
          </div>
        </div>
        {formErrors.syncInvDate.error && <div className="flex-container end width-100 pad-r5">
          <div className="errored message">{formErrors.syncInvDate.message}</div>
        </div>}
        <div className="flex-container row width-100">
          <b className="flex-item sopHub sopDrawer-options-left center text-13">Date Paid</b>
          {createDateInput('', 'datePaid', false, moment(formData.datePaid).utc(true).format('YYYY-MM-DD'), (ev) =>  actionUpdateDeal(ev), null, 'sopHub sopDrawer-options-right', null, null, 'fullBorder border-colour-grey-4')}
        </div>
        <div className="flex-container row width-100">
          <b className="flex-item sopHub sopDrawer-options-left center text-13">Comms Paid Date</b>
          {createDateInput('', 'comsPaid', false, moment(formData.comsPaid).utc(true).format('YYYY-MM-DD'), (ev) =>  actionUpdateDeal(ev), null, 'sopHub sopDrawer-options-right', null, null, 'fullBorder border-colour-grey-4')}
        </div>
        <div className="flex-container row width-100">
          <b className="flex-item sopHub sopDrawer-options-left center text-13">Comms Paid</b>
          {createSelectInput('', 'b_comsPaid', false, formData.b_comsPaid, getComsPaidSelect(), (ev) =>  actionUpdateDeal(ev), null, 'sopHub sopDrawer-options-right', null, null, 'fullBorder border-colour-grey-4')}
        </div>
        <div className="flex-container row width-100">
          <b className="flex-item sopHub sopDrawer-options-left center text-13">3rd Party Install</b>
          {createNumberInput('', 'thirdPartyInstall', false, formData.thirdPartyInstall, (ev) => actionUpdateDeal(ev), null, 'sopHub sopDrawer-options-right', null, null, 'fullBorder border-colour-grey-4')}
        </div>
      </div>
      <div className="flex-item center align-center font-weight-bold text-16 mar-t20 mar-b5 width-100">Purchase Orders</div>
      <div className="flex-container row start width-100 pad-l5 pad-r5">
        {createTextInput('PO', 'dmcPoNo', false, formData.dmcPoNo, (ev) => actionUpdateDeal(ev), null, 'sopHub sopDrawer-options-po', null, null, 'fullBorder border-colour-grey-4')}
        {createTextInput('Supplier', 'supplierOrderNo', false, formData.supplierOrderNo, (ev) => actionUpdateDeal(ev), null, 'sopHub sopDrawer-options-po', null, null, 'fullBorder border-colour-grey-4')}
        {createTextInput('ETA', 'finalETA', false, formData.finalETA, (ev) => actionUpdateDeal(ev), null, 'sopHub sopDrawer-options-po', null, null, 'fullBorder border-colour-grey-4')}
      </div>
      <div className="flex-container row start width-100 pad-l5 pad-r5">
        {createTextInput('', 'dmcPoNo2', false, formData.dmcPoNo2, (ev) => actionUpdateDeal(ev), null, 'sopHub sopDrawer-options-po', null, null, 'fullBorder border-colour-grey-4')}
        {createTextInput('', 'supplierOrderNo2', false, formData.supplierOrderNo2, (ev) => actionUpdateDeal(ev), null, 'sopHub sopDrawer-options-po', null, null, 'fullBorder border-colour-grey-4')}
        {createTextInput('', 'finalETA2', false, formData.finalETA2, (ev) => actionUpdateDeal(ev), null, 'sopHub sopDrawer-options-po', null, null, 'fullBorder border-colour-grey-4')}
      </div>
      <div className="flex-container row start width-100 pad-l5 pad-r5">
        {createTextInput('', 'dmcPoNo3', false, formData.dmcPoNo3, (ev) => actionUpdateDeal(ev), null, 'sopHub sopDrawer-options-po', null, null, 'fullBorder border-colour-grey-4')}
        {createTextInput('', 'supplierOrderNo3', false, formData.supplierOrderNo3, (ev) => actionUpdateDeal(ev), null, 'sopHub sopDrawer-options-po', null, null, 'fullBorder border-colour-grey-4')}
        {createTextInput('', 'finalETA3', false, formData.finalETA3, (ev) => actionUpdateDeal(ev), null, 'sopHub sopDrawer-options-po', null, null, 'fullBorder border-colour-grey-4')}
      </div>
      <div className="flex-container row start width-100 pad-l5 pad-r5">
        {createTextInput('', 'dmcPoNo4', false, formData.dmcPoNo4, (ev) => actionUpdateDeal(ev), null, 'sopHub sopDrawer-options-po', null, null, 'fullBorder border-colour-grey-4')}
        {createTextInput('', 'supplierOrderNo4', false, formData.supplierOrderNo4, (ev) => actionUpdateDeal(ev), null, 'sopHub sopDrawer-options-po', null, null, 'fullBorder border-colour-grey-4')}
        {createTextInput('', 'finalETA4', false, formData.finalETA4, (ev) => actionUpdateDeal(ev), null, 'sopHub sopDrawer-options-po', null, null, 'fullBorder border-colour-grey-4')}
      </div>
      <div className="flex-container row start width-100 pad-l5 pad-r5">
        {createTextInput('', 'dmcPoNo5', false, formData.dmcPoNo5, (ev) => actionUpdateDeal(ev), null, 'sopHub sopDrawer-options-po', null, null, 'fullBorder border-colour-grey-4')}
        {createTextInput('', 'supplierOrderNo5', false, formData.supplierOrderNo5, (ev) => actionUpdateDeal(ev), null, 'sopHub sopDrawer-options-po', null, null, 'fullBorder border-colour-grey-4')}
        {createTextInput('', 'finalETA5', false, formData.finalETA5, (ev) => actionUpdateDeal(ev), null, 'sopHub sopDrawer-options-po', null, null, 'fullBorder border-colour-grey-4')}
      </div>
      <div className='flex-container row end width-100 mar-t20 mar-r5'>
        <UILIB.Button className='red' disabled={saving} value='Cancel' onClick={() => onFinished()} />
        <UILIB.Button className='green' disabled={saving} loading={saving} value='Save' onClick={async () => await actionSaveForm()} />
      </div>
      {bannerError.error && <div className="flex-container salesHub submitDeal-button center">
        <div className="errored message">{bannerError.message}</div>
      </div>}
    </div>
  </Fragment>

}
