import { Fragment, useState, useRef } from 'react';
import Axios from 'classes/axios';
import UILIB from 'components';
import { useHistory } from 'react-router-dom';
import { getFormErrors, getEmptyError } from '../../../../classes/formErrors/formErrors';
import { getProcessVo } from '../../../../classes/formErrors/repository';
import { consumableChip, getDeliveryType, getSelectedYield, getPrimaryContact, handleSiteStockCustomerClicked } from '../functions';
import QuantityContainer from './quantityContainer';
import { getAgilicoStores } from "classes/helpers";

export default function RequestSendVO({ entityLookup, sendToVo, setSendToVo }) {
  const headerData = [
    { label: 'Quantity', value: 'quantity', type: 'string', align: 'left', filtering: false, minWidth: 60, maxWidth: 60 },
    { label: 'Customer', value: 'custName', type: 'string', filtering: true },
    { label: 'Serial', value: 'serialNumber', type: 'date', filtering: true },
    { label: 'Model', value: 'machineName', type: 'string', filtering: true },
    { label: 'Avg Volume (last 3 months)', value: 'avgVolume', type: 'number', filtering: true },
    { label: 'Consumable', value: 'tonerTypeCast', type: 'string', filtering: true },
    { label: 'Type', value: 'type', type: 'number', filtering: false, minWidth: 250, maxWidth: 250 }];


  const stockText = `By default, this order will be sent to VO - if you want to reduce the Customer's Site Stock 
                        please uncheck this box (no Sales order will be created in VO)`;
  const invalidOptions = 'Please correct any highlighted options and try again';
  const exceptionError = 'There was an exception our end while processing, Please check VO to make sure the order does not exist';
  const belowZeroError = 'Cannot take from stock as one or more values would drop below zero';
  const storeNotFoundError = 'Unable to find a Store for this device, please check Site Stores and add one if needed';
  const conflictError = 'There is already a pending or completed request for the selected record(s), Please visit the VO Requests area';
  const invalidSend = `Error sending to Vantage Online. There was a problem sending this order to Vantage Online. 
                        Relevant parties have been notified. You do not need to re-submit this request.`;
  const exceptions = [400,404,409,422];
  const defaultError = { error: false, message: '' };
  const tablePageDefaults = { paging: { pages: [10,20,50,100,200], pageSelected: 20, limit: 20, offset: 0, orderBy: 'tonerID', orderDir: 'DESC' } };
  const select = { value: -1, label: "Select" };
  const entityData = useRef({});
  const selectData = useRef([]);
  const [contactData, setContactData] = useState([]);
  const [storeData, setStoreData] = useState([]);
  const formErrors = useRef({});
  const history = useHistory();
  const [bannerError, setBannerError] = useState(defaultError);
  const [process, setProcess] = useState(false);
  const [zeroValue, setZeroValue] = useState(false);
  const [yieldData, setYieldData] = useState(null);
  const [localRender, setLocalRender] = useState(false);

  const handleChangeReduceStock = () => {
    Object.values(sendToVo.consumables).forEach(x => x.sendVo = !sendToVo.sendVo);
    sendToVo.sendVo = !sendToVo.sendVo;
    setLocalRender(!localRender);
  };

  const handleChangeCourier = (event) => {
    sendToVo.courier = event.target.value;
    setLocalRender(!localRender);
  };

  const handleChangeContact = (event) => {
    sendToVo.contact = entityData.current.contact.find(x => Number(x.Id) ===  Number(event.target.value));
    setLocalRender(!localRender);
  };

  const handleChangeStore = (event) => {
    sendToVo.store = entityData.current.stores.find(x => Number(x.Id) ===  Number(event.target.value));
    setLocalRender(!localRender);
  };

  const handleChangeGeneric = (event, tonerID, field) => {
    sendToVo = { ...sendToVo, ...sendToVo.consumables[tonerID][field] = event.target.value };
    setLocalRender(!localRender);
  };

  const handleChangeQuantity = () => {
    setZeroValue(Object.keys(sendToVo.consumables).some(x => Number(sendToVo.consumables[x].quantity) === 0))
    setLocalRender(!localRender);
  };

  const getYieldData = (data) => {
    const type = (data.volume > 5000) ? 'High' : 'Low'; 
    return {
      message: <div>The machine <b>{data.serial}</b> {`is currently averaging ${data.volume.toLocaleString()} copies per month. ${type} Yield Toners have been auto-selected below where available.`}</div>,
      class: `colour background ${(data.volume > 5000) ? 'darkGreen' : 'darkPink'}`
    }
  };

  const actionProcessData = async () => { 
    setProcess(true);
    try {
      setBannerError(defaultError);
      const formResult = await getFormErrors(getProcessVo(formErrors, sendToVo), null, setLocalRender(!localRender));
      if(formResult.errorCount > 0) {
        setBannerError({ error: true, message: invalidOptions });
      } else {
        const result = await Axios.put(`/entities/workflow/requestSendToVO/${sendToVo.equipmentId}/0`, sendToVo);
        if(exceptions.includes(result.status)) {
          if(result.status === 422 && result.data.result === 'QUANTITY BELOW ZERO') setBannerError({ error: true, message: belowZeroError });
          if(result.status === 422 && result.data.result === 'STORE NOT FOUND') setBannerError({ error: true, message: storeNotFoundError });
          if(result.status === 400) setBannerError({ error: true, message: invalidSend });
          if(result.status === 404) setBannerError({ error: true, message: invalidSend });
          if(result.status === 409) setBannerError({ error: true, message: conflictError });
        } else if (Number(sendToVo.totalRecords) <= 1) {
          history.push("/consumablesHub");
        } else {
          setSendToVo({ sendVo: true, sending: false });
        }
      }
    } catch (err) {
      console.log(err);
      setBannerError({ error: true, message: exceptionError });
    }
    setProcess(false);
  };

  async function constructTable(tonderData) {

    const tableData = tonderData.map(row => {

      const inputClass = "textInput fullBorder tableInput mar-b0";

      sendToVo.consumables[row.tonerID] = { 
        ...sendToVo.consumables[row.tonerID], 
        row: row };

      const customerContainer = handleSiteStockCustomerClicked(row, history, row.sa_cukey);

      const typeSelectControl = <UILIB.Select 
        disabled={process}
        className="tableInput mar-b0" 
        outerclassname="tableInput mar-b0" 
        value={getSelectedYield(row, selectData.current, sendToVo.consumables[row.tonerID])} 
        data={selectData.current.filter(x => (x.Name && row.tonerType) ? (x.Name.toLowerCase() === row.tonerType.toLowerCase()) : x)} 
        onChange={(ev) => handleChangeGeneric(ev, row.tonerID, 'type')} 
        errored={formErrors.current[`${row.tonerID}-type`]} />

      return {
        quantity: { value: <QuantityContainer data={sendToVo.consumables[row.tonerID]} className={inputClass} disabled={process} onChange={() => handleChangeQuantity()} /> },
        custName: { value: customerContainer, raw: row.customerName },
        serialNumber: { value: row.serialNumber, raw: row.serialNumber },
        machineName: { value: row.machineName, raw: row.machineName },
        avgVolume: { value: row.avgVolume, raw: row.avgVolume },
        tonerTypeCast: { value: consumableChip(row.tonerTypeCast), raw: row.tonerTypeCast },
        type: { value: typeSelectControl, raw: getSelectedYield(row, selectData.current, sendToVo.consumables[row.tonerID]) }
      }
    })
    
    return tableData;
  }

  const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken) => {

    const queryLocal = (query !== null) ? query : '';
    const pagingLocal = (limit !== null && offset !== null) ? `&$limit=${limit}&$offset=${offset}` : '';
    const orderLocal = (orderBy !== null && orderDir !== null) ? `&$order=${orderBy}&$direction=${orderDir}` : '';
    const types = [1,2,3,4,5];

    if(!sendToVo || !sendToVo.tonerIds.length) return;

    entityData.current.toner = await Axios.get(`/entities/tonerOrders/getSummary/sendToVo/?&$filter=tonerID in ${sendToVo.tonerIds} and tonerDespatched ne 9 and deletedAt is null ${queryLocal} ${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(api => api.data);

    if(!entityData.current.toner) return false;

    setYieldData(Array.from(new Set(entityData.current.toner.result.map(x => 
      { return JSON.stringify({ serial: x.serialNumber, volume: x.avgVolume, title: (Number(x.avgVolume) > 5000) ? 'High Yield Machine' : 'Low Yield Machine' }) }))).map(x => { return JSON.parse(x) }));
    
    const serials = Array.from(new Set(entityData.current.toner.result.map(x => x.serialNumber)));
    entityData.current.type = await Axios.get(`/entities/equipments/getSummarySendToVo/?&$filter=SerialNumber in ${serials.join(',')}`, { cancelToken: cancelToken.token }).then(res => res.data.result);

    const customerIds = Array.from(new Set(entityData.current.type.map(x => x.CustomerId)));
    entityData.current.contact = await Axios.get(`/entities/customerPersons/?&$filter=CustomerId in ${customerIds.join(',')} and DeletedDate is null`, { cancelToken: cancelToken.token }).then(res => res.data.result);

    entityData.current.stores = await getAgilicoStores();

    setStoreData(entityData.current.stores.map(x => { return { value: x.Id, label: x.Town } }));
    setContactData(entityData.current.contact.map(x => { return { value: x.Id, label: x.FullName } }));
    selectData.current = [select, ...entityData.current.type.filter(x => types.includes(x.ConsumableTypeId)).map(x => { return { ...x, value: x.PartNumber, label: `${x.Description}(${x.PartNumber}) from ${x.supplierName}` } })];
    selectData.current = Array.from(new Set(selectData.current.map(x => JSON.stringify(x)))).map(x => JSON.parse(x));

    sendToVo.serialNumber = serials;
    sendToVo.consumableRaw = entityData.current.type;
    sendToVo.contact = getPrimaryContact(entityData.current.contact, String(sendToVo.contact), sendToVo);

    entityData.current.toner.result.forEach(x => {
      const typeSelectValue = getSelectedYield(x, selectData.current, sendToVo.consumables[x.tonerID]);
      getEmptyError(formErrors, typeSelectValue, `${x.tonerID}-type`, null, 0); 
    });

    return { tableData: await constructTable(entityData.current.toner.result), raw: entityData.current.toner.result, nonePaged: entityData.current.toner.nonePaged };
  }

  return <Fragment>
    <UILIB.Paper className='width-100'>
      <div className='flex-container wrap'>
        <div className='flex-item flex-grow-1 start align-center wrap consumablesHub button-align-250'><h3 className='mar-b0'>Send To VO</h3></div>
        <div className='flex-item end wrap consumablesHub button-align-250'>
          <UILIB.Button loading={process} disabled={process || zeroValue} name='Process' value='Process' onClick={actionProcessData} />
          <UILIB.Button disabled={process} name='Cancel' value='Cancel' className="red" onClick={() => setSendToVo({ ...sendToVo, sending: false })} />
        </div>
      </div>
      {bannerError.error && <div className="flex-container end width-100">
        <div className="errored message">{bannerError.message}</div>
      </div>}
      <div className='flex-container row mar-t10'>
        {Boolean(yieldData) && yieldData.map(x => { return <UILIB.BannerContainer className={'mar-t2 mar-b2'} background={getYieldData(x).class} title={x.title} content={getYieldData(x).message} /> })}
      </div>
      <UILIB.TableNew
        name='RequestSendVO'
        className='small'
        overflowX='auto'
        overflowY='hidden'    
        header={headerData}
        remoteQuery={getTableData}
        remoteRender={[entityLookup]}
        localQuery={constructTable}
        localRender={[localRender, process]}
        defaultSettings={tablePageDefaults} />
      <div className='flex-container consumablesHub courier-contact-options'>
      <div className='flex-container column start mar-r10 consumablesHub courier-contact-options-width'>
          <h4 className='flex-item start flex-grow-1 mar-t15 mar-b5'>Store Options</h4>
          <UILIB.Select 
            disabled={!Object.keys(entityData.current).length || process}
            className="tableInput mar-b0" 
            outerclassname="tableInput mar-b0" 
            value={(sendToVo?.store?.Id) ? String(sendToVo.store.Id) : -1} 
            data={[select, ...storeData]} 
            onChange={(ev) => handleChangeStore(ev)}
            errored={formErrors.current['store']} />
        </div>
        <div className='flex-container column start mar-r10 consumablesHub courier-contact-options-width'>
          <h4 className='flex-item start flex-grow-1 mar-t15 mar-b5'>Contact Options</h4>
          <UILIB.Select 
            disabled={!Object.keys(entityData.current).length || process}
            className="tableInput mar-b0" 
            outerclassname="tableInput mar-b0" 
            value={(sendToVo?.contact?.Id) ? String(sendToVo.contact.Id) : -1} 
            data={[select, ...contactData]} 
            onChange={(ev) => handleChangeContact(ev)} />
        </div>
        <div className='flex-container column start mar-r10 consumablesHub courier-contact-options-width'>
          <h4 className='flex-item start flex-grow-1 mar-t15 mar-b5'>Courier Options</h4>
          <UILIB.Select 
            disabled={!Object.keys(entityData.current).length || process}
            className="tableInput mar-b0" 
            outerclassname="tableInput mar-b0" 
            value={String(sendToVo.courier)} 
            data={[select, ...getDeliveryType()]}
            onChange={(ev) => handleChangeCourier(ev)}
            errored={formErrors.current['courier']} />
        </div>
        <div className='flex-container column wrap flex-grow-1 align-end end consumablesHub courier-contact-options-pad'>
          <div className='flex-item row nowrap width-100'>
            <UILIB.Checkbox disabled={!Object.keys(entityData.current).length || process} checked={(sendToVo.sendVo)} type='checkbox' onChange={() => handleChangeReduceStock()} />
            <div className="text-small">{stockText}</div>
          </div>
        </div>
      </div>
    </UILIB.Paper>
  </Fragment>
}