import AddAddressDrawer from './drawer/addAddressDrawer';
import AddNewProductDrawer from './drawer/addNewProductDrawer';
import CloneProductDrawer from './drawer/cloneProductDrawer';
import ConfirmCreateOrderDrawer from './drawer/confirmCreateOrderDrawer';
import DeleteAddressDrawer from './drawer/deleteAddressDrawer';
import DeleteEquipmentRemovalDrawer from './drawer/deleteEquipmentRemovalDrawer';
import DeleteProductDrawer from './drawer/deleteProductDrawer';
import DeleteSettlementDrawer from './drawer/deleteSettlementDrawer';
import EditEquipmentRemovalDrawer from './drawer/editEquipmentRemovalDrawer';
import KeyContactSelectDrawer from './drawer/keyContactSelectDrawer';
import OrderCopyDrawer from './drawer/orderCopyDrawer';
import OrderDeclineDrawer from './drawer/orderDeclineDrawer';
import QuoteCopyDrawer from './drawer/quoteCopyDrawer';
import RemoveEquipmentAdditionalDrawer from './drawer/removeEquipmentAdditionalDrawer';
import RemoveEquipmentExistingDrawer from './drawer/removeEquipmentExistingDrawer';
import SettlementAdditionalDrawer from './drawer/settlementAdditionalDrawer';
import SettlementExistingDrawer from './drawer/settlementExistingDrawer';
import SwapQuoteDrawer from './drawer/swapQuoteDrawer';
import FillColumnDrawer from './drawer/fillColumnDrawer';
import ImportPSDrawer from './drawer/importPSDrawer';
import KeyContactsDrawer from './drawer/keyContactsDrawer';
import OrderDeleteDrawer from './drawer/orderDeleteDrawer';


const exported = {
  AddAddressDrawer,
  AddNewProductDrawer,
  CloneProductDrawer,
  ConfirmCreateOrderDrawer,
  DeleteAddressDrawer,
  DeleteEquipmentRemovalDrawer,
  DeleteProductDrawer,
  DeleteSettlementDrawer,
  EditEquipmentRemovalDrawer,
  KeyContactSelectDrawer,
  OrderCopyDrawer,
  OrderDeclineDrawer,
  QuoteCopyDrawer,
  RemoveEquipmentAdditionalDrawer,
  RemoveEquipmentExistingDrawer,
  SettlementAdditionalDrawer,
  SettlementExistingDrawer,
  SwapQuoteDrawer,
  FillColumnDrawer,
  ImportPSDrawer,
  KeyContactsDrawer,
  OrderDeleteDrawer
}

export default exported;