import { useState, useRef, Fragment } from 'react';
import Modules from '../Modules';
import * as functions from './functions';


export default function ProductConfigurationModal(props) {

    const [productsLoading, setProductsLoading] = useState(true);
    const [finishedLoading, setFinishedLoading] = useState(false);
    const [typesLoading, setTypesLoading] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(props.product || []);
    const [manufacturerId, setManufacturerId] = useState(0);
    const [refurbsLoading, setRefurbsLoading] = useState(false);
    const [ranges, setRanges] = useState([]);
    const [refurbs, setRefurbs] = useState([]);
    const [rangesLoading, setRangesLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [compatibles, setCompatibles] = useState([]);
    const [compatiblesLoaded, setCompatiblesLoaded] = useState(false);
    const [rangeId, setRangeId] = useState(0)
    const [saving, setSaving] = useState(false);
    const saveError = useRef({ error: false, message: ''});
    const [partTypes, setPartTypes] = useState([]);
    const [types, setTypes] = useState([]);
    const [pageErrors, setPageErrors] = useState({
        saveAndExit: { error: false, message: <p className="text-red">&#9888; There was an error saving this configuration, please try again.</p> }
    });

    if(props.options === 0) {
        return <Fragment>
                  <div className="body modal productConfiguratorModal">
                    <div className="productConfiguratorModalContent" style={{ width: "90%", height: "90%"}}>
                      <Modules.ProductConfigurator 
                        onFinish={props.onFinish}
                        onCancel={props.onCancel}
                        dealID={props.ourDeal.dealID} 
                        ourDeal={props.ourDeal}
                        setOurDeal={props.setOurDeal}
                        hostConfig={props.hostConfig}
                        dealProductId={props.dealProductId}
                        selectedProduct={selectedProduct}
                        setSelectedProduct={setSelectedProduct}
                        compatibles={compatibles}
                        setCompatibles={setCompatibles}
                        compatiblesLoaded={compatiblesLoaded} 
                        setCompatiblesLoaded={setCompatiblesLoaded}
                        setProductsLoading={setProductsLoading}
                        productsLoading={productsLoading}
                        products={products}
                        setProducts={setProducts}
                        saving={saving}
                        setSaving={setSaving}
                        saveError={saveError}
                        partTypes={partTypes}
                        setPartTypes={setPartTypes}
                        pageErrors={pageErrors}
                        setPageErrors={setPageErrors}
                        types={types}
                        setTypes={setTypes}
                        typesLoading={typesLoading}
                        setTypesLoading={setTypesLoading}
                        manufacturerId={manufacturerId}
                        setManufacturerId={setManufacturerId}
                        refurbsLoading={refurbsLoading}
                        setRefurbsLoading={setRefurbsLoading}
                        refurbs={refurbs}
                        setRefurbs={setRefurbs}
                        ranges={ranges}
                        setRanges={setRanges}
                        rangesLoading={rangesLoading}
                        setRangesLoading={setRangesLoading}
                        rangeId={rangeId}
                        setRangeId={setRangeId}
                        functions={functions}
                        options={props.options}
                        finishedLoading={finishedLoading}
                        setFinishedLoading={setFinishedLoading}
                      />
                    </div>
                  </div>
                </Fragment>
    }
    if(props.options === 1) {
        return <Fragment>
                  <div className="body modal productConfiguratorModal">
                    <div className="productConfiguratorModalContent" style={{ width: "90%", height: "90%"}}>
                      <Modules.ProductSelected 
                        onFinish={props.onFinish}
                        onCancel={props.onCancel}
                        product={props.product}
                        ourDeal={props.ourDeal}
                        setOurDeal={props.setOurDeal}
                        hostConfig={props.hostConfig}
                        portalProductId={props.portalProductId}
                        dealProductId={props.dealProductId}
                        selectedProduct={selectedProduct}
                        setSelectedProduct={setSelectedProduct}
                        compatibles={compatibles}
                        setCompatibles={setCompatibles}
                        compatiblesLoaded={compatiblesLoaded} 
                        setCompatiblesLoaded={setCompatiblesLoaded}
                        setProductsLoading={setProductsLoading}
                        productsLoading={productsLoading}
                        products={products}
                        setProducts={setProducts}
                        saving={saving}
                        setSaving={setSaving}
                        saveError={saveError}
                        partTypes={partTypes}
                        setPartTypes={setPartTypes}
                        pageErrors={pageErrors}
                        setPageErrors={setPageErrors}
                        types={types}
                        setTypes={setTypes}
                        typesLoading={typesLoading}
                        setTypesLoading={setTypesLoading}
                        manufacturerId={manufacturerId}
                        setManufacturerId={setManufacturerId}
                        refurbsLoading={refurbsLoading}
                        setRefurbsLoading={setRefurbsLoading}
                        ranges={ranges}
                        setRanges={setRanges}
                        rangesLoading={rangesLoading}
                        setRangesLoading={setRangesLoading}
                        rangeId={rangeId}
                        setRangeId={setRangeId}
                        functions={functions}
                        options={props.options}
                        finishedLoading={finishedLoading}
                        setFinishedLoading={setFinishedLoading}
                      />
                    </div>
                  </div>
                </Fragment>
    }
}