import Axios from "classes/axios";
import { useCallback } from "react";

export default function useUpdateProductEntity(ourDeal, setOurDeal, setWorking) {

  const handleUpdateProductEntity = useCallback(
    async (product, type, fields, timeout) => {
      return new Promise((resolve, reject) => {
        clearTimeout(product.timerObject[`${product.prodID}-${type}`]);
        const timer = setTimeout(async () => {
          setWorking(true);
          const reduced = fields.reduce((acc, obj) => ({ ...acc, [obj]: product[obj] }), {});
          await Axios.put(`/entities/workflow/deals/actionUpdateProduct/${ourDeal.dealID}/${product.prodID}/${product.isADevice}/${type}`, reduced)
            .then(res => {
              setOurDeal({ 
                ...ourDeal,
                pricing: res.data.result.pricing,
                leasePeriodPayment: res.data.result.lease
              })
              setWorking(false);
              resolve(res.data);
            })
            .catch(error => {
              setWorking(false);
              reject(error.data);
            })
        }, timeout);
        product.timerObject[`${product.prodID}-${type}`] = timer;
      });
    },
    [
      ourDeal, 
      setOurDeal, 
      setWorking
    ]
  );

  return { handleUpdateProductEntity };
}