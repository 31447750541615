import UILIB from 'components';
import { formatCurrency } from 'classes/format';

export default function SingleStatCard(props) 
  {
    return <UILIB.Paper className="width-100">
        <h2>{props.header}</h2>
        {!props.loading && <h1>{formatCurrency(props.stat)}</h1>}
        {props.loading && <UILIB.Loading type={3} />}
      </UILIB.Paper>
  }