import { useEffect, useState, useRef, Fragment } from 'react';
import UILIB from 'components';
import Axios from 'classes/axios';
import { createButton, createSelectInput, createMultiSelectInput } from 'classes/controls/formControls';

const dataMapperCRM = (row) => {
  return { 
    selected: false,
    contactFirstName: (row?.firstname) ? row.firstname : '',
    contactLastName: (row?.lastname) ? row.lastname : '',
    role: (row?.dmc_jobdescription) ? row.dmc_jobdescription : '',
    contactPhone: (row?.telephone1) ? row.telephone1 : '',
    contactMobile: (row?.MobileNumber) ? row.MobileNumber : '',
    contactEmail: (row?.emailaddress1) ? row.emailaddress1 : ''
  }
}

const dataMapperVO = (row) => {
  return { 
    selected: false,
    contactFirstName: (row?.FirstName) ? row.FirstName : '',
    contactLastName: (row?.LastName) ? row.LastName : '',
    role: (row?.Role) ? row.Role : '',
    contactPhone: (row?.PhoneNumber) ? row.PhoneNumber : '',
    contactMobile: (row?.MobileNumber) ? row.MobileNumber : '',
    contactEmail: (row?.EmailAddress) ? row.EmailAddress : ''
  }
}

export default function KeyContactSelectDrawer({ onFinished, contact, ourDeal, setOurDeal, isActive }) {

  const headerData = [
    { label: "", value: "selected", align: "left", type: 'number', width: "2%" },
    { label: "First Name", value: "contactFirstName", type: 'string', filtering: true, minWidth: 40, maxWidth: 40 },
    { label: "Last Name", value: "contactLastName", type: 'string', filtering: true, minWidth: 40, maxWidth: 40 },
    { label: "Role", value: "role", type: 'string', filtering: true, minWidth: 40, maxWidth: 40 },
    { label: "Phone Number", value: "contactPhone", type: 'string', filtering: true, minWidth: 40, maxWidth: 40 },
    { label: "Mobile Number", value: "contactMobile", type: 'string', filtering: true, minWidth: 100, maxWidth: 100 },
    { label: "Email Address", value: "contactEmail", type: 'string', filtering: true, minWidth: 120, maxWidth: 120 }];


  const tableRaw = useRef([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const defaultError = { error: false, message: '' };
  const [bannerError, setBannerError] = useState(defaultError);
  const dataSource = [{ label: 'VO', value: 1 }, { label: 'CRM', value: 2 }];
  const [selectSource, setSelectedSource] = useState(1);
  const selectData = ourDeal.keyContact.map(x => { return { label: x.contactTypeTxt, value: Number(x.contactTypeId) }});
  const [selectedValues, setSelectedValues] = useState([contact.contactTypeId]);
  const [selectedContact, setSelectedContact] = useState({});
  const [localRender, setLocalRender] = useState(false);
  const exceptions = [400,404];

  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while processing the record(s), please reload or contact support';


  const handleCatagoriesChanged = (event) => {
    setSelectedValues(event.target.value);
    setLocalRender(!localRender);
  };

  const handleSourceChanged = async (event) => {
    setSelectedSource(Number(event.target.value));
    await getContacts(Number(event.target.value));
  };

  const handleTableSelceted = (data, contact) => {
    data.filter(x => JSON.stringify(x) !== JSON.stringify(contact)).forEach(x => x.selected = false);
    contact.selected = !contact.selected;
    setSelectedContact(contact);
    setLocalRender(!localRender);
  };

  const actionsubmitForm = async () => {
    setSaving(true);
    try {
      const filtered = ourDeal.keyContact.filter(x => selectedValues.includes(Number(x.contactTypeId)));
      const mapped = filtered.map(x => { return { ...x, ...selectedContact } });
      const result = await Axios.put(`/entities/workflow/deals/actionUpdateKeyContacts`, mapped); 
      if(exceptions.includes(result.status)) {
        if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
        if(result.status === 404) setBannerError({ error: true, message: notFoundError });
      } else {
        const excluded = ourDeal.keyContact.filter(x => !selectedValues.includes(Number(x.contactTypeId)));
        ourDeal.keyContact = [...result.data.result, ...excluded].sort(function(a, b){
          if(Number(a.contactTypeId) < Number(b.contactTypeId)) { return -1; }
          if(Number(a.contactTypeId) > Number(b.contactTypeId)) { return 1; }
          return 0; });
        setOurDeal(ourDeal);
        setSaving(false);
        onFinished();
      }
    } catch (error) {
      console.log(error);
      setBannerError({ error: true, message: exceptionError });
      setSaving(false);
    }
  };

  async function getContacts(source) {
    try {
      setLoading(true);
      if (source === 1 && ourDeal.accountNo) {
        const voResult = await Axios.get(`/sales/customers/${ourDeal.customerID}/contacts`);
        tableRaw.current = voResult.data.map(row => { return dataMapperVO(row) });
      } else {
        const crmResult = await Axios.get(`/dynamics/contacts/${ourDeal.crm.primaryAccount.accountid}`);
        const contactids = crmResult.data.map(user => user.contactid);
        const filtered = crmResult.data.filter(({ contactid }, index) => !contactids.includes(contactid, index + 1));
        tableRaw.current = filtered.map(row => { return dataMapperCRM(row) });
      }
      setLoading(false);
    }
    catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const asyncEffect = async () => {
      await getContacts(1);
    }
    asyncEffect();
  }, []);

  useEffect(() => {
    setTableData(tableRaw.current.map(contact => {
      return {
        selected: { value: <UILIB.Checkbox onChange={() => handleTableSelceted(tableRaw.current, contact)} disabled={(!isActive || saving)} checked={contact.selected} />, raw: 0 },
        role: { value: contact.role, raw: contact.role },
        contactFirstName: { value: contact.contactFirstName,  raw: contact.contactFirstName },
        contactLastName: { value: contact.contactLastName,  raw: contact.contactLastName },
        contactPhone: { value: contact.contactPhone, raw: contact.contactPhone },
        contactMobile: { value: contact.contactMobile, raw: contact.contactMobile },
        contactEmail: { value: contact.contactEmail, raw: contact.contactEmail }
      }
    }))
  }, [tableRaw.current, localRender]);


  return <Fragment>
    <div className='flex-container row mar-b10'>
      <div className="flex-item start align-center flex-grow-1 text-16 font-weight-bold width-100 mar-b10">Assign Key Contacts</div>
    </div>
    <div className='flex-container row start wrap evenly width-100 mar-b20'>
      <div className="flex-container row width-100">
        {createMultiSelectInput('Select Contacts', 'selectContacts', (!isActive || saving), selectedValues, selectData, 'All Contacts', (ev) => handleCatagoriesChanged(ev), null, null, null, null, 'border-colour-grey-4')}
        {createSelectInput('Select Data Source', 'selectDataSource', (!isActive || saving), selectSource, dataSource, async (ev) => await handleSourceChanged(ev), null, 'width-50', null, null, 'fullBorder border-colour-grey-4')}
        {createButton('', 'Apply Changes', 'Save Changes', (!isActive || saving || !tableRaw.current.some(x => x.selected)), saving, async () => await actionsubmitForm(), null, 'flex-item width-20')}
      </div>
      {bannerError.error && <div className="flex-item end wrap width-100">
        <div className="errored message">{bannerError.message}</div>
      </div>}
    </div>
    <div className="mar-l5 mar-r5">
      <UILIB.Table
        className="small"
        height="100%"
        overflowX="auto"
        overflowY="hidden"
        width={760}
        loading={loading}
        header={headerData}
        data={tableData}
        sortable={true}
        filter={false}
        maxRows={15}
        autoResize={false}
        defaultOrderBy="Name"
        defaultOrderDir="ASC" />
    </div>
  </Fragment >
}