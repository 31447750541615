import { useEffect, useState, Fragment } from 'react';
import UILIB from 'components';


export default function SelectAll(props) {
  
    const [ticked, setTicked] = useState(false);

    const updateLocalField = (value) => {

      setTicked(value);

      const products = props.ourDeal.product.map(x => { return { ...x, ticked: value }});

      if(value) {
        props.setProductTicked(products);
      } else {
        props.setProductTicked([]);
      }

      props.setOurDeal({ 
        ...props.ourDeal, 
        product: products
      });

    }

    useEffect(() => {
      if(props.ourDeal.product.length) {
        setTicked(props.ourDeal.product.every(x => x.ticked));
      }
    }, [props.productTicked, props.ourDeal.product]);

    return <Fragment>       
        <div className="flex-container row">    
          <div className="flex-item flex-grow-1 align-center mar-l2">
            <UILIB.Checkbox
                disabled={!props.isActive || props.working}
                name="ticked"
                checked={ticked}
                onChange={() => updateLocalField(!ticked)} 
            />
          </div>
        </div>
      </Fragment>

}