import { Fragment } from 'react';
import UILIB from 'components';
import { statCard } from 'classes/statsCard';


export default function DealHeader({ ourDeal, processData }) {

  const statContainerClass = 'cardGeneric';
  const statChildClass = { top: 'cardGeneric cardChild', bottom: 'cardGeneric cardChild pointer-events-all text-16' };

  const dealType = (Number(ourDeal.leaseorcash) === -1 || !ourDeal.leaseorcash) 
    ? 'Unknown' 
    : ourDeal.leaseorcash;

  const crmStatus = (!ourDeal?.crm?.opportunities?.stepname) 
    ? 'Unknown' 
    : ourDeal?.crm?.opportunities?.stepname;

  const oppertunityLink = (ourDeal?.links?.dynamics_base_url && ourDeal?.dynamicsOpportunityNumber) 
    ? <UILIB.Link 
        href={`${ourDeal?.links?.dynamics_base_url}main.aspx?app=d365default&forceUCI=1&pagetype=entityrecord&etn=opportunity&id=${ourDeal?.dynamicsOpportunityGuid}`} 
        rel="noreferrer" 
        target="_blank">
        <div className="flex-item center width-100">{ourDeal?.dynamicsOpportunityNumber}</div>
      </UILIB.Link>
    : ourDeal?.dynamicsOpportunityNumber

  return <Fragment>
    <div className="flex-container row center width-100 mar-5">
      <div className="flex-container row center align-center width-100">
        {statCard(false, false, false, 'Deal Number', ourDeal.finalDealNo, statContainerClass, statChildClass)}
        {statCard(false, false, false, 'Opportunity Number', oppertunityLink, statContainerClass, statChildClass)}
        {statCard(false, false, false, 'Deal Status', processData.current.procStage, statContainerClass, statChildClass)}
        {statCard(false, false, false, 'CRM Status', crmStatus, statContainerClass, statChildClass)}
        {statCard(false, false, false, 'Deal Type', dealType, statContainerClass, statChildClass)}
      </div>
    </div>
  </Fragment>
}