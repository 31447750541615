import PatchCRM from './modules/patchCRM';
import DealHeader from './modules/dealHeader';
import AddRefinanced from './modules/addRefinanced';
import ViewMfiles from './modules/viewMfiles';
import OrderSubmitModal from './modules/orderSubmitModal';
import AddAdditionalQuote from './modules/addAdditionalQuote';
import OrderApproveModal from './modules/orderApproveModal';
import AddSettlementLine from './modules/addSettlementLine';
import OrderArchiveModal from './modules/orderArchiveModal';
import ExportProducts from './modules/exportProducts';
import AddressLookup from './modules/addressLookup';
import SubmitDeal from './modules/submitDeal';
import CopyOrder from './modules/copyOrder';
import CopyQuote from './modules/copyQuote';
import DownloadQuoteSheet from './modules/downloadQuoteSheet';
import DownloadOrderSheet from './modules/downloadOrderSheet';
import CreateOrder from './modules/createOrder';
import SwapQuote from './modules/swapQuote';
import DeleteOrder from './modules/deleteOrder';
import DeclineOrder from './modules/declineOrder';
import ProductConfigurationModal from './configurator/ProductConfigurationModal';
import ProductConfigurator from './configurator/ProductConfigurator';
import ProductSelected from './configurator/ProductSelected';
import SingleStatCard from './modules/singleStatCard';
import PricingStats from './modules/pricingStats';
import ExpandPricingStats from './modules/expandPricing';


const exported = {
  PatchCRM,
  DealHeader,
  AddRefinanced,
  ViewMfiles,
  OrderSubmitModal,
  AddAdditionalQuote,
  OrderApproveModal,
  AddSettlementLine,
  OrderArchiveModal,
  ExportProducts,
  AddressLookup,
  SubmitDeal,
  CopyOrder,
  CopyQuote,
  DownloadQuoteSheet,
  DownloadOrderSheet,
  CreateOrder,
  SwapQuote,
  DeleteOrder,
  DeclineOrder,
  ProductConfigurationModal,
  ProductConfigurator,
  ProductSelected,
  SingleStatCard,
  PricingStats,
  ExpandPricingStats
}

export default exported;