import { useCallback } from "react";
import useFindItem from '../hooks/useFindItem';

export default function useFindNestedItem(items) {

  const { handleFindItem } = useFindItem(items);

  const handleFindNestedItem = useCallback(
    (id) => {
      const nested = items.reduce((acc, obj) => acc.concat(obj?.children), []);
      const item = nested.find(x => Number(x?.id) === Number(id));
      const parent = handleFindItem(item?.raw?.parentId);
      return {
        item,
        index: parent?.item?.children?.indexOf(item),
      };
    },
    [items, handleFindItem]
  );

  return { handleFindNestedItem };
}